export default {
    options: {
		path: '/',
		domain: window.location.host,
		duration: false,
		secure: true,
		document: document,
		encode: true
  },
  getAuthDomain(){
    let domain = false;
    if(window.location.hostname !== 'localhost'){
        var hostSplit = window.location.hostname.split(".");
        domain = "searchmysocial.media";
        if(hostSplit.length === 3){
            hostSplit.splice(0, 1);
            domain = hostSplit.join(".");
        }else if(hostSplit.length === 4){
            hostSplit.splice(0, 2);
            domain = hostSplit.join(".");
        }else{
            domain = window.location.hostname;
        }
    }
    return domain;
  },
  write(key,value){
		if (this.options.encode) value = encodeURIComponent(value);
        //if (this.options.domain) value += '; domain=' + this.options.domain;
        if(window.location.hostname !== 'localhost'){
            const domain = this.getAuthDomain();
            if(domain){
                this.options.domain = "."+domain;
            }
        }
        if (this.options.domain) value += '; domain=' + this.options.domain;
		if (this.options.path) value += '; path=' + this.options.path;
		if (this.options.duration){
			var date = new Date();
			date.setTime(date.getTime() + this.options.duration * 24 * 60 * 60 * 1000);
			value += '; expires=' + date.toGMTString();
		}
        if (this.options.secure) value += '; secure';
		this.options.document.cookie = key + '=' + value;
		return this;
    },
    read(key){
		var value = this.options.document.cookie.match('(?:^|;)\\s*' + key + '=([^;]*)');
		return (value) ? decodeURIComponent(value[1]) : null;
	},

	dispose(key){
        this.options.duration = -1;
        this.write(key,'');
        this.options.duration = false;
		return this;
	},
	getAll(){
		var cookies = { };
    	if (document.cookie && document.cookie !== '') {
			var split = document.cookie.split(';');
			for (var i = 0; i < split.length; i++) {
				var name_value = split[i].split("=");
				name_value[0] = name_value[0].replace(/^ /, '');
				if(name_value[1].trim().length > 0){
					cookies[decodeURIComponent(name_value[0])] = decodeURIComponent(name_value[1]);
				}				
			}
		}
		return cookies;
	},
	getCookieData(){
		let data = {
			"identifiers": {
				"searchmysocial": {
					'cookies': this.getAll(),
					'userId': "unknown"
				},
				'ip': {
					'v4_ip': 'unknown',
					'v6_ip': 'unknown',
				},
				'userAgent': navigator.userAgent
			}
		};
		return encodeURIComponent(JSON.stringify(data));
    },
    getAdsData(){
        const data = {};
        const gclid = this.read("ad_gclid");
        if(gclid && gclid.length > 0 && gclid !== "null"){
            data['ad_gclid'] = gclid;
        }
        const fbclid = this.read("ad_fbclid");
        if(fbclid && fbclid.length > 0 && fbclid !== "null"){
            data['ad_fbclid'] = fbclid;
        }
        return data;
    }
}