import React from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "./CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import AuthApi from '../../assets/js/utils/Auth';
import Cookie from '../../assets/js/utils/Cookie';

import manageSessionStyle from "../../assets/jss/manageSessionStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

class ManageSession extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        this.sessionTimeout = null;
        this.tokenExpireTimeout = null;
        this.state = {
            open: false
        };
    }
    componentDidMount(){
        this.setTimout();
    }
    setTimout(){
        const { authorized } = this.store.getState();
        if(!authorized){
            return;
        }
        const expireTime = (new Date(parseInt(Cookie.read("expires_in"))) - new Date());
        if(expireTime <= 0){
            return;
        }
        const beforeTime = 2*60*100; //2 minutes before expiring
        const timeoutTime = expireTime - beforeTime;
        if(timeoutTime <= 0){
            this.setState({open: true});
            return;
        }
        this.sessionTimeout = setTimeout(() => {
            this.setState({open: true});
        }, timeoutTime);

        this.tokenExpireTimeout = setTimeout(() => {
            this.logout();
        }, expireTime);
    }
    componentWillUnmount(){
        if(this.sessionTimeout !== null){
            clearTimeout(this.sessionTimeout);
        }
        if(this.tokenExpireTimeout !== null){
            clearTimeout(this.tokenExpireTimeout);
        }
    }
    onClose(){
        this.setState({open: false});
    }
    logout(){
        this.onClose();
        clearTimeout(this.tokenExpireTimeout);
        this.history.push("/logout");
    }
    continue(){
        this.onClose();
        this.refreshTokens();
        clearTimeout(this.tokenExpireTimeout);
    }
    refreshTokens(){
        const that = this;
        AuthApi.refreshUserTokens().then((isValidToken) => {
            if(isValidToken !== false){
                AuthApi.idleRefreshToken();
                that.setTimout();
            }
        });
    }
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.state.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.onClose()}
                aria-labelledby="manageSession-slide-title"
                aria-describedby="manageSession-slide-description"
            >   
            <DialogContent
                id="manageSession-slide-description"
                className={classes.modalBody}
            >
                <p>Your session is about to expire.  Do you wish to continue?</p>
            </DialogContent>
            <DialogActions
            className={classes.modalFooter + " " + classes.justifyContentCenter}
            >
                <Button onClick={() => this.logout()} color="white">Log Out</Button>
                <Button onClick={() => this.continue()} color="success">Continue</Button>
            </DialogActions>
        </Dialog>
        );
    }
}

ManageSession.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(manageSessionStyle)(ManageSession);