import React from 'react';
// import { Link as RouterLink } from "react-router-dom";
// import Container from '@material-ui/core/Container';
// import Link from '@material-ui/core/Link';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    nav: {
        marginTop: theme.spacing(1),
    },
}));

export default function Footer(props) {
    const classes = useStyles();
    return (
        <footer>
            <nav className={classes.nav}>            
                
            </nav>
        </footer>
    )
}
