import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "../CustomButtons/Button.jsx";
import UserAbout from "./About";
import Link from "@material-ui/core/Link";
import Config from '../../../../Config';

// @material-ui/icons
import Close from "@material-ui/icons/Close";

import aboutModalStyle from "../../../assets/jss/user/aboutModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const AboutModal = class extends React.Component {
    isInfoFilled(){
        const { user } = this.props;
        if((user.hasOwnProperty("bio") && user.bio.trim().length > 0) || (user.hasOwnProperty("socialIcons") && user.socialIcons.hasOwnProperty("blogUrl") && user.socialIcons.blogUrl.trim().length > 0)
            || (user.hasOwnProperty("topSearchTerms") && user.topSearchTerms.trim().length > 0)){
            return true;
        }
        return false;
    }
    render() {
        const { classes, user } = this.props;
        const { theme } = user.guestPreferences;
        const adminAppUrl = Config.getAdminUrl();
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot+" "+classes.modalRootCustom+" "+(theme === "dark" ? classes.darkModalRoot : ""),
                    paper: classes.modal+" "+classes.modalCustom,
                    scrollPaper: classes.modalScrollPaper
                }}
                open={this.props.open}
                onClose={() => this.props.onClose()}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="about-modal-slide-title"
                aria-describedby="about-modal-slide-description"
            >
                <DialogContent
                    id="about-modal-slide-description"
                    className={classes.modalBody}
                    >
                    <DialogTitle
                            id="about-slide-title"
                            disableTypography
                            className={classes.modalHeader+" "+classes.modalHeaderCustom}
                            >
                                <Button
                                    simple
                                    className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                                    key="close"
                                    aria-label="Close"
                                    onClick={() => this.props.onClose()}
                                >
                                    {" "}
                                    <Close className={classes.modalClose} />
                                </Button>
                    </DialogTitle>
                    <UserAbout store={this.props.store} user={this.props.user} primaryColor={this.props.primaryColor} onSearchTerm={this.props.onSearchTerm} />
                    {
                        this.isInfoFilled() === false ?
                            <div className={classes.editInfo}>
                                <Link href={adminAppUrl+"/dashboard/editmode/search/about"} underline="always">Edit this info</Link>
                            </div>
                        :
                        <></>
                    }
                </DialogContent>
            </Dialog>
        )
    }
}

AboutModal.defaultProps = {
    open: false,
}
AboutModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
}
export default withStyles(aboutModalStyle)(AboutModal);