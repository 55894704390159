import React from 'react';
import PropTypes from "prop-types";
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";

import loaderStyle from "../../assets/jss/loaderStyle";

class Loader extends React.Component {
    render(){
        const { align, classes, saving } = this.props;
        let color = this.props.color;
        if(typeof(color) === "string" && color.indexOf("#") !== -1){
            color = "custom";
        }
        const loaderClasses = classNames({
            [classes[color]]: color,
            [classes.saving]: saving
        });
        return (
            <div className={"sd_loader align_"+align+" "+loaderClasses}>
                <CircularProgress size={30} color="inherit"/>
            </div>
        )
    }
}

Loader.defaultProps = {
    align: "center",
    color: "primary",
    saving: false
}
Loader.propTypes = {
    align: PropTypes.string,
    // color: PropTypes.oneOf([
    //     "primary",
    //     "secondary",
    //     "info",
    //     "success",
    //     "rose",
    //     "gray",
    //     "customInfo",
    //     "customSuccess",
    //     "customRose",
    //     "white",
    // ]),
    classes: PropTypes.object,
    saving: PropTypes.bool
};

export default withStyles(loaderStyle)(Loader);