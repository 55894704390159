import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../Grid/GridContainer.jsx";
import GridItem from "../Grid/GridItem.jsx";
import Config from '../../../../Config';

import youtubeVideoStyle from "../../../assets/jss/material-kit-pro-react/views/Smearch/youtubeVideoStyle.jsx";

class YoutubeVideoComponent extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            subDomain: this.props.subDomain,
            subDomainUrl: this.props.subDomainUrl,   
        };
    }
    createVideo(){
        var videoSrc = 'https://www.youtube.com/embed/9nr9_8GCsDI?autoplay=1&controls=1&amp&amp;showinfo=0&showtitle=0&amp;modestbranding=1&autohide=1';
        var videoHolderOne = document.querySelector(".video-holder");
        var videoHolder = '';
        videoHolder += '<iframe width="100%" height="100%" src="'+ videoSrc +'" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope;"></iframe>';
        videoHolderOne.innerHTML = videoHolder;
    }
    render() {
    const { classes } = this.props;
    const cdnUrl = Config.getCdnUrl();
    const videopreview = cdnUrl+"/www/videopreview.png";
    return (
        <div className={classes.container}>
            <div className={classes.cardSection}>
                <GridContainer>
                    <GridItem xs={12} md={6}
                    className={
                        classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
                    }
                    >
                        <h2 className={classes.title}>About Our Product </h2>
                    </GridItem>
                </GridContainer>
                <GridContainer className={classes.topHolder}>
                    <GridItem lg={6} sm={12} md={12} className={classes.leftSide}>
                        <div className={classes.videoContainer + " video-holder"}  onClick={this.createVideo}>
                            <img width="100%;" height="100%;" src={videopreview} alt="" />
                        </div>
                    </GridItem>
                    <GridItem lg={6} sm={12} md={12} className={classes.rightSide}>
                        <div className={classes.titleHolder}>
                            <h4>Watch Our Product Video On YouTube!</h4>
                        </div>
                        <div className={classes.descriptionHolder}>
                            <p>Search My Social will forever change the way you search for, find, and share your online content. No more scrolling for days. No more waiting for a DM reply. No more lost content.</p>
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
    }
}

YoutubeVideoComponent.propTypes = {
  classes: PropTypes.object
};

export default withStyles(youtubeVideoStyle)(YoutubeVideoComponent);
