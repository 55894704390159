import React from 'react';
import { helper } from "../../assets/js/utils/Element";
import Api from '../../assets/js/utils/Api';
import callToActionStyle from '../../assets/jss/callToActionStyle.jsx';
import Cookie from '../../assets/js/utils/Cookie';
import SignupCodeModal from "../components/Auth/SignupCodeModal"
import Slide from "@material-ui/core/Slide";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../components/Grid/GridContainer.jsx";
import { matchPath } from 'react-router';

const CallToAction = class extends React.Component {
    constructor(props) {
        super(props);
        
        this.history = this.props.history;
        this.store = this.props.store;

        this.state = {
            hideCallToAction: this.isCallToActionHidden(),
            showCallToAction: false,
            signupCodeModal: false,
            user: this.getUser(),
        };

        this.hidePopup = this.hidePopup.bind(this);
        this.showSignup = this.showSignup.bind(this);
        this.onSignupCodeDialogClose = this.onSignupCodeDialogClose.bind(this);
    }
    componentWillReceiveProps(){
        this.setState({
            user: this.getUser()
        });
    }
    componentDidMount(){
        const that = this;
        if(process.env.REACT_APP_ACTION_POPUP === true || process.env.REACT_APP_ACTION_POPUP === "true"){
            setTimeout(() =>{
                this.setState({
                    showCallToAction: true,
                });
            }, 3000);
            setTimeout(() =>{
                Cookie.options.duration = 1/24;
                Cookie.write("hide_call_to_action", 1);
                Cookie.options.duration = false;
                this.setState({
                    showCallToAction: false,
                });


                //Remove completely when animation is complete
                setTimeout(function(){
                    that.setState({
                        hideCallToAction: true,
                    });
                },500);
            }, 6500);
        }
    }
    getUser(){
        const { user, authorized, searchedUser, url } = this.store.getState();
        let searchUser = null;
        let searchMatch = matchPath(url, {
            path: "/:username",
        });
        if(authorized && searchMatch !== null && searchMatch.params.hasOwnProperty("username") && searchMatch.params.username.toLowerCase() === user.username.toLowerCase()){
            searchUser = user;
        }
        
        if(searchedUser !== false && searchMatch !== null && searchMatch.params.hasOwnProperty("username")){
            searchUser = null;
            if(searchedUser !== null && searchMatch.params.username.toLowerCase() === searchedUser.username.toLowerCase()){
                searchUser = searchedUser;
            }
        }
        return searchUser;
    }
    isCallToActionHidden() {
        let hideCallToAction = Cookie.read("hide_call_to_action");
        if (hideCallToAction !== null) {
            return true;
        }
        return false;
    }
    hidePopup(){
        Cookie.options.duration = 1/24;
        Cookie.write("hide_call_to_action", 1);
        Cookie.options.duration = false;
        this.setState({
            showCallToAction: false,
            signupCodeModal: false,
            hideCallToAction: true,
        });

        this.showSignup();
    }
    onSignupCodeDialogClose(){
        this.setState({signupCodeModal: false, hideCallToAction: true});
    }
    showSignup(){
        // this.setState({signupCodeModal: false});
        const { user } = this.state;
        let url = "/signup";
        if(user !== null){
            url += "?refType=cta&refUId="+user.userId;

            var requestData = [];
            requestData.push({
                'activityType': "cta_clicked",
                'data': {
                    'original_url': window.location.href,
                    'destination_url': (window.location.protocol+"//"+window.location.host+url)
                },
                relatedId: user.userId
            });
            Api.sendActivity(requestData);
        }
        this.history.push(url);
    }
    render() {
        const { hideCallToAction, showCallToAction, signupCodeModal } = this.state;
        const { classes } = this.props;
        if(signupCodeModal){
            return <SignupCodeModal showSignup={this.showSignup} onClose={this.onSignupCodeDialogClose} open={signupCodeModal} history={this.history}/>;
        }
        if (hideCallToAction || showCallToAction === false) {
            return <></>
        }
        return (
            <div className={classes.container+" "+(helper.isIOS()?classes.positionTop:"")} >
                <Slide  direction={(helper.isIOS()?"down":"up")} in={showCallToAction} timeout={{enter: 500, exit: 500}}>
                    <div className={classes.callToAction}>
                        <GridContainer className={classes.containerRow} >
                            <p className={classes.whiteColor}>
                                Make your Instagram searchable in less than 3 minutes. <span to={''} onClick={this.hidePopup} className={classes.anchorTag}>Click Here</span>
                            </p>
                        </GridContainer>
                    </div>
                </Slide>
            </div>
        );
    }
}
export default withStyles(callToActionStyle)(CallToAction);