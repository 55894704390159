
const googleRecaptchaV2Style = theme => ({
    formControl: {
        marginBottom: "17px",
        marginTop: "17px"
    },
    loading: {
        "& .g-recaptcha": {
            display: "none"
        }
    }
});

export default googleRecaptchaV2Style;
