import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../Grid/GridContainer.jsx";
import GridItem from "../Grid/GridItem.jsx";
import Card from "../Card/Card.jsx";
import CardBody from "../Card/CardBody.jsx";
import CardFooter from "../Card/CardFooter.jsx";
// import Check from "@material-ui/icons/Check";
// import Close from "@material-ui/icons/Close";
// import Success from "../Typography/Success.jsx";
// import Danger from "../Typography/Danger.jsx";
import Api from '../../../assets/js/utils/Api';
import Button from "../../components/CustomButtons/Button.jsx";
import Config from '../../../../Config';
import Link from "@material-ui/core/Link";

import pricingStyle from "../../../assets/jss/material-kit-pro-react/views/Smearch/pricingStyle.jsx";

const SectionPricing = class extends React.Component {
    constructor(props) {
        super(props);

        this.state ={
            packages: null
        }
        this.store = this.props.store;
    }
    componentDidMount(){
        this.getPackages();
    }
    getPackages(){
        Api.getSettings().then(data => {
            const packages = data.en.packages;
            this.setState({
                packages: packages
            })
        });
    }
    render() {
        const { classes, ...rest } = this.props;
        const adminAppUrl = Config.getAdminUrl();
        const { packages } = this.state;
        return (
            <div className="cd-section" {...rest}>
            <div className={classes.pricing}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem
                            xs={12}
                            sm={6}
                            md={6}
                            className={
                                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
                            }
                        >
                            <h2 className={classes.title}>Pick the best plan for you</h2>
                            <h5 className={classes.description}>
                                You have Free Unlimited Updates and Pro Support on each package.
                            </h5>
                        </GridItem>
                        <GridItem className={classes.buttonSection}>
                            <RouterLink to="/feature-comparison">
                                <Button size={"lg"} className={"button"}>See All Features and Compare Packages</Button>
                            </RouterLink>
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.featuresContainer}>
                        { 
                            packages ?
                                packages.map((item) => {
                                    if(item.hasOwnProperty("legacy") && item.legacy){
                                        return null
                                    }
                                    return(
                                        <GridItem xs={12} sm={6} md={6} key={item.level} className={classes.packageCard +" "+(item.level === 1 ? classes.purpleCard : classes.whiteCard)}>
                                            <Card pricing plain>
                                                <CardBody pricing plain>
                                                    {
                                                        item.level === 1 ? 
                                                            <p className="package-des">Recommended for Influencers</p>
                                                        : item.level === 2 ?
                                                            <p className="package-des">Recommended for Brands and Public Figures</p>
                                                        :
                                                            <p className="package-des"></p>
                                                    }
                                                    <h6 className={classes.cardCategory}>
                                                        {
                                                            item.level === 1 ?
                                                                "Pro"
                                                            :
                                                            item.name
                                                        }
                                                    </h6>
                                                    <h1 className={classes.cardTitle+" package-price"}>
                                                        ${item.pricing.monthlyAmount} <small>/mo</small>
                                                    </h1>
                                                    <ul className={classes.packageFeatures}>
                                                        {
                                                            item.features.map((feature) => {
                                                                return <li className={classes.featureName} key={feature.name}>{feature.name}</li>
                                                            })
                                                        }
                                                    </ul>
                                                </CardBody>
                                                <CardFooter
                                                    pricing
                                                    className={classes.justifyContentCenter}
                                                >
                                                    {
                                                        item.level === 0 ?
                                                            <Link underline="none" href={adminAppUrl+"/signup?"+window.location.search.substr(1)+"&refType=smearch_site_sms&refLink=cta_free"}>
                                                                <Button size={"sm"} color={"primary"}>Click to Signup</Button>
                                                            </Link>
                                                        : item.level === 1 ?
                                                            <Link underline="none" href={adminAppUrl+"/signup?"+window.location.search.substr(1)+"&refType=smearch_site_sms&refLink=cta_premium"}>
                                                                <Button size={"sm"} color={"white"}>Click to Signup</Button>
                                                            </Link>
                                                        :
                                                            <Link underline="none" href={adminAppUrl+"/signup?"+window.location.search.substr(1)+"&refType=smearch_site_sms&refLink=cta_premiumplus"}>
                                                                <Button size={"sm"} color={"primary"}>Click to Signup</Button>
                                                            </Link>
                                                    }
                                                </CardFooter>
                                            </Card>
                                        </GridItem>
                                    )
                                })
                            :
                                <></>
                        }
                        
                    </GridContainer>
                    <GridContainer>
                        {
                            packages ?
                                <div className={classes.featureComparison}>
                                    <RouterLink to="/feature-comparison">See All Features and Compare Packages</RouterLink>
                                </div>
                            :
                            <></>
                        }
                    </GridContainer>
                </div>
            </div>
            {/* Pricing 4 END */}
            </div>
        );
    }
}

SectionPricing.propTypes = {
  classes: PropTypes.object
};

export default withStyles(pricingStyle)(SectionPricing);
