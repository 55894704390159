import React from 'react';
// import LoaderComponent from '../../components/Loader'
import {Helmet} from 'react-helmet';
import axios from 'axios';
import Config from '../../../../Config';
import Api from '../../../assets/js/utils/Api';
import { helper } from "../../../assets/js/utils/Element";
import AuthApi from '../../../assets/js/utils/Auth';
import SearchForm from "../../components/Search/Form";
import UserAbout from "../../components/Search/About";
import SplashScreen from '../../components/SplashScreen';
import { createBrowserHistory } from 'history';

// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from '@material-ui/core/styles/withTheme';
// core components
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import CoverPhoto from "../../components/CoverPhoto/CoverPhoto.jsx";
import Clearfix from "../../components/Clearfix/Clearfix.jsx";
import Warning from "../../components/Typography/Warning.jsx";
// import Button from "../../components/CustomButtons/Button.jsx";
import Link from "@material-ui/core/Link";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
// import EditIcon from "@material-ui/icons/Edit";
import AboutModal from "../../components/Search/AboutModal";

import christian from "../../../assets/img/default-avatar.png";
import coverPhoto from "../../../assets/img/examples/city.jpg";

import searchPageStyle from "../../../assets/jss/user/searchPageStyle.js";
import "../../../assets/scss/plugins/_plugin-react-slick.scss";
const history = createBrowserHistory();

class UserSearch extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        const { user, authorized, searchedUser } = this.store.getState();
        this.primaryColor = null;
        let searchUser = null;
        let contentLoaded = false;
        const { username } = this.props.match.params;
        if(authorized && user.username.toLowerCase() === username.toLowerCase()){
            searchUser = user;
            contentLoaded = true;
        }
        if(searchedUser !== false){
            searchUser = null;
            contentLoaded = false;
            if(searchedUser !== null && searchedUser.username.toLowerCase() === username.toLowerCase()){
                searchUser = searchedUser;
                contentLoaded = true;
            }
        }
        
        this.state = {
            user: searchUser,
            cancelToken: null,
            contentLoaded: contentLoaded,
            isSearchPage: this.props.slide === "0" ?true: false,
            snackbarOpen: false,
            searchTerm: null,
            activityCancelToken: null,
            aboutModal: (this.props.slide !== "0")
        };
        this.sliderInstance = null;
        this.activitySent = false;
        this.onSearchPopupClosed = this.onSearchPopupClosed.bind(this);
        this.onCopy = this.onCopy.bind(this);
        this.closeSnackbar = this.closeSnackbar.bind(this);
        this.onSearchTerm = this.onSearchTerm.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;

        if(this.state.user === null){
            if(this.state.contentLoaded === false){
                this.loadUserInfo();
            }
        }else{
            this.listenHistory(this.state.user);
            if(this.activitySent === false){
                this.activitySent = true;
                this.sendActivity(this.state.user);
            }
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
        if (this.state.activityCancelToken) {
            this.state.activityCancelToken.cancel('Request Cancelled')
        }
    }
    loadUserInfo(){
        let { username } = this.props.match.params;
        if(username){
            username = username.toLowerCase();
        }
        const ApiUrl = Config.getApiUrl()+"data/user/username/"+username+"?buttons=true&contentPlatforms=true";
        let headers = Config.getApiHeaders();
        const source = axios.CancelToken.source(); 
        this.setState({ cancelToken: source });
        axios({
            url: ApiUrl,
            method: "GET",
            headers: headers,
            cancelToken: source.token
        }).then(res => {
            let response = null;
            if(res.data.numberOfResponses > 0){
                response = res.data.response[0];
                response = Api.prepareMemberObject(response);
            }
            this.setState({ user: response, cancelToken: null, contentLoaded: true});
            if(response !== null){
                this.store.dispatch({type: 'UPDATE_STATE',state: {searchedUser: response}});
            }            
            this.listenHistory(response);
            const { authorized } = this.store.getState();
            if(response !== null && !authorized && response.isPrivate){
                this.history.push("/login?return="+window.location.pathname);
            }
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({ user: null, cancelToken: null, contentLoaded: true});
            }            
        });
    }
    listenHistory(user){
        const that = this;
        history.listen(function(location){
            if(location.pathname === "/"+user.username+"/about"){
                that.setState({isSearchPage: false, searchTerm: null});
            }else{
                that.setState({isSearchPage: true});
            }
            window.fbq('track', 'PageView');
        });
    }
    onSearchPopupClosed(slide = 0){
        const { user } = this.state;
        let template = null;
        if(user !== null && user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("template")){
            template = user.guestPreferences.template;            
        }
        if(slide === 1 && template === "simple"){
            this.setState({aboutModal: true});
            return;
        }

        if(this.sliderInstance !== null){
            this.sliderInstance.slickGoTo(slide, true);
        }
    }
    sendActivity(user){
        const hasAccessToken = AuthApi.hasAccessToken();
        const { authorized } = this.store.getState();
        if(hasAccessToken && !authorized){
            return;
        }
        var requestData = [];
        requestData.push({
            'activityType': "user_viewed",
            'userId': user.userId
        });
        requestData.push({
            'activityType': "user_clicked",
            'userId': user.userId
        });
        const source = axios.CancelToken.source(); 
        this.setState({ activityCancelToken: source });
        Api.sendActivity(requestData, source);
    }
    onCopy(){
        this.setState({snackbarOpen: true});
    }
    closeSnackbar(){
        this.setState({snackbarOpen: false});
    }
    getPrimaryColor(){
        if(this.primaryColor !== null){
            return this.primaryColor;
        }
        const { palette } = this.props.theme;
        this.primaryColor = palette.custom.main;
        return palette.custom.main;
    }
    onSearchTerm(searchTerm){
        const { user } = this.state;
        history.push("/"+user.username+"?q="+searchTerm);
        this.setState({
            searchTerm: searchTerm,
            isSearchPage: true,
            aboutModal: false
        });
        this.sliderInstance.slickGoTo(0, true);
    }
    onAboutModalClose(){
        const { user } = this.state;
        this.setState({aboutModal: false});
        history.push("/"+user.username);
    }
    render() {
        const { classes } = this.props;
        const { user, contentLoaded, isSearchPage, searchTerm, aboutModal } = this.state;
        const { authorized } = this.store.getState();
        const authorizedUser = this.store.getState().user;
        const adminAppUrl = Config.getAdminUrl();
        const imageClasses = classNames(
          classes.imgRaised,
          classes.imgRoundedCircle,
          classes.imgFluid
        );
        let defaultAvatar = christian;
        if(user !== null && user.hasOwnProperty("avatarMedia")){
            defaultAvatar = user.avatarMedia.downloadUrlProfile;
        }
        let defaultCover = coverPhoto;
        if(user !== null && user.hasOwnProperty("coverMedia")){
            defaultCover = user.coverMedia.downloadUrlMain;
        }
        let slide = this.props.slide;
        let template = "classic";
        if(user !== null && user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("template") && user.guestPreferences.template){
            template = user.guestPreferences.template;            
        }
        if(template === "simple"){
            slide = 0;
        }
        const settings = {
            dots: false,
            infinite: false,
            speed: 300,
            touchThreshold: 10,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            arrows: false,
            swipe: false,
            waitForAnimate: false,
            initialSlide: slide,
            beforeChange: function(currentSlide, nextSlide){
                if(nextSlide === 1 || nextSlide === "01"){
                    history.push("/"+user.username+"/about");
                }else if(searchTerm === null){
                    history.push("/"+user.username);
                }
            }
        };
        if(user !== null && user.hasOwnProperty("isSuspended") && user.isSuspended){
            return <div>
                <CoverPhoto
                    image={christian}
                    filter="dark"
                />
                <div className={classNames(classes.main, classes.mainRaised)+" "+(contentLoaded?classes.hiddenSplash:"")}>
                    <div className={classes.container+" "+classes.textAlign}>
                        {user.username}'s search page is temporarily unavailable due to an issue with their account.
                    </div>
                </div>
            </div>
        }
        let headLinks = [];
        let headStyles = [];
        if(user !== null && user.guestPreferences.hasOwnProperty("fontFamily")){
            if(helper.canLoadGoogleFont(user.guestPreferences.fontFamily)){
                headLinks.push({
                    rel: "stylesheet",
                    href: "https://fonts.googleapis.com/css?family="+user.guestPreferences.fontFamily+":400,500,700&display=swap"
                });
            }
            headStyles.push({
                "cssText": `
                    body, h1, h2, h3, h4, h5, h6, p, div, a, span, label {
                        font-family: '`+user.guestPreferences.fontFamily+`', "Helvetica", "Arial", sans-serif !important;
                    }
                    a {
                        color: `+this.getPrimaryColor()+`
                    }
                `
            });
        }

        
        let theme = "light"
        if(user !== null && user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("theme") && user.guestPreferences.theme === "dark"){
            theme = "dark";            
        }

        let backgroundCss = {}
        if(user !== null && user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("layoutTransparency")){
            const { layoutTransparency } = user.guestPreferences;
            let backgroundOpacity = 1-(layoutTransparency/100);
            backgroundCss.backgroundColor= "rgb(255, 255, 255, "+backgroundOpacity+")";
            if(theme === "dark"){
                backgroundCss.backgroundColor= "rgb(1, 1, 1, "+backgroundOpacity+")";
            }
            
            if(backgroundOpacity === 0){
                backgroundCss.boxShadow= "none"
            }
        }

        let previewLabelUrl = adminAppUrl+"/dashboard/editmode/search";
        if(!isSearchPage){
            previewLabelUrl += "/about";
        }

        if(template === "simple"){
            backgroundCss['backgroundColor'] = "transparent";
        }

        return (
            <div className={classes[template]}>
                <CoverPhoto
                    image={defaultCover}
                    filter="dark"
                />
                {
                    user !==null && authorized && authorizedUser.username === user.username ?
                        <div className={classes.previewLabel}>
                            <Link href={previewLabelUrl} underline="always">Edit</Link>
                        </div>
                    :
                    <></>
                }
                <div className={classNames(classes.main, classes.mainRaised)+" "+(contentLoaded?classes.hiddenSplash:"")+" "+(theme === "dark" ? classes.dark : classes.light)} style={backgroundCss}>
                    <div className={classes.container}>
                        {
                            contentLoaded && user !== null ?
                                <>
                                <GridContainer justify="center">
                                    <GridItem xs={12} sm={12} md={6}>
                                        <div className={classes.profile}>
                                            <div className={classes.profileAvatar}>
                                                <img src={defaultAvatar} alt="..." className={imageClasses} onClick={(event) => { this.onSearchPopupClosed(isSearchPage ? 1: 0); }} />
                                            </div>
                                            <div className={classes.name}>
                                            </div>
                                        </div>
                                    </GridItem>
                                    {/* {
                                        authorized && authorizedUser.username === user.username ?
                                            isSearchPage ?
                                                <Link underline="none" href={adminAppUrl+"/dashboard/preview"} style={{color: this.getPrimaryColor()}}  className={classes.editLink}>
                                                    <Button color={this.getPrimaryColor()} size="sm" justIcon><EditIcon /></Button>
                                                </Link>
                                            :
                                                user.userLevel >= 1 ?
                                                    <Link underline="none" href={adminAppUrl+"/dashboard/preview/about"} style={{color: this.getPrimaryColor()}} className={classes.editLink}>
                                                        <Button color={this.getPrimaryColor()} size="sm" justIcon><EditIcon /></Button>
                                                    </Link>
                                                :
                                                <></>
                                            :
                                        <></>
                                    } */}
                                    {
                                        template !== "simple" ?
                                            isSearchPage ?
                                                <Link underline="always" href={"/"+user.username+"/about"} style={{color: this.getPrimaryColor()}} onClick={(event) => { event.preventDefault(); this.onSearchPopupClosed(1); this.sendActivity(user); }} className={classes.aboutLink}>About Me</Link>
                                            :
                                            <Link underline="always" href={"/"+user.username} style={{color: this.getPrimaryColor()}} onClick={(event) => { event.preventDefault(); this.onSearchPopupClosed(0); this.sendActivity(user); }} className={classes.aboutLink}>Search Me</Link>
                                        :
                                        <></>
                                    }                                    
                                </GridContainer>
                                <GridContainer justify="center">
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <Carousel {...settings} className={classes.carousel} ref={slider => (this.sliderInstance = slider)}>
                                            <GridItem className={classes.mobileSpacing}>                                        
                                                <SearchForm match={this.props.match} store={this.store} user={user} onPopupClose={this.onSearchPopupClosed} onCopy={this.onCopy} primaryColor={this.getPrimaryColor()} searchTerm={searchTerm} />
                                            </GridItem>
                                            <GridItem>
                                                <UserAbout store={this.store} user={user} primaryColor={this.getPrimaryColor()} onSearchTerm={this.onSearchTerm} />
                                            </GridItem>
                                        </Carousel>
                                    </GridItem>
                                </GridContainer>                                    
                                <Clearfix />
                                {
                                    aboutModal && template === "simple" ?
                                        <AboutModal open={aboutModal} onClose={() => this.onAboutModalClose()} store={this.store} user={user} primaryColor={this.getPrimaryColor()} onSearchTerm={this.onSearchTerm} onUserUpdate={this.props.onUserUpdate} />
                                    :
                                    <></>
                                }
                                </>
                            :
                            <>
                            {
                                contentLoaded ? 
                                    <GridItem xs={12} sm={8} md={4} lg={4} className={classes.marginAuto+" "+classes.textAlign}>
                                        <Warning>User not found.</Warning>
                                    </GridItem>
                                :
                                <SplashScreen  />
                            }
                            </>
                        }                                    
                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    className={classes.snackbar}
                    open={this.state.snackbarOpen}
                    autoHideDuration={1000}
                    onClose={this.closeSnackbar}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Your code has been copied</span>}
                    action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.closeSnackbar}
                    >
                        <CloseIcon />
                    </IconButton>,
                    ]}
                />
                <Helmet 
                    title={process.env.REACT_APP_TITLE}
                    link={headLinks}
                    style={headStyles}
                />
            </div>
        );
    }
}

UserSearch.propTypes = {
    classes: PropTypes.object
};
  
export default withTheme(withStyles(searchPageStyle)(UserSearch));