import { whiteColor } from "./material-dashboard-pro-react";

const splashScreenStyle = theme => ({
    overlay: {
        position: "fixed",
        top:"0px",
        left:"0px",
        right:"0px",
        bottom:"0px",
        width: "100%",
        height: "100%",
        background: whiteColor,
        zIndex: "9999999"
    },
    loader:{
        maxHeight:"100px",
        width:"50px",
        position:"absolute", 
        top:"0px",
        left:"0px",
        right:"0px",
        bottom:"0px",
        margin:"auto"
    }
});
  
export default splashScreenStyle;
  