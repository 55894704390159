import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Instruction from "../Instruction/Instruction.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

import toolTipsModalStyle from "../../../assets/jss/tooltips/toolTipsModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const YoutubeImportTooltipModal = class extends React.Component {    
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="instagramImport-modal-slide-title"
                aria-describedby="instagramImport-modal-slide-description"
            >
                <DialogTitle
                    id="instagramImport-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>Import Your Youtube</h4>                            
                </DialogTitle>
                <DialogContent
                    id="instagramImport-modal-slide-description"
                    className={classes.modalBody}
                    >
                    <Instruction
                        text={
                            <span>
                                1. As official Youtube partners we use their API to access your channel and load your search engine.  We do not ever have access to your password and we only receive READ-ONLY access to your videos (meaning we cannot change anything on your Youtube channel).
                            </span>
                        }
                        className={classes.instructionNoticeModal}
                        imageClassName={classes.imageNoticeModal}
                    />
                    <Instruction
                        text={
                            <span>
                                2. We will check your Youtube every day for new videos.  You also have the ability to import new content at any time.
                            </span>
                        }
                        className={classes.instructionNoticeModal}
                        imageClassName={classes.imageNoticeModal}
                    />                 
                </DialogContent>
                <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                    <div>
                        <Button
                            onClick={() => this.props.onClose()}
                            color="gray"
                            round
                        >
                            Sounds Good
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        )
    }
}

YoutubeImportTooltipModal.defaultProps = {
    open: false
}
YoutubeImportTooltipModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
}
export default withStyles(toolTipsModalStyle)(YoutubeImportTooltipModal);