/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Divider from "@material-ui/core/Divider";
import Popper from "@material-ui/core/Popper";
// core components
import Button from "../CustomButtons/Button.jsx";

import customDropdownStyle from "../../../assets/jss/material-kit-pro-react/components/customDropdownStyle.jsx";

class CustomDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selected: -1,
      selectedMulti: this.props.selectedMulti
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
  }

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };
  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };
  handleCloseMenu(param, key) {
    this.setState({ open: false, selected: key });
    if (this.props && this.props.onClick) {
      this.props.onClick(param, key);
    }
  }
  handleMultiMenu(param, key) {
    let selectedMulti = this.state.selectedMulti;
    if(selectedMulti.indexOf(key) !== -1){
        selectedMulti.splice(selectedMulti.indexOf(key),1);
    }else{
        selectedMulti.push(key);
    }
    this.setState({ selectedMulti: selectedMulti });
    if (this.props && this.props.onClick) {
      this.props.onClick(param, selectedMulti);
    }
  }
  render() {
    const { open, selectedMulti } = this.state;
    const {
      classes,
      buttonText,
      buttonIcon,
      buttonIconImage,
      dropdownList,
      buttonProps,
      dropup,
      dropdownHeader,
      caret,
      hoverColor,
      dropPlacement,
      rtlActive,
      noLiPadding,
      innerDropDown,
      navDropdown,
      selected,
      popperProps,
      disabledList,
      paperProps,
      managerProps,
    } = this.props;
    const caretClasses = classNames({
      [classes.caret]: true,
      [classes.caretDropup]: dropup && !open,
      [classes.caretActive]: open && !dropup,
      [classes.caretRTL]: rtlActive
    });
    const dropdownItem = classNames({
      [classes.dropdownItem]: true,
      [classes[hoverColor + "Hover"]]: true,
      [classes.noLiPadding]: noLiPadding,
      [classes.dropdownItemRTL]: rtlActive
    });
    const dropDownMenu = (
      <MenuList role="menu" className={classes.menuList}>
        {dropdownHeader !== undefined ? (
          <MenuItem
            onClick={() => this.handleCloseMenu(dropdownHeader)}
            className={classes.dropdownHeader}
          >
            {dropdownHeader}
          </MenuItem>
        ) : null}
        {dropdownList.map((prop, key) => {
          if (prop.divider) {
            return (
              <Divider
                key={key}
                value={key}
                onClick={() => this.handleCloseMenu("divider", key)}
                className={classes.dropdownDividerItem}
              />
            );
          } else if (
            prop.props !== undefined &&
            prop.props["data-ref"] === "multi"
          ) {
            return (
              <MenuItem
                key={key}
                value={key}
                className={dropdownItem}
                style={{ overflow: "visible", padding: 0 }}
                onClick={() => this.handleMultiMenu(prop, key)}
                selected={( selectedMulti.includes(key) === true ? true : false )}
                disabled={( disabledList.includes(key) === true ? true : false )}
              >
                {prop}
              </MenuItem>
            );
          }
          return (
            <MenuItem
              key={key}
              value={key}
              onClick={() => this.handleCloseMenu(prop, key)}
              className={dropdownItem}
              selected={( selected === key ? true : false )}
            >
              {prop}
            </MenuItem>
          );
        })}
      </MenuList>
    );
    return (
      <div className={(innerDropDown ? classes.innerManager : classes.manager)+" "+managerProps.className}>
        <div className={buttonText !== undefined ? "" : classes.target}>
          <Button
            aria-label="Notifications"
            aria-owns={open ? "menu-list" : null}
            aria-haspopup="true"
            buttonRef={node => {
              this.anchorEl = node;
            }}
            {...buttonProps}
            onClick={this.handleClick}
          >
            {buttonIcon !== undefined ? (
              <this.props.buttonIcon className={classes.buttonIcon} />
            ) : null}
            {buttonIconImage !== undefined ? (
              buttonIconImage
            ) : null}
            {buttonText !== undefined ? buttonText : null}
            {caret ? <b className={caretClasses} /> : null}
          </Button>
        </div>
        <Popper
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          placement={dropPlacement}
          className={classNames({
            [classes.popperClose]: !open,
            [classes.pooperResponsive]: true,
            [classes.pooperNav]: open && navDropdown
          })+" "+popperProps.className}
        >
          {() => (
            <Grow
              in={open}
              id="menu-list"
              style={
                dropup
                  ? { transformOrigin: "0 100% 0" }
                  : { transformOrigin: "0 0 0" }
              }
            >
              <Paper className={classes.dropdown+" "+paperProps.className}>
                {innerDropDown ? (
                  dropDownMenu
                ) : (
                  <ClickAwayListener onClickAway={this.handleClose}>
                    {dropDownMenu}
                  </ClickAwayListener>
                )}
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

CustomDropdown.defaultProps = {
    caret: true,
    dropup: false,
    hoverColor: "primary",
    selected: -1,
    selectedMulti: [],
    popperProps: {
        className: ""
    },
    disabledList: [],
    paperProps: {
        className: ""
    },
    managerProps: {
        className: ""
    }
};

CustomDropdown.propTypes = {
    classes: PropTypes.object.isRequired,
    // hoverColor: PropTypes.oneOf([
    //     "dark",
    //     "primary",
    //     "info",
    //     "success",
    //     "warning",
    //     "danger",
    //     "rose",
    //     "customInfo",
    //     "customSuccess",
    //     "customRose",
    //     ""
    // ]),
    buttonText: PropTypes.node,
    buttonIcon: PropTypes.object,
    buttonIconImage: PropTypes.node,
    dropdownList: PropTypes.array,
    buttonProps: PropTypes.object,
    dropup: PropTypes.bool,
    dropdownHeader: PropTypes.node,
    rtlActive: PropTypes.bool,
    caret: PropTypes.bool,
    dropPlacement: PropTypes.oneOf([
        "bottom",
        "top",
        "right",
        "left",
        "bottom-start",
        "bottom-end",
        "top-start",
        "top-end",
        "right-start",
        "right-end",
        "left-start",
        "left-end"
    ]),
    noLiPadding: PropTypes.bool,
    innerDropDown: PropTypes.bool,
    navDropdown: PropTypes.bool,
    // This is a function that returns the clicked menu item
    onClick: PropTypes.func,
    selected: PropTypes.number,
    selectedMulti: PropTypes.array,
    popperProps: PropTypes.object,
    disabledList: PropTypes.array,
    paperProps: PropTypes.object,
    managerProps: PropTypes.object
};

export default withStyles(customDropdownStyle)(CustomDropdown);
