
const contentItemLocationStyle = theme => ({
    endornment: {
        cursor: "pointer",
        height: "auto"
    },
    endornmenttDisabled: {
        cursor: "default"
    }
});

export default contentItemLocationStyle;