import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Close from "@material-ui/icons/Close";
import contentDeleteModalStyle from "../../../../assets/jss/dashboard/content/contentDeleteModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ContentDeleteModal = class extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                root: classes.modalRoot,
                paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="deleteContent-slide-title"
                aria-describedby="deleteContent-slide-description"
            >
                <DialogTitle
                    id="deleteContent-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <h4 className={classes.modalTitle}>Delete Item?</h4>
                </DialogTitle>
                <DialogContent
                    id="deleteContent-slide-description"
                    className={classes.modalBody}
                    >
                    <p>Do you want to delete this item?</p>
                </DialogContent>
                <DialogActions
                    className={classes.modalFooter + " " + classes.justifyContentCenter}
                    >
                    <Button onClick={() => this.props.onClose()} color="white">
                        <ArrowBack />
                        Back
                    </Button>
                    <Button className={classes.rightButton} onClick={() => this.props.onSuccess()} color="danger">
                        <Close />
                        Delete
                    </Button>                    
                </DialogActions>
            </Dialog>
        );
    }
};

ContentDeleteModal.defaultProps = {
  open: false
};
ContentDeleteModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool
};
export default withStyles(contentDeleteModalStyle)(ContentDeleteModal);
