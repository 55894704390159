import {
    whiteColor,
    primaryColor,
} from "./material-kit-pro-react.jsx";

const callToActionStyle = theme => ({
    container: {
        position: "fixed",
        bottom: "0",
        left: "0",
        zIndex: "1500",
        width: "100%",
    },
    whiteColor: {
        color: whiteColor,
        margin: "auto"
    },
    anchorTag:{
        color: whiteColor,
        textDecoration: "underline",
        cursor: "pointer",
        [theme.breakpoints.down("xs")]: {
            display: "block"
        }
    },
    callToAction: {
        backgroundColor: primaryColor[0],
        width: "100%",
        display: "block",
        overflow: "hidden",
        height: "80px",
        textAlign: "center",        
        [theme.breakpoints.down("sm")]: {
            height: "60px"
        },
        [theme.breakpoints.down("xs")]: {
            height: "auto"
        }
    },
    containerRow: {
        alignItems: "center",
        height: "100%",
        [theme.breakpoints.down("xs")]: {
            marginLeft: "0px",
            marginRight: "0px"
        }
    },
    positionTop: {
        top: "0px",
        bottom: "auto"
    }
});

export default callToActionStyle;