import React from 'react'
import { Redirect } from 'react-router-dom'
import { createBrowserHistory } from 'history';
import Api from '../../../assets/js/utils/Api'
import LoaderComponent from '../../components/Loader'
import { helper } from '../../../assets/js/utils/Element'
import axios from 'axios';
import Config from '../../../../Config';
import ContentItemLocation from "../../components/Location/ContentItemLocation";
import StreamView from "./StreamView";
import StreamViewCard from "./StreamViewCard";
import GridView from "./GridView";
import ListView from "./ListView";
import ThreeColumnGrid from "./ThreeColumnGrid";
import SearchResultSlide from "./SearchResultSlide";
import ButtonsView from "./ButtonsView";
import NextArrow from "./Carousel/NextArrow";
import PreviousArrow from "./Carousel/PreviousArrow";
import ContentAffiliateModal from "./ContentAffiliateModal";
// import { getBoundsOfDistance } from "geolib";

// nodejs library to set properties for components
import PropTypes from "prop-types";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from '@material-ui/core/styles/withTheme';
import Grid from '@material-ui/core/Grid';
import InputAdornment from "@material-ui/core/InputAdornment";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import SearchIcon from "@material-ui/icons/Search";
// import Warning from "../../components/Typography/Warning.jsx";
import PlaceIcon from "@material-ui/icons/Place";
import MicIcon from "@material-ui/icons/Mic";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import RefreshIcon from "@material-ui/icons/Refresh";
import CustomAutocomplete from "../CustomInput/CustomAutocomplete";
import CustomSelect from "../CustomInput/CustomSelect";
import instagramIcon from "../../../assets/img/instagram-icon.png";
import youtubeIcon from "../../../assets/img/youtube-icon.png";
import YouTubeIcon from '@material-ui/icons/YouTube';
import Link from "@material-ui/core/Link";
import ButtonBase from '@material-ui/core/ButtonBase';
import SocialIcon from "./SocialIcon";

import { ReactComponent as FeedViewIcon } from "../../../assets/icons/feed_view-2.svg";
import { ReactComponent as ListViewIcon } from "../../../assets/icons/list_view-1.svg";
import { ReactComponent as FilterIcon } from "../../../assets/icons/filter.svg";
import { ReactComponent as ShoppingBagIcon } from "../../../assets/icons/shopping_bag.svg";

import MaterialIcon from "@mdi/react";
import { mdiViewGrid, mdiLink, mdiEyeSettingsOutline, mdiGrid, mdiViewDashboard } from '@mdi/js';

import searchFormStyle from "../../../assets/jss/user/searchFormStyle";
import defaultImage from "../../../assets/img/dg1.jpg";
import { InputLabel } from '@material-ui/core';
import {
    primaryColor,
} from "../../../assets/jss/material-kit-pro-react.jsx";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
  
Transition.displayName = "Transition";
const history = createBrowserHistory();

class SearchForm extends React.Component {
    constructor(props){
        super(props);
        
        this.store = this.props.store;
        this.checkElementsInViewTimeout = null;
        const { authorized, user } = this.store.getState();
        this.user = this.props.user;
        let viewType = 1;
        let filterTypes = [0,1,2,3];
        if(!authorized){
            filterTypes = [1,2,3];
        }
        // if(authorized && user.hasOwnProperty("userPreferences")){
        //     const userPref = user.userPreferences;
        //     const defaultViewType = userPref.searchViewResults;
        //     viewType = 1;
        //     if(defaultViewType === "stream"){
        //         viewType = 0;
        //     }else if(defaultViewType === "list"){
        //         viewType = 1;
        //     }else if(defaultViewType === "grid"){
        //         viewType = 2;
        //     }else if(defaultViewType === "threecolumngrid"){
        //         viewType = 3;
        //     }else if(defaultViewType === "card"){
        //         viewType = 4;
        //     }
            
        //     if(userPref.searchFilterLinks === false){
        //         filterTypes.splice(1,1);
        //     } 
        //     if(userPref.searchFilterInstagram === false){
        //         filterTypes.splice(2,1);
        //     }
        //     if(userPref.searchFilterYoutube === false){
        //         filterTypes.splice(3,1);
        //     }    
        // }else if(this.user.hasOwnProperty("guestPreferences")){
            const userPref = this.user.guestPreferences;
            const defaultViewType = userPref.defaultLayout;
            viewType = 1;
            if(defaultViewType === "stream"){
                viewType = 0;
            }else if(defaultViewType === "list"){
                viewType = 1;
            }else if(defaultViewType === "grid"){
                viewType = 2;
            }else if(defaultViewType === "threecolumngrid"){
                viewType = 3;
            }else if(defaultViewType === "card"){
                viewType = 4;
            }
        // }
        let platforms = [];
        if(user.hasOwnProperty("contentPlatforms")){
            platforms = user.contentPlatforms;
        }

        const query = helper.getParam("q");
        this.state = {
            values: {
                q: (query !== "null" ? query: ""),
                radius: '1',
                radiusType: 'Mi',
                viewType: viewType,
                filterTypes: filterTypes,
                location: "",
                googlePlacesResponse: {
                    latitude: 0.0,
                    longitude: 0.0,
                    location: "",
                    googlePlacesId: "",
                    formatted_address: "",
                    city: "",
                    state: "",
                    zipcode: "",
                    country: "",
                },
                pageSize: 20,
                allFilter: false,
                shoppableFilter: false,
                linksFilter: false,
                instagramFilter: false,
                youtubeFilter: false,
                locationFilter: false,
            },
            userLocation: {
                latitude: 0.0,
                longitude: 0.0,
            },
            loading: false,
            response: [],
            cancelToken: null,
            contentLoaded: false,
            showFilter: false,
            showAdvancedSearch: false,
            showModal: false,
            currentContent: false, 
            currentKey: 0,
            defaultFilters: !authorized,
            featuredLinks: true,
            contentFeedResponse: [],
            slidePosition: "left",
            userSearched: (query !== "null" ? true : false),
            featuredLastKey: null,
            featuredLoadingMore: false,
            showClearSearch: false,
            searchTerms: [],
            affiliateMode: false,
            platforms: platforms,
            isOptionsOpen: false,
            hasFilterChange: false,
            hasOpenFilter: false,
            openStatus: false,
            startSearch: false
        }

        this.onSearchChange = this.onSearchChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.toggleAdvancedSearch = this.toggleAdvancedSearch.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
        this.handleViewOptions = this.handleViewOptions.bind(this);
        this.handleFilterOptions = this.handleFilterOptions.bind(this);
        this.handleFilterOption = this.handleFilterOption.bind(this);
        this.onSearchResultOpen = this.onSearchResultOpen.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.handleElementsInScrollView = this.handleElementsInScrollView.bind(this);
        this.loadFeed = this.loadFeed.bind(this);
        this.onFeaturedScroll = this.onFeaturedScroll.bind(this);
        this.onAffiliateModalClose = this.onAffiliateModalClose.bind(this);
        this.filterOptionsOnSearch = this.filterOptionsOnSearch.bind(this);
        this.onEnterSubmit = this.onEnterSubmit.bind(this);
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
        window.removeEventListener("scroll", this.handleElementsInScrollView);
        window.removeEventListener("scroll", this.onFeaturedScroll);
    }
    componentWillReceiveProps(props){
        const query = helper.getParam("q");
        if(query && query !== "null" && this.state.values.q !== query){
            this.setState({
                values: {
                    ...this.state.values,
                    q: query
                },
                startSearch: true,
                userSearched: false
            });
        }
    }
    componentDidMount(){
        const query = this.state.values.q;
        if(query && query.trim().length > 0){
            this.searchCatalog();
        }else{
            this.loadFeed();
        }
        window.addEventListener("scroll", this.handleElementsInScrollView);
        window.addEventListener("scroll", this.onFeaturedScroll);
        this.getUserSearchTerms();

        // const { user } = this.store.getState();
        // if(user.username.toLowerCase() === this.user.username.toLowerCase()){
        //     this.loadUserPlatforms();
        // }
    }
    componentDidUpdate(prevProps, prevState){
        if(this.state.startSearch === true && this.state.userSearched === false){
            this.searchCatalog();
        }
        // const { values } = this.state;
        // if((values.locationFilter === true && prevState.values.locationFilter === false) ||
        // (values.locationFilter === false && prevState.values.locationFilter === true)){
        //     this.toggleAdvancedSearch();
        // }
    }
    onFeaturedScroll(){
        const viewMore = document.getElementById("featuredContentViewMore");
        if(!viewMore){
            return;
        }
        if(!this.state.featuredLinks){
            return;
        }
        let scrollPosition = document.documentElement.scrollTop;
        if(scrollPosition <= 0){
            scrollPosition = document.body.scrollTop;
        }

        let elementPostionY = 0;
        if (typeof (viewMore.offsetParent) != 'undefined') {
            elementPostionY = viewMore.offsetTop;
        } else {
            elementPostionY = viewMore.y;
        }

        if (elementPostionY <= scrollPosition + (window.innerHeight - 100)) {
            this.loadFeed(true);
        }
    }
    clearContents(e){
        if(!this.state.values.q){
            return null;
        }

        this.clearSearch();
    }
    handleSubmit(e){
        e.preventDefault();
        this.setState({startSearch: true});
        this.searchCatalog();
    }
    onEnterSubmit(e){
        e.preventDefault();
        if(e.nativeEvent.keyCode === 13){
            this.setState({startSearch: true, openStatus: false, isOptionsOpen: false});
            this.searchCatalog();
        }
    }
    handleChange(event, name) {
        this.setState({
            values:{
                ...this.state.values,
                [name]: event.target.value
            },
            userSearched: false,
        });
    };
    handleLocationChange(location, userLocation = false){
        let userLocationObj = this.state.userLocation;
        if(userLocation === true){
            userLocationObj = {
                latitude: location.latitude,
                longitude: location.longitude,
            }
        }
        this.setState({
            values: {
                ...this.state.values,
                googlePlacesId: location.googlePlacesId,
                latitude: location.latitude,
                longitude: location.longitude,
                location: location.location,
                googlePlacesResponse: location
            },
            userLocation: userLocationObj,
            userSearched: !this.state.showAdvancedSearch
        });
    }
    toggleAdvancedSearch(){
        this.setState({
            showAdvancedSearch: (!this.state.showAdvancedSearch),
            userSearched: false
        });
    }
    toggleFilter(){
        this.setState({
            values: {
                ...this.state.values,
                shoppableFilter: false,
                linksFilter: false,
                instagramFilter: false,
                youtubeFilter: false,
                locationFilter: false,
                allFilter: true
            },
            showFilter: (!this.state.showFilter),
            showAdvancedSearch: false,
            hasOpenFilter: (!this.state.hasOpenFilter),
        });
    }
    searchCatalog(){
        const { values } = this.state;
        const { googlePlacesResponse } = values;
        let radius = values.radius;
        if(values.radiusType === "Mi"){
            // radius = parseInt(radius)*1.60934;
        }
        let searchData = {
            q: values.q.replace("All Topics",""),
            u: this.user.userId,
        }

        //Advanced Filters
        if(values.filterTypes.includes(0)){
            searchData['featured'] = true;
        }

        if(values.filterTypes.includes(1)){
            searchData['links'] = true;
        }

        if(values.filterTypes.includes(2)){
            searchData['lists'] = true;
        }


        // new search params
        
        let ctArray = [];
        if(values.youtubeFilter === true){
            ctArray.push("youtube");
        }
        if(values.instagramFilter === true){
            ctArray.push("instagram_post");
        }
        if(values.linksFilter === true){
            ctArray.push("link");
        }
        if(ctArray.length > 0){
            searchData['ct'] = ctArray.join(',');
        }

        if(values.shoppableFilter === true){
            searchData['shopping'] = true;
        }

        if(this.state.showAdvancedSearch){
            searchData['r'] = radius;
            searchData['rt'] = values.radiusType.toLowerCase();
            if(googlePlacesResponse.latitude !== 0.0){
                searchData['sl'] = googlePlacesResponse.latitude+","+googlePlacesResponse.longitude;

                const southEastAngle = 135;
                const northWestAngle = 315;
                let meterRadius = values.radius;
                if(values.radiusType === "Mi"){
                    meterRadius = parseInt(radius)*1.60934;
                }
                var myLatLng = new window.google.maps.LatLng(googlePlacesResponse.latitude,googlePlacesResponse.longitude);
                const southEast = helper.computeOffset(myLatLng, meterRadius, southEastAngle);
                const northWest = helper.computeOffset(myLatLng, meterRadius, northWestAngle);
                searchData['b'] = ["'"+northWest.lat()+","+northWest.lng()+"'","'"+southEast.lat()+","+southEast.lng()+"'"];
            }

            // const bounds = getBoundsOfDistance(
            //     { latitude: googlePlacesResponse.latitude, longitude: googlePlacesResponse.longitude },
            //     radius
            // );
            // const topBounds = bounds[0];
            // const bottomBounds = bounds[1];
            // searchData['b'] = ["'"+topBounds.latitude+","+topBounds.longitude+"'","'"+bottomBounds.latitude+","+bottomBounds.longitude+"'"];
        }

        const userLocation = this.state.userLocation;
        if(userLocation !== 0.0){
            searchData['ul'] = userLocation.latitude+","+userLocation.longitude;
        }
        const searchUrl = "/"+this.user.username+"?q="+searchData['q'];
        history.push(searchUrl);
        try{
            window.fbq('track', 'PageView');
        }catch(e){ }
        
        const ApiUrl = Config.getApiUrl()+"data/search?"+helper.serialize(searchData);
        let headers = Config.getApiHeaders();
        const source = axios.CancelToken.source();
        const that = this;
        this.setState({
            cancelToken: source,
            loading: true,
            response: [],
            contentLoaded: false,
            featuredLinks: false,
            userSearched: true,
            startSearch: false
        });
        axios({
            url: ApiUrl,
            method: "GET",
            headers: headers,
            cancelToken: source.token
        }).then(res => {
            that.updateResponse(res.data.response);
            setTimeout(function(){
                helper.checkElementInViewPort();
            },500);
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                that.updateResponse([]);
            }            
        });
    }
    updateResponse(response){
        let values = {
            ...this.state.values
        }
        if(this.state.hasFilterChange === false){
            values = {
                ...this.state.values,
                allFilter: true
            }
        }
        this.setState({
            response: response,
            loading: false,
            contentLoaded: true,
            cancelToken: null,
            showClearSearch: true,
            showFilter: true,
            // userSearched: false,
            values: values
        });
    }
    goBack(){
        if(window.history.length > 1){
            window.history.back(-1);
        }else{
            return <Redirect to={"/user/home"} />
        }
    }
    handleViewOptions(param, key){
        const { authorized, user} = this.store.getState();
        let cancelToken = this.state.cancelToken;
        if(authorized){
            user.userPreferences.searchViewResults = "stream";
            if(key === 1){
                user.userPreferences.searchViewResults = "list";
            }
            if(key === 2){
                user.userPreferences.searchViewResults = "grid";
            }
            if(key === 3){
                user.userPreferences.searchViewResults = "threecolumngrid";
            }
            if(key === 3){
                user.userPreferences.searchViewResults = "threecolumngrid";
            }
            if(key === 4){
                user.userPreferences.searchViewResults = "card";
            }
            Api.updateUser(user.userId, user).then((data) => {
                if(data.numberOfResponses > 0){
                    // this.store.dispatch({type: 'UPDATE_STATE',state: {user: data.response[0] }});
                }
            });
        }

        this.setState({
            values: {
                ...this.state.values,
                viewType: key,
                cancelToken: cancelToken
            }
        });

        setTimeout(function(){
            helper.checkElementInViewPort();
        },500);
    }
    handleFilterOption(event, name){
        let { values } = this.state;
        if(name === "allFilter" && values.allFilter === true){
            return;
        }
        if(name === "allFilter" && values.allFilter === false){
            this.setState({
                values: {
                    ...this.state.values,
                    shoppableFilter: false,
                    linksFilter: false,
                    instagramFilter: false,
                    youtubeFilter: false,
                    locationFilter: false,
                    [name]: !values[name],
                },
                hasFilterChange: true,
                showAdvancedSearch: false,
                startSearch: true,
                userSearched: false
            })
        }else{
            values = {
                ...this.state.values,
                allFilter: false,
                [name]: !values[name],
            };
            const state = {
                values: values,
                hasFilterChange: true,
                startSearch: true,
                userSearched: false
            };
            if(values.shoppableFilter === false && values.linksFilter === false && values.instagramFilter === false 
                && values.youtubeFilter === false && values.locationFilter === false){
                state.values.allFilter = true;
            }
            this.setState(state);
            if(name === "locationFilter"){
                this.toggleAdvancedSearch();
            }
        }
    }
    handleFilterOptions(param, filterTypes){
        const { authorized, user} = this.store.getState();
        let cancelToken = this.state.cancelToken;
        if(authorized){
            user.userPreferences.searchFilterLinks = false;
            user.userPreferences.searchFilterInstagram = false;
            user.userPreferences.searchFilterYoutube = false;
            if(filterTypes.indexOf(1) !== -1){
                user.userPreferences.searchFilterLinks = true;
            }
            if(filterTypes.indexOf(2) !== -1){
                user.userPreferences.searchFilterInstagram = true;
            }
            if(filterTypes.indexOf(3) !== -1){
                user.userPreferences.searchFilterYoutube = true;
            }
            Api.updateUser(user.userId, user).then((data) => {
                if(data.numberOfResponses > 0){
                    // this.store.dispatch({type: 'UPDATE_STATE',state: {user: data.response[0] }});
                }
            });
        }
        
        
        this.setState({
            values: {
                ...this.state.values,
                filterTypes: filterTypes,
                cancelToken: cancelToken
            },
            defaultFilters: false
        })
    }
    getSearchResults(){
        if(this.state.featuredLinks === true){
            return this.state.contentFeedResponse;
        }
        if(this.state.response.length <= 0){
            return null;
        }
        const data = this.state.response[0];
        if(data.response.found <= 0){
            return null;
        }
        let searchHits = [];
        const filterTypes = this.state.values.filterTypes;
        const defaultFilters = this.state.defaultFilters;
        data.response.hit.map(hit => {
            if(hit.hasOwnProperty("content")){
                if(!defaultFilters && filterTypes.includes(0) === true && hit.content.isFeatured !== true){
                    //return null;
                }
                searchHits.push(hit.content);
            }
            return null;
        });
        return searchHits;
    }
    filterResults(searchResults){
        const { user } = this.store.getState();
        let userPreferences = user.userPreferences;
        if(userPreferences.searchFilterLinks || userPreferences.searchFilterInstagram || userPreferences.searchFilterYoutube){
            let searchResultsArray = [];
            searchResults.map((searchResult, key) => {
                if(searchResult.hasOwnProperty("type")){
                    if(
                        (userPreferences.searchFilterLinks && searchResult.type === "link") ||
                        (userPreferences.searchFilterInstagram && searchResult.type === "instagram_post") || 
                        (userPreferences.searchFilterYoutube && searchResult.type === "youtube")
                    ){
                        searchResultsArray[key] = searchResult;
                    }
                }
                return null;
            });
            return searchResultsArray;
        }        
        return searchResults;
    }
    filterActiveResults(searchResults){
        const { user } = this.props;
        let searchResultsArray = [];
        let platforms = [];
        if(user.hasOwnProperty("contentPlatforms")){
            platforms = user.contentPlatforms;
        }
        searchResults.map((searchResult, key) => {
            const contentPlatform = platforms.find(x => x.contentPlatformId === searchResult.contentPlatformId);
            if(typeof(contentPlatform) === "object" && ((contentPlatform.hasOwnProperty("isActive") && !contentPlatform.isActive)
             || (contentPlatform.hasOwnProperty("isDeleted") && contentPlatform.isDeleted))){
                return null;
            }
            if(
                (searchResult.hasOwnProperty("isDeleted") && searchResult.isDeleted) ||
                (searchResult.hasOwnProperty("isSearchable") && !searchResult.isSearchable)
            ){
                return null;
            }
            if(searchResult.hasOwnProperty("contentPublishedDateTime") && searchResult.contentPublishedDateTime.length > 0){
                const startDiff = new Date(searchResult.contentPublishedDateTime) - new Date();
                if(startDiff > 0){
                    return null;
                }
            }
            if(searchResult.hasOwnProperty("contentExpiredDateTime") && searchResult.contentExpiredDateTime.length > 0){
                const startDiff = new Date(searchResult.contentExpiredDateTime) - new Date();
                if(startDiff < 0){
                    return null;
                }
            }
            searchResultsArray[key] = searchResult;
            return null;
        });
        return searchResultsArray;
    }
    renderResults(searchResults){
        // const { authorized } = this.store.getState();
        // const authorizedUser = this.store.getState().user;
        // var showBetaFeatures = (authorized && authorizedUser.hasOwnProperty("userPreferences") && authorizedUser.userPreferences.showBetaFeatures);
        // if(showBetaFeatures){
        //     searchResults = this.filterResults(searchResults);
        // }

        searchResults = this.filterActiveResults(searchResults);

        let view = null;
        switch(this.state.values.viewType){
            case 0:
                view = <StreamView user={this.user} response={searchResults} onOpen={this.onSearchResultOpen} primaryColor={this.props.primaryColor} />
                break;
            case 1:
                view = <ListView user={this.user} response={searchResults} onOpen={this.onSearchResultOpen} primaryColor={this.props.primaryColor} />
                break;
            case 2:
                view = <GridView user={this.user} response={searchResults} onOpen={this.onSearchResultOpen} primaryColor={this.props.primaryColor} />
                break;
            case 3:
                view = <ThreeColumnGrid user={this.user} response={searchResults} onOpen={this.onSearchResultOpen} primaryColor={this.props.primaryColor} />
                break;
            case 4:
                view = <StreamViewCard user={this.user} response={searchResults} onOpen={this.onSearchResultOpen} store={this.store} platforms={this.state.platforms} primaryColor={this.props.primaryColor} onEdit={(key,newContent) => this.onEdit(key,newContent)} onDelete={(key, content) => this.onDelete(key, content)}/>
                break;
            default:
                view = <StreamView user={this.user} response={searchResults} onOpen={this.onSearchResultOpen} primaryColor={this.props.primaryColor} />
                break;
        }
        return view;
    }
    allowDisclose(currentContent){
        if(
            (currentContent.isSponsored && (currentContent.hasOwnProperty("url") && currentContent.url.trim().length > 0)) ||
            (currentContent.isSponsored && (currentContent.hasOwnProperty("affiliateUrl") && currentContent.affiliateUrl.trim().length > 0))
        ){
            return true;
        }
        if(currentContent.hasOwnProperty("discountCode") && currentContent.discountCode.trim().length > 0){
            return true;
        }
        return false;
    }
    sendClickActivity(content, url, type = null){
        var requestData = [];
        let activityType = "content_url_clicked";
        if(type === "secondary"){
            activityType = "content_affiliate_clicked";
        }
        requestData.push({
            'activityType': activityType,
            'contentId': content.contentId
        });
        requestData.push({
            'activityType': "content_viewed",
            'contentId': content.contentId
        });
        requestData.push({
            'activityType': "content_clicked",
            'contentId': content.contentId
        });
        Api.sendActivity(requestData);
    }
    onSearchResultOpen(show = true, currentContent = false, currentKey = 0, button = false){
        if(currentContent){
            if(this.user.guestPreferences.linkBehavior === "clickThrough" || button){
                if(this.allowDisclose(currentContent)){
                    this.setState({affiliateMode: true, currentContent: currentContent, currentKey: currentKey});
                }else if(currentContent.url || currentContent.affiliateUrl){
                    if(currentContent.hasOwnProperty("affiliateUrl") && currentContent.affiliateUrl.length > 0){
                        this.sendClickActivity(currentContent, currentContent.affiliateUrl, "secondary");
                        window.open(currentContent.affiliateUrl,"_blank");
                    }else{
                        this.sendClickActivity(currentContent, currentContent.url);
                        window.open(currentContent.url,"_blank");
                    }
                }
                return;
            }
        }
        this.setState({showModal: show, currentContent: currentContent, currentKey: currentKey});
        let htmlOverflow = "";
        if(show === true){
            htmlOverflow = "overflow_hidden";
            let scrollPosition = document.documentElement.scrollTop;
            if(scrollPosition <= 0){
                scrollPosition = document.body.scrollTop;
            }
            document.documentElement.setAttribute("data-scroll", scrollPosition);
        }
        setTimeout(function(){
            document.documentElement.className = htmlOverflow;
            document.body.scrollTop = 0;
        },(show?300:0));
        if(show === false && this.props.onPopupClose){
            this.props.onPopupClose();
        }
        if(show === false && document.documentElement.hasAttribute("data-scroll")){
            setTimeout(function(){
                document.body.scrollTop = 0;
                const scrollPositionSaved = parseFloat(document.documentElement.getAttribute("data-scroll"));
                window.scrollTo(0,scrollPositionSaved);
                document.documentElement.removeAttribute("data-scroll");
            },5);
        }
        
    }
    getCoverPhoto(content){
        if(!content.hasOwnProperty("coverMedia")){
            return defaultImage;
        }
        return content.coverMedia.downloadUrlMain;
    }
    loadFeed(viewMore = false){
        const { user, searchedUser } = this.store.getState();
        if(user.username.toLowerCase() !== this.user.username.toLowerCase() && searchedUser === false){
            return;
        }
        const requestData = {
            pageSize: 12,
            feed: true
        };
        if(viewMore && this.state.featuredLastKey !== null){
            requestData['startKey'] = this.state.featuredLastKey;
        }

        const ApiUrl = Config.getApiUrl()+"data/content/user/"+this.user.userId+"?"+helper.serialize(requestData);
        let headers = Config.getApiHeaders();
        const source = axios.CancelToken.source();
        const that = this;
        this.setState({cancelToken: source, featuredLinks: true, loading: (!viewMore), featuredLoadingMore: viewMore});
        axios({
            url: ApiUrl,
            method: "GET",
            headers: headers,
            cancelToken: source.token
        }).then(res => {
            const data = res.data;
            let lastKey = null;
            if(data.hasOwnProperty("lastKey")){
                lastKey = data.lastKey;
            }
            
            const oldResponse = (viewMore ? that.state.contentFeedResponse : []);
            const response = oldResponse.concat(data.response);
            that.setState({
                contentFeedResponse: response,
                loading: false,
                contentLoaded: true,
                cancelToken: null,
                featuredLastKey: lastKey,
                featuredLoadingMore: false
            });
            setTimeout(function(){
                helper.checkElementInViewPort();
            },500);
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                that.setState({
                    contentFeedResponse: [],
                    loading: false,
                    contentLoaded: true,
                    cancelToken: null
                });
            }            
        });
    }
    clearSearch(){
        if(this.state.hasOpenFilter === true){
            let showAdvancedSearch = false;
            if(this.state.showAdvancedSearch === true){
                showAdvancedSearch = true;
            }
            this.setState({
                values: {
                    ...this.state.values,
                    q: ""
                },
                showFilter: true,
                showAdvancedSearch: showAdvancedSearch,
                contentLoaded: true,
                response: [],
                featuredLinks: true,
                showClearSearch: false,
                userSearched: false,
                startSearch: false
            });
            history.push("/"+this.user.username);

            return;
        }
        this.setState({
            values: {
                ...this.state.values,
                shoppableFilter: false,
                linksFilter: false,
                instagramFilter: false,
                youtubeFilter: false,
                locationFilter: false,
                allFilter: true,
                q: ""
            },
            showFilter: false,
            showAdvancedSearch: false,
            contentLoaded: true,
            response: [],
            featuredLinks: true,
            showClearSearch: false,
            userSearched: false,
            startSearch: false
        });
        history.push("/"+this.user.username);
    }
    sendActivity(content){
        var requestData = [];
        requestData.push({
            'activityType': "content_viewed",
            'contentId': content.contentId
        });
        requestData.push({
            'activityType': "content_clicked",
            'contentId': content.contentId
        });
        Api.sendActivity(requestData);
    }
    handleElementsInScrollView(){
        if(this.checkElementsInViewTimeout){
            clearTimeout(this.checkElementsInViewTimeout);
        }
        this.checkElementsInViewTimeout = setTimeout(function(){
            this.checkElementsInViewTimeout = null;
            helper.checkElementInViewPort();
        },500);
    }
    onEdit(key, newContent){
        if(newContent === null){
            return;
        }
        if(this.state.featuredLinks === true){
            const contentFeedResponse = this.state.contentFeedResponse;
            contentFeedResponse[key] = newContent;
            this.setState({contentFeedResponse: contentFeedResponse});
            return;
        }
        if(this.state.response.length <= 0){
            return null;
        }
        const data = this.state.response[0];
        if(data.response.found <= 0){
            return null;
        }
        const searchHits = data.response.hit;
        searchHits.map((hit, newKey) => {
            if(hit.hasOwnProperty("content") && hit.content.contentId === newContent.contentId){
                searchHits[newKey].content = newContent;
            }
            return null;
        });

        const newResponse = this.state.response;
        newResponse[0].response.hit = searchHits;
        this.setState({response: newResponse});
    }
    onDelete(key, content){
        if(key === null){
            return;
        }
        if(this.state.featuredLinks === true){
            const contentFeedResponse = this.state.contentFeedResponse;
            contentFeedResponse.splice(key,1);
            this.setState({contentFeedResponse: contentFeedResponse});
            return;
        }
        if(this.state.response.length <= 0){
            return null;
        }
        const data = this.state.response[0];
        if(data.response.found <= 0){
            return null;
        }
        const searchHits = data.response.hit;
        searchHits.map((hit, newKey) => {
            if(hit.hasOwnProperty("content") && hit.content.contentId === content.contentId){
                searchHits.splice(newKey,1);
            }
            return null;
        });
        
        const newResponse = this.state.response;
        newResponse[0].response.hit = searchHits;
        this.setState({response: newResponse});
    }
    getUserSearchTerms(){
        const { user } = this.props;
        if(!user.hasOwnProperty("topSearchTerms")){
            user['topSearchTerms'] = "";
        }
        let topSearchTerms = user.topSearchTerms;
        let topSearchTermsStr = "Most Commonly Searched,"+topSearchTerms.trim();
        let topSearchTermsArray = topSearchTermsStr.split(',');
        if(topSearchTerms.trim().length <= 0){
            topSearchTermsArray = [];
        }
        this.setState({
            searchTerms: topSearchTermsArray
        })
    }
    onSearchChange(event, value){
        if(value){
            this.setState({
                values :{
                    ...this.state.values,
                    q: value
                },
                startSearch: true,
                userSearched: false
            })
        }else{
            this.clearSearch();
        }
    }
    onSearchInputChange(e, value, name) {
        this.setState({
            values:{
                ...this.state.values,
                [name]: value
            },
            startSearch: false,
            userSearched: false
        });
        this.filterOptionsOnSearch(value);
    }
    filterOptionsOnSearch(value, returnStatus = false){
        const { searchTerms } = this.state;
        
        var filteredOptions = [];
        searchTerms.map((option) => {
            if(option.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                filteredOptions.push(option);
            }
            return null;
        });
        
        let isOptionsOpen = true;
        if(filteredOptions.length === 0){
            isOptionsOpen = false;
        }

        if(returnStatus){
            return isOptionsOpen;
        }

        this.setState({
            isOptionsOpen: isOptionsOpen,
            openStatus: isOptionsOpen
        })
    }
    onOpenOptions(e){
        const { q } = this.state.values;
        let status = true;
        if(q && q !== "null" && q.length > 0){
            status = this.filterOptionsOnSearch(q, true);
        }
        const { searchTerms } = this.state;
        if((typeof(searchTerms) === "object" && searchTerms.length <= 0) || (typeof(searchTerms) === "string" && searchTerms.length <= 0)){
            status = false;
        }
        this.setState({
            isOptionsOpen: status,
            openStatus: status
        })
    }
    onCloseOptions(e){
        this.setState({
            isOptionsOpen: false,
            openStatus: false
        })
    }
    onAffiliateModalClose(){
        this.setState({affiliateMode: false});
    }
    loadUserPlatforms(){
        const source = axios.CancelToken.source();
        const that = this;
        this.setState({cancelToken: source});
        Api.getPlatforms({}, source, this.user.userId).then(data => {
            that.setState({
                platforms: data.response,
            });
        });
    }
    getViewOptionsDropdown(){
        const { classes } = this.props;
        const { authorized } = this.store.getState();
        const authorizedUser = this.store.getState().user;
        var showBetaFeatures = (authorized && authorizedUser.hasOwnProperty("userPreferences") && authorizedUser.userPreferences.showBetaFeatures);
        let options = [
            <Button type="button" color="transparent" justIcon>
                <FeedViewIcon className={classes.customIcon+" MuiSvgIcon-root"} />
            </Button>,
            <Button type="button" color="transparent" justIcon>
                <ListViewIcon className={classes.customIcon+" MuiSvgIcon-root"} />
            </Button>,
            <Button type="button" color="transparent" justIcon>
                <MaterialIcon path={mdiViewGrid} className="MuiSvgIcon-root" />
            </Button>,
            <Button type="button" color="transparent" justIcon>
                <MaterialIcon path={mdiGrid} className="MuiSvgIcon-root" />
            </Button>
        ];
        if(showBetaFeatures){
            options.push(
                <Button type="button" color="transparent" justIcon>
                    <MaterialIcon path={mdiViewDashboard} className="MuiSvgIcon-root" />
                </Button>
            );
        }
        return options;
    }
    getPrimaryColor(primaryColor){
        const { palette } = this.props.theme;
        return palette.custom.main;
    }
    onBlurOpenOptions(e){
        console.log("testing");
        this.setState({
            openStatus: true
        })
    }
    getButtonStyles(){
        const { primaryColor } = this.props;
        const { fontSize, buttonStyle } = this.props.user.guestPreferences;
        const { palette } = this.props.theme;
        let buttonStyles = {
            backgroundColor: primaryColor, 
            fontSize: (fontSize === "large" ? "14px" : "12px"),
            borderRadius: (buttonStyle === "rounded" ? "30px" : "0px"),
            color: palette.custom.buttonText
        };
        return buttonStyles;
    }
    renderDefaultButton(){
        const { classes, user } = this.props;
        const buttonStyles = this.getButtonStyles();
        const { buttonStyle } = this.props.user.guestPreferences;
        const { authorized } = this.store.getState();
        const authorizedUser = this.store.getState().user;
        return (
            <GridContainer justify="center" alignItems="center" className={classes.gridContainer}>
                <div className={classes.button+" "+classes.gridSpacing}>
                    <GridItem xs={12} sm={12} md={12} lg={12} className={classes.gridItem+" "+classes.adButton} style={{borderRadius: (buttonStyle === "rounded" ? "30px" : "0px")}}>
                        <ButtonBase className={classes.buttonBase+(buttonStyle === "rounded" ? " rounded" : "")}>
                            <Link href={Config.getAdminUrl()+"/signup?refType=search_result&refLink=cta_free&refUId="+user.userId} underline="none" style={buttonStyles}>
                                I made my Instagram searchable for free and now you can too!
                            </Link>
                            {
                                authorized && user.userId === authorizedUser.userId ?
                                    <Link href={Config.getAdminUrl()+"/dashboard/account/upgrade?return=search"} className={classes.removeAd}>
                                        Remove this ad
                                    </Link>
                                :
                                <></>
                            }
                        </ButtonBase>
                    </GridItem>
                </div>
            </GridContainer>
        )
    }
    renderSocialIcons(){
        const socialIcons =  this.user.socialIcons;
        let socialIconsRendered = [];
        for (const socialIcon in socialIcons){
            if(socialIcon === "blogUrl"){
                continue;
            }
            const icon = this.renderSocialIcon(socialIcon);
            if(icon === null){
                continue;
            }
            socialIconsRendered.push(<li key={socialIcon}>
                {icon}
            </li>)
        }
        return socialIconsRendered;
    }
    renderSocialIcon(type){
        const socialIconsValues = this.user.socialIcons;
        return <SocialIcon type={type} socialIcons={socialIconsValues} />
    }
    render(){
        const { classes, user } = this.props;
        const { authorized } = this.store.getState();
        const authorizedUser = this.store.getState().user;
        var showBetaFeatures = (authorized && authorizedUser.hasOwnProperty("userPreferences") && authorizedUser.userPreferences.showBetaFeatures);
        const state = this.state;
        const { contentLoaded, loading, values, showModal, currentContent, currentKey, featuredLinks, userSearched, 
            featuredLoadingMore, featuredLastKey, showClearSearch, affiliateMode, platforms,isOptionsOpen, openStatus } = state;
        const { username } = user;
        let allowedFilters = {
            instagramFilter: true,
            linksFilter: false,
            locationFilter: true,
            shoppableFilter: true,
            youtubeFilter: true
        };
        if(user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("filters")){
            allowedFilters = user.guestPreferences.filters;
        }
        const radiusArray = Api.getRadiusArray();
        let searchResults = this.getSearchResults();        
        let contentLength = 0;
        let maxFeatured = -1;
        let showButtons = false;
        if(user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("maxFeatured")){
            maxFeatured = parseInt(user.guestPreferences.maxFeatured);
        }
        if(user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("showButtons")){
            showButtons = user.guestPreferences.showButtons;
        }
        if(searchResults !== null){
            // if(this.state.featuredLinks){
                // if(user.userLevel === 0){
                //     searchResults = searchResults.slice(0, 6);
                // }else if(user.userLevel === 1){
                //     searchResults = searchResults.slice(0, 12);
                // }
                // if(maxFeatured > 0){
                //     searchResults = searchResults.slice(0, maxFeatured);
                // }
            // }
            contentLength = searchResults.length;
        }
        const that = this;
        const settings = {
            dots: false,
            infinite: false,
            adaptiveHeight: true,
            speed: 300,
            touchThreshold: 10,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            arrows: false,
            waitForAnimate: false,
            lazyLoad: "progressive",
            currentSlide: currentKey,
            prevArrow: <PreviousArrow />,
            nextArrow: <NextArrow />,
            onInit(){
                if(searchResults !== null && searchResults.length > 0){
                    let content = searchResults[currentKey];
                    that.sendActivity(content);
                }
            },
            beforeChange(currentSlide, nextSlide){
                if(searchResults !== null && searchResults.length > 0){
                    let content = searchResults[nextSlide];
                    that.sendActivity(content);
                }
            },
            afterChange(currentSlide){
                let content = searchResults[currentSlide];
                that.setState({currentKey: currentSlide, currentContent: content});                
            },
        };
        let buttons = null;
        if(user.hasOwnProperty("buttons")){
            buttons = user.buttons;
        }
        const { primaryColor, buttonStyle, theme } = user.guestPreferences;
        let isRounded = false;
        if(buttonStyle === "rounded"){
            isRounded = true;
        }
        let isDark = false;
        if(theme === "dark"){
            isDark = true;
        }
        const cdn = Config.getCdnUrl();
        const logoUrl = cdn+"/app/Smearch_Icon_shaded.png";
        let template = "classic"
        if(user !== null && user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("template") && user.guestPreferences.template){
            template = user.guestPreferences.template;            
        }

        return (
            <Grid item>
                {
                    template === "simple" ?
                        <GridContainer justify="flex-start" alignItems="center" className={classes.gridContainerSocialIconsSpacing}>
                            <GridItem className={classes.userAvatarContainer+" "+classes.socialIconsContainer}>
                                <ul>
                                    {this.renderSocialIcons()}
                                </ul>
                            </GridItem>
                        </GridContainer>
                    :
                    <></>
                }
                <GridContainer justify="flex-start" alignItems="center" className={classes.gridContainerSpacing+" "+(isDark ? classes.dark : classes.light)}>
                    <form method="GET" action={"/"+username+"/results"} onSubmit={this.handleSubmit} className={classes.form+" wobble_effect"} >
                        <GridItem xs={12} lg={9} className={classes.marginAuto}>
                            <GridContainer alignItems="center" justify="space-between" className={classes.searchFormContainer}>
                                <GridItem className={"form-grid"}>
                                    <CustomAutocomplete 
                                        options={this.state.searchTerms}
                                        value={values.q ? values.q : ""}
                                        labelText={"Search "+username}
                                        onClear={() => this.clearSearch()}
                                        showClear={showClearSearch}
                                        disabledFirst={true}
                                        onChange={this.onSearchChange}
                                        onInputChange={(e, value) => this.onSearchInputChange(e, value, 'q')}
                                        onOpenOptions={(e) => this.onOpenOptions(e)}
                                        onCloseOptions={(e) => this.onCloseOptions(e)}
                                        autocompleteProps={{
                                            onKeyUp: (e) => this.onEnterSubmit(e),
                                            open: openStatus,
                                            disablePortal: true
                                        }}
                                        inputProps={{
                                            endAdornment: null, 
                                            autoFocus: true,
                                        }}
                                        popperClass={"autosuggest-popper"}
                                        color={primaryColor}
                                        startAdornment={
                                            (
                                                <InputAdornment 
                                                    className={classes.startornment}
                                                >
                                                    <Button justIcon simple onClick={this.toggleFilter} className={"searchButton filterButton"}>
                                                        <img src={logoUrl} alt={user.username} />
                                                    </Button>
                                                </InputAdornment>
                                            )
                                        }
                                        endAdornment={
                                            (
                                                <InputAdornment 
                                                    className={classes.endornment}
                                                >
                                                    {
                                                        showBetaFeatures ?
                                                            <MicIcon />
                                                        :
                                                            <></>
                                                    }
                                                    <Button type="submit" justIcon simple className={"searchButton"}>
                                                        <SearchIcon style={{color: this.getPrimaryColor(primaryColor)}} className={classes.icons} />
                                                    </Button>
                                                </InputAdornment>
                                            )
                                        }
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.autocomplete+" "+classes.searchFormInput+" "+(isRounded ? "round-input": "")+" search-form-input "+(showBetaFeatures ? "show-beta-features" : "hide-beta-features")+" "+(isOptionsOpen ? "options-open" : "")
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={12} lg={9} className={classes.filtersGrid+" "+classes.marginAuto +" "+(state.showFilter ?'':classes.hide) }>
                            <ul>
                                <li>
                                    <Button color="transparent" simple round={isRounded} onClick={(e) => this.handleFilterOption(e,'allFilter')} className={(values.allFilter === true ? "active" : "")}>
                                        All
                                    </Button>
                                </li>
                                {
                                    allowedFilters.hasOwnProperty("locationFilter") && allowedFilters.locationFilter ?
                                        <li>
                                            <Button color="transparent" justIcon round={isRounded} onClick={(e) => this.handleFilterOption(e,'locationFilter')} className={(values.locationFilter === true ? "active" : "")}>
                                                <PlaceIcon />
                                            </Button>
                                        </li>
                                    :
                                    <></>
                                }
                                {
                                    showBetaFeatures && allowedFilters.hasOwnProperty("shoppableFilter") && allowedFilters.shoppableFilter  ?
                                        <li>
                                            <Button color="transparent" justIcon round={isRounded} onClick={(e) => this.handleFilterOption(e,'shoppableFilter')} className={(values.shoppableFilter === true ? "active" : "")}>
                                                <ShoppingBagIcon className={classes.materialIcon+" "+classes.customIcon+" MuiSvgIcon-root"} />
                                            </Button>
                                        </li>
                                    :
                                    <></>
                                }
                                {
                                    allowedFilters.hasOwnProperty("instagramFilter") && allowedFilters.instagramFilter ?
                                        <li>
                                            <Button color="transparent" justIcon round={isRounded} onClick={(e) => this.handleFilterOption(e,'instagramFilter')} className={(values.instagramFilter === true ? "active" : "")}>
                                                <img src={instagramIcon} alt="instagram" className="instagram-icon" />
                                            </Button>
                                        </li>
                                    :
                                    <></>
                                }
                                {
                                    allowedFilters.hasOwnProperty("youtubeFilter") && allowedFilters.youtubeFilter ?
                                        <li>
                                            <Button color="transparent" justIcon round={isRounded} onClick={(e) => this.handleFilterOption(e,'youtubeFilter')} className={(values.youtubeFilter === true ? "active" : "")}>
                                                <img src={youtubeIcon} className="youtube-icon" alt="youtube"/>
                                            </Button>
                                        </li>
                                    :
                                    <></>
                                }
                                {
                                    allowedFilters.hasOwnProperty("linksFilter") && allowedFilters.linksFilter ?
                                        <li>
                                            <Button color="transparent" justIcon round={isRounded} onClick={(e) => this.handleFilterOption(e,'linksFilter')} className={(values.linksFilter === true ? "active" : "")}>
                                                <MaterialIcon path={mdiLink} className={classes.materialIcon} />
                                            </Button>
                                        </li>
                                    :
                                    <></>
                                }
                            </ul>
                        </GridItem>
                        <GridItem xs={12} lg={9} className={classes.advanceSearch+" "+classes.marginAuto +" "+(state.showAdvancedSearch ?'':classes.hide) }>
                            <GridContainer alignItems="center">
                                <GridItem xs={4} sm={4} md={4} lg={4}>
                                    <InputLabel className={classes.labelRoot}>Within</InputLabel>
                                    <GridContainer alignItems="center">
                                        <GridItem xs={6} sm={6} md={6} lg={6} className={classes.mobileWithinSpacing}>
                                            <CustomSelect 
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: "custom-select"
                                                }}
                                                selectProps={{
                                                    onChange: (e) => this.handleChange(e,"radius"),
                                                    value: state.values.radius
                                                }}
                                                inputProps={{
                                                    name: "radius",
                                                    id: "outlined-radius",
                                                    className: classes.alignLeft
                                                }}
                                                items={radiusArray}
                                                color={primaryColor}
                                            />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={6} lg={6} className={classes.mobileWithinSpacing}>
                                            <CustomSelect 
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: "custom-select"
                                                }}
                                                selectProps={{
                                                    onChange: (e) => this.handleChange(e,"radiusType"),
                                                    value: state.values.radiusType
                                                }}
                                                inputProps={{
                                                    name: "radiusType",
                                                    id: "outlined-radiusType",
                                                    className: classes.alignLeft
                                                }}
                                                items={["Mi","KM"]}
                                                color={primaryColor}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>                                        
                                <GridItem xs={8} sm={8} md={8} lg={8}>
                                    <ContentItemLocation 
                                        onChange={this.handleLocationChange} 
                                        radius={this.state.values.radius} 
                                        radiusType={this.state.values.radiusType} 
                                        googlePlacesResponse={{}} 
                                        requestUserLocation={true} 
                                        store={this.store}
                                        color={primaryColor}
                                        className={classes.locationSearch}
                                    />
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={12} lg={9} className={classes.marginAuto}>
                            {
                                false && (authorized && authorizedUser.hasOwnProperty("userPreferences") && authorizedUser.userPreferences.showBetaFeatures) ?
                                    <GridItem className={classes.dropdownGridItem}>
                                        <CustomDropdown
                                            buttonText={<FilterIcon className={classes.customIcon+" MuiSvgIcon-root"} />}
                                            caret={false}
                                            buttonProps={{
                                                justIcon: true,
                                                round: true,
                                                block: true,
                                                color: primaryColor,
                                                className: classes.dropdownButton
                                            }}
                                            className={classes.filterOptionsDropdown}
                                            selectedMulti={values.filterTypes}
                                            dropPlacement="bottom"
                                            onClick={this.handleFilterOptions}
                                            popperProps={{
                                                className: classes.filterOptionsDropdown
                                            }}
                                            //disabledList={[1,2]}
                                            dropdownList={[
                                                <Button type="button" data-ref="multi" color="transparent" justIcon>
                                                    <MaterialIcon path={mdiLink} className="MuiSvgIcon-root" />
                                                </Button>,
                                                <Button type="button" data-ref="multi" color="transparent" justIcon>
                                                    <i className={classes.customIcon+" fab fa-instagram "+classes.instagramLink} />
                                                </Button>,
                                                <Button type="button" data-ref="multi" color="transparent" justIcon>
                                                    <YouTubeIcon className={classes.customIcon} />
                                                </Button>,
                                            ]}
                                        />
                                    </GridItem>
                                :
                                <></>
                            }
                            {
                                false && contentLoaded ?
                                    <GridItem className={classes.dropdownGridItem}>
                                        <CustomDropdown
                                            buttonText={<MaterialIcon path={mdiEyeSettingsOutline} className="MuiSvgIcon-root" />}
                                            caret={false}
                                            buttonProps={{
                                                justIcon: true,
                                                round: true,
                                                block: true,
                                                color: primaryColor,
                                                className: classes.dropdownButton,
                                            }}
                                            onClick={this.handleViewOptions}
                                            popperProps={{
                                                className: classes.viewOptionsDropdown+" "+ (showBetaFeatures ? classes.viewOptionsDropdownBeta : "")
                                            }}
                                            selected={values.viewType}
                                            dropPlacement="bottom"
                                            dropdownList={this.getViewOptionsDropdown()}
                                        />
                                    </GridItem>
                                :
                                <></>
                            } 
                        </GridItem>
                    </form>
                </GridContainer>              
                <GridContainer justify="center" alignItems="center" className={classes.gridContainerSpacing}>
                    <GridItem xs={12} lg={9} className={classes.marginAuto}>
                        {
                            contentLoaded ?
                                (searchResults && searchResults.length > 0) || (buttons !== null && featuredLinks) ?
                                    featuredLinks ?
                                       <></>
                                    :
                                    <h4 className={classes.title}>Search Results</h4> 
                                :
                                userSearched ?
                                        state.showAdvancedSearch && values.location.length > 0 ?
                                            <h4 className={classes.title}>{user.username} has no search results containing "{values.q}" within {values.radius} {values.radiusType}(s) of {values.location}</h4> 
                                        :
                                            <h4 className={classes.title}>{user.username} has no search results containing "{values.q}"</h4> 
                                    :
                                    <></>
                            :
                            <></>
                        }
                        {
                            featuredLinks ?
                                <ButtonsView user={this.user} response={buttons} showButtons={showButtons} store={this.store} onOpen={(show, currentContent, key) => this.onSearchResultOpen(show, currentContent, key, true)} primaryColor={this.props.primaryColor} />
                            :
                            <></>
                        }
                        {
                            contentLoaded ?
                                searchResults ?
                                    <>
                                        {
                                            userSearched && user.userLevel <= 0 && searchResults.length > 0 ?
                                                this.renderDefaultButton()
                                            :
                                            <></>
                                        }
                                        {this.renderResults(searchResults)}
                                        {
                                            featuredLastKey !== null && featuredLoadingMore === false && featuredLinks === true && (maxFeatured === -1 || maxFeatured > searchResults.length) ?
                                                <Button className={classes.loadMore} color={primaryColor} id="featuredContentViewMore" round onClick={(e) => this.loadFeed(true)}>
                                                    <RefreshIcon className={classes.icons} />View More
                                                </Button>
                                            :
                                                featuredLoadingMore === true && featuredLinks === true ?
                                                    <LoaderComponent align="center" />
                                                :
                                                <></>
                                        }
                                        <Dialog
                                            classes={{
                                                root: classes.modalRoot+" searchFormResultsModal "+classes.modalRootCustom,
                                                paper: classes.modal+" "+classes.modalPaperCustom,
                                                scrollPaper: classes.paperScrollPaperCustom,
                                                scrollBody: classes.scrollBodyCustom,
                                            }}
                                            open={showModal}
                                            className={classes.modalCustom}
                                            TransitionComponent={Transition}
                                            keepMounted
                                            onClose={() => this.onSearchResultOpen(false)}
                                            aria-labelledby="classic-modal-slide-title"
                                            aria-describedby="classic-modal-slide-description"
                                        >   
                                            <div className={classes.modalCustom}>
                                                <DialogTitle
                                                    id="classic-modal-slide-title"
                                                    disableTypography
                                                    className={classes.modalHeader+" "+classes.modalHeaderCustom}
                                                    >
                                                    <Button
                                                        simple
                                                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                                                        key="close"
                                                        aria-label="Close"
                                                        onClick={() => this.onSearchResultOpen(false)}
                                                    >
                                                        {" "}
                                                        <Close className={classes.modalClose} />
                                                    </Button>                                    
                                                </DialogTitle>
                                                <DialogContent className={classes.modalBody+" "+classes.modalBodyCustom}>
                                                    {
                                                        currentContent ?
                                                            <Carousel {...settings} className={classes.carousel+" searchFormCarousel"}>
                                                                {
                                                                    searchResults.map((content, key) => {
                                                                        let slidePosition = null;
                                                                        // if(key === 0){
                                                                        //     slidePosition = "left";
                                                                        // }else if(contentLength === 2 && key === 1){
                                                                        //     slidePosition = "right";
                                                                        // }else if(contentLength > 2 && key > 1 && key === contentLength-1){
                                                                        //     slidePosition = "right";
                                                                        // }else if(contentLength > 2 && key < contentLength-1){
                                                                        //     slidePosition = "middle";
                                                                        // }

                                                                        return (
                                                                            <SearchResultSlide onSearchResultOpen={this.onSearchResultOpen} contentkey={key+1} slidePosition={slidePosition} searchResultsLength={contentLength} content={content} store={this.store} key={content.contentId} onEdit={(newContent) => this.onEdit(key, newContent)} onDelete={(key, content) => this.onDelete(key, content)} onCopy={this.props.onCopy} primaryColor={this.props.primaryColor} user={user} platforms={platforms} />
                                                                        )
                                                                    })
                                                                }
                                                            </Carousel>
                                                        :
                                                        <></>
                                                    }
                                                    
                                                </DialogContent>
                                            </div>
                                        </Dialog>
                                    </>
                                                                    
                                :
                                <></>
                            :
                            <>
                            {
                                loading ?
                                    <LoaderComponent align="center" color={primaryColor} />
                                :
                                <></>
                            }
                            </>
                        }
                    </GridItem> 
                </GridContainer>
                {
                    affiliateMode ?
                        <ContentAffiliateModal content={currentContent} open={affiliateMode} onClose={() => this.onAffiliateModalClose()} user={user} />  
                    :
                    <></>
                }
            </Grid>
        )
    }
}

SearchForm.defaultProps = {
    onPopupClose: function(){ },
    onCopy: function(){ },
    primaryColor: primaryColor
}
SearchForm.propTypes = {
    classes: PropTypes.object,
    onPopupClose: PropTypes.func,
    onCopy: PropTypes.func,
    primaryColor: PropTypes.string
};
  
export default withTheme(withStyles(searchFormStyle)(SearchForm));