import {
    cardTitle,
    whiteColor,
    grayColor,
    primaryColor,
    hexToRgb,
    blackColor
} from "../material-kit-pro-react.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
  
const loginModalStyle = theme => ({
    ...modalStyle(theme),
    textCenter: {
        textAlign: "center"
    },
    cardTitle: {
        ...cardTitle,
        textDecoration: "none",
        textAlign: "center !important",
        marginBottom: "0px !important",
        marginTop: "20px !important"
    },
    cardTitleWhite: {
        ...cardTitle,
        color: whiteColor + "  !important"
    },
    socialLine: {
        marginTop: "1rem",
        textAlign: "center",
        padding: "0"
    },
    socialLineButton: {
        "&, &:hover": { color: whiteColor },
        marginLeft: "5px",
        marginRight: "5px"
    },
    cardLoginHeader: {
        marginTop: "-40px",
        padding: "20px 0",
        width: "100%",
        marginBottom: "15px"
    },
    cardLoginBody: {
        paddingTop: "0",
        paddingBottom: "0"
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important"
    },
    anchor: {
        color: primaryColor[0],
        cursor: "pointer"
    },
    modalCloseButtonLogin: {
        color: grayColor[1]+" !important",
        top: "16px !important",
        right: "16px !important",
        position: "absolute !important",
        "& svg": {
            marginRight: "0px"
        }
    },
    forgotGridItem: {
        padding: "0px",
        display: "flex",
        justifyContent: "space-between"
    },
    modalHeader: {
        padding: "0px !important"
    },
    socialLinks: {
        marginTop: theme.spacing(2),
        "& a": {
            display: "inline-block"
        },
        "& button": {
            width: "300px",
            height: "42px",
            padding: "0px 1px",
            textTransform: "none",
            "& .MuiButton-label": {
                height: "42px",
                justifyContent: "start",
                padding: "1px 0px"
            },
            [theme.breakpoints.down("xs")]: {
                width: "270px"
            }
        },
        "& svg": {
            display: "inline-block",
            height: "100%",
            width: "36px",
            marginRight: "6px",
            padding: "6px",
            textAlign: "center",
            verticalAlign: "middle",
            "&.google-icon": {
                background: whiteColor,
                borderRadius: "2px"
            }
        }
    },
    googleButton: {
        backgroundColor: "#4285F4",
        boxShadow:
            "0 2px 2px 0 rgba(" +
            hexToRgb("#4285F4") +
            ", 0.14), 0 3px 1px -2px rgba(" +
            hexToRgb("#4285F4") +
            ", 0.2), 0 1px 5px 0 rgba(" +
            hexToRgb("#4285F4") +
            ", 0.12)",
        "&:hover,&:focus": {
            backgroundColor: "#4285F4",
            boxShadow:
                "0 14px 26px -12px rgba(" +
                hexToRgb("#4285F4") +
                ", 0.42), 0 4px 23px 0px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12), 0 8px 10px -5px rgba(" +
                hexToRgb("#4285F4") +
                ", 0.2)"
        }
    },
    chosenSettings: {
        marginTop: "5px"
    },
    errorsSpacing: {
        marginTop: "10px"
    }
});
  
export default loginModalStyle;
  