import Api from '../../assets/js/utils/Api';
import AuthApi from '../../assets/js/utils/Auth';

let topics = [];
if(localStorage.getItem("searchmysocial_topics") !== null){
    topics = JSON.parse(localStorage.getItem("searchmysocial_topics"));
}
let packages = [];
if(localStorage.getItem("searchmysocial_packages") !== null){
    packages = JSON.parse(localStorage.getItem("searchmysocial_packages"));
}
let authIdentity = null;
let authUsername = null;
if(localStorage.getItem("auth_identity") !== null){
    authIdentity = localStorage.getItem("auth_identity");
}
if(localStorage.getItem("auth_username") !== null){
    authUsername = localStorage.getItem("auth_username");
}

let onboarding = [];
if(localStorage.getItem("searchmysocial_onboarding") !== null){
    onboarding = JSON.parse(localStorage.getItem("searchmysocial_onboarding"));
}
let notifications = [];
if(localStorage.getItem("searchmysocial_sysnotifications") !== null){
    notifications = JSON.parse(localStorage.getItem("searchmysocial_sysnotifications"));
}
let fullstory = [];
if(localStorage.getItem("searchmysocial_fullstory") !== null){
    fullstory = JSON.parse(localStorage.getItem("searchmysocial_fullstory"));
}
let settings = null;
if(localStorage.getItem("searchmysocial_settings") !== null){
    settings = JSON.parse(localStorage.getItem("searchmysocial_settings"));
}

let initialState = {
    url: window.location.pathname,
    authorized: false,
    user: Api.getDefaultMemberObject(),
    mainLoading: false,
    topics: topics,
    packages: packages,
    isLocationEnabled: false,
    address: "",
    lat: "",
    lng: "",
    radius: "25",
    radiusType: "Miles",
    searchedUser: false,
    authIdentity: authIdentity,
    authUsername: authUsername,
    onboarding: onboarding,
    notifications: notifications,
    fullstory: fullstory,
    settings: settings
}

export default (state = initialState, action) => {
    if(!action.hasOwnProperty("state")){
        action.state = {};
    }
    switch (action.type) {
        case 'LOGOUT':
            action.state.authorized = false;
            action.state.mainLoading = false;
            //sendUserActivity("logged_out");
            action.state.user = Api.getDefaultMemberObject();
            initialState = Object.assign({}, state, action.state);
            return initialState;
        case 'LOGIN':
            action.state.authorized = true;
            action.state.mainLoading = false;
            initialState = Object.assign({}, state, action.state);         
            sendUserActivity("logged_in");

            //Handle idle refresh flow
            AuthApi.idleRefreshToken();

            //Update user logged in time
            initialState.user.lastLoggedInDateTime = new Date().toISOString();
            Api.updateUser(initialState.user.userId, initialState.user);
            return initialState;
        case 'UPDATE_STATE':
            initialState = Object.assign({}, state, action.state);
            return initialState;
        case 'LOADING':
            initialState = Object.assign({}, state, {
                mainLoading: true
            });
            return initialState;
        default:
        return state
    }
}

function sendUserActivity(activityType){
    var requestData = [];
    requestData.push({
        'activityType': activityType,
        'userId': initialState.user.userId
    });
    Api.sendActivity(requestData);
}