import React from 'react';
import Config from "../../../../Config";

// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from '@material-ui/core/styles/withTheme';
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Link from "@material-ui/core/Link";
import ButtonBase from '@material-ui/core/ButtonBase';

import buttonsViewStyle from "../../../assets/jss/user/buttonsViewStyle";
import defaultImage from "../../../assets/img/image_placeholder.jpg";

class ButtonsView extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.state = {
            user: this.props.user,
            response: this.props.response,
        };
        this.randomPlace = null;
    }
    componentWillReceiveProps(props){
        this.setState({
            response: props.response,
        });
    }
    getCoverPhoto(content){
        if(content.hasOwnProperty("coverMediaSourceUrl") && !content.hasOwnProperty("coverMedia") && 
        content.coverMediaSourceUrl && content.coverMediaSourceUrl.length > 0){
            return content.coverMediaSourceUrl;
        }
        if(!content.hasOwnProperty("coverMedia")){
            return defaultImage;
        }
        return content.coverMedia.downloadUrlProfile;
    }
    getTitle(content){
        if(!content.hasOwnProperty("title")){
            return;
        }
        if(content.title.length <= 64){
            return content.title;
        }
        return content.title.substr(0,64).trim()+"..."
    }
    isShowPicture(content){
        if(!content.hasOwnProperty("coverMedia") && !content.hasOwnProperty("coverMediaSourceUrl")){
            return false;
        }
        if(!content.hasOwnProperty("showPicture")){
            return true;
        }
        return content.showPicture;
    }
    getButtonStyles(){
        const { primaryColor } = this.props;
        const { fontSize, buttonStyle } = this.props.user.guestPreferences;
        const { palette } = this.props.theme;
        let buttonStyles = {
            backgroundColor: primaryColor, 
            fontSize: (fontSize === "large" ? "14px" : "12px"),
            borderRadius: (buttonStyle === "rounded" ? "30px" : "0px"),
            color: palette.custom.buttonText
        };
        return buttonStyles;
    }
    onAdClick(){
        const { user } = this.props;
        const signupUrl = Config.getAdminUrl()+"/signup?refType=ad_button&refLink=cta_free&refUId="+user.userId;
        window.location.href = signupUrl;
    }
    renderDefaultButton(spacingTop = false, spacingBottom = false, eachContentHasPictures = false){
        const { classes, user } = this.props;
        const buttonStyles = this.getButtonStyles();
        const { buttonStyle } = this.props.user.guestPreferences;
        const { authorized } = this.store.getState();
        const authorizedUser = this.store.getState().user;
        const cdn = Config.getCdnUrl();
        const logoUrl = cdn+"/app/Smearch_Icon_shaded.png";
        return (
            <div className={(spacingTop ? classes.gridSpacingTopAd : "")+" "+(spacingBottom ? classes.gridSpacingBottomAd : "")}>
                <GridItem xs={12} sm={12} md={12} lg={12} className={classes.gridItem+" "+classes.hidePicture} style={{borderRadius: (buttonStyle === "rounded" ? "30px" : "0px")}}>
                    <ButtonBase className={(eachContentHasPictures ? classes.buttonIcon : "")+ " "+classes.buttonBase+(buttonStyle === "rounded" ? " rounded" : "")}>
                        {
                            eachContentHasPictures ?
                                <img src={logoUrl} alt="logo" onClick={() => this.onAdClick()} />
                            :
                            <></>
                        }
                        <Link href={Config.getAdminUrl()+"/signup?refType=ad_button&refLink=cta_free&refUId="+user.userId} underline="none" style={buttonStyles}>
                            Get Your Own Search My Social Account - Free Forever!
                        </Link>
                        {
                            authorized && user.userId === authorizedUser.userId ?
                                <Link href={Config.getAdminUrl()+"/dashboard/account/upgrade?return=search"} className={classes.removeAd}>
                                    Remove this ad
                                </Link>
                            :
                            <></>
                        }
                    </ButtonBase>
                </GridItem>
            </div>
        )
    }
    render(){
        const { response } = this.state;
        const { classes, user, showButtons } = this.props;
        const buttonStyles = this.getButtonStyles();
        const { buttonStyle } = this.props.user.guestPreferences;
        if(this.randomPlace === null && showButtons && response !== null){
            let randomPlace = Math.floor(Math.random() * (response.length - 0 + 1) + 0);
            this.randomPlace = randomPlace;
        }
        let eachContentHasPictures = true;
        response.map((content, key) => {
            if(!this.isShowPicture(content)){
                eachContentHasPictures = false;
            }
            return null;
        });
        return (
            <GridContainer justify="center" alignItems="center" className={classes.gridContainer}>
                {
                    showButtons && response !== null && response.length > 0 ?
                        response.map((content, key) => (
                            <div key={key} className={classes.button+" "+classes.gridSpacing}>
                                {
                                    (key === this.randomPlace) && user.userLevel <= 0 ?
                                        this.renderDefaultButton(false, true, eachContentHasPictures)
                                    :
                                    <></>
                                }
                                <div data-type="Content" key={content.contentId} className={"sd_track_view"} data-id={content.contentId}>
                                    <GridItem xs={12} sm={12} md={12} lg={12} className={classes.gridItem+" "+classes.hidePicture} style={{borderRadius: (buttonStyle === "rounded" ? "30px" : "0px")}}>
                                        <ButtonBase className={(this.isShowPicture(content) ? classes.buttonIcon : "")+" "+classes.buttonBase+(buttonStyle === "rounded" ? " rounded" : "")}>
                                            {
                                                this.isShowPicture(content) ?
                                                    <img src={this.getCoverPhoto(content)} alt={content.title} onClick={() => this.props.onOpen(true, content, key)} />
                                                :
                                                <></>
                                            }
                                            <Link href={user.username+"/"+content.contentId} onClick={(e) => {e.preventDefault(); this.props.onOpen(true, content, key)}} underline="none" style={buttonStyles}>{content.title}</Link>
                                        </ButtonBase>
                                    </GridItem>
                                </div>
                                {
                                    (key !== this.randomPlace && this.randomPlace === response.length && (key+1) === this.randomPlace) && user.userLevel <= 0 ?
                                        this.renderDefaultButton(true, false, eachContentHasPictures)
                                    :
                                    <></>
                                }
                            </div>
                        ))
                    :
                        user.userLevel <= 0 ?
                            <div className={classes.button+" "+classes.gridSpacing}>
                                {this.renderDefaultButton(false, false)}
                            </div>
                        :
                        <></>
                }
            </GridContainer>
        );
    }
}

ButtonsView.defaultProps = {
    onOpen: function(){ },
    showButtons: true
}
ButtonsView.propTypes = {
    classes: PropTypes.object,
    onOpen: PropTypes.func,
};
  
export default withTheme(withStyles(buttonsViewStyle)(ButtonsView));