import {
    cardTitle,
    whiteColor,
    grayColor
} from "../material-kit-pro-react.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
import signupPageStyle from "../material-kit-pro-react/views/signupPageStyle.jsx";
  
const signupModalStyle = theme => ({
    ...modalStyle(theme),
    ...signupPageStyle(theme),
    textCenter: {
        textAlign: "center"
    },
    cardTitleWhite: {
        ...cardTitle,
        color: whiteColor + "  !important"
    },
    socialLine: {
        marginTop: "1rem",
        textAlign: "center",
        padding: "0"
    },
    socialLineButton: {
        "&, &:hover": { color: whiteColor },
        marginLeft: "5px",
        marginRight: "5px"
    },
    cardLoginHeader: {
        marginTop: "-40px",
        padding: "20px 0",
        width: "100%",
        marginBottom: "15px"
    },
    cardLoginBody: {
        paddingTop: "0",
        paddingBottom: "0"
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important"
    },
    customModalSignup: {
        marginTop: "50px !important",
        [theme.breakpoints.down("xs")]: {
            marginTop: "0px !important",
        }
    },
    modalSignupCard: {
        margin: "0px"
    },
    modalHeader: {
        padding: "0px"
    },
    modalCloseButton: {
        position: "absolute !important",
        top: "0px !important",
        right: "0px !important",
        "&, &:hover": {
            color: grayColor[1]+" !important",
        },
        "&:hover": {
            opacity: "1"
        },
        cursor: "pointer",
        padding: "1rem",
        backgroundColor: "transparent",
        border: "0",
        WebkitAppearance: "none",
        fontSize: "1.5rem",
        fontWeight: "500",
        lineHeight: "1",
        textShadow: "0 1px 0 " + whiteColor,
        opacity: ".5",
        margin: "0px",
        "& svg": {
            marginRight: "0px"
        }
    },
    modalBody: {
        padding: "0px 24px",
        position: "relative"
    },
    modalGridContainer: {
        marginLeft: '0px',
        marginRight: "0px"
    },
    botFields: {
        display: "none !important"
    },
    recaptcha: {
        position: "absolute",
        visibility: "hidden",
        left: "0px",
        right: "0px",
        width: "100%",
        background: whiteColor,
        height: "230px",
        zIndex: "1",
        "& .g-recaptcha > div": {
            margin: "0px auto"
        },
    },
    showRecaptcha: {
        visibility: "visible",
    }
});
  
export default signupModalStyle;
  