import React from "react";
import Config from '../../../Config';
import { Helmet } from "react-helmet";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import NavPills from "../components/NavPills/NavPills.jsx";
import Button from "../components/CustomButtons/Button.jsx";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Link from "@material-ui/core/Link";
import { HashLink } from 'react-router-hash-link';
import MaterialIcon from "@mdi/react";
import MarketingFooter from "../components/MarketingFooter"
import InstagramImportTooltipModal from "../components/ToolTips/InstagramImportTooltipModal";
import YoutubeImportTooltipModal from "../components/ToolTips/YoutubeImportTooltipModal";
import ConciergeSetupTooltipModal from "../components/ToolTips/ConciergeSetupTooltipModal";
import { helper } from '../../assets/js/utils/Element';
import { 
    mdiInformationOutline,
} from '@mdi/js';

import featureComparisonPageStyle from "../../assets/jss/material-kit-pro-react/views/featureComparisonPageStyle.jsx";

const FeatureComparison = class extends React.Component {
    constructor(props){
        super(props);

        this.navPills = React.createRef();
        this.state = {
            billingFrequency: "Monthly",
            plan: 0,
            tooltips: {
                instagramImport: false,
                youtubeImport:  false,
                conciergeSetup: false
            },
        };

        this.history = this.props.history;
        this.store = this.props.store;
    }
    billingFrequencyChange(tabId){
        this.setState({
            billingFrequency: (tabId === 0 ? "Monthly" : "Annually")
        });
    }
    planChange(tabId){
        this.setState({
            plan: tabId
        });
    }
    planPrice(plan = null){
        const { classes } = this.props;
        const { billingFrequency } = this.state;
        if(plan === null){
            plan = this.state.plan;
        }
        if(plan === 0){
            return <p className={classes.featurePricing}><b>$0</b> forever</p>;
        }
        if(plan === 1){
            if(billingFrequency.toLowerCase() === "monthly"){
                return <p className={classes.featurePricing}><b>$5</b> /mo</p>;
            }else{
                return <p className={classes.featurePricing}><b>$50</b> /year<span>(Save $10)</span></p>;
            }
        }
        if(plan === 2){
            if(billingFrequency.toLowerCase() === "monthly"){
                return <p className={classes.featurePricing}><b>$14.99</b> /mo</p>;
            }else{
                return <p className={classes.featurePricing}><b>$149.99</b> /year<span>(Save $29.89)</span></p>;
            }
        }
        return "";
    }
    renderFeatureIcon(feature, plan = null){
        if(plan === null && isWidthUp("md", this.props.width)){
            return null;
        }
        if(plan === null){
            plan = this.state.plan;
        }
        const { classes } = this.props;
        let label = null;
        switch(plan){
            case 0:
                label = <span className={classes.danger+" "+classes.responsivePlanFeature+" "+classes.centerResponsiveIcons}><Close /></span>;
                if(feature === 1 || feature === 2 || feature === 3 || feature === 4 || feature === 5 || feature === 6 || feature === 7 || feature === 8 || feature === 9 || feature === 10 || feature === 14){
                    label = <span className={classes.success+" "+classes.responsivePlanFeature+" "+classes.centerResponsiveIcons}><Check /></span>;
                }else if(feature === 17){
                    label = <span className={classes.bold+" "+classes.responsivePlanFeature+" "+classes.centerResponsivePrice}>$99 one time <a href="mailto:support@smearch.com">Contact Us!</a></span>
                }
                break;
            case 1:
                label = <span className={classes.success+" "+classes.responsivePlanFeature+" "+classes.centerResponsiveIcons}><Check /></span>;
                if(feature === 17){
                    label = <span className={classes.bold+" "+classes.responsivePlanFeature+" "+classes.centerResponsivePrice}>$99 one time <a href="mailto:support@smearch.com">Contact Us!</a></span>
                }
                break;
            case 2:
                label = <span className={classes.success+" "+classes.responsivePlanFeature+" "+classes.centerResponsiveIcons}><Check /></span>;
                if(feature === 17){
                    label = <span className={classes.bold+" "+classes.responsivePlanFeature+" "+classes.centerResponsivePrice}>$99 one time <a href="mailto:support@smearch.com">Contact Us!</a></span>
                }
                break;
            default:
                break;
        }
        return label;
    }
    renderSignupLink(plan = null){
        if(plan === null){
            plan = this.state.plan;
        }
        const { classes } = this.props;
        const adminAppUrl = Config.getAdminUrl();
        let label = null;
        switch(plan){
            case 0:
                label = <Link underline="none" href={adminAppUrl+"/signup?"+window.location.search.substr(1)+"&refType=smearch_site_sms_compare&refLink=cta_free"}>
                        <Button size="sm" color="primary" className={classes.primaryButton}>Sign Up Now!</Button>
                    </Link>;
                break;
            case 1:
                label = <Link underline="none" href={adminAppUrl+"/signup?"+window.location.search.substr(1)+"&refType=smearch_site_sms_compare&refLink=cta_premium"} >
                        <Button size="sm" color="primary" className={classes.primaryButton}>Sign Up Now!</Button>
                    </Link>;
                break;
            case 2:
                label = <Link underline="none" href={adminAppUrl+"/signup?"+window.location.search.substr(1)+"&refType=smearch_site_sms_compare&refLink=cta_premiumplus"} >
                        <Button size="sm" color="primary" className={classes.primaryButton}>Sign Up Now!</Button>
                    </Link>;
                break;
            default:
                break;
        }
        return label;
    }
    onTooltip(name, status){
        this.setState({
            tooltips: {
                [name]: status
            }
        });
    }
    render() {
        const { classes } = this.props;
        const { plan, tooltips } = this.state;
        const cdnUrl = Config.getCdnUrl();
        const chromeExtensionUrl = Config.getChromeExtensionUrl();
        const image = cdnUrl+"/www/homebanner.jpg";
        return (
            <>
                <div>
                    <div
                        className={classes.navigation}
                        style={{ backgroundImage: "url(" + image + ")" }}
                        >
                    </div>
                    <div className={classNames(classes.main, classes.mainRaised)}>
                        <div>
                            <h1 className={classes.title}>Feature Comparison</h1>
                            {
                                isWidthUp("md", this.props.width) ?
                                    <></>
                                :
                                    <div className={classes.plans}>
                                        <NavPills
                                            alignCenter
                                            color="rose"
                                            active={plan}
                                            onChange={(tabId) => this.planChange(tabId)}
                                            tabs={[
                                                {
                                                    tabButton: "Free",
                                                    className: "plan_tab"
                                                },
                                                {
                                                    tabButton: "Professional",
                                                    className: "plan_tab"
                                                },
                                                // {
                                                //     tabButton: "Premium Plus",
                                                //     className: "plan_tab"
                                                // }
                                            ]}
                                        />
                                    </div>
                            }
                            {/* <div className={classes.billingFrequency}>
                                <NavPills
                                    ref={this.navPills}
                                    alignCenter
                                    color="rose"
                                    active={(billingFrequency.toLowerCase() === "monthly" ? 0 : 1)}
                                    onChange={(tabId) => this.billingFrequencyChange(tabId)}
                                    tabs={[
                                        {
                                            tabButton: "Monthly",
                                        },
                                        {
                                            tabButton: "Yearly",
                                        }
                                    ]}
                                />
                            </div> */}
                        </div>
                        <GridContainer className={classes.featuresContainer}>
                            <GridItem xs={12} sm={12} md={6}>
                                
                                <ul className={classes.features+" "+classes.featureDetails}>
                                    {
                                        isWidthUp("md", this.props.width) ?
                                            <li>
                                                {" "}
                                            </li>
                                        :
                                        <></>
                                    }
                                    <li className={classes.featurePricing}>
                                        {
                                            isWidthUp("md", this.props.width) ?
                                                <p className={classes.featurePricing+" "+classes.featuresHeading+" "+classes.centerFeaturesLabel}>{"Features"}</p>
                                            :
                                            this.planPrice()
                                        }
                                    </li>
                                    <li className={classes.centerFeaturesLabel}>
                                        <p>Personalized Bio Link Search Engine</p>
                                        <span>Your content will never again be "lost in the feed"</span>
                                        {this.renderFeatureIcon(1)}
                                    </li>
                                    <li className={classes.centerFeaturesLabel}>
                                        <p>
                                            Instagram Import and Auto-Populate
                                            <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('instagramImport', true)} />
                                        </p>
                                        <span>Load your search engine in seconds!</span>
                                        {this.renderFeatureIcon(8)}
                                    </li>
                                    <li className={classes.centerFeaturesLabel}>
                                        <p>
                                            YouTube Import and Auto-Populate
                                            <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('youtubeImport', true)} />
                                        </p>
                                        <span>Load your search engine in seconds!</span>
                                        {this.renderFeatureIcon(8)}
                                    </li>
                                    <li className={classes.centerFeaturesLabel}>
                                        <p>Web links and Buttons</p>
                                        <span>All the features of other bio link apps</span>
                                        {this.renderFeatureIcon(2)}
                                    </li>
                                    <li className={classes.centerFeaturesLabel}>
                                        <p>Photos</p>
                                        <span>Add or import photos for your links</span>
                                        {this.renderFeatureIcon(3)}
                                    </li>
                                    <li className={classes.centerFeaturesLabel}>
                                        <p>Click-Through Links</p>
                                        <span>Add blog or affiliate links to any search result</span>
                                        {this.renderFeatureIcon(4)}
                                    </li>
                                    <li className={classes.centerFeaturesLabel}>
                                        <p>Personalized About Me Page</p>
                                        <span>No more need for a blog!</span>
                                        {this.renderFeatureIcon(5)}
                                    </li>
                                    <li className={classes.centerFeaturesLabel}>
                                        <p>Top Search Terms</p>
                                        <span>Help your fans find your best content with quick click buttons</span>
                                        {this.renderFeatureIcon(6)}
                                    </li>
                                    <li className={classes.centerFeaturesLabel}>
                                        <p>Social Media Channel Links</p>
                                        <span>All your online accounts in one place</span>
                                        {this.renderFeatureIcon(7)}
                                    </li>
                                    <li className={classes.centerFeaturesLabel}>
                                        <p>Download our <Link underline="none" target="_blank" href={chromeExtensionUrl}>Chrome Extension</Link></p>
                                        <span>Easily and quickly load any public web content (e.g. blog) with one click</span>
                                        {this.renderFeatureIcon(9)}
                                    </li>
                                    <li className={classes.centerFeaturesLabel}>
                                        <p>Custom Themes</p>
                                        <span>Background Images, Fonts, Colors, & More!</span>
                                        {this.renderFeatureIcon(10)}
                                    </li>
                                    <li className={classes.centerFeaturesLabel}>
                                        <p>Discount Codes</p>
                                        <span>Add your personal discount code to any content</span>
                                        {this.renderFeatureIcon(11)}
                                    </li>
                                    <li className={classes.centerFeaturesLabel}>
                                        <p>Shopping Links</p>
                                        <span>Visually indicate which of your search results can be purchased at the search link</span>
                                        {this.renderFeatureIcon(12)}
                                    </li>
                                    <li className={classes.centerFeaturesLabel}>
                                        <p>Scheduled Posts</p>
                                        <span>Time your search results or featured links to go live with your promotions</span>
                                        {this.renderFeatureIcon(13)}
                                    </li>
                                    <li className={classes.centerFeaturesLabel}>
                                        <p>Ad Free Experience</p>
                                        {this.renderFeatureIcon(18)}
                                    </li>
                                    <li className={classes.centerFeaturesLabel}>
                                        <p>Remove Search My Social Branding</p>
                                        {this.renderFeatureIcon(19)}
                                    </li>
                                    <li className={classes.centerFeaturesLabel}>
                                        <p>Support</p>
                                        <span>Forums, Videos & Knowledge Base</span>
                                        {this.renderFeatureIcon(14)}
                                    </li>
                                    <li className={classes.centerFeaturesLabel}>
                                        <p>Pro Support</p>
                                        <span>Phone, email, and in-app chat</span>
                                        {this.renderFeatureIcon(15)}
                                    </li>
                                    <li className={classes.centerFeaturesLabel}>
                                        <p>
                                            Concierge Setup
                                            <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('conciergeSetup', true)} />
                                        </p>
                                        <span>Let us help you setup your account!</span>
                                        {this.renderFeatureIcon(17)}
                                    </li>
                                    <li className={classes.centerFeaturesLabel}></li>
                                    <li>
                                        {
                                            isWidthUp("md", this.props.width) ?
                                                " "
                                            :
                                                this.renderSignupLink()
                                        }
                                    </li>
                                </ul>
                            </GridItem>
                            {
                                isWidthUp("md", this.props.width) ?
                                    <GridItem xs={12} sm={6} md={6} className={classes.freePlan+" "+classes.centerFeatureIcons}>
                                        <ul className={classes.features}>
                                            <li>
                                                Free
                                            </li>
                                            <li>
                                                {this.planPrice(0)}
                                            </li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.danger}><Close /></li>
                                            <li className={classes.danger}><Close /></li>
                                            <li className={classes.danger}><Close /></li>
                                            <li className={classes.danger}><Close /></li>
                                            <li className={classes.danger}><Close /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.danger}><Close /></li>
                                            <li>
                                                {this.renderFeatureIcon(17, 0)}
                                            </li>
                                            <li></li>
                                            <li>
                                                {this.renderSignupLink(0)}
                                            </li>
                                        </ul>
                                    </GridItem>
                                :
                                <></>
                            }
                            {
                                isWidthUp("md", this.props.width) ?
                                    <GridItem xs={12} sm={6} md={6} className={classes.premiumPlan+" "+classes.centerFeatureIcons}>
                                        
                                        <ul className={classes.features}>
                                            <li>
                                                Pro
                                            </li>
                                            <li>
                                                <p className={classes.featurePricing}>
                                                    {
                                                        this.planPrice(1)
                                                    }
                                                </p>
                                            </li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li>
                                                {this.renderFeatureIcon(17, 1)}
                                            </li>
                                            <li>Recommended for Influencers</li>
                                            <li>
                                                {this.renderSignupLink(1)}
                                            </li>
                                        </ul>
                                    </GridItem>
                                :
                                <></>
                            }
                            {/* {
                                isWidthUp("md", this.props.width) ?
                                    <GridItem xs={12} sm={6} md={2} className={classes.premiumPlusPlan+" "+classes.centerFeatureIcons}>
                                        
                                        <ul className={classes.features}>
                                            <li>
                                                Premium Plus
                                            </li>
                                            <li>
                                                <p className={classes.featurePricing}>
                                                    {
                                                        this.planPrice(2)
                                                    }
                                                </p>
                                            </li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.bold}>Whenever you want</li>
                                            <li className={classes.bold}>Whenever you want</li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li className={classes.success}><Check /></li>
                                            <li>
                                                {this.renderFeatureIcon(17, 2)}
                                            </li>
                                            <li>Recommended for Brands and Public Figures</li>
                                            <li>
                                                {this.renderSignupLink(2)}
                                            </li>
                                        </ul>
                                    </GridItem>
                                :
                                <></>
                            } */}
                        </GridContainer>
                        <div className={classes.productOverview}>
                            <HashLink to="/home#overview" scroll={el => el.scrollIntoView({ behavior: 'smooth' })}>Product Overview</HashLink>
                        </div>
                    </div>
                    {
                        tooltips.instagramImport ?
                            <InstagramImportTooltipModal open={tooltips.instagramImport} onClose={() => this.onTooltip('instagramImport', false)} />
                        :
                        <></>
                    }
                    {
                        tooltips.youtubeImport ?
                            <YoutubeImportTooltipModal open={tooltips.youtubeImport} onClose={() => this.onTooltip('youtubeImport', false)} />
                        :
                        <></>
                    }
                    {
                        tooltips.conciergeSetup ?
                            <ConciergeSetupTooltipModal open={tooltips.conciergeSetup} onClose={() => this.onTooltip('conciergeSetup', false)} />
                        :
                        <></>
                    }
                    <Helmet 
                        htmlAttributes={{
                            class: helper.getBodyClass(this.history.location.pathname)
                        }}
                        title={process.env.REACT_APP_TITLE}
                    />
                </div>
                <MarketingFooter />
            </>
        );
    }
};

export default withWidth()(withStyles(featureComparisonPageStyle)(FeatureComparison));
