import React from "react";
import { Helmet } from "react-helmet";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Config from '../../../../Config';
import ScrollAnimation from "react-animate-on-scroll";
import { Link as RouterLink } from "react-router-dom";

import "animate.css/animate.min.css";
// import tiktok from "../../../assets/img/tiktok.png";

import productSectionStyle from "../../../assets/jss/material-kit-pro-react/views/Smearch/productSectionStyle.jsx";

const ProductSection = class extends React.Component {
    constructor(props) {
        super(props);

        this.store = this.props.store;
    }
    render() {
        const cdnUrl = Config.getCdnUrl();
        const amz = cdnUrl+"/www/amz.png";
        const fcb = cdnUrl+"/www/fcb.png";
        const insta = cdnUrl+"/www/insta.png";
        const mltformats = cdnUrl+"/www/mltformats_smearch_s.png";
        const pdcst = cdnUrl+"/www/pdcst.png";
        const tiktok = cdnUrl+"/www/tiktok.png";
        const pntrst = cdnUrl+"/www/pntrst.png";
        const wblnk = cdnUrl+"/www/wblnk.png";
        const wrprs = cdnUrl+"/www/wrprs.png";
        const { classes, ...rest } = this.props;
        return (
            <div className="cd-section" {...rest}>
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem xs={12} md={11}>
                            <h2 className={classes.title}>Upgrade Your Link In Bio Today!</h2>
                            <h5 className={classes.description}>It only takes 5 minutes to set-up! We will import your existing content and auto-populate your future content.<br/>Learn about our features and find out what package is right for you!</h5>
                        </GridItem>
                        <GridItem className={classes.buttonSection}>
                            <RouterLink to="/feature-comparison">
                                <Button size={"lg"} className={"button"}>Features and Packages</Button>
                            </RouterLink>
                        </GridItem>
                    </GridContainer>
                    {/* <div className={classes.features}>
                        <GridContainer>
                        <GridItem md={4} sm={4}>
                            <InfoArea
                                title="Searchable"
                                description="Whether you are creating a mix of content or focused in one niche, with Search My Social, your Fans can search your feed by keyword, location, or both, letting them focus their search as specifically, or as broadly as they want."
                                icon={Search}
                                iconColor="danger"
                                vertical={true}
                            />
                        </GridItem>
                        <GridItem md={4} sm={4}>
                            <InfoArea
                                title="Linkable"
                                description="Add click through or affiliate links to your search results, letting your audience quickly access your recipes, shopping links, travel blogs, and more... even months after they’ve moved down your feed! Set up your Search Results to link directly to your website, or through the optional viewing window - the choice is yours!"
                                icon={Link}
                                iconColor="primary"
                                vertical={true}
                            />
                        </GridItem>
                        <GridItem md={4} sm={4}>
                            <InfoArea
                                title="Shopable"
                                description="Your Search My Social profile can be used to maximize your online potential, by adding affiliate or secondary links to your search link results, a shopping icon,  and discount codes that go with them!"
                                icon={ShoppingCart}
                                iconColor="success"
                                vertical={true}
                            />
                        </GridItem>
                        </GridContainer>
                    </div> */}
                    <GridContainer justify="center">
                        <GridItem md={12} className={classes.mlAuto}>
                            <div className={classes.imageContainer}>
                                <div className={classes.animeInstaImg}>
                                    <ScrollAnimation animateIn="fadeInUp">
                                        <img
                                            src={insta}
                                            alt="Instagram"
                                            className={classes.instaImg+" "+classes.imgIcon}
                                        />
                                    </ScrollAnimation>
                                </div>
                                <div className={classes.animePntrstImg}>
                                    <ScrollAnimation animateIn="fadeInUp">
                                        <img
                                            src={pntrst}
                                            alt="Pinterest"
                                            className={classes.pntrstImg+" "+classes.imgIcon}
                                        />
                                    </ScrollAnimation>
                                </div>
                                <div className={classes.animeWpImg}>
                                    <ScrollAnimation animateIn="fadeInUp">
                                        <img
                                            src={wrprs}
                                            alt="Wordpress"
                                            className={classes.wpImg+" "+classes.imgIcon}
                                        />
                                    </ScrollAnimation>
                                </div>
                                <div className={classes.animeYtImg}>
                                    <ScrollAnimation animateIn="fadeInUp">
                                        <Button justIcon simple className={classes.ytImg+" fontIcon "+classes.imgIcon}>
                                            <i className="fab fa-youtube" />
                                        </Button>
                                    </ScrollAnimation>
                                </div>
                                <div className={classes.animeTiktokImg}>
                                    <ScrollAnimation animateIn="fadeInUp">
                                        <img
                                            src={tiktok}
                                            alt="Tiktok"
                                            className={classes.tiktokImg+" "+classes.imgIcon}
                                        />
                                    </ScrollAnimation>
                                </div>
                                <div className={classes.animeWlImg}>
                                    <ScrollAnimation animateIn="fadeInUp">
                                        <img
                                            src={wblnk}
                                            alt="Weblink"
                                            className={classes.wlImg+" "+classes.imgIcon}
                                        />
                                    </ScrollAnimation>
                                </div>
                                <div className={classes.animeAmzImg}>
                                    <ScrollAnimation animateIn="fadeInUp">
                                        <img
                                            src={amz}
                                            alt="Amazon"
                                            className={classes.amzImg+" "+classes.imgIcon}
                                        />
                                    </ScrollAnimation>
                                </div>
                                <div className={classes.animeFbImg}>
                                    <ScrollAnimation animateIn="fadeInUp">
                                        <img
                                            src={fcb}
                                            alt="Facebook"
                                            className={classes.fbImg+" "+classes.imgIcon}
                                        />
                                    </ScrollAnimation>
                                </div>
                                <div className={classes.animePdcstImg}>
                                    <ScrollAnimation animateIn="fadeInUp">
                                        <img
                                            src={pdcst}
                                            alt="Rss"
                                            className={classes.pdcstImg+" "+classes.imgIcon}
                                        />
                                    </ScrollAnimation>
                                </div>
                                <img
                                    className={classes.searchMySocialImg}
                                    src={mltformats}
                                    alt="Search My Social"
                                />
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
                <Helmet>
                    <title>Smearch - Product</title>
                </Helmet>
            </div>
        );
    }
};

export default withStyles(productSectionStyle)(ProductSection);
