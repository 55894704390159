/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import Cancel from "@material-ui/icons/Cancel";
// core components

import MaterialIcon from "@mdi/react";
import { mdiLoading, mdiInformationOutline, mdiLockOutline } from '@mdi/js';

import customInputStyle from "../../../assets/jss/material-kit-pro-react/components/customInputStyle.jsx";

class CustomInput extends React.Component {
    constructor(props){
        super(props);

        this.input = React.createRef();
        this.forceFocus = false;
        this.onChange = this.onChange.bind(this);
        this.onShowInfo = this.onShowInfo.bind(this);
    }
    getClearIcon(){
        const { inputProps } = this.props;
        if(inputProps.hasOwnProperty("disabled") && inputProps.disabled){
            return null;
        }
        if(inputProps.hasOwnProperty("readonly") && inputProps.readonly){
            return null;
        }
        if(this.props.showClear){
            return this.getCancelIcon();
        }
        if(inputProps.hasOwnProperty("value")){
            if(inputProps.value.trim().length > 0){
                return this.getCancelIcon();
            }else{
                return null;
            }
        }
        if(this.input.current === null){
            return null;
        }
        let input = this.input.current.querySelector("input");
        if(!input){
            input = this.input.current.querySelector("textarea");
        }
        if(!input){
            return null;
        }
        if(input.tagName === "TEXTAREA" || input.tagName === "textarea"){
            if(input.value.trim().length <= 0 && input.innerHTML.trim().length <= 0){
                return null;
            }
        }else{
            if(input.value.trim().length <= 0){
                return null;
            }
        }
        return this.getCancelIcon();        
    }
    getCancelIcon(){
        const { inputProps, classes } = this.props;
        let hasEndAdornment = false;
        if(inputProps.hasOwnProperty("endAdornment")){
            hasEndAdornment = true;
        }
        return <Cancel onClick={(e) => this.clearContents(e)} value="" className={classes.feedback + " " + classes.cancelIcon+" "+(hasEndAdornment?classes.hasClearEndAdornment:"")} />
    }
    clearContents(e){
        const { inputProps } = this.props;
        let input = this.input.current.querySelector("input");
        if(!input){
            input = this.input.current.querySelector("textarea");
        }
        if(!input){
            return null;
        }
        input.value = "";

        if(this.props.onClear){
            this.props.onClear(e);
            return;
        }
        if(inputProps.onChange){
            e.nativeEvent.target.value = "";
            inputProps.onChange(e.nativeEvent);
        }
        input.focus();
    }
    onChange(e){
        if(this.props.inputProps.onChange){
            this.props.inputProps.onChange(e);
        }
    }
    onShowInfo(){
        // const { inputProps } = this.props;
        // if(inputProps.hasOwnProperty("disabled") && inputProps.disabled){
        //     return;
        // }
        if(this.props.onShowInfo){
            this.props.onShowInfo();
        }
    }
    render(){
        const {
            classes,
            formControlProps,
            labelText,
            id,
            labelProps,
            inputProps,
            error,
            white,
            inputRootCustomClasses,
            success,
            loading,
            showInfoTooltip,
            showPrivateIcon
        } = this.props;
        let color = this.props.color;
        if(typeof(color) === "string" && color.indexOf("#") !== -1){
            color = "custom";
        }

        const labelClasses = classNames({
            [" " + classes.labelRootError]: error,
            [" " + classes.labelRootSuccess]: success && !error,
            [classes[color]]: true
        });
        const underlineClasses = classNames({
            [classes.underlineError]: error,
            [classes.underlineSuccess]: success && !error,
            [classes.underline]: true,
            [classes.whiteUnderline]: white,
            [classes[color]]: true
        });
        const marginTop = classNames({
            [inputRootCustomClasses]: inputRootCustomClasses !== undefined
        });
        const inputClasses = classNames({
            [classes.input]: true,
            [classes.whiteInput]: white
        });
        var formControlClasses;
        if (formControlProps !== undefined) {
            formControlClasses = classNames(
            formControlProps.className,
            classes.formControl
            );
        } else {
            formControlClasses = classes.formControl;
        }
        let hasEndAdornment = false;
        if(inputProps.hasOwnProperty("endAdornment")){
            hasEndAdornment = true;
        }
        const hasClearIcon = this.getClearIcon();

        return (
            <FormControl {...formControlProps} className={formControlClasses}>
                {labelText !== undefined ? (
                    <InputLabel
                        className={classes.labelRoot + " " + labelClasses}
                        htmlFor={id}
                        {...labelProps}
                    >
                        {labelText}
                        {
                            showPrivateIcon ?
                                <MaterialIcon path={mdiLockOutline} className="MuiSvgIcon-root private_icon info_tooltip_icon" />
                            :
                            <></>
                        }
                        {
                            showInfoTooltip ?
                                <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={this.onShowInfo} />
                            :
                            <></>
                        }
                    </InputLabel>
                ) : null}
                <Input
                    classes={{
                        input: inputClasses,
                        root: marginTop,
                        disabled: classes.disabled,
                        underline: underlineClasses
                    }}
                    id={id}
                    ref={this.input}
                    {...inputProps}
                    onChange={this.onChange}
                />
                {loading ? (
                    <MaterialIcon path={mdiLoading} className={classes.feedback+" "+classes.animate+" MuiSvgIcon-root "+(hasEndAdornment?classes.hasEndAdornment:"")+" "+(hasClearIcon?classes.hasClearIcon:"")} />
                ) : error ? (
                    <Clear className={classes.feedback + " " + classes.labelRootError+" "+(hasEndAdornment?classes.hasEndAdornment:"")+" "+(hasClearIcon?classes.hasClearIcon:"")} />
                ) : success ? (
                    <Check className={classes.feedback + " " + classes.labelRootSuccess+" "+(hasEndAdornment?classes.hasEndAdornment:"")+" "+(hasClearIcon?classes.hasClearIcon:"")} />
                ) : null }
                {hasClearIcon}
            </FormControl>
        )
    }
}

CustomInput.defaultProps = {
    loading: false,
    inputProps: {
        value: "",
        onChange: function(){ }
    },
    onClear: null,
    showClear: false,
    showInfoTooltip: false,
    onShowInfo: function(){ },
    showPrivateIcon: false,
    color: "primary"
}

CustomInput.propTypes = {
    classes: PropTypes.object.isRequired,
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
    loading: PropTypes.bool,
    onClear: PropTypes.func,
    showClear: PropTypes.bool,
    showInfoTooltip: PropTypes.bool,
    onShowInfo: PropTypes.func,
    showPrivateIcon: PropTypes.bool,
    // color: PropTypes.oneOf([
    //     "primary",
    //     "info",
    //     "success",
    //     "gray",
    //     "rose",
    //     "customInfo",
    //     "customSuccess",
    //     "customRose",
    //     ""
    // ]),
};

export default withStyles(customInputStyle)(CustomInput);
