import {
    whiteColor,
    blackColor,
    hexToRgb,
    primaryColor,
    title,
    grayColor
} from "../../../assets/jss/material-kit-pro-react.jsx";

import rotatingCards from "../../../assets/jss/material-kit-pro-react/rotatingCards.jsx";

const streamViewCardStyle = theme => ({
    ...rotatingCards,
    marginAuto: {
        margin: "0px auto"
    },
    removePadding: {
        padding: "0px",
        [theme.breakpoints.down("xs")]: {
            padding: "0px 3px"
        }
    },
    gridItem: {
        margin: "2px auto",
        paddingLeft: "0px",
    },
    streamCardLists: {
        width: "100%",
        margin: "0px"
    },
    gridListRoot: {
        width: "100%",
    },  
    icons: {
        width: "17px",
        height: "17px",
        color: whiteColor
    },
    center: {
        textAlign: "center"
    },
    gridList: {
        width: "100%",
        display: 'block',
    },
    gridRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        padding: "0px"
    },
    gridTile: {
        height: "auto !important",
        overflow: "hidden",
        width: "100%",
        margin: "0px auto",
        borderRadius: "6px",
        "& h3": {
            margin: "0px"
        },
        "& img": {
            transform: "none",
            height: "auto",
            left: "auto",
            top: "auto",
            width: "100%"
        }
    },
    hidePicture: {
        width: "100%",
        height: "auto !important",
        margin: "0px auto",
        padding: "0px !important",
        borderRadius: "6px",
        overflow: "hidden"
    },
    featuredIcon: {
        position: "absolute",
        left: "5px",
        top: "5px",
        zIndex: 1,
        width: "30px",
        height: "30px",
        color: whiteColor,
        [theme.breakpoints.down('sm')]:{
            width: "18px",
            height: "18px",
        }
    },
    cartIcon: {
        position: "absolute",
        right: "5px",
        bottom: "5px",
        zIndex: 1,
        width: "30px",
        height: "30px",
        color: whiteColor,
        [theme.breakpoints.down('sm')]:{
            width: "18px",
            height: "18px",
        }
    },
    overlay: {
        background: "rgba(" +
            hexToRgb(blackColor) +
            ", 0.12)",
        position: "absolute",
        height: "100%",
        width: "100%",
        display: "block",
        left: "0px",
        right: "0px",
        top: "0px",
        bottom: "0px",
        cursor: "pointer"
    },
    buttonBase: {
        height: "100%",
        width: "100%"
    },
    title: {
        ...title,
        fontSize: "20px",
        marginTop: "0px",
        marginBottom: theme.spacing(1),
        textAlign: "left",
        minHeight: "0px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "15px",
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: "0px"
        }
    },
    locationContainer: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        alignItems: "center"
    },
    location: {
        textAlign: "left",
        fontSize: "13px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        marginBottom: "0px"
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    iconButton: {
        marginTop: '4px',
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    iconButtonBlock: {
        display: "block"
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2)
    },  
    coverPhoto: {
        marginLeft: "15px",
        marginRight: "15px",
        marginTop: "-30px",
        borderRadius: "6px",
        zIndex: "1",
        "& img": {
            borderRadius: "6px",
            height: "260px",
            objectFit: "cover",
            backgroundColor: whiteColor,
            width: "100%",
            boxShadow:
                "0 5px 15px -8px rgba(" +
                hexToRgb(blackColor) +
                ", 0.24), 0 8px 10px -5px rgba(" +
                hexToRgb(blackColor) +
                ", 0.2), 0px 10px 10px 1px rgba(" +
                hexToRgb(blackColor)+", 0.2)",
        },
        "& iframe": {
            borderRadius: "6px",
            height: "260px",
            objectFit: "cover",
            width: "100%",
            boxShadow:
                "0 5px 15px -8px rgba(" +
                hexToRgb(blackColor) +
                ", 0.24), 0 8px 10px -5px rgba(" +
                hexToRgb(blackColor) +
                ", 0.2), 0px 10px 10px 1px rgba(" +
                hexToRgb(blackColor)+", 0.2)",
        }
    },
    slideContent: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginLeft: "0px",
        marginRight: "0px",
        paddingBottom: "20px"
    },
    manageButtonPadding: {
        paddingBottom: "20px"
    },
    label: {
        color: grayColor[12] + " !important",
        fontSize: "14px"
    },
    menuItems: {
        width: "auto",
        alignSelf: "center",
        paddingRight: "0px",
        paddingLeft: "0px",
    },
    dropdownLink: {
        "&,&:hover,&:focus": {
            color: "inherit",
            textDecoration: "none",
            display: "flex",
            padding: "0.75rem 1.25rem 0.75rem 0.75rem",
            alignItems: "center"
        },
        "& i": {
            marginRight: "5px"
        },
        "&:hover": {
            "& svg, svg *": {
                fill: whiteColor
            }
        }
    },
    dropdownButton: {
        textTransform: "Capitalize",
        margin: "0px"
    },
    dropdownManager: {
        display: "inline-flex"
    },
    contentTypeIcon: {
        width: "22px",
        height: "22px",
        minWidth: "22px",
        padding: "0px",
        margin: "0px",
        "& svg": {
            width: "100%",
            height: "100%",
        },
        "& i": {
            fontSize: "20px !important",
            justifyContent: "center",
            alignItems: "center",
            display: "flex !Important",
            [theme.breakpoints.down('sm')]:{
                fontSize: "16px !important"
            }
        },
    },
    customIcon: {
        fill: primaryColor[0],
        color: primaryColor[0],
    },
    autoWidth: {
        width: "auto",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    resetMargins: {
        marginLeft: "0px",
        marginRight: "0px"
    },
    dotsSection: {
        lineHeight: "51px",
        position: "absolute",
        left: "0px",
        right: "0px",
        maxWidth: "80px",
        margin: "0px auto",
        textAlign: "center",
        fontSize: "13px"
    },
    googleMapLink: {
        "& button": {
            padding: "0px",
            margin: "0px",
            float: "right",
        },
        "& svg": {
            width: "40px",
            height: "40px",
            color: "#6f6f6f"
        },
    },
    dotCircle: {
        border: "1px solid "+blackColor,
        borderRadius: "100%",
        width: "5px",
        height: "5px",
        display: "inline-block",
        margin: "0px 5px",
        "&.active":{
            backgroundColor: blackColor
        }
    },
    customAccordionIcon:{
        textAlign: "left",
        paddingLeft: "28px",
        "& div[class*='Accordion-root']": {
            marginBottom: "0px"
        },
        "& .MuiExpansionPanelSummary-root": {
            paddingTop: "15px"
        },
        '& .Mui-expanded':{
            '& .MuiIconButton-label:before':{
                content:"'\\f057'",
                display:"inline-block",
                fontFamily: "'Font Awesome 5 Free'",
                fontWeight: 900,
            },
        },
        '& .MuiExpansionPanelSummary-expandIcon':{
            position:"absolute !important",
            left:"-40px !important",
            right: "auto !important",
            top: "5px !important",
            '& .MuiIconButton-label:before':{
                content:"'\\f055'",
                display:"inline-block",
                fontFamily: "'Font Awesome 5 Free'",
                fontWeight: 900,
                MozOsxFontSmoothing: "grayscale",
                WebkitFontSmoothing: "antialiased",
                fontStyle: "normal",
                fontVariant: "normal",
                textRendering: "auto",
                lineHeight: 1
            },
            '& svg':{
                display:"none",
            },
        } 
    },
    viewMore: {
        display: "block",
        textAlign: "center",
        padding: "5px",
        boxSizing: "border-box",
        fontSize: "16px",
        maxWidth: "100%",
        width: "100%",
        marginTop: "15px",
        textTransform: "capitalize",
        boxShadow: "none !important"
    },
    manageButtons: {
        "& >div": {
            "&:hover": {
                cursor: "pointer",
            }
        },
        textAlign: "left",
        "& .sd-icon": {
            width: "40px",
            height: "40px",
            minWidth: "40px",
            padding: "0px",
            marginRight: "10px",
        },
        "& .sd-icon svg": {
            width: "30px",
            height: "30px",
        },
    },
    carouselHeightFix: {
        height: "auto !important"
    },
    optionsContainer: {
        minHeight: "51px"
    },
    copyButton: {
        marginBottom: "0px"
    },
    textAlign: {
        textAlign: "center",
        fontSize: "12px"
    },
    contentCoverPhoto: {
        position: "relative"
    },
    addPhotoText: {
        position: "absolute",
        bottom: "2px",
        right: "0px",
        left: "0px",
        width: "95%",
        textAlign: "center",
        border: "2px solid "+whiteColor,
        margin: "0px auto",
        padding: "5px",
        "& p": {
            margin: "0px",
            fontSize: "20px",
            fontWeight: "500",
            "& button": {
                margin: "0px",
                padding: "0px 5px",
                fontSize: "20px",
                textTransform: "none",
                lineHeight: "normal",
                verticalAlign: "bottom",
                color: primaryColor[0]+" !important",
                textDecoration: "underline !Important"
            }
        }
    },
    textCenter: {
        textAlign: "center"
    },
    manageCoverPhoto: {
        borderRadius: "0px",
        width: "120px !important",
        height: "120px !important",
        objectFit: "cover",
        margin: "0px auto"
    },
    managePhotoSection: {
        margin: "20px 0px"
    },
    rotateButton: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        position: "absolute",
        bottom: "0px",
        left: "0px",
        right: "0px",
    },
    cardBodyRotate: {
        padding: "0px"
    },
    rotatingCardContainerCustom: {
        marginBottom: "30px !important"
    },
    customFront: {
        transition: "all 0.3s ease",
        maxWidth: "100% !important",
        width: "100% !important",
        margin: "0px auto",
        left: "0px !important",
        right: "0px !important",
        position: "static !important"
    },
    customBack: {
        transition: "all 0.3s ease",
        maxWidth: "100% !important",
        width: "100% !important",
        margin: "0px auto",
        left: "0px !important",
        right: "0px !important"
    },
    cardRotateCustom: {
        boxShadow: "none",
        // marginTop: "0px",
        // marginBottom: "0px"
    },
    card: {
        border: "0",
        marginBottom: "30px",
        marginTop: "30px",
        borderRadius: "6px",
        color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
        background: whiteColor,
        width: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        minWidth: "0",
        wordWrap: "break-word",
        fontSize: ".875rem",
        // some jss/css to make the cards look a bit better on Internet Explorer
        "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
          display: "inline-block !important"
        }
    },
    darkCard: {
        backgroundColor: grayColor[29],
        "& $front, img": {
            backgroundColor: grayColor[29]+" !important",
            color: whiteColor
        },
        "& [class*='MuiPaper-root']": {
            backgroundColor: grayColor[29]+" !important",
            "& [class*='MuiButtonBase-root']": {
                color: whiteColor+" !important"
            }
        },
        "& h3, h6, p": {
            color: whiteColor+" !important"
        },
        "& .MuiCollapse-wrapper": {
            color: whiteColor
        }
    },
    cardBody: {
        flex: "1 1 auto",
        position: "relative",
        WebkitBoxFlex: "1"
    },
    searchableIcon: {
        color: blackColor+" !important",
        border: "0px !important",
        boxShadow: "none !important"
    },
    buttonView: {
        height: "auto !important",
        margin: "2px auto",
        paddingLeft: "0px",
        paddingRight: "0px",
        "& a": {
            background: primaryColor[0],
            color: whiteColor,
            padding: theme.spacing(2,1),
            cursor: "pointer",
            fontWeight: "bold",
            minHeight: "56px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            fontSize: "18px"
        },
        [theme.breakpoints.down('xs')]: {            
            width: "100% !important"
        },
        boxShadow:
            "0 2px 2px 0 rgba(" +
            hexToRgb(theme.palette.custom.main) +
            ", 0.14), 0 3px 1px -2px rgba(" +
            hexToRgb(theme.palette.custom.main) +
            ", 0.2), 0 1px 5px 0 rgba(" +
            hexToRgb(theme.palette.custom.main) +
            ", 0.12)",
    },
    buttonIcon: {
        "& a": {
            paddingLeft: "65px",
            paddingRight: "65px",
        },
        "& img": {
            width: "50px",
            height: "50px",
            objectFit: "cover",
            position: "absolute",
            left: "10px",
        }
    }
});

export default streamViewCardStyle;