import {
    title,
    whiteColor,
    primaryColor,
    hexToRgb,
} from "../../../assets/jss/material-kit-pro-react.jsx";

const buttonsViewStyle = theme => ({
    marginAuto: {
        margin: "0px auto"
    },
    gridContainer: {
        [theme.breakpoints.down('xs')]: {
            marginLeft: "-4px",
            marginRight: "-4px"
        }
    },
    gridSpacing: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginBottom: "6px",
        }
    },
    button: {
        width: "100%",
        marginLeft: "4px",
        marginRight: "4px"
    },
    gridItem: {
        margin: "2px auto",
        paddingLeft: "0px",
    },
    title: {
        ...title,
        fontSize: "20px",
        marginTop: "0px",
        marginBottom: theme.spacing(1),
        textAlign: "left",
        minHeight: "0px",
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: {
            fontSize: "15px",
        },
    },
    hidePicture: {
        height: "auto !important",
        margin: "2px auto",
        paddingLeft: "0px",
        paddingRight: "0px",
        overflow: "hidden",
        "& a": {
            background: primaryColor[0],
            color: whiteColor,
            padding: theme.spacing(2,1),
            cursor: "pointer",
            fontWeight: "bold",
            minHeight: "56px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            fontSize: "12px"
        },
        [theme.breakpoints.down('xs')]: {            
            width: "100% !important"
        },
        boxShadow:
            "0 2px 2px 0 rgba(" +
            hexToRgb(theme.palette.custom.main) +
            ", 0.14), 0 3px 1px -2px rgba(" +
            hexToRgb(theme.palette.custom.main) +
            ", 0.2), 0 1px 5px 0 rgba(" +
            hexToRgb(theme.palette.custom.main) +
            ", 0.12)",
    },
    buttonBase: {
        height: "100%",
        width: "100%",
        "&.rounded $removeAd": {
            paddingRight: "20px !important",
            [theme.breakpoints.down("xs")]: {
                paddingRight: "22px !important",
            }
        }
    },
    buttonIcon: {
        "& a": {
            paddingLeft: "65px",
            paddingRight: "65px",
        },
        "& img": {
            width: "50px",
            height: "50px",
            objectFit: "cover",
            position: "absolute",
            left: "10px",
        },
        "&.rounded": {
            "& img": {
                borderRadius: "100%",
            }
        },
        [theme.breakpoints.down('xs')]: {
            "& a": {
                paddingLeft: "45px",
                paddingRight: "45px",
            },
            "& img": {
                width: "36px",
                height: "36px",
                left: "5px"
            },
        }
    },
    removeAd: {
        position: "absolute",
        background: "none !important",
        width: "130px !important",
        right: "0px",
        alignItems: "flex-end !important",
        padding: "2px 5px !important",
        color: theme.palette.custom.buttonText+" !important",
        fontWeight: "normal !important",
        fontStyle: "italic",
        fontSize: "12px",
        textAlign: "right",
        display: "block !important",
        minHeight: "0px !important",
        bottom: "0px",
        [theme.breakpoints.down("xs")]: {
            padding: "2px 5px !important",
        }
    },
    gridSpacingTopAd: {
        marginTop: "20px",
        [theme.breakpoints.down('sm')]: {
            marginTop: "10px",
        }
    },
    gridSpacingBottomAd: {
        marginBottom: "20px",
        [theme.breakpoints.down('sm')]: {
            marginBottom: "10px",
        }
    },
});

export default buttonsViewStyle;