import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
// import Email from "@material-ui/icons/Email";
import Code from "@material-ui/icons/Code";
import Close from "@material-ui/icons/Close";

import signupCodeModalStyle from "../../../assets/jss/auth/signupCodeModalStyle.jsx";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const SignupCodeModal = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        
        this.state = {
            signupCodeEmail: "",
            signupCode:"",
            showError:false,
            errorMessage: "",
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSignup = this.handleSignup.bind(this);
    }

    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    handleSignup(e){
        e.preventDefault();
        if(this.state.signupCode === "smearch2019"){
            this.setState({signupCode: this.state.signupCode});
            this.props.showSignup(this.state.signupCode);
        }
        else{
            this.setState({showError: true , errorMessage: 'Your code was not accepted'});
        }
    }

    render() {
        const { classes } = this.props;
        const { showError, errorMessage} = this.state;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="signupcode-modal-slide-title"
                aria-describedby="signupcode-modal-slide-description"
                >
                <DialogTitle
                    id="signupcode-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>This is a closed beta.  Please enter your beta code in the form</h4>                     
                </DialogTitle>
                <DialogContent
                    id="signupcode-modal-slide-description"
                    className={classes.modalBody}
                    >
                    <GridContainer>
                        <GridItem sm={8} xs={7}>
                            <CustomInput
                                id="signupCode"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    placeholder: "Beta Code",
                                    type: "text",
                                    onChange: (e) => this.handleChange(e, 'signupCode'),
                                    name: "signupCode",
                                    value: this.state.signupCode,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                        <Code className={classes.inputIconsColor} />
                                        </InputAdornment>
                                    )
                                }}
                            /> 
                        </GridItem>
                        <GridItem sm={4} xs={5} className={classes.sendBtn}>
                            <Button
                                onClick={this.handleSignup}
                                color="info"
                                round
                            >
                                LET ME IN!
                            </Button>
                        </GridItem>
                        {
                            showError ?
                            <GridItem >
                                <span className={classes.red}>{errorMessage}</span>
                            </GridItem>
                            :
                            <></>
                        }  
                    </GridContainer>    
                    {/* <GridContainer>
                        <GridItem sm={9} xs={7}>
                            <CustomInput
                                id="signupCodeEmail"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    placeholder: "If you are not invited to the beta, enter your email to be notified of the next beta test",
                                    type: "email",
                                    onChange: (e) => this.handleChange(e, 'signupCodeEmail'),
                                    name: "signupCodeEmail",
                                    value: this.state.email,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                        <Email className={classes.inputIconsColor} />
                                        </InputAdornment>
                                    )
                                }}
                            /> 
                        </GridItem>
                        <GridItem sm={3} xs={5} className={classes.sendBtn}>
                            <Button
                                color="info"
                                round
                            >
                                SEND
                            </Button>
                        </GridItem>
                    </GridContainer> */}
                </DialogContent>
            </Dialog>
        )
    }
}

SignupCodeModal.defaultProps = {
    open: false
}
SignupCodeModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
}
export default withStyles(signupCodeModalStyle)(SignupCodeModal);