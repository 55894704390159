import React from 'react';
import { helper } from '../../assets/js/utils/Element';
import Cookie from '../../assets/js/utils/Cookie';

import Accordion from "../components/Accordion/Accordion.jsx";
import { makeStyles } from '@material-ui/core/styles';
import {
    main,
    container,
} from "../../assets/jss/material-kit-pro-react.jsx";
const useStyles = makeStyles(theme => ({
    ...main,
    container: {
        ...container,
        padding: "0px !important"
    },
}));

const accordionStyles = makeStyles(theme => ({
    root: {
        overflow: "hidden",
        [theme.breakpoints.down("md")]: {
            marginBottom: "0px"
        }        
    },
    expansionPanel: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        paddingBottom: theme.spacing(2),
    },
    expansionPanelDetails: {
        paddingRight: theme.spacing(2)
    }
}));

export default function Debug(props){
    const classes = useStyles();
    const accordianClasses = accordionStyles();
    const store = props.store;
    const storageName = props.domain+"_app_version";
    let currentVersion = localStorage.getItem(storageName);
    if(currentVersion === null || !currentVersion){
        currentVersion = "0.0.0";
    }
    const appState = store.getState();
    if(process.env.REACT_APP_DEBUG !== true && process.env.REACT_APP_DEBUG !== "true"){
        return <></>
    }
    let debugInfo = [];
    debugInfo.push(<li key="version">App Version: {currentVersion}</li>);
    if(appState.user.userId.length > 0){
        debugInfo.push(<li key="userid">UserId: {appState.user.userId}</li>);
    }
    if(Cookie.read("_ga")){
        debugInfo.push(<li key="googleanalytics">Google Analytics Identifier: {Cookie.read("_ga")}</li>);
    }
    if(navigator.userAgent.indexOf("Instagram") !== -1){
        debugInfo.push(<li key="instagram">Instagram: true</li>);
    }else{
        debugInfo.push(<li key="instagram">Instagram: false</li>);
    }
    debugInfo.push(<li key="userAgent">User Agent: {navigator.userAgent}</li>);
    debugInfo.push(<li key="referrer">Referrer: {document.referrer}</li>);
    debugInfo.push(<li key="route">Page Route: {appState.url}</li>);

    let cookiesInfo = [];
    const cookies = Cookie.getAll();
    for(var i in cookies){
        cookiesInfo.push(<li key={i}>{helper.capitalize(i)}: {JSON.stringify(cookies[i])}</li>);
    }
    debugInfo.push(<li key="cookies">Cookies: <ul>{cookiesInfo}</ul></li>);

    let appStateInfo = [];
    for(var key in appState){
        appStateInfo.push(<li key={key}>{helper.capitalize(key)}: {JSON.stringify(appState[key])}</li>);
    }
    debugInfo.push(<li key="appState">Application State: <ul>{appStateInfo}</ul></li>);
    return (
        <div className={classes.container+" "+classes.main+" app_debug_info"}>
            <Accordion 
                activeColor="rose"
                classes={accordianClasses}
                collapses={
                    [{
                        "title": "Debug Info BE v1 1.1.2",
                        "content": <ul>{debugInfo}</ul>
                    }]
                }
            
            >
            </Accordion>
        </div>
        
    )
}