import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Instruction from "../Instruction/Instruction.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import toolTipsModalStyle from "../../../assets/jss/tooltips/toolTipsModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ConciergeSetupTooltipModal = class extends React.Component {    
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="conciergeSetup-modal-slide-title"
                aria-describedby="conciergeSetup-modal-slide-description"
            >
                <DialogContent
                    id="conciergeSetup-modal-slide-description"
                    className={classes.modalBody}
                    >
                    <Instruction
                        text={
                            <span>
                                1. Concierge Setup
                            </span>
                        }
                        className={classes.instructionNoticeModal}
                        imageClassName={classes.imageNoticeModal}
                    />
                    <Instruction
                        text={
                            <span>
                                2. You can be up and running on our app in minutes, but new software can still be intimidating.  We can hold your hand through the process, or even do all of you initial account setup.  For a one time fee we will help you import your instagram, link any other relevant blogs or content, and also setup useful search terms for your audience.  You will be up and running in no time!
                            </span>
                        }
                        className={classes.instructionNoticeModal}
                        imageClassName={classes.imageNoticeModal}
                    />
                    <Instruction
                        text={
                            <span>
                                3. Contact us for more info <a href="mailto:support@smearch.com">Here!</a>
                            </span>
                        }
                        className={classes.instructionNoticeModal}
                        imageClassName={classes.imageNoticeModal}
                    />                    
                </DialogContent>
                <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                    <div>
                        <Button
                            onClick={() => this.props.onClose()}
                            color="gray"
                            round
                        >
                            Close
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        )
    }
}

ConciergeSetupTooltipModal.defaultProps = {
    open: false
}
ConciergeSetupTooltipModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
}
export default withStyles(toolTipsModalStyle)(ConciergeSetupTooltipModal);