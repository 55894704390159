/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  container,
  title,
  main,
  whiteColor,
  grayColor,
  mainRaised,
  hexToRgb,
  sectionDescription,
  youtubeColor,
} from "../../../material-kit-pro-react.jsx";

const productSectionStyle = theme => ({
  main: {
    ...main
    /*overflow: "hidden"*/
  },
  mainRaised,
  parallax: {
    height: "90vh",
    overflow: "hidden"
  },
  container: {
    ...container,
    zIndex: 1
  },
  brand: {
    color: whiteColor,
    textAlign: "center",
    "& h1": {
      fontSize: "4.2rem",
      fontWeight: "600",
      display: "inline-block",
      position: "relative"
    }
  },
  proBadge: {
    position: "relative",
    fontSize: "22px",
    textTransform: "uppercase",
    fontWeight: "700",
    right: "-10px",
    padding: "10px 18px",
    top: "-30px",
    background: whiteColor,
    borderRadius: "3px",
    color: grayColor[18],
    lineHeight: "22px",
    boxShadow: "0 5px 5px -2px rgba(" + hexToRgb(grayColor[25]) + ",.4)"
  },
  sectionDescription,
  title: {
    ...title,
    [theme.breakpoints.down("md")]: {
        fontSize: "2rem",
        lineHeight: "normal"
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: "1.8rem",
    },
    [theme.breakpoints.down("xs")]: {
        fontSize: "1.5rem",
    },
    "& + $description": {
      marginBottom: "10px",
      marginTop: "0px"
    }
  },
  description: {
    color: grayColor[0],
    marginTop: "35px",
    marginBottom: "15px"
  },
  imageContainer: {
    position: "relative",
    marginTop: theme.spacing(5),
  },
  searchMySocialImg: {
    width: "90%",
    [theme.breakpoints.down("md")]: {
        width: "80%"
    },
  },
  imgIcon: {
    width: "72px",
    top: "50%",
    left: "0%",
    "&.fontIcon": {    
        width: "68px",
        height: "68px",
        backgroundColor: youtubeColor,
        color: whiteColor,
        "& .fab": {
            lineHeight: "68px",
            fontSize: "30px",
        }
    },
    [theme.breakpoints.down("md")]: {
        width: "50px",
        "&.fontIcon": {    
            width: "48px",
            height: "48px",
            "& i.fab": {
                lineHeight: "48px",
                fontSize: "24px",
            }
        },
    },
    [theme.breakpoints.down("sm")]: {
        width: "40px",
        "&.fontIcon": {   
            minWidth: "38px", 
            width: "38px",
            height: "38px",
            "& i.fab": {
                lineHeight: "38px",
                fontSize: "20px",
            }
        },
    },
    [theme.breakpoints.down("xs")]: {
        width: "30px",
        "&.fontIcon": {    
            minWidth: "28px",
            width: "28px",
            height: "28px",
            "& i.fab": {
                lineHeight: "28px",
                fontSize: "16px",
            }
        },
    }
  },
  animeInstaImg: {
    top: "0%",
    left: "0%",
    position: "absolute",
    [theme.breakpoints.down("md")]: {
        left: "1%"
    },
    [theme.breakpoints.down("sm")]: {
        top: "-10%"
    }
  },
  animePntrstImg: {
    top: "19%",
    left: "5%",
    position: "absolute",
    [theme.breakpoints.down("md")]: {
        left: "7%",
        top: "17%"
    },
    [theme.breakpoints.down("sm")]: {
        top: "12%"
    }
  },
  animeWpImg: {
    top: "35%",
    left: "-3%",
    position: "absolute",
    [theme.breakpoints.down("md")]: {
        left: "1%"
    }
  },
  animeYtImg: {
    top: "54%",
    left: "0%",
    position: "absolute",
    [theme.breakpoints.down("xs")]: {
        left: "1%"
    }
  },
  animeTiktokImg: {
    top: "5%",
    left: "70%",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
        top: "-7%"
    }
  },
  animeWlImg: {
    top: "0%",
    left: "82%",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
        top: "-10%"
    }
  },
  animeAmzImg: {
    top: "23%",
    left: "79%",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
        top: "15%"
    }
  },
  animeFbImg: {
    top: "20%",
    left: "90%",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
        top: "12%"
    }
  },
  animePdcstImg: {
    top: "42%",
    left: "89%",
    position: "absolute"
  },
    buttonSection: {
        "& .button, & .button:hover": {
            display: "block",
            backgroundColor: "#4da9ef",
            borderRadius: "20px",
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            marginRight: "auto",
            marginLeft: "auto",
            textTransform: "Capitalize",
            fontWeight: "bold",
        },
    }
});

export default productSectionStyle;
