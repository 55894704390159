import {
  container,
  mlAuto,
  mrAuto,
  title,
  description,
  whiteColor,
} from "../../../material-kit-pro-react.jsx";

const videoStyle = theme => ({
    mlAuto,
    mrAuto,
    description,
    container: {
        overflow: "hidden",
    },
    title: {
        ...title,
        fontWeight: "600",
        fontSize: "40px",
        marginBottom: "48px",
        [theme.breakpoints.down("md")]: {
            fontSize: "2rem",
            lineHeight: "normal"
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.8rem",
            marginBottom: "30px"
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "1.5rem",
        },
    },
    cardSection: {
        ...container,
        paddingBottom: "48px",
        paddingTop: "0px",
        [theme.breakpoints.down("sm")]: {
            width: "90%",
            boxShadow: "none",
            margin: "0 auto",
            paddingBottom: "48px",
        },
    },
    textCenter: {
        textAlign: "center"
    },
    topHolder: {
        [theme.breakpoints.down("sm")]: {
            display: "flex",
        },
    },
    leftSide: {
        cursor: "pointer",
        paddingLeft: "0px",
        paddingRight: "0px",
        backgroundColor: whiteColor,
        borderTopLeftRadius: "10px",
        borderBottomLeftRadius: "10px",
        display: "flex",
    },
    videoContainer: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        borderRadius: "4px",
        [theme.breakpoints.down("md")]: {
            height: "400px",
        },
        [theme.breakpoints.down("sm")]: {
            height: "350px",
        },
        [theme.breakpoints.down("xs")]: {
            height: "250px",
        },
        "& img": {
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
            objectFit: "cover",
            [theme.breakpoints.down("sm")]: {
                borderTopRightRadius: "10px",
                borderBottomLeftRadius: "0px",
            },
        }
    },
    imgSvgIcon: {
        backgroundColor: whiteColor,
        width: "62px",
        height: "62px",
        cursor: "pointer",
        borderRadius: "50% !important",
        position: "absolute !important",
        alignItems: "center !important",
        justifyContent: "center",
        display: "flex",
        "& svg": {
            overflow: "hidden",
            color: "#5CC7CE",
        },
        "&:hover": {
            backgroundColor: "#5CC7CE",
            "& svg": {
                color: whiteColor
            }
        }
    },
    rightSide: {
        borderTopRightRadius: "10px",
        borderBottomRightRadius: "10px",
        backgroundColor: whiteColor,
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "48px",
        paddingBottom: "48px",
        textAlign: "left",
        [theme.breakpoints.down("sm")]: {
            paddingTop: "0px",
            paddingBottom: "48px",
            textAlign: "left"
        },
        [theme.breakpoints.down("xs")]: {
            paddingTop: "0px",
            paddingBottom: "0px"
        },
    },
    titleHolder: {
        paddingTop: "48px",
        paddingLeft: "16px",
        paddingRight: "16px",
        "& h4": {
            fontSize: "16px",
            letterSpacing: "1",
            color: "#5CC7CE",
            fontWeight: "bold",
            textTransform: "uppercase",
            marginBottom: "0px",
            paddingBottom: "0px",
            paddingTop: "0px",
            paddingLeft: "48px",
            paddingRight: "48px",
            [theme.breakpoints.down("xs")]: {
                textAlign: "center",
            },
        }
    },
    descriptionHolder: {
        paddingBottom: "48px",
        paddingLeft: "16px",
        fontWeight: "600",
        paddingRight: "16px",
        [theme.breakpoints.down("xs")]: {
            paddingBottom: "0px",
        },
        "& p": {
            fontSize: "33px",
            lineHeight: "1.3",
            paddingLeft: "48px",
            paddingRight: "48px",
            [theme.breakpoints.down("md")]: {
                fontSize: "28px",
            },
            [theme.breakpoints.down("sm")]: {
                fontSize: "24px",
            },
            [theme.breakpoints.down("xs")]: {
                textAlign: "center",
                fontSize: "20px",
                paddingLeft: "8px",
                paddingRight: "8px",
            },
        }
    }
});

export default videoStyle;
