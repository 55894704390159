import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import Api from '../../../../assets/js/utils/Api';
import contentFlagModalStyle from "../../../../assets/jss/dashboard/content/contentFlagModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ContentFlagModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        const authorizedUser = this.props.authorizedUser;

        this.state = {
            content: this.props.content,
            reason: "",
            email: authorizedUser.userEmail,
            flagSubmit: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.sendActivity = this.sendActivity.bind(this);
    }
    componentWillReceiveProps(props){
        this.setState({
            content: props.content,
        });
    }
    handleChange(event, name) {
        this.setState({
            [name]: event.target.value
        });
    }
    sendActivity(){
        var requestData = [];
        requestData.push({
            'activityType': "content_flagged",
            'contentId': this.state.content.contentId,
            'data': {
                'reason': this.state.reason,
                'email': this.state.email,
            }
        });
        Api.sendActivity(requestData);
        this.setState({
            flagSubmit: true,
        })
    }
    render() {
        const { classes } = this.props;
        const { content, reason, email, flagSubmit } = this.state;
        return (
            <Dialog
                classes={{
                root: classes.modalRoot,
                paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="deleteContent-slide-title"
                aria-describedby="deleteContent-slide-description"
            >
                {
                    flagSubmit ?
                        <DialogTitle
                            id="deleteContent-slide-title"
                            disableTypography
                            className={classes.modalHeader}
                            >
                            <h4 className={classes.modalTitle}>Thanks!  We will review this flagged report and follow up as soon as possible</h4>
                        </DialogTitle>
                    :
                    <></>
                }
                {
                    !flagSubmit ?
                        <DialogContent
                            id="deleteContent-slide-description"
                            className={classes.modalBody}
                        >
                            <CustomInput
                                id="outlined-title"
                                labelText="Content Title"                                    
                                inputProps={{
                                    value: content.title,
                                    onChange: (e) => this.handleChange(e, 'title'),
                                    name: "title",
                                    disabled: true,
                                }}                                    
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                            <CustomInput
                                id="outlined-contentId"
                                labelText="Content ID"
                                inputProps={{
                                    value: content.contentId,
                                    onChange: (e) => this.handleChange(e, 'contentId'),
                                    name: "contentId",
                                    disabled: true,
                                }}                                    
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                            <CustomInput
                                id="outlined-reason"
                                labelText="Reason"                                    
                                inputProps={{
                                    value: reason,
                                    onChange: (e) => this.handleChange(e, 'reason'),
                                    name: "reason",
                                    multiline: true,
                                    rows: 3,
                                }}                                    
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                            <CustomInput
                                id="outlined-email"
                                labelText="Email"
                                inputProps={{
                                    value: email,
                                    onChange: event => this.handleChange(event, "email"),
                                    name: "email",
                                }}                                    
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.formControl
                                }}
                            />
                        </DialogContent>
                    :
                    <></>
                }
                <DialogActions
                    className={classes.modalFooter + " " + classes.justifyContentCenter}
                >
                    {
                        flagSubmit ?
                            <Button className={classes.closeButton} onClick={() => this.props.onClose()} color="info" round>OK</Button>
                        :
                            <>
                                <Button className={""} onClick={this.sendActivity} color="info" round>OK</Button>
                                <Button onClick={() => this.props.onClose()} color="info" round>Cancel</Button>
                            </>
                    }
                </DialogActions>
            </Dialog>
        );
    }
};

ContentFlagModal.defaultProps = {
  open: false
};
ContentFlagModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool
};
export default withStyles(contentFlagModalStyle)(ContentFlagModal);
