import React from 'react';
import { hydrate, render } from 'react-dom';
// import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import App from './app/views/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import configureStore from './store';
import "./app/assets/scss/material-kit-pro-react.scss?v=1.7.0";
// import {
//     primaryColor,
//     grayColor,
//     whiteColor,
//     infoColor
// } from "./app/assets/jss/material-kit-pro-react.jsx";
let store = configureStore();

// const muiTheme = createMuiTheme({
//     palette: {
//         primary: {
//             main: primaryColor[0],
//             light: grayColor[5],
//             dark: grayColor[20],
//             contrastText: whiteColor,
//         },
//         secondary: {
//             main: infoColor[0],
//             light: infoColor[6],
//             dark: infoColor[0],
//             contrastText: whiteColor,
//         },
//     },
//     typography: {
//         fontFamily: [
//             '"Gotham-Book"',
//             '"Helvetica Neue"',
//             'Arial',
//             'sans-serif'
//         ].join(',')
//     }
// });

const rootElement = document.getElementById('root');
function renderApp(){
    if (rootElement.hasChildNodes()) {
        hydrate(
            <Provider store={store}>
                {/* <MuiThemeProvider theme={muiTheme}> */}
                    <App store={store} />
                {/* </MuiThemeProvider> */}
            </Provider>,
            rootElement
        );
    } else {
        render(
            <Provider store={store}>
                {/* <MuiThemeProvider theme={muiTheme}> */}
                    <App store={store} />
                {/* </MuiThemeProvider> */}
            </Provider>,
            rootElement
        );
    }
}

store.subscribe(renderApp);
renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
    onUpdate: function(registration, serviceWorker){
        registration.update();
        serviceWorker.postMessage('SKIP_WAITING');
    },
    onSuccess: function(registration, serviceWorker){
        //console.log("Service Worker Installed");
    }
});
