import React from 'react'
import FixedPlugin from "../components/FixedPlugin/FixedPlugin.jsx";

const FixedSidebar = class extends React.Component {
    constructor(props) {
        super(props);

        this.store = this.props.store;
        this.state = {
            mobileOpen: false,
            miniActive: false,
            image: require("../../assets/img/sidebar-2.jpg"),
            color: "blue",
            bgColor: "black",
            hasImage: true,
            fixedClasses: "dropdown",
            logo: require("../../assets/img/logo-white.svg")
        };
    }
    handleImageClick = image => {
        this.setState({ image: image });
      };
    handleColorClick = color => {
        this.setState({ color: color });
    };
    handleBgColorClick = bgColor => {
        switch (bgColor) {
          case "white":
            this.setState({ logo: require("../../assets/img/logo.svg") });
            break;
          default:
            this.setState({ logo: require("../../assets/img/logo-white.svg") });
            break;
        }
        this.setState({ bgColor: bgColor });
    };
    handleFixedClick = () => {
        if (this.state.fixedClasses === "dropdown") {
          this.setState({ fixedClasses: "dropdown show" });
        } else {
          this.setState({ fixedClasses: "dropdown" });
        }
    };
    sidebarMinimize = () => {
        this.setState({ miniActive: !this.state.miniActive });
    };
    resizeFunction = () => {
        if (window.innerWidth >= 960) {
          this.setState({ mobileOpen: false });
        }
    };
    render() {
        const { authorized, user } = this.store.getState();
        if(!user.hasOwnProperty("userPreferences") || !user.userPreferences.showSidebarControl){
            return <></>
        }
        return (
            <>
                {
                    authorized ?
                        <FixedPlugin
                            handleImageClick={this.handleImageClick}
                            handleColorClick={this.handleColorClick}
                            handleBgColorClick={this.handleBgColorClick}
                            handleHasImage={this.handleHasImage}
                            color={this.state["color"]}
                            bgColor={this.state["bgColor"]}
                            bgImage={this.state["image"]}
                            handleFixedClick={this.handleFixedClick}
                            fixedClasses={this.state.fixedClasses}
                            sidebarMinimize={this.sidebarMinimize.bind(this)}
                            miniActive={this.state.miniActive}
                        />
                    :
                    <></>
                }
            </>
        )
    }
}

export default FixedSidebar
