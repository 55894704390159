import {
  primaryColor,
  dangerColor,
  successColor,
  grayColor,
  roseColor,
  infoColor,
} from "../../material-kit-pro-react.jsx";

const customAutcompleteStyle = theme => ({
    disabled: {
        "&:before": {
            backgroundColor: "transparent !important"
        }
    },
    underline: {
        "&:hover:not($disabled):before,&:before": {
            borderBottomColor: grayColor[11] + " !important",
            borderBottomWidth: "1px !important"
        },
        "&:after": {
            borderBottomColor: primaryColor[0]
        }
    },
    underlineError: {
        "&:after": {
            borderBottomColor: dangerColor[0]
        }
    },
    underlineSuccess: {
        "&:after": {
            borderBottomColor: successColor[0]
        }
    },
    labelRootError: {
        color: dangerColor[0] + " !important"
    },
    labelRootSuccess: {
        color: successColor[0] + " !important"
    },
    feedback: {
        position: "absolute",
        bottom: "4px",
        right: "0px",
        zIndex: "2",
        display: "block",
        width: "24px",
        height: "24px",
        textAlign: "center",
        pointerEvents: "none"
    },
    formControl: {
        margin: "0 0 10px 0",
        paddingTop: "10px",
        position: "relative",
        "& .MuiInputBase-root.Mui-disabled": {
            backgroundColor: grayColor[19]
        },
        "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
            color: grayColor[13]
        },
    },
    input: {
        color: grayColor[13],
        height: "unset",
        "&,&::placeholder": {
            fontSize: "14px",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: "400",
            lineHeight: "1.42857",
            opacity: "1"
        },
        "&::placeholder": {
            color: grayColor[12]
        }
    },
    animate: {
        animation: "customSpin 1.3s infinite linear"
    },
    cancelIcon: {
        cursor: "pointer",
        pointerEvents: "all",
        right: "0px"
    },
    hasClearIcon: {
        right: "25px"
    },
    hasEndAdornment: {
        right: "50px"
    },
    hasClearEndAdornment: {
        right: "25px"
    },
    listbox: {
        padding: "0px"
    },
    listboxOption: {
        minHeight: "30px",
        paddingTop: "0px",
        paddingBottom: "0px",
        fontSize: "14px"
    },
    primary: {
        "&:hover:not($disabled):before,&:before": {
            borderBottomColor: grayColor[11] + " !important",
            borderBottomWidth: "1px !important"
        },
        "&:after": {
            borderBottomColor: primaryColor[0]
        },
        "&.Mui-focused": {
            color: grayColor[12]
        }
    },
    rose: {
        "&:hover:not($disabled):before,&:before": {
            borderBottomColor: grayColor[11] + " !important",
            borderBottomWidth: "1px !important"
        },
        "&:after": {
            borderBottomColor: roseColor[0]
        },
        "&.Mui-focused": {
            color: grayColor[12]
        }
    },
    success: {
        "&:hover:not($disabled):before,&:before": {
            borderBottomColor: grayColor[11] + " !important",
            borderBottomWidth: "1px !important"
        },
        "&:after": {
            borderBottomColor: successColor[0]
        },
        "&.Mui-focused": {
            color: grayColor[12]
        }
    },
    info: {
        "&:hover:not($disabled):before,&:before": {
            borderBottomColor: grayColor[11] + " !important",
            borderBottomWidth: "1px !important"
        },
        "&:after": {
            borderBottomColor: infoColor[0]
        },
        "&.Mui-focused": {
            color: grayColor[12]
        }
    },
    gray: {
        "&:hover:not($disabled):before,&:before": {
            borderBottomColor: grayColor[11] + " !important",
            borderBottomWidth: "1px !important"
        },
        "&:after": {
            borderBottomColor: grayColor[26]
        },
        "&.Mui-focused": {
            color: grayColor[12]
        }
    },
    customRose: {
        "&:hover:not($disabled):before,&:before": {
            borderBottomColor: grayColor[11] + " !important",
            borderBottomWidth: "1px !important"
        },
        "&:after": {
            borderBottomColor: roseColor[5]
        },
        "&.Mui-focused": {
            color: grayColor[12]
        }
    },
    customSuccess: {
        "&:hover:not($disabled):before,&:before": {
            borderBottomColor: grayColor[11] + " !important",
            borderBottomWidth: "1px !important"
        },
        "&:after": {
            borderBottomColor: successColor[8]
        },
        "&.Mui-focused": {
            color: grayColor[12]
        }
    },
    customInfo: {
        "&:hover:not($disabled):before,&:before": {
            borderBottomColor: grayColor[11] + " !important",
            borderBottomWidth: "1px !important"
        },
        "&:after": {
            borderBottomColor: infoColor[8]
        },
        "&.Mui-focused": {
            color: grayColor[12]
        }
    },
    custom: {
        "&:hover:not($disabled):before,&:before": {
            borderBottomColor: grayColor[11] + " !important",
            borderBottomWidth: "1px !important"
        },
        "&:after": {
            borderBottomColor: theme.palette.custom.main
        },
        "&.Mui-focused": {
            color: grayColor[12]
        }
    },
});

export default customAutcompleteStyle;
