import React from 'react'
import PropTypes from "prop-types";
// import { helper } from "../../../assets/js/utils/Element";

import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import NearMeIcon from "@material-ui/icons/NearMe";

import contentItemLocationStyle from "../../../assets/jss/location/contentItemLocationStyle";
class ContentItemLocation extends React.Component {
    constructor(props) {
        super(props);
        this.store = this.props.store;
        const storeState = this.store.getState();
        let location = storeState.address;
        let place = this.props.googlePlacesResponse;
        if(typeof(place) !== "object"){
            place = {};
        }
        this.mounted = true;

        this.state = {
            mapGetDirection: null,
            latitude: (place.hasOwnProperty("latitude") ? place.latitude: storeState.lat),
            longitude: (place.hasOwnProperty("longitude") ? place.longitude: storeState.lng),
            location: (place.hasOwnProperty("location") ? place.location: location),
            googlePlacesId: (place.hasOwnProperty("googlePlacesId") ? place.googlePlacesId: ""),
            formatted_address: (place.hasOwnProperty("formatted_address") ? place.formatted_address: ""),
            city: (place.hasOwnProperty("city") ? place.city: ""),
            state: (place.hasOwnProperty("state") ? place.state: ""),
            zipcode: (place.hasOwnProperty("zipcode") ? place.zipcode: ""),
            country: (place.hasOwnProperty("country") ? place.country: ""),
            mounted: true,
        };

        this.initGoogleAutocomplete = this.initGoogleAutocomplete.bind(this);
        this.requestUserLocation = this.requestUserLocation.bind(this);
        this.randomNo = Math.floor(Math.random(9999999,1000000000) * 100000000); //This would avoid duplicate content problem on one page

    }
    componentDidMount(){
        this.initGoogleAutocomplete();
        if(this.state.mapGetDirection === null){
            this.loadDefaultMap();
        }

        const that = this;
        if(this.props.requestUserLocation && typeof(navigator.permissions) === "object"){
            navigator.permissions.query({name: 'geolocation'}).then(function(PermissionStatus) {
                if(PermissionStatus.state === 'granted' && that.state.location.length <= 0){
                    try{
                        setTimeout(function(){ that.requestUserLocation(); },1000);
                    }catch(e){ }
                }
            })
        }
    }
    componentWillUnmount(){
        this.mounted = false;
        this.setState({mounted: false});
    }
    componentWillReceiveProps(props){
        if(!props.hasOwnProperty("googlePlacesResponse") || !props.googlePlacesResponse.hasOwnProperty("latitude")){
            return;
        }
        this.setState({ 
            latitude: props.googlePlacesResponse.latitude,
            longitude: props.googlePlacesResponse.longitude,
            location: props.googlePlacesResponse.location,
            googlePlacesId: props.googlePlacesResponse.googlePlacesId,
            formatted_address: props.googlePlacesResponse.formatted_address,
            city: props.googlePlacesResponse.location.city,
            state: props.googlePlacesResponse.state,
            zipcode: props.googlePlacesResponse.zipcode,
            country: props.googlePlacesResponse.country,
        })
    }
    loadDefaultMap(){
        const mapGetDirection = new window.google.maps.Map(document.getElementById("content_location_map"+this.randomNo),  {
            zoom: 4,
            center: new window.google.maps.LatLng(56.130366,-106.34677099999999),
            navigationControl: true,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            maxZoom: 14
        });
        this.setState({mapGetDirection: mapGetDirection});
        return mapGetDirection;
    }
    initGoogleAutocomplete(){
        try{
            const that = this;
            const input = document.getElementById('content_location'+this.randomNo);
            var mapAutoComplete = new window.google.maps.places.Autocomplete(input);
            window.google.maps.event.addListener(mapAutoComplete, 'place_changed', function() {
                var place = mapAutoComplete.getPlace();
                if (!place.geometry) {
                        return;
                }
                that.populatePlace(place, input.value.replace(/"/g, ""));
            });
        }catch(e){ console.log(e); }
    }
    requestUserLocation(e){
        const { inputProps } = this.props;
        if(inputProps.disabled){
            return;
        }
        const that = this;
        let mapGetDirection = this.state.mapGetDirection;
        if(this.state.mapGetDirection === null){
            mapGetDirection = this.loadDefaultMap();
        }
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position){
                var lat = position.coords.latitude;
                var lng = position.coords.longitude;
                mapGetDirection.setCenter({lat:lat, lng:lng});
                mapGetDirection.setZoom(9);
                var myoverlay = new window.google.maps.OverlayView();
                myoverlay.draw = function () {
                    this.getPanes().markerLayer.id = 'markerLayer';
                };
                myoverlay.setMap(mapGetDirection);
                if(!position.address) {
                  var service = new window.google.maps.places.PlacesService(mapGetDirection);
                  var request = {
                    location: new window.google.maps.LatLng(lat,lng), 
                    radius: 500
                  };
                                                                                          
                  service.search(request, function(results, status) {
                    if (status  ===  'OK') {
                      var index = 0;
                      var radian = 3.141592653589793/ 180;
                      var my_distance = 1000; 
                      for (var i = 0; i < results.length; i++){
                        var R = 6371; // km
                        var lat2 = results[i].geometry.location.lat();
                        var lon2 = results[i].geometry.location.lng(); 
                        var dLat = (lat2-lat) * radian;
                        var dLon = (lon2-lng) * radian;
                        var lat1 = lat * radian;
                        lat2 = lat2 * radian;
    
                        var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
                          Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
                        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
                        var d = R * c;
                        if(d < my_distance) {
                          index = i;
                          my_distance = d;
                        }
                      }
                      that.loadPlaceDetails(results[index].place_id, true);
                    }
                  });    
                }else{
                    const delimiter = (position.address && position.address.street !==  '' && position.address.city !==  '') ? ', ' : '';   
                    const location= (position.address) ? (position.address.street + delimiter + position.address.city) : '';
                    const state = {
                        location: location,
                        latitude: lat,
                        longitude: lng,
                        formatted_address: location.replace(/"/g, ""),
                        city: '',
                        state: '',
                        zipcode: '',
                        country: '',
                        googlePlacesId: ''
                    };
                    that.setState(state);
                    that.props.onChange(state, true);
                }
            },function(){
                //Failed to get location.
            });
        }else{
            //Location not available due to old browser.
        }
    }
    loadPlaceDetails(placeId, userLocation = false){
        if(this.state.mapGetDirection === null){
            this.loadDefaultMap();
        }
        var request = {
            placeId: placeId,
            fields: ['name', 'formatted_address', 'place_id', 'geometry', 'address_components']
        };
        const that = this;
        var service = new window.google.maps.places.PlacesService(this.state.mapGetDirection);
        service.getDetails(request, function(place, status) {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                that.populatePlace(place, place.formatted_address.replace(/"/g, ""), userLocation);
            }
        });
    }
    populatePlace(place, location, userLocation = false){
        var address = '';
        var country = '';
        var state = '';
        var zipcode = '';
        var city = '';
        var len_add = '';
        var types_location = '';
        if (place.address_components) {
            len_add = place.address_components.length;

            for (var i = 0; i < len_add; i++) {
                types_location = place.address_components[i]['types'][0];
                if (types_location === 'country') {
                    country = place.address_components[i]['long_name'];
                } else if (types_location === 'administrative_area_level_1') {
                    state = place.address_components[i]['long_name'];
                } else if (types_location === 'administrative_area_level_2') {
                    city = place.address_components[i]['long_name'];
                } else if (types_location === 'postal_code' || types_location === 'zip_code') { 
                    zipcode = place.address_components[i]['long_name'];
                } else if (types_location === 'street_address') {
                    if (address === '')
                            address = place.address_components[i]['long_name'];
                    else
                            address = address + ',' + place.address_components[i]['long_name'];
                } else if (types_location === 'locality') {
                    if (address === '')
                            address = place.address_components[i]['long_name'];
                    else
                            address = address + ',' + place.address_components[i]['long_name'];
                } else if (types_location === 'room') {
                    if (address === '')
                            address = place.address_components[i]['long_name'];
                    else
                            address = address + ',' + place.address_components[i]['long_name'];
                } else if (types_location === 'route') {
                    if (address === '')
                            address = place.address_components[i]['long_name'];
                    else
                            address = address + ',' + place.address_components[i]['long_name'];
                } else if (types_location === 'sublocality') {
                    if (address === '')
                            address = place.address_components[i]['long_name'];
                    else
                            address = address + ',' + place.address_components[i]['long_name'];
                } else if (types_location === 'street_number') {
                    if (address === '')
                            address = place.address_components[i]['long_name'];
                    else
                            address = address + ',' + place.address_components[i]['long_name'];
                } else if (types_location === 'postal_town') {
                    if (address === '')
                            address = place.address_components[i]['long_name'];
                    else
                            address = address + ',' + place.address_components[i]['long_name'];
                } else if (types_location === 'postal_code') {
                    if (address === '')
                            address = place.address_components[i]['long_name'];
                    else
                            address = address + ',' + place.address_components[i]['long_name'];
                } else if (types_location === 'subpremise') {
                    if (address === '')
                            address = place.address_components[i]['long_name'];
                    else
                            address = address + ',' + place.address_components[i]['long_name'];
                } else if (types_location === 'neighborhood') {
                    if (address === '')
                            address = place.address_components[i]['long_name'];
                    else
                            address = address + ',' + place.address_components[i]['long_name'];
                } else if (types_location === 'post_box') {
                    if (address === '')
                            address = place.address_components[i]['long_name'];
                    else
                            address = address + ',' + place.address_components[i]['long_name'];
                } else if (types_location === 'park') {
                    if (address === '')
                            address = place.address_components[i]['long_name'];
                    else
                            address = address + ',' + place.address_components[i]['long_name'];
                } else if (types_location === 'natural_feature') {
                    if (address === '')
                            address = place.address_components[i]['long_name'];
                    else
                            address = address + ',' + place.address_components[i]['long_name'];
                }
            }
        }
        if(this.mounted === false){
            return;
        }

        // const that = this;
        const locationState = {
            location: location,
            formatted_address: address.replace(/"/g, ""),
            city: city,
            state: state,
            zipcode: zipcode,
            country: country,
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
            googlePlacesId: place.place_id
        };
        this.props.onChange(locationState, userLocation);
        this.setState(locationState);

        // if(!this.props.radius){
        //     return;
        // }
        // const radius = this.props.radius*1000;
        // const southEastAngle = 135;
        // const northWestAngle = 315;
        // const southWestAngle = 225;
        // const northEastAngle = 45;
        // var myLatLng = new window.google.maps.LatLng(locationState.latitude,locationState.longitude);
        // window.locationMarker = new window.google.maps.Marker({position: myLatLng, map: this.state.mapGetDirection});
        // var infowindow = new window.google.maps.InfoWindow({
        //     content: location
        // });
        // window.locationMarker.addListener('click', function() {
        //     infowindow.open(that.state.mapGetDirection, window.locationMarker);
        // });

        // window.southEast = this.computeOffset(myLatLng, radius, southEastAngle);
        // window.southWest = this.computeOffset(myLatLng, radius, southWestAngle);
        // window.northWest = this.computeOffset(myLatLng, radius, northWestAngle);
        // window.northEast = this.computeOffset(myLatLng, radius, northEastAngle);
        // window.mapCircle = new window.google.maps.Circle({
        //     strokeColor: '#FF0000',
        //     strokeOpacity: 0.8,
        //     strokeWeight: 2,
        //     fillColor: '#FF0000',
        //     fillOpacity: 0.35,
        //     map: this.state.mapGetDirection,
        //     center: {lat: locationState.latitude, lng: locationState.longitude},
        //     radius: radius
        // });


        // var flightPlanCoordinates = [
        //     {lat: window.northWest.lat(), lng: window.northWest.lng()},
        //     {lat: window.southEast.lat(), lng: window.southEast.lng()},
        // ];
        // var flightPath = new window.google.maps.Polyline({
        //     path: flightPlanCoordinates,
        //     geodesic: true,
        //     strokeColor: '#FF0000',
        //     strokeOpacity: 1.0,
        //     strokeWeight: 2
        // });  
        // flightPath.setMap(this.state.mapGetDirection);

        // var flightPlanCoordinates2 = [
        //     {lat: window.southWest.lat(), lng: window.southWest.lng()},
        //     {lat: window.northEast.lat(), lng: window.northEast.lng()},
        // ];
        // var flightPath2 = new window.google.maps.Polyline({
        //     path: flightPlanCoordinates2,
        //     geodesic: true,
        //     strokeColor: '#FF0000',
        //     strokeOpacity: 1.0,
        //     strokeWeight: 2
        // });  
        // flightPath2.setMap(this.state.mapGetDirection);
    }
    handleLocationChange(event, name) {
        let state = [];
        state[name] = event.target.value;
        this.setState(state);
    }
    fc(a){
        return a * Math.PI / 180;
    }
    gc(a){
        return 180 * a / Math.PI;
    }
    ud(a){
        return this.fc(a.lat())
    }
    vd(a) {
        return this.fc(a.lng())
    }
    computeOffset(a, b, c, d){
        b /= d || 6378137;
        c = this.fc(c);
        var e = this.ud(a);
        a = this.vd(a);
        d = Math.cos(b);
        b = Math.sin(b);
        var f = Math.sin(e);
        e = Math.cos(e);
        var g = d * f + b * e * Math.cos(c);
        return new window.google.maps.LatLng(this.gc(Math.asin(g)), this.gc(a + Math.atan2(b * e * Math.sin(c), d - f * g)));
    }

    render(){
        const { classes, labelText, error, success, onClear, inputProps, color } = this.props;
        const { location } = this.state;
        return(
            <div className={this.props.className} >
                <CustomInput
                    success={success}
                    error={error}
                    onClear={onClear}
                    id={"content_location"+this.randomNo}
                    labelText={labelText}
                    color={color}
                    inputProps={{
                        ...inputProps,
                        placeholder: "",
                        value: location,
                        onChange: (e) => this.handleLocationChange(e,"location"),
                        endAdornment: (
                            <InputAdornment 
                                position="end" 
                                className={classes.endornment+" "+(inputProps.disabled?classes.endornmenttDisabled:"")}
                                onClick={this.requestUserLocation}
                                >
                                <NearMeIcon />
                            </InputAdornment>
                        )
                    }}                                    
                    formControlProps={{
                        fullWidth: true
                    }}
                />
                <div id={"content_location_map"+this.randomNo} style={{display: "none"}}></div>
            </div>
        )
    }
}

ContentItemLocation.defaultProps = {
    requestUserLocation: false,
    onChange: function(){ },
    radius: false,
    labelText: "Location",
    success: false,
    error: false,
    onClear: null,
    inputProps: {},
    color: "primary",
    className: ""
}
ContentItemLocation.propTypes = {
    classes: PropTypes.object,
    googlePlacesResponse: PropTypes.object.isRequired,
    requestUserLocation: PropTypes.bool,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    labelText: PropTypes.node,
    success: PropTypes.bool,
    error: PropTypes.bool,
    inputProps: PropTypes.object,
    // color: PropTypes.oneOf([
    //     "primary",
    //     "info",
    //     "success",
    //     "gray",
    //     "rose",
    //     "customInfo",
    //     "customSuccess",
    //     "customRose",
    //     ""
    // ]),
    className: PropTypes.string
};

export default withStyles(contentItemLocationStyle)(ContentItemLocation);