import {
    blackColor,
    container,
    title,
    infoColor,
    whiteColor,
    grayColor,
} from "../material-kit-pro-react.jsx";

const userAboutStyle = theme => ({
    container: {
        ...container,
        padding: theme.spacing(2),
        textAlign: "center"
    },
    title: {
        ...title,
        position: "relative",
        marginTop: "0px",
        marginBottom: "0px",
        minHeight: "32px",
        textDecoration: "none"
    },
    darkTitle: {
        color: grayColor[6]+" !important"
    },
    buttonSpacing: {
        marginRight: "-2px",
    },
    gridContainerSpacing: {
        marginBottom: theme.spacing(2),
        textAlign: "center"
    },
    userAvatarContainer: {
        maxWidth: "none",
        width: "auto",
        flexBasis: "auto",
        paddingRight: "0px",
        margin: "0px auto"
    },
    userAvatar: {
        maxWidth: "100px",
        [theme.breakpoints.down('sm')]: {
            maxWidth: "60px",
        }
    },
    iconButton: {
        color: blackColor,
        marginLeft: "5px",
        "& i": {
            fontSize: "30px !important",
        }
    },
    about: {
        textAlign: "center",
        display: "block"
    },
    socialIconsContainer: {
        "& ul": {
            padding: "0px"
        },
        "& li": {
            display: "inline-block",
            height: "51px",
            width: "51px",
            verticalAlign: "middle",
            "& svg": {
                width: "41px",
                height: "41px",
                fontSize: "20px",
                minWidth: "41px",
                margin: ".3125rem 1px",
            },
            "&:hover svg": {
                boxShadow: "0px 14px 26px -12px rgba(89, 89, 89, 0.42), 0px 4px 23px 0px rgba(0, 0, 0, 0.12), 0px 8px 10px -5px rgba(204, 204, 204, 0.2)"
            }
        },
    },
    blogLink: {
        display: "inline-block",
        marginTop: theme.spacing(-1),
        marginBottom: theme.spacing(2)
    },
    topSearchTerms: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        "& label": {
            display: "block",
            width: "100%",
            textAlign: "center"
        },
        "& .react-tagsinput": {
            paddingLeft: "0px",
            borderBottomColor: "transparent",
            borderBottomStyle: "solid",
            borderBottomWidth: "1px !important",
            width: "100%",
            textAlign: "center",
            "& .react-tagsinput-tag": {
                margin: "3px 3px 3px 0px",
                paddingTop: "5px",
                paddingBottom: "5px",
                "&:hover": {
                    paddingRight: "8px"
                }
            }
        }
    },
    topSearchTermsLabel: {
        color: infoColor[0]
    },
    dark: {
        "& $title, p, $topSearchTermsLabel": {
            color: whiteColor
        }
    }
});

export default userAboutStyle;