import React from 'react'
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from '@material-ui/core/styles/withTheme';
import Link from "@material-ui/core/Link";
import Button from "../../components/CustomButtons/Button.jsx";

import { ReactComponent as PinterestIcon } from "../../../assets/icons/pinterest.svg";
import { ReactComponent as LinkedinIcon } from "../../../assets/icons/linkedin.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/icons/facebook.svg";
import { ReactComponent as MailIcon } from "../../../assets/icons/mail.svg";
import instagramIcon from "../../../assets/img/instagram-icon.png";
import tiktokIcon from "../../../assets/img/tiktok-icon.png";
import snapchatIcon  from "../../../assets/img/snapchat-icon.png";
import soundcloudIcon from "../../../assets/img/soundcloud-icon.png";
import whatsappIcon from "../../../assets/img/whatsapp-icon.png"
import twitchIcon from "../../../assets/img/twitch-icon.png";
import patreonIcon from "../../../assets/img/patreon-icon.png";
import spotifyIcon from "../../../assets/img/spotify-icon.png";
import amazonLiveIcon from "../../../assets/img/amazon-live-icon.png";
import amazonShopIcon from "../../../assets/img/amazon-shop-icon.png";
import appleMusicIcon from "../../../assets/img/apple-music-icon.png";
import itunesIcon from "../../../assets/img/itunes-icon.png";
import googlePlayIcon from "../../../assets/img/gogole-play-icon.png";
import mediumIcon from "../../../assets/img/medium-icon.png";
import likeToKnowItIcon from "../../../assets/img/like-to-know-icon.png";

import userAboutStyle from "../../../assets/jss/user/userAboutStyle";

class SocialIcon extends React.Component {
    render(){
        const { classes, type } = this.props;
        const socialIconsValues = this.props.socialIcons;
        let socialIconItem = null;
        switch(type){
            case "instagramUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.instagramUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={instagramIcon} alt="instagram"/>
                    </Button>
                </Link> 
                break;
            case "twitterUrl": 
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.twitterUrl}>
                    <Button color="twitter" justIcon><i className="fab fa-twitter" /></Button>
                </Link>  
                break;
            case "youtubeUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.youtubeUrl}>
                    <Button color="youtube" justIcon><i className="fab fa-youtube" /></Button>
                </Link> 
                break;
            case "tiktokUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.tiktokUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={tiktokIcon} alt="tiktok"/>
                    </Button>
                </Link>    
                break;
            case "facebookUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.facebookUrl}>
                    <FacebookIcon className={classes.customIcon+" MuiSvgIcon-root"} />
                </Link> 
                break;
            case "pinterestUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.pinterestUrl}>
                    <PinterestIcon className={classes.customIcon+" MuiSvgIcon-root"} />
                </Link>
                break;
            case "linkedinUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.pinterestUrl}>
                    <LinkedinIcon className={classes.customIcon+" MuiSvgIcon-root"} />
                </Link> 
                break;
            case "contactEmail":
                socialIconItem = <Link href={"mailto:"+socialIconsValues.contactEmail} className={classes.buttonSpacing}>
                    <MailIcon className={classes.customIcon+" MuiSvgIcon-root"} />
                </Link>   
                break;
            case "snapchatUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.snapchatUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={snapchatIcon} alt="tiktok"/>
                    </Button>
                </Link> 
                break;
            case "soundcloudUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.soundcloudUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={soundcloudIcon} alt="soundcloud"/>
                    </Button>
                </Link> 
                break;
            case "whatsappNumber":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={"https://api.whatsapp.com/send?phone="+socialIconsValues.whatsappNumber}>
                    <Button justIcon className="instagram-icon">
                        <img src={whatsappIcon} alt="whatsapp"/>
                    </Button>
                </Link> 
                break;
            case "twitchUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.twitchUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={twitchIcon} alt="twitch"/>
                    </Button>
                </Link>
                break;
            case "patreonUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.patreonUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={patreonIcon} alt="patreon"/>
                    </Button>
                </Link>
                break;
            case "spotifyUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.spotifyUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={spotifyIcon} alt="spotify"/>
                    </Button>
                </Link>
                break;
            case "amazonLiveUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.amazonLiveUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={amazonLiveIcon} alt="amazonLive"/>
                    </Button>
                </Link>
                break;
            case "amazonShopUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.amazonShopUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={amazonShopIcon} alt="amazonShop"/>
                    </Button>
                </Link>
                break;
            case "appleMusicUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.appleMusicUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={appleMusicIcon} alt="appleMusic"/>
                    </Button>
                </Link>
                break;
            case "itunesUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.itunesUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={itunesIcon} alt="itunes"/>
                    </Button>
                </Link>
                break;
            case "googlePlayUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.googlePlayUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={googlePlayIcon} alt="googlePlay"/>
                    </Button>
                </Link>
                break;
            case "mediumUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.mediumUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={mediumIcon} alt="medium"/>
                    </Button>
                </Link>
                break;
            case "likeToKnowItUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.likeToKnowItUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={likeToKnowItIcon} alt="likeToKnowIt"/>
                    </Button>
                </Link>
                break;
            default :
                socialIconItem = null;
                break;
        }
        return socialIconItem;
    }
}

SocialIcon.defaultProps = {
    type: null
}
SocialIcon.propTypes = {
    classes: PropTypes.object,
    type: PropTypes.string
};
  
export default withTheme(withStyles(userAboutStyle)(SocialIcon));