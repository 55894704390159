import React from 'react';
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { matchPath } from 'react-router';

import scrollTopStyle from "../../assets/jss/scrollTopStyle.jsx";

const ScrollTop = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        

        this.state = {
            fadeButton: false,
            user: this.getUser()
        };
        this.pushScrollTop = this.pushScrollTop.bind(this);
        this.checkScrollTop = this.checkScrollTop.bind(this);
    }
    componentWillReceiveProps(){
        this.setState({
            user: this.getUser()
        });
    }
    componentDidMount(){
        window.addEventListener("scroll",this.checkScrollTop);
    }
    componentWillUnmount(){
        window.removeEventListener("scroll",this.checkScrollTop);
    }
    getUser(){
        const { user, authorized, searchedUser, url } = this.store.getState();
        let searchUser = null;
        let searchMatch = matchPath(url, {
            path: "/:username",
        });
        if(authorized && searchMatch !== null && searchMatch.params.hasOwnProperty("username") && searchMatch.params.username.toLowerCase() === user.username.toLowerCase()){
            searchUser = user;
        }
        
        if(searchedUser !== false && searchMatch !== null && searchMatch.params.hasOwnProperty("username")){
            searchUser = null;
            if(searchedUser !== null && searchMatch.params.username.toLowerCase() === searchedUser.username.toLowerCase()){
                searchUser = searchedUser;
            }
        }
        return searchUser;
    }
    pushScrollTop(){
        var scrollDuration = 300;
        var scrollStep = -window.scrollY / (scrollDuration / 15);
        var scrollInterval = setInterval(function(){
            if ( window.scrollY !== 0 ) {
                window.scrollBy( 0, scrollStep );
            }
            else clearInterval(scrollInterval); 
        },15);
    }
    checkScrollTop(){
        var element = document.querySelector(".custom_scroll_top");
        if (element) {
            if (window.innerHeight > window.pageYOffset) {
                this.setState({fadeButton: false});
            } else {
                this.setState({fadeButton: true});
            }
        }
    }
    render() {
        const { fadeButton, user } = this.state;
        const { classes } = this.props;
        let buttonClasses = '';
        if(user !== null){
            buttonClasses = classNames({
                [classes.button]: true,
                [classes[user.guestPreferences.primaryColor]]: true
            });
        }else{
            buttonClasses = classNames({
                [classes.button]: true
            });
        }
        
        return (
            <div className={"custom_scroll_top "+buttonClasses +" "+(fadeButton ? "" : "Offscreen")} title="Scroll to Top" onClick={this.pushScrollTop}>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 14.999 20"><path className="a" d="M.088,18.993,7.1.355c.089-.533.71-.444.888.089L14.91,18.105c.266.621-.089,1.42-1.154.621l-6.035-4.7L1.242,19.7a.722.722,0,0,1-.976.178A.667.667,0,0,1,.088,18.993Z" transform="translate(0.004 0.003)"></path></svg>
                </span>
            </div>
        )
    }
}

export default withStyles(scrollTopStyle)(ScrollTop);