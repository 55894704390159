import {
    cardTitle,
    whiteColor,
    dangerColor,
    primaryColor,
} from "../material-kit-pro-react.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";

const signupCodeModalStyle = theme => ({
    ...modalStyle(theme),
    textCenter: {
        textAlign: "center"
    },
    cardTitleWhite: {
        ...cardTitle,
        color: whiteColor + "  !important"
    },
    socialLine: {
        marginTop: "1rem",
        textAlign: "center",
        padding: "0"
    },
    socialLineButton: {
        "&, &:hover": { color: whiteColor },
        marginLeft: "5px",
        marginRight: "5px"
    },
    cardLoginHeader: {
        marginTop: "-40px",
        padding: "20px 0",
        width: "100%",
        marginBottom: "15px"
    },
    cardLoginBody: {
        paddingTop: "0",
        paddingBottom: "0"
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important"
    },
    anchor: {
        color: primaryColor[0],
        cursor: "pointer"
    },
    sendBtn:{
        marginTop:"15px !important"
    },
    red:{
        color: dangerColor[2]
    }
});

export default signupCodeModalStyle;
