import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import coverPhotoStyle from "../../../assets/jss/material-kit-pro-react/components/coverPhotoStyle.jsx";

class CoverPhoto extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }
    render() {
        const {
            classes,
            filter,
            className,
            children,
            style,
            image,
            small
        } = this.props;
        const coverPhotoClasses = classNames({
            [classes.cover]: true,
            [classes[filter + "Color"]]: filter !== undefined,
            [classes.small]: small,
            [className]: className !== undefined
        });
        return (
            <div
                className={coverPhotoClasses}
                style={{
                    ...style,
                    backgroundImage: "url(" + image + ")",
                }}
            >
                {children}
            </div>
        );
    }
}

CoverPhoto.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    filter: PropTypes.oneOf([
        "primary",
        "rose",
        "dark",
        "info",
        "success",
        "warning",
        "danger"
    ]),
    children: PropTypes.node,
    style: PropTypes.string,
    image: PropTypes.string,
    small: PropTypes.bool
};

export default withStyles(coverPhotoStyle)(CoverPhoto);
