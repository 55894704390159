import React from 'react';
import gpdrStyle from '../../assets/jss/gpdrStyle.jsx';
import Cookie from '../../assets/js/utils/Cookie';
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../components/Grid/GridItem";
import GridContainer from "../components/Grid/GridContainer.jsx";
import { Link } from 'react-router-dom';
import Hidden from "@material-ui/core/Hidden";

const GpdrBanner = class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hideBanner: this.isBannerHidden(),
        };
        this.savePreferences = this.savePreferences.bind(this);
        this.agree = this.agree.bind(this);
    }


    savePreferences() {
        Cookie.options.duration = 365;
        Cookie.write("hide_cookies_banner", 1);
        Cookie.options.duration = false;
        this.setState({
            showPreferencePopup: false,
            hideBanner: true
        });
    }
    isBannerHidden() {
        var hideBanner = Cookie.read("hide_cookies_banner");
        if (hideBanner && hideBanner.length > 0) {
            return true;
        }
        return false;
    }
    agree() {
        Cookie.options.duration = 365;
        Cookie.write("hide_cookies_banner", 1);
        Cookie.options.duration = false;
        this.setState({
            showPreferencePopup: false,
            hideBanner: true
        });
    }
    render() {
        const { hideBanner } = this.state;
        const { classes } = this.props;
        if (hideBanner) {
            return <></>
        }
        if (navigator.userAgent.indexOf("Instagram") !== -1) {
            return <></>
        }

        return (
            <div className={classes.cookiesGdprbanner}>
                <div className={classes.userPrivacyBanner} id="footer_cookies_banner_wrapper">
                    <div className={classes.container + ' ' + classes.container2}>
                        <GridContainer className={classes.containerRow}>
                            <GridItem sm={6} className={classes.containerCol}>

                                <p className={classes.whiteColor + ' ' + classes.small}>
                                    This website uses cookies to ensure you get the best experience on our website. To learn more about our privacy policy <Link to={"/help/privacy"} className={classes.anchorClick}>Click here</Link>
                                </p>

                            </GridItem>
                            <GridItem sm={3} className={classes.rightSide}>
                                <div className={classes.marginRight}>
                                    <Link to={"/help/privacy"} >
                                        <span id="cookie_banner_preference_button" className={classes.btn + ' ' + classes.small}>
                                            <svg style={{ "fill": "#fff" }} width="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.855 20"><defs></defs><path d="M9,4.58V4a2.006,2.006,0,0,1,2-2h2a2,2,0,0,1,2,2v.58a8,8,0,0,1,1.92,1.11l.5-.29a2,2,0,0,1,2.74.73l1,1.74a2,2,0,0,1-.73,2.73l-.5.29a8.06,8.06,0,0,1,0,2.22l.5.3a2,2,0,0,1,.73,2.72l-1,1.74a2,2,0,0,1-2.73.73l-.5-.3A8,8,0,0,1,15,19.43V20a2,2,0,0,1-2,2H11a2,2,0,0,1-2-2v-.58a8,8,0,0,1-1.92-1.11l-.5.29a2,2,0,0,1-2.74-.73l-1-1.74a2,2,0,0,1,.73-2.73l.5-.29a8.06,8.06,0,0,1,0-2.22l-.5-.3a2,2,0,0,1-.73-2.72l1-1.74A2,2,0,0,1,6.57,5.4l.5.3A8,8,0,0,1,9,4.57ZM7.88,7.64l-.54.51L5.57,7.13l-1,1.74L6.33,9.88l-.17.73a6.02,6.02,0,0,0,0,2.78l.17.73L4.57,15.13l1,1.74,1.77-1.02.54.51a6,6,0,0,0,2.4,1.4l.72.2V20h2V17.96l.71-.2a6,6,0,0,0,2.41-1.4l.54-.51,1.77,1.02,1-1.74-1.76-1.01.17-.73a6.02,6.02,0,0,0,0-2.78l-.17-.73,1.76-1.01-1-1.74L16.66,8.15l-.54-.51a6,6,0,0,0-2.4-1.4L13,6.04V4H11V6.04l-.71.2a6,6,0,0,0-2.41,1.4ZM12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16Zm0-2a2,2,0,1,0-2-2A2,2,0,0,0,12,14Z" transform="translate(-2.572 -2)" /></svg>
                                            <span >Privacy Preferences</span>
                                        </span>
                                    </Link>
                                </div>
                                <div >
                                    <span onClick={this.agree} id="cookie_banner_agree_button" className={classes.btnAgree + ' ' + classes.small}>I Agree</span>
                                </div>
                            </GridItem>
                            <Hidden smUp>
                                <p className={classes.mobileText}>We use cookies to make all this work</p>
                            </Hidden>
                        </GridContainer>
                    </div>
                </div>
            </div >
        );
    }
}
export default withStyles(gpdrStyle)(GpdrBanner);