import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Instruction from "../../components/Instruction/Instruction.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

import loginModalStyle from "../../../assets/jss/auth/loginModalStyle.jsx";
import dg1 from "../../../assets/img/dg1.jpg";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const LoginOptionsModal = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        
    }
    
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="loginoptions-modal-slide-title"
                aria-describedby="loginoptions-modal-slide-description"
            >
                <DialogTitle
                    id="loginoptions-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>Why So Many Options?</h4>                            
                </DialogTitle>
                <DialogContent
                    id="loginoptions-modal-slide-description"
                    className={classes.modalBody}
                    >
                    <Instruction
                        title="1. With Password"
                        text={
                            <span>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                            </span>
                        }
                        image={dg1}
                        className={classes.instructionNoticeModal}
                        imageClassName={classes.imageNoticeModal}
                    />
                    <Instruction
                        title="2. With Email Link"
                        text={
                            <span>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                            </span>
                        }
                        className={classes.instructionNoticeModal}
                        imageClassName={classes.imageNoticeModal}
                    />                    
                </DialogContent>
                <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                    <Button
                        onClick={() => this.props.onClose()}
                        color="info"
                        round
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

LoginOptionsModal.defaultProps = {
    open: false
}
LoginOptionsModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
}
export default withStyles(loginModalStyle)(LoginOptionsModal);