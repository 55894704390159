import React from 'react';

import { helper } from '../../../assets/js/utils/Element';
import Api from '../../../assets/js/utils/Api';
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown.jsx";
import MaterialIcon from "@mdi/react";
import { mdiDotsVertical, mdiGoogleMaps } from '@mdi/js';
import { ReactComponent as ShoppingBagIcon } from "../../../assets/icons/shopping_bag.svg";
import Link from '@material-ui/core/Link';
import Accordion from "../../components/Accordion/Accordion.jsx";
import ContentDeleteModal from "../Dashboard/Content/ContentDeleteModal";
// import WebLinkEditWizard from "../../pages/dashboard/platforms/WebLinkEditWizard";
import ContentAffiliateModal from "../../components/Search/ContentAffiliateModal";
import CustomInput from "../../components/CustomInput/CustomInput";
import CopyIcon from '@material-ui/icons/FileCopyRounded';
import ContentFlagModal from "../Dashboard/Content/ContentFlagModal";
import ContentTypeIcon from "../ContentTypeIcon";

import searchResultSlideStyle from "../../../assets/jss/user/searchResultSlideStyle";
import defaultImage from "../../../assets/img/image_placeholder.jpg";

class SearchResultSlide extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.discountCodeInput = React.createRef();
        this.contentPlatform = null;
        let hasCoverPhoto = false;
        if(this.props.content.hasOwnProperty("coverMedia")){
            hasCoverPhoto = true;
        }
        this.state = {
            content: this.props.content,
            open: false,
            searchResultsLength: this.props.searchResultsLength,
            // slidePosition: this.props.slidePosition,
            contentkey: this.props.contentkey,
            deleteMode: false,
            showDialog: this.props.open,
            editMode: false,
            snackbarOpen: false,
            affiliateMode: false,
            flagMode: false,
            platforms: this.props.platforms,
            hasCoverPhoto: hasCoverPhoto,
        };
        this.deleteCatalog = this.deleteCatalog.bind(this);
        this.onDeleteModalClose = this.onDeleteModalClose.bind(this);
        this.onAccordionChange = this.onAccordionChange.bind(this);
        this.sendActivity = this.sendActivity.bind(this);
        this.onWizardClose = this.onWizardClose.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.cancel = this.cancel.bind(this);
        this.copyDiscountCode = this.copyDiscountCode.bind(this);
        this.onAffiliateModalClose = this.onAffiliateModalClose.bind(this);
        this.onFlagModalClose = this.onFlagModalClose.bind(this);
    }
    componentWillReceiveProps(props){
        this.setState({
            content: props.content,
            searchResultsLength: props.searchResultsLength,
            // slidePosition: props.slidePosition,
            contentkey: this.props.contentkey,
            platforms: props.platforms
        });
        
        //Fix to modal scroll when copy button is clicked for iOS
        setTimeout(function(){
            const modal = document.querySelector(".searchFormResultsModal");
            const hasAttribute = document.documentElement.hasAttribute("data-scroll");
            if(modal && hasAttribute){
                const modalScroll = document.documentElement.getAttribute("data-modalscroll");
                if(!modalScroll){
                    return;
                }
                const scrollPositionSaved = parseFloat(modalScroll);
                modal.scrollTo(0,scrollPositionSaved);
                document.documentElement.removeAttribute("data-modalscroll");
            }
        });
    }
    prepareUrl(href){
        if (!href.match(/^[a-zA-Z]+:\/\//)){
            href = 'http://' + href;
        }
        return href;
    }
    getCoverPhoto(){
        const content = this.state.content;
        if(!content.hasOwnProperty("coverMedia")){
            return defaultImage;
        }
        return content.coverMedia.downloadUrlMain;
    }
    getMenu(){
        const { classes } = this.props;
        const authorizedUser = this.store.getState().user;
        let menuItems = [];    
        menuItems.push(<Button simple className={classes.dropdownLink+" "+classes.dropdownButton} onClick={() => this.flagModal(this.state.content)}>
            Flag Content
        </Button>);
        if(authorizedUser.hasOwnProperty("userPreferences") && authorizedUser.userPreferences.showBetaFeatures){
            menuItems.push(<Link href={"/"} className={classes.dropdownLink}>
                Save to List
            </Link>);
            menuItems.push(<Link href={"/"} className={classes.dropdownLink}>
                Share
            </Link>);
        }
        return menuItems;
    }
    onDeleteModalClose(){
        this.setState({deleteMode: false});
    }
    deleteModal(content, key){
        this.setState({deleteMode: true, content: content, editMode: false});
    }
    deleteCatalog(){
        const content = this.state.content;
        const key = this.state.contentkey-1;
        if(this.props.onDelete){
            this.props.onDelete(key, content);
        }
        this.setState({
            content: null,
            deleteMode: false
        });
        Api.deleteContent(content.contentId);
        this.props.onSearchResultOpen(false);
    }
    flagModal(content, key){
        this.setState({flagMode: true, content: content});
    }
    onFlagModalClose(){
        this.setState({flagMode: false});
    }
    toggleBoolean(content, attrib){
        if(!content.hasOwnProperty(attrib)){
            return;
        }
        content[attrib] = !content[attrib];
        this.setState({
            content: content,
        });
        if(attrib === "isFeatured"){
            content.featuredSequence = 100
        }
        Api.updateContent(content.contentId, content).then((data) => {
            //Handle Success
        }).catch(err => {
            //Handle Error
        });
    }
    getAccordionData(content, user, classes){
        let accordionData = [];
        // let descriptionContent = null;
        // if(content.hasOwnProperty("publicDescription") && content.publicDescription.trim().length > 0){
        //     let viewMore = null;
        //     if(content.affiliateUrl && content.affiliateUrl !== "" && !content.hasPurchaseInfo){
        //         viewMore = <Button className={classes.viewMore} type="button" color="transparent" onClick={() => this.affiliateModal()}>View More</Button>
        //     }
        //     descriptionContent = (
        //         <div>
        //             <p>{content.publicDescription}</p>
        //             {viewMore}
        //         </div>
        //     )
        // }

        let privateNotes = null;
        if(content.hasOwnProperty("privateNotes") && content.privateNotes.trim().length > 0){
            privateNotes =  <div>
                                <h6>Private Notes</h6>
                                <p>{content.privateNotes}</p>
                            </div>
        }
        let keywords = null;
        if(content.hasOwnProperty("keywords") && typeof(content.keywords === "object")){
            let regularTagsStr = content.keywords.toString();
            content.keywords = regularTagsStr;
        }
        if(content.hasOwnProperty("keywords") && content.keywords.trim().length > 0){
            keywords =  <div>
                            <h6>Search Key Words</h6>
                            <p>{content.keywords}</p>
                        </div>
        }
        let manageContent = null;
        manageContent = (
            <div>
                {privateNotes}
                {keywords}
            </div>
        )
        // if(descriptionContent){
        //     accordionData.push(
        //         {
        //             title: "Description",
        //             content: descriptionContent
        //         }
        //     )
        // }
        if(content.ownerId === user.userId){
            accordionData.push(
                {
                    title: "Manage this Search Result",
                    content: manageContent
                }
            )
        }
        return accordionData;
    }
    onAccordionChange(active){
        const slickList = document.querySelector(".searchFormCarousel .slick-list");
        if(active){
            const { classes } = this.props;
            slickList.className = "slick-list "+classes.carouselHeightFix;
        }else{
            slickList.className = "slick-list";
            const activeSlideHeight = slickList.querySelector(".slick-slide.slick-active.slick-current").scrollHeight;
            helper.setStyle(slickList, "height",activeSlideHeight+"px");
        }
    }
    sendActivity(e, url, type = null){
        e.preventDefault();
        var requestData = [];
        let activityType = "content_url_clicked";
        if(type === "secondary"){
            activityType = "content_affiliate_clicked";
        }
        requestData.push({
            'activityType': activityType,
            'contentId': this.state.content.contentId
        });
        Api.sendActivity(requestData);
        window.open(url,"_blank");
    }
    onEdit(){
        this.setState({editMode: true});
    }
    getContentPlatform(){
        const { content, platforms } = this.state;
        let defaultPlatform = {
            contentPlatformId: null
        };
        let contentPlatformId = null;
        if(content.hasOwnProperty("contentPlatformId")){
            contentPlatformId = content.contentPlatformId;
        }
        platforms.map(userPlatform => {
            if(contentPlatformId === userPlatform.contentPlatformId){
                defaultPlatform = userPlatform;
            }
            return null;
        });
        this.contentPlatform = defaultPlatform;
        return this.contentPlatform;
    }
    onWizardClose(afterEdit, newContent = null){
        if(newContent !== null){
            this.setState({editMode: false, content: newContent});
            if(this.props.onEdit){
                this.props.onEdit(newContent);
            }
        }else{
            this.setState({editMode: false});
        }        
    }
    copyDiscountCode(code){
        helper.copyTextToClipboard(code);
        const modal = document.querySelector(".searchFormResultsModal");
        if(modal){
            document.documentElement.setAttribute("data-modalscroll", modal.scrollTop);
        }
        
        if(this.props.onCopy){
            this.props.onCopy();
        }
    }
    cancel(){
        this.setState({
            snackbarOpen: false,
        });
    }
    affiliateModal(){
        this.setState({affiliateMode: true});
    }
    onAffiliateModalClose(){
        this.setState({affiliateMode: false});
    }
    renderYoutubeVideo(){
        const { content } = this.state;
        let videoUrl = "";
        if(content.hasOwnProperty("url") && content.url){
            videoUrl = content.url;
        }
        if(videoUrl.length <= 0 && content.hasOwnProperty("affiliateUrl") && content.affiliateUrl){
            videoUrl = content.affiliateUrl;
        }
        if(videoUrl.length <= 0){
            return <img src={this.getCoverPhoto(content)} alt={content.title} />;
        }
        const code = videoUrl.split("=")[1];
        return <iframe title={content.title} width="100%" height="315" src={"https://www.youtube.com/embed/"+code+"?autoplay=1"} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    }
    renderTiktokVideo(){
        const { content } = this.state;
        let videoUrl = content.url;
        videoUrl = videoUrl.replace(/\/$/,"");
        const splitArray = videoUrl.split("/");
        const code = splitArray[splitArray.length - 1];
        return <iframe name={"__tt_embed__"+code} title={content.title} width="100%" height="660" src={"https://www.tiktok.com/embed/v2/"+code+"?lang=en-US&autoplay=1"} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    }
    render(){
        const { content, /*searchResultsLength,contentkey,*/ deleteMode, /*editMode,*/ affiliateMode, flagMode, hasCoverPhoto } = this.state;
        const { classes, user } = this.props;
        const authorizedUser = this.store.getState().user;
        // const match = {
        //     params: {
        //         contentId: content.contentId
        //     }
        // }
        return (
            <>
                {
                    content ?
                        <>
                            <div className={classes.contentCoverPhoto}>
                                {
                                    content.hasOwnProperty("type") && content.type === "youtube" ?
                                        this.renderYoutubeVideo()
                                    :
                                        content.hasOwnProperty("type") && content.type === "tiktok" ?
                                            <img src={this.getCoverPhoto()} alt={content.title} className={classes.coverPhoto} />
                                        :
                                        <img src={this.getCoverPhoto()} alt={content.title} className={classes.coverPhoto} />
                                }
                                {
                                    content.hasOwnProperty("type") && (content.type === "youtube" || content.type === "tiktok") ?
                                        <></>
                                    :
                                        hasCoverPhoto === false &&  authorizedUser.userId === content.ownerId ?
                                            <div className={classes.addPhotoText}>
                                                <p>This search link does not have a picture. 
                                                <Button simple onClick={this.onEdit}>
                                                    Click here
                                                </Button>
                                                to add one now!</p>
                                            </div>
                                        :
                                        <></>
                                }
                            </div>
                            <GridContainer className={classes.resetMargins+" "+classes.optionsContainer} justify="space-between" alignItems="flex-end">
                                <GridItem className={classes.autoWidth}>
                                    {
                                        (authorizedUser) ?
                                            <CustomDropdown
                                                noLiPadding
                                                hoverColor={user.guestPreferences.primaryColor}
                                                buttonText=""
                                                caret={false}
                                                buttonProps={{
                                                    className: classes.navLink,
                                                    color: "transparent",
                                                    justIcon: true
                                                }}
                                                popperProps={{
                                                    responsive: false
                                                }}
                                                managerProps={{
                                                    className: classes.dropdownManager
                                                }}
                                                buttonIconImage={<MaterialIcon path={mdiDotsVertical} className={classes.materialIcon+" MuiSvgIcon-root"} />}
                                                dropdownList={this.getMenu()}
                                            />
                                        :
                                        <></>
                                    }
                                    {
                                        content.hasOwnProperty("url") && content.url.trim().length > 0 ?
                                            <a href={this.prepareUrl(content.url)} onClick={(e) => this.sendActivity(e, content.url)} rel="noopener noreferrer" target="_blank" className={classes.iconButton}>
                                                <Button justIcon type="button" color="transparent">
                                                    <ContentTypeIcon contentType={content.type} user={user} />
                                                </Button>
                                            </a>
                                        :
                                        <></>
                                    }
                                </GridItem>
                                {/* <GridItem className={classes.autoWidth+" "+classes.dotsSection}>
                                    {
                                        content && searchResultsLength > 1 ?
                                            <span>{contentkey} of {searchResultsLength}</span>
                                        :
                                        <></>
                                    }
                                </GridItem> */}
                                {
                                    content.hasOwnProperty("hasPurchaseInfo") && content.hasPurchaseInfo && content.isAffiliate ?
                                        <GridItem className={classes.autoWidth}>
                                            {
                                                content.hasOwnProperty("isSponsored") && content.isSponsored ?
                                                    <Button justIcon type="button" color="transparent" onClick={() => this.affiliateModal()}>
                                                        <ShoppingBagIcon className={classes.customIcon+" MuiSvgIcon-root"} style={{color: this.props.primaryColor}} />
                                                    </Button>
                                                :
                                                    (content.affiliateUrl && content.affiliateUrl.trim().length > 0) ?
                                                        <a href={this.prepareUrl(content.affiliateUrl)} onClick={(e) => this.sendActivity(e, content.affiliateUrl, 'secondary')} rel="noopener noreferrer" target="_blank">
                                                            <Button justIcon type="button" color="transparent">
                                                                <ShoppingBagIcon className={classes.customIcon+" MuiSvgIcon-root"} style={{color: this.props.primaryColor}} />
                                                            </Button>
                                                        </a>
                                                    :
                                                        (content.url && content.url.trim().length > 0) ?
                                                            <a href={this.prepareUrl(content.url)} onClick={(e) => this.sendActivity(e, content.url)} rel="noopener noreferrer" target="_blank">
                                                                <Button justIcon type="button" color="transparent">
                                                                    <ShoppingBagIcon className={classes.customIcon+" MuiSvgIcon-root"} style={{color: this.props.primaryColor}} />
                                                                </Button>
                                                            </a>
                                                        :
                                                        <></>
                                                    
                                            }
                                        </GridItem>
                                    :
                                    <></>
                                }
                            </GridContainer>
                            <GridContainer className={classes.slideContent}>      
                                <GridItem xs={12}>                           
                                    <h3 className={classes.title}>{content.title}</h3>
                                </GridItem>
                                {
                                    content.hasOwnProperty("location") && content.location.trim().length > 0 ?
                                        <div className={classes.locationContainer}>
                                            <GridItem xs={9}>                           
                                                <p className={classes.location}>{content.location}</p>
                                            </GridItem>
                                            <GridItem xs={3} className={classes.googleMapLink}>                           
                                                <a href={"https://www.google.com/maps/search/?api=1&query="+content.latitude+","+content.longitude} rel="noopener noreferrer" target="_blank">
                                                    <Button justIcon type="button" color="transparent" className={classes.button}>
                                                        <MaterialIcon path={mdiGoogleMaps} className={classes.customIcon+" MuiSvgIcon-root"} style={{color: this.props.primaryColor}} />
                                                    </Button>
                                                </a>
                                            </GridItem>
                                        </div>
                                    :
                                    <></>
                                }
                                {
                                    content.hasOwnProperty("discountCode") && content.discountCode.trim().length > 0 ?
                                    <GridItem> 
                                        <GridContainer alignItems="center">
                                            <GridItem xs={8} sm={8} md={8} lg={8}> 
                                                <CustomInput
                                                    id="outlined-discountCode"
                                                    labelText="Discount Code"                                    
                                                    inputProps={{
                                                        value: content.discountCode,
                                                        disabled: true,
                                                    }}                                    
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    ref={this.discountCodeInput}
                                                />
                                            </GridItem>
                                            <GridItem xs={3} sm={3} md={3} lg={3}>
                                                <Button
                                                    color={user.guestPreferences.primaryColor}
                                                    className={classes.iconButton+" "+classes.copyButton}
                                                    aria-label="Copy to Clipboard"
                                                    component="span"
                                                    round
                                                    onClick={() => this.copyDiscountCode(content.discountCode)}
                                                    justIcon
                                                    >
                                                    <CopyIcon />
                                                </Button>
                                            </GridItem>
                                        </GridContainer>                                
                                    </GridItem>
                                    :
                                    <></>
                                }
                                {
                                    (content.affiliateUrl && content.affiliateUrl.trim().length > 0) ?
                                        <GridItem> 
                                            <a href={this.prepareUrl(content.affiliateUrl)} onClick={(e) => this.sendActivity(e, content.affiliateUrl, 'secondary')} rel="noopener noreferrer" target="_blank" className={classes.iconButton+" "+classes.iconButtonBlock}>
                                                <Button className={classes.viewMore} type="button" color={user.guestPreferences.primaryColor}>Visit</Button>
                                            </a>
                                            {
                                                content.hasOwnProperty("isSponsored") && content.isSponsored ?
                                                    <p className={classes.textAlign}>I may be receiving a commission at the above third party link</p>
                                                :
                                                <></>
                                            }
                                        </GridItem>
                                    :
                                    (content.hasOwnProperty("url") && content.url.trim().length > 0) ?
                                        <GridItem> 
                                            <a href={this.prepareUrl(content.url)} onClick={(e) => this.sendActivity(e, content.url)} rel="noopener noreferrer" target="_blank" className={classes.iconButton+" "+classes.iconButtonBlock}>
                                                <Button className={classes.viewMore} type="button" color={user.guestPreferences.primaryColor}>Visit</Button>
                                            </a>
                                            {
                                                content.hasOwnProperty("isSponsored") && content.isSponsored ?
                                                    <p className={classes.textAlign}>I may be receiving a commission at the above third party link</p>
                                                :
                                                <></>
                                            }
                                        </GridItem>
                                    :
                                    <></>
                                }     
                                {
                                    content.hasOwnProperty("publicDescription") && content.publicDescription.trim().length > 0 ?
                                        <GridItem className={classes.customAccordionIcon}>
                                            <Accordion
                                                activeColor={user.guestPreferences.primaryColor}
                                                onChange={this.onAccordionChange}
                                                collapses={[
                                                    {
                                                        title: "Description",
                                                        content: <div dangerouslySetInnerHTML={{__html: content.publicDescription}}></div>
                                                    }
                                                ]}
                                            />
                                        </GridItem>
                                    :
                                    <></>
                                }     
                                {/* <GridItem className={classes.customAccordionIcon}>
                                    <Accordion
                                        activeColor={user.guestPreferences.primaryColor}
                                        onChange={this.onAccordionChange}
                                        collapses={
                                            this.getAccordionData(content, authorizedUser, classes)
                                        }
                                    />
                                </GridItem> */}
                                {
                                    deleteMode ?
                                        <ContentDeleteModal open={deleteMode} onClose={() => this.onDeleteModalClose()} onSuccess={() => this.deleteCatalog()} />
                                    :
                                    <></>
                                }                                
                                {/* {
                                    editMode ?
                                        <WebLinkEditWizard match={match} open={editMode} store={this.store} content={content} onWizardClose={this.onWizardClose} platform={this.getContentPlatform()} onDelete={() => this.deleteModal(content)} hardrefresh={true} />
                                    :
                                        <></>
                                } */}
                                {
                                    affiliateMode ?
                                        <ContentAffiliateModal content={content} open={affiliateMode} onClose={() => this.onAffiliateModalClose()} user={user} />  
                                    :
                                    <></>
                                }
                                {
                                    flagMode ?
                                        <ContentFlagModal open={flagMode} content={content} authorizedUser={authorizedUser} onClose={() => this.onFlagModalClose()}/>
                                    :
                                    <></>
                                }
                            </GridContainer>
                        </>
                    :
                    <></>
                }
            </>
        )
    }
}

export default withStyles(searchResultSlideStyle)(SearchResultSlide);