/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import Cancel from "@material-ui/icons/Cancel";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import MaterialIcon from "@mdi/react";
import { mdiLoading } from '@mdi/js';

import customAutcompleteStyle from "../../../assets/jss/material-kit-pro-react/components/customAutcompleteStyle.jsx";

class CustomAutocomplete extends React.Component {
    constructor(props){
        super(props);

        this.input = React.createRef();
        this.forceFocus = false;
        this.getOptionSelected = this.getOptionSelected.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onOpenOptions = this.onOpenOptions.bind(this);
        this.onCloseOptions = this.onCloseOptions.bind(this);
        this.filterOptions = this.filterOptions.bind(this);
    }
    componentDidMount(){
        this.removeAutoComplete();
    }
    componentDidUpdate(){
        this.removeAutoComplete();
    }
    removeAutoComplete(){
        if(this.input.current){
            const input = this.input.current.querySelector("input");
            if(input){
                input.setAttribute("autocomplete", "new-password");
            }
        }
    }
    getClearIcon(){
        const { inputProps } = this.props;
        if(inputProps.hasOwnProperty("disabled") && inputProps.disabled){
            return null;
        }
        if(inputProps.hasOwnProperty("readonly") && inputProps.readonly){
            return null;
        }
        if(this.props.showClear){
            return this.getCancelIcon();
        }
        if(inputProps.hasOwnProperty("value")){
            if(inputProps.value.trim().length > 0){
                return this.getCancelIcon();
            }else{
                return null;
            }
        }
        if(this.input.current === null){
            return null;
        }
        let input = this.input.current.querySelector("input");
        if(!input){
            input = this.input.current.querySelector("textarea");
        }
        if(!input){
            return null;
        }
        if(input.tagName === "TEXTAREA" || input.tagName === "textarea"){
            if(input.value.trim().length <= 0 && input.innerHTML.trim().length <= 0){
                return null;
            }
        }else{
            if(input.value.trim().length <= 0){
                return null;
            }
        }
        return this.getCancelIcon();        
    }
    getCancelIcon(){
        const { classes, endAdornment } = this.props;
        let hasEndAdornment = false;
        if(endAdornment !== null){
            hasEndAdornment = true;
        }
        return <Cancel onClick={(e) => this.clearContents(e)} value="" className={classes.feedback + " cancel-icon " + classes.cancelIcon+" "+(hasEndAdornment?classes.hasClearEndAdornment:"")} />
    }
    clearContents(e){
        let input = this.input.current.querySelector("input");
        if(!input){
            input = this.input.current.querySelector("textarea");
        }
        if(!input){
            return null;
        }
        input.value = "";

        if(this.props.onClear){
            this.props.onClear(e);
            return;
        }
        if(this.props.onChange){
            e.nativeEvent.target.value = "";
            this.props.onChange(e.nativeEvent, "");
        }
        input.focus();
    }
    isDisabledFirst(option){
        const { disabledFirst, options } = this.props;
        if(!disabledFirst || options.length <= 0){
            return false;
        }
        return (options[0] === option);
    }
    getOptionSelected(option){
        return (option === this.props.value);
    }
    onChange(e, value){
        if(this.props.onChange){
            this.props.onChange(e, value);
        }
        let input = this.input.current.querySelector("input");
        if(!input){
            input = this.input.current.querySelector("textarea");
        }
        if(!input){
            return null;
        }
        input.blur();
    }
    onInputChange(e, value){
        if(e === null || e.nativeEvent.type === "click"){
            return;
        }
        if(e.nativeEvent.keyCode === 13){
            return;
        }
        if(this.props.onInputChange){
            this.props.onInputChange(e, value);
        }
    }
    onOpenOptions(e){
        if(this.props.onOpenOptions){
            this.props.onOpenOptions(e);
        }
    }
    onCloseOptions(e){
        if(this.props.onCloseOptions){
            this.props.onCloseOptions(e);
        }
    }
    filterOptions(option, state){
        const { options, value } = this.props;
        if(!value || value.trim().length <= 0){
            return options;
        }
        var filteredOptions = [];
        options.map((option) => {
            if(option.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                filteredOptions.push(option);
            }
            return null;
        });
        return filteredOptions;
    }
    render(){
        const {
            popperClass,
            classes,
            formControlProps,
            labelText,
            labelProps,
            inputProps,
            error,
            inputRootCustomClasses,
            success,
            loading,
            options,
            value,
            autocompleteProps,
            startAdornment,
            endAdornment,
            disabled,
        } = this.props;
        let color = this.props.color;
        if(typeof(color) === "string" && color.indexOf("#") !== -1){
            color = "custom";
        }

        const labelClasses = classNames({
            [" " + classes.labelRootError]: error,
            [" " + classes.labelRootSuccess]: success && !error,
            [classes[color]]: true
        });
        const underlineClasses = classNames({
            [classes.underlineError]: error,
            [classes.underlineSuccess]: success && !error,
            [classes.underline]: true,
            [classes[color]]: true
        });
        const marginTop = classNames({
            [inputRootCustomClasses]: inputRootCustomClasses !== undefined
        });
        const inputClasses = classNames({
            [classes.input]: true,
        });
        var formControlClasses;
        if (formControlProps !== undefined) {
            formControlClasses = classNames(
            formControlProps.className,
            classes.formControl
            );
        } else {
            formControlClasses = classes.formControl;
        }
        let hasEndAdornment = false;
        if(endAdornment !== null){
            hasEndAdornment = true;
        }
        const hasClearIcon = this.getClearIcon();
        
        return (
            <FormControl {...formControlProps} className={formControlClasses}>
                {startAdornment}
                <Autocomplete
                    options={options}
                    getOptionLabel={option => option}
                    getOptionDisabled={option => this.isDisabledFirst(option)}
                    getOptionSelected={this.getOptionSelected}
                    filterOptions={this.filterOptions}
                    onOpen={this.onOpenOptions}
                    onClose={this.onCloseOptions}
                    freeSolo
                    inputValue={value}
                    value={value}
                    disabled={disabled}
                    {...autocompleteProps}
                    classes={{
                        listbox: classes.listbox,
                        option: classes.listboxOption,
                        popper: popperClass
                    }}
                    onChange={this.onChange} 
                    onInputChange={this.onInputChange}
                    // debug={true}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label={labelText}
                            ref={this.input}
                            InputProps={{
                                ...params.InputProps,
                                value: value,
                                classes: {
                                    input: inputClasses,
                                    root: marginTop,
                                    disabled: classes.disabled,
                                    underline: underlineClasses,
                                },
                                ...inputProps,
                            }}
                            InputLabelProps={{
                                ...labelProps,
                                className: labelClasses
                            }}
                            fullWidth={true}
                        />
                    )}
                />
                {endAdornment}
                {loading ? (
                    <MaterialIcon path={mdiLoading} className={classes.feedback+" "+classes.animate+" MuiSvgIcon-root "+(hasEndAdornment?classes.hasEndAdornment:"")+" "+(hasClearIcon?classes.hasClearIcon:"")} />
                ) : error ? (
                    <Clear className={classes.feedback + " " + classes.labelRootError+" "+(hasEndAdornment?classes.hasEndAdornment:"")+" "+(hasClearIcon?classes.hasClearIcon:"")} />
                ) : success ? (
                    <Check className={classes.feedback + " " + classes.labelRootSuccess+" "+(hasEndAdornment?classes.hasEndAdornment:"")+" "+(hasClearIcon?classes.hasClearIcon:"")} />
                ) : null }
                {hasClearIcon}
            </FormControl>
        )
    }
}

CustomAutocomplete.defaultProps = {
    loading: false,
    inputProps: {
        endAdornment: null
    },
    onChange: function(){ },
    onInputChange: function(){ },
    onOpenOptions: function(){ },
    onCloseOptions: function(){ },
    autocompleteProps: {},
    onClear: null,
    showClear: false,
    disabledFirst: false,
    startAdornment: null,
    endAdornment: null,
    disabled: false,
    color: "primary",
    popperClass: "",
}

CustomAutocomplete.propTypes = {
    popperClass: PropTypes.string,
    classes: PropTypes.object.isRequired,
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
    loading: PropTypes.bool,
    onClear: PropTypes.func,
    showClear: PropTypes.bool,
    options: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    autocompleteProps: PropTypes.object,
    disabledFirst: PropTypes.bool,
    startAdornment: PropTypes.object,
    endAdornment: PropTypes.object,
    disabled: PropTypes.bool,
    // color: PropTypes.oneOf([
    //     "primary",
    //     "info",
    //     "success",
    //     "gray",
    //     "rose",
    //     "customInfo",
    //     "customSuccess",
    //     "customRose",
    //     ""
    // ]),
};

export default withStyles(customAutcompleteStyle)(CustomAutocomplete);
