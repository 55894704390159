import {
  container,
  section,
  sectionDark,
  mlAuto,
  mrAuto,
  title,
  description,
  cardTitle,
  roseColor,
  blackColor,
  whiteColor,
  grayColor,
  hexToRgb,
  primaryColor
} from "../../../material-kit-pro-react.jsx";

const pricingSection = theme => ({
  container,
  mlAuto,
  mrAuto,
  cardTitle,
  description,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor + "  !important"
  },
  title: {
    ...title,
    [theme.breakpoints.down("md")]: {
        fontSize: "2rem",
        lineHeight: "normal"
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: "1.8rem",
    },
    [theme.breakpoints.down("xs")]: {
        fontSize: "1.5rem",
    },
  },
  sectionGray: {
    background: grayColor[14]
  },
  section: {
    ...section,
    ...sectionDark,
    position: "relative",
    "& $container": {
      position: "relative",
      zIndex: "2"
    },
    "& $description": {
      color: "rgba(" + hexToRgb(whiteColor) + ", 0.5)"
    },
    "& $cardCategory": {
      color: "rgba(" + hexToRgb(whiteColor) + ", 0.76)"
    },
    "& $title": {
      color: whiteColor,
      marginBottom: "10px"
    },
    "&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
      backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.7)"
    }
  },
  pricing1: {
    "&$section:after": {
      backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.8)"
    }
  },
  pricing: {
    padding: "80px 0",
    backgroundColor: whiteColor
  },
  textCenter: {
    textAlign: "center"
  },
  sectionSpace: {
    height: "70px",
    display: "block"
  },
  cardCategory: {
    ...description,
    fontSize: "1.2rem",
    fontWeight: "bold"
  },
  cardCategoryWhite: {
    color: whiteColor
  },
  cardDescription: {
    ...description
  },
  justifyContentCenter: {
    WebkitBoxPack: "center !important",
    MsFlexPack: "center !important",
    justifyContent: "center !important"
  },
  icon: {
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.76)",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid " + grayColor[14],
    borderRadius: "50%",
    lineHeight: "174px",
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      fontSize: "55px",
      lineHeight: "55px"
    },
    "& svg": {
      width: "55px",
      height: "55px"
    }
  },
  iconWhite: {
    color: whiteColor
  },
  iconRose: {
    color: roseColor[0]
  },
  marginTop30: {
    marginTop: "30px"
  },
  marginBottom20: {
    marginBottom: "20px"
  },
  marginBottom30: {
    marginBottom: "30px"
  },
  packageFeatures: {
        minHeight: "265px",
        maxWidth: "100% !important",
        [theme.breakpoints.down("xs")]: {
            minHeight: "auto",
        },
  },
  packageCard: {
    borderRadius: "5px",
    "& .MuiButton-label a": {
        textTransform: "capitalize",
        "&:hover": {
            textDecoration : "none",
        }
    },
    "& .package-price": {
        width: "100px",
        height: "100px",
        borderRadius: "100%",
        marginRight: "auto",
        marginLeft: "auto",
        display: "block",
        fontSize: "24px",
        backgroundColor: primaryColor[0],
        color: whiteColor,
        paddingTop: "20px",
        "& small": {
            display: "block",
            position: "static !Important",
            color: whiteColor,
        }
    },
    [theme.breakpoints.up("md")]: {
        maxWidth: "40%",
        marginRight: "auto",
        marginLeft: "auto"
    },
    "& .package-des": {
        fontSize: "12px",
        minHeight: "24px",
    },
    "& div>div": {
        padding: "0px !Important"
    },
    "& button": {
        textTransform: "capitalize",
    }
  },
  whiteCard: {
    backgroundImage: "linear-gradient(#ffffff,#dfdfdf)",
    "& .MuiButton-label a": {
        color: whiteColor
    },
    "& ul li": {
        color: grayColor[26]
    },
    "& .package-des": {
        color: grayColor[26]
    }
  },
  purpleCard: {
    backgroundImage: "linear-gradient(#38b6ff,#c337eb)",
    "& h1, & h6, & ul li": {
        color: whiteColor
    },
    "& .package-price": {
        backgroundColor: whiteColor,
        color: primaryColor[0],
        "& small": {
            color: primaryColor[0]
        }
    },
    [theme.breakpoints.down("xs")]: {
        margin: "20px 0px"
    },
    "& .package-des": {
        color: whiteColor
    },
    "& button, button:hover": {
        color: primaryColor[0]
    }
  },
  featuresContainer: {
      justifyContent: "center"
  },
  featureComparison: {
      marginTop: "20px",
      width: "100%"
  },
    buttonSection: {
        paddingBottom: theme.spacing(5),
        "& .button, & .button:hover": {
            display: "block",
            backgroundColor: "#4da9ef",
            borderRadius: "20px",
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            marginRight: "auto",
            marginLeft: "auto",
            textTransform: "Capitalize",
            fontWeight: "bold",
            [theme.breakpoints.down("xs")]: {
                paddingRight: "12px",
                paddingLeft: "12px"
            }
        },
    }
});

export default pricingSection;
