import {
  main,
  whiteColor,
  grayColor,
  mainRaised,
  successColor,
  dangerColor,
  primaryColor
} from "../../material-kit-pro-react.jsx";

const featureComparisonPageStyle = theme => ({
    main: {
        ...main
        /*overflow: "hidden"*/
    },
    mainRaised: {
        ...mainRaised,
        textAlign: "center",
        margin: "-60px 30px -10px",
        overflow: "hidden",
        paddingRight: "20px",
        paddingLeft: "20px",
        [theme.breakpoints.down("sm")]: {
            paddingRight: "0px",
            paddingLeft: "0px",
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: "-30px",
            marginBottom: "-30px",
        },
    },
    navigation: {
        backgroundPosition: "50%",
        backgroundSize: "cover",
        marginTop: "0",
        minHeight: "400px",
        [theme.breakpoints.down("xs")]: {
            minHeight: "250px",
        }
    },
    title: {
        margin: theme.spacing(3, 0, 3),
        fontWeight:"bold",
        textAlign:"center",
        fontSize: "1.0625rem",
        display: "none"
    },
    featuresContainer: {
        marginLeft: "0px",
        marginRight: "0px",
        marginTop: theme.spacing(3),
        "& .MuiGrid-item": {
            paddingLeft: "0px",
            paddingRight: "0px",
            maxWidth: "56%",
            flexBasis: "56%",
            [theme.breakpoints.down("sm")]: {
                maxWidth: "100%",
                flexBasis: "100%",
            }
        }
    },
    freePlan: {
        maxWidth: "22% !important",
        flexBasis: "22% !important",
        "& ul": {
            "& li:first-child": {
                borderLeft: "0px !important",
                "&+li": {
                    borderLeft: "0px !important"
                }
            }
        }
    },
    premiumPlan: {
        maxWidth: "22% !important",
        flexBasis: "22% !important"
    },
    premiumPlusPlan: {
        maxWidth: "22% !important",
        flexBasis: "22% !important"
    },
    featurePricing: {
        minHeight: "48px",
        marginBottom: "0px",
        [theme.breakpoints.down("sm")]: {
            minHeight: "0px",
            marginBottom: "15px",
            paddingRight: "0px !important",
            textAlign: "center"
        },
        "& span": {
            display: "block",
            textAlign: "center"
        },
        "& b": {
            fontSize: "25px"
        }
    },
    features: {
        listStyleType: "none",
        paddingLeft: "0px",
        "& li": {
            height: "64px",
            padding: "10px",
            borderLeft: "1px solid "+grayColor[6],
            [theme.breakpoints.down("md")]: {
                height: "75px",
            },
            "&:last-child": {
                display: "flex",
                justifyContent: "center",
                "& button": {
                    display: "inline-flex",
                    alignSelf: "center"
                },
                [theme.breakpoints.down("sm")]: {
                    height: "auto",
                    marginBottom: "0px",
                    textAlign: "center",
                    paddingRight: "0px"
                }
            },
            [theme.breakpoints.down("sm")]: {
                height: "auto",
                marginBottom: "8px",
                position: "relative"
            }
        },
        "& li:nth-child(even)": {
            background: grayColor[2],
        },
        "& p": {
            marginBottom: "0px",
            fontSize: "14px",
            lineHeight: "14px",
            [theme.breakpoints.down("sm")]: {
                lineHeight: "17px",
            }
        },
        "& span": {
            lineHeight: "12px",
            fontSize: "12px"
        }
    },
    success: {
        color: successColor[0],
    },
    danger: {
        color: dangerColor[0],
    },
    bold: {
        fontWeight: "bold",
    },
    featureDetails: {
        textAlign: "left",
        "& li": {
            border: "none !important",
            [theme.breakpoints.down("sm")]: {
                paddingRight: "85px"
            }
        }
    },
    whiteButton: {
        "& a": {
            color: primaryColor[0]+" !important",
            whiteSpace: "pre-wrap"
        }
    },
    primaryButton: {
        "& a": {
            color: whiteColor+" !important",
            whiteSpace: "pre-wrap"
        }
    },
    productOverview: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            marginTop: "0px"
        }
    },
    plans: {
        "& .plan_tab": {
            background: "transparent !important",
            boxShadow: "none !important",
            minWidth: "0px !important",
            padding: "0px 10px !important",
            margin: "0px 10px",
            borderRadius: "0px",
            overflow: "visible",
            color: primaryColor[0],
            "&.Mui-selected": {
                color: primaryColor[0],
                borderBottom: "1px solid "+grayColor[6],
            },
            "&+.plan_tab": {
                "&:before": {
                    content: "''",
                    background: grayColor[0],
                    height: "20px",
                    width: "1px",
                    position: "absolute",
                    left: "-11px",
                    top: "0px",
                    bottom: "0px",
                    margin: "auto"
                }
            }
        },
        "& .navPillsContentWrapper": {
            borderBottom: "1px solid "+grayColor[6],
            maxWidth: "250px",
            margin: "0px auto"
        }
    },
    desktopPlans: {
        marginLeft: "34%",
        "& .plan_tab": {
            cursor: "default",
            width: "33.33333%",
            color: "inherit",
            "&.Mui-selected": {
                color: "inherit",
                borderBottom: "none !important",
            },
            "& .MuiTouchRipple-root": {
                display: "none !important"
            }
        },
    },
    responsivePlanFeature: {
        [theme.breakpoints.down("sm")]: {
            position: "absolute",
            right: "0px",
            width: "85px",
            textAlign: "center",
            top: "0px",
            bottom: "0px",
            margin: "auto"
        }
    },
    billingFrequency: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "34%"
        }
    },
    featuresHeading: {
        fontWeight: "bold",
        fontSize: "16px"
    },
    centerFeatureIcons: {
        "& li": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& p": {
                minHeight: "0px"
            }
        }
    },
    centerResponsiveIcons: {
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "0px !important",
        }
    },
    centerResponsivePrice: {
        [theme.breakpoints.down("sm")]: {
            display: "block",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "0px !important",
            height: "33px"
        }
    },
    centerFeaturesLabel: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        flexDirection: "column",
        "& p": {
            marginBottom: "7px"
        },
        "& span": {
            lineHeight: "16px"
        }
    },
});

export default featureComparisonPageStyle;
