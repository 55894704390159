import {
    title,
    whiteColor,
    primaryColor,
    hexToRgb, grayColor
} from "../../../assets/jss/material-kit-pro-react.jsx";

const listViewStyle = theme => ({
    marginAuto: {
        margin: "0px auto"
    },
    gridContainer: {
        [theme.breakpoints.down('xs')]: {
            marginLeft: "-4px",
            marginRight: "-4px"
        }
    },
    gridSpacing: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginBottom: "6px",
        }
    },
    gridItem: {
        margin: "2px auto",
        paddingLeft: "0px",
    },
    listView: {
        width: "100%",
        marginLeft: "4px",
        marginRight: "4px"
    },
    title: {
        ...title,
        fontSize: "20px",
        marginTop: "0px",
        marginBottom: theme.spacing(1),
        textAlign: "left",
        minHeight: "0px",
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: {
            fontSize: "15px",
        },
    },
    coverPhoto: {
        maxWidth: "100%",
        cursor: "pointer",
        width: "100%",
        maxHeight: "200px",
        objectFit: "cover",
        [theme.breakpoints.down('xs')]: {
            maxHeight: "150px",
            height: "100%"
        }
    },
    featuredIcon: {
        position: "absolute",
        left: "20px",
        top: "5px",
        zIndex: 1,
        width: "30px",
        height: "30px",
        color: whiteColor,
        [theme.breakpoints.down('sm')]:{
            left: "5px",
            width: "18px",
            height: "18px",
        }
    },
    contentTypeIcon: {
        position: "absolute",
        left: "5px",
        top: "5px",
        zIndex: 1,
        width: "30px",
        height: "30px",
        minWidth: "30px",
        padding: "0px",
        margin: "0px",
        color: whiteColor,
        "&:hover": {
            color: whiteColor,
        },
        "& svg": {
            width: "100%",
            height: "100%",
        },
        "& i": {
            fontSize: "22px !important",
            justifyContent: "center",
            alignItems: "center",
            display: "flex !Important",
            [theme.breakpoints.down('sm')]:{
                fontSize: "16px !important"
            }
        },
        [theme.breakpoints.down('sm')]:{
            minWidth: "18px", 
            width: "18px",
            height: "18px",
        }
    },
    cartIcon: {
        position: "absolute",
        right: "20px",
        bottom: "5px",
        zIndex: 1,
        width: "30px",
        height: "30px",
        color: whiteColor,
        [theme.breakpoints.down('sm')]:{
            width: "18px",
            height: "18px",
        }
    },
    location: {
        textAlign: "left",
        fontSize: "13px"
    },
    hideCoverPhoto: {
        background: primaryColor[0],
        height: "100%",
        width: "100%",
        display: "block"
    },
    hidePicture: {
        height: "auto !important",
        margin: "2px auto",
        paddingLeft: "0px",
        paddingRight: "0px",
        "& a": {
            background: primaryColor[0],
            color: whiteColor,
            padding: theme.spacing(2,1),
            cursor: "pointer",
            fontWeight: "bold",
            minHeight: "56px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            fontSize: "18px"
        },
        [theme.breakpoints.down('xs')]: {            
            width: "100% !important"
        },
        boxShadow:
            "0 2px 2px 0 rgba(" +
            hexToRgb(theme.palette.custom.main) +
            ", 0.14), 0 3px 1px -2px rgba(" +
            hexToRgb(theme.palette.custom.main) +
            ", 0.2), 0 1px 5px 0 rgba(" +
            hexToRgb(theme.palette.custom.main) +
            ", 0.12)",
    },
    buttonBase: {
        height: "100%",
        width: "100%"
    },
    buttonView: {
        width: "100% !important",
        height: "auto !important",
        minHeight: "0px !important",
        "& a": {
            background: primaryColor[0],
            color: whiteColor,
            padding: theme.spacing(2,1),
            cursor: "pointer",
            fontWeight: "bold",
            minHeight: "56px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            fontSize: "12px",
            margin: "0px"
        },
        [theme.breakpoints.down('xs')]: {            
            width: "100% !important"
        },
        boxShadow:
            "0 2px 2px 0 rgba(" +
            hexToRgb(primaryColor[0]) +
            ", 0.14), 0 3px 1px -2px rgba(" +
            hexToRgb(primaryColor[0]) +
            ", 0.2), 0 1px 5px 0 rgba(" +
            hexToRgb(primaryColor[0]) +
            ", 0.12)",
    },
    buttonIcon: {
        "& a": {
            paddingLeft: "65px",
            paddingRight: "65px",
        },
        "& img": {
            width: "50px",
            height: "50px",
            objectFit: "cover",
            position: "absolute",
            left: "10px",
        }
    },
    darkTitle: {
        "& h3, & p": {
            color: grayColor[6]
        }
    }
});

export default listViewStyle;