import React from 'react'
import ApiHelper from '../../assets/js/utils/Api';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    close: {
      padding: theme.spacing(0.5),
    },
}));

export default function NewUpdate(props) {
    const storageName = props.domain+"_app_version";
    const classes = useStyles();
    const [state, setState] = React.useState({
        updateAvailable: false,
        open: false,
        currentVersion: localStorage.getItem(storageName),
        newVersion: "0.0.0",
    });
    
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('controllerchange', function () {
            checkUpdate();
        });
    }

    if(state.updateAvailable){
        compareVersions();
    }
    
    function checkUpdate(){
        ApiHelper.getSettings().then(data => {
            let loadedVersion = state.currentVersion;
            if(!loadedVersion){
                loadedVersion = "0.0.0";
            }
            if(data['fe-react-pwa'].version === loadedVersion){
                setState({
                    ...state,
                    updateAvailable: false,
                    open: true,
                    newVersion: loadedVersion
                });
                return;
            }
            setState({
                ...state,
                updateAvailable: true,
                currentVersion: loadedVersion,
                newVersion: data['fe-react-pwa'].version
            });
        })        
        .catch(err => {
            console.log(err);
        });
    }
    function update(){
        const storageName = props.domain+"_app_version";
        localStorage.setItem(storageName,state.newVersion);
        setState({
            ...state,
            updateAvailable: false,
            open: false,
            currentVersion: state.newVersion,
        });
        window.location.reload(true);
    }
    function cancel(){
        setState({
            ...state,
            open: false,
        });
    }
    function compareVersions(){
        var currentVersionSplit = state.currentVersion.split(".");
        var newVersionSplit = state.newVersion.split(".");
        if(newVersionSplit.length <= 0){
            return;
        }
        //If No Current Version
        if(currentVersionSplit.length <= 0){
            update();
            return;
        }
        //Check Major Version
        if(currentVersionSplit[0] !== newVersionSplit[0]){
            console.log("Major Version");
            update();
            return;
        }
        if(newVersionSplit.length <= 1){
            return;
        }
        //Check Minor Version
        if(currentVersionSplit[1] !== newVersionSplit[1]){
            console.log("Minor Version");
            showUpdate();
            return;
        }
        //Ignore Patch Versions
        const storageName = props.domain+"_app_version";
        localStorage.setItem(storageName,state.newVersion);
        console.log("Patch Version");
    }
    function showUpdate(){
        setState({
            ...state,
            currentVersion: state.newVersion,
            open: true
        });
    }
    const { open } = state;
    return (
        <Snackbar
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
            open={open}
            onClose={cancel}
            ContentProps={{
            'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">Our engineers did it again!!  Want the latest version of the app?</span>}
            action={[
            <Button key="undo" color="primary" size="small" onClick={update}>
                Yes
            </Button>,
            <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={cancel}
            >
                <CloseIcon />
            </IconButton>,
            ]}
        />
    )
}