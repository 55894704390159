import Api from './Api'
import Config from '../../../../Config';
import Cookie from './Cookie';
import axios from "axios";
import DomParser from "dom-parser";
import { matchPath } from 'react-router';

const $ = function(selector){
    return document.querySelectorAll(selector);
}
export default $;

const html = document.body.parentElement;
const hasOpacity = (html.style.opacity != null);
// const hasFilter = (html.style.filter != null);
const reAlpha = /alpha\(opacity=([\d.]+)\)/i;
var floatName = (html.style.cssFloat == null) ? 'styleFloat' : 'cssFloat';

const helper = {
    isMobile: null,
    serialize(obj){
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    },
    getParam(name) {
        return decodeURI(
            (RegExp(name+"=(.+?)(&|$)").exec(window.location.search)||['',null])[1]
        );
    },
    getSubDomainType(){
        var host = document.location.hostname;
        var domain = host.replace("www.","");
        var subDomain = domain.split(".")[0];
        var subDomainPart = subDomain.split("-");
        if(subDomainPart.length >= 1){
            return subDomainPart[0];
        }
        return null;
    },
    getSubDomain(){
        var host = document.location.hostname;
        var domain = host.replace("www.","");
        var subDomain = domain.split(".")[0];
        var subDomainPart = subDomain.split("-");
        if(subDomainPart.length === 2){
            return subDomainPart[1];
        }
        return subDomainPart[0];
    },
    getSubDomainUrl(){
        var path = document.location.pathname;
        var pathSplit = [];
        if(path.charAt(0) === "/"){
          pathSplit = path.slice(1).split("/");
        }else{
          pathSplit = path.split("/");
        }
        var domainUrl = pathSplit[0];
        const domains = Api.getDomains();
        if(domains.includes(domainUrl)){
          return domainUrl;
        }
        return "-";
    },
    formatViewCount: function(viewCount){
        if (viewCount >= 1000 && viewCount < 1000000){
            viewCount = (viewCount/1000).round(1)+"K";
        }
        if (viewCount >= 1000000){
            viewCount = (viewCount/1000000).round(1)+"M";
        }
        return viewCount;
    },
    getBaseUrl(basePath){
        let apiUrl = Config.getPGApiUrl();
        if(basePath){
          return apiUrl.split(".com")[0]+".com";
        }
        apiUrl = apiUrl.split("/api")[0];
        return apiUrl;
    },
    openIframeLink: function(element){
        const href = element.getAttribute("data-href");
        if(!href || href.length <= 0){
            element = element.parentElement;
        }
        this.logShareAction(element);
        window.open(element.getAttribute("data-href"), 'share',
        'status=0,' +
        'toolbar=0,' +
        'location=0,' +
        'menubar=0,' +
        'directories=0,' +
        'scrollbars=0,' +
        'resizable=0,' +
        'height=500,' +
        'width=600');
    },
    logShareAction: function(element){
        try{
            var requestData = {};
            requestData.contentType = element.getAttribute("data-type");
            requestData.contentID = element.getAttribute("data-id");
            requestData.network = element.getAttribute("data-network");
            Api.logShareAction(requestData);            
        }catch(e){  }
    },
    objectToUrl(object){
        const keys = Object.keys(object);
        let url = "";
        for (const key of keys) {
            url += "/"+key+"/"+object[key];
        }
        return url;
    },
    getCampaignParams(loggedIn){
        let params = [];
        if(loggedIn){
            params['gg'] = Cookie.read("user_content_id");
            params['utm_content'] = "gg="+params['gg'];
        }else if(Cookie.read("__utmc")){
            params['__utmc'] = Cookie.read("__utmc");
            params['utm_content'] = "__utmc="+params['__utmc'];
        }
        params['utm_medium'] = 'share';
        return params;
    },
    prepareShareUrl(params,href){
        href = href.substring(0,href.indexOf("?")); //Remove query string;
        href = href.replace(/\/$/, "");
        href = href+this.objectToUrl(params);
        return href;
    },
    getFbShareUrl(loggedIn,href){
        const params = this.getCampaignParams(loggedIn);
        params['utm_source'] = 'facebook';
        return this.prepareShareUrl(params,href);
    },
    getPintShareUrl(loggedIn,href){
        const params = this.getCampaignParams(loggedIn);
        params['utm_source'] = 'pinterest';
        return this.prepareShareUrl(params,href);
    },
    getEmailShareUrl(loggedIn,href){
        const params = this.getCampaignParams(loggedIn);
        params['utm_source'] = 'email';
        return this.prepareShareUrl(params,href);
    },
    camelCase: function(string){
		return string.replace(/-\D/g, function(match){
			return match.charAt(1).toUpperCase();
		});
    },
    capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    setOpacity(element, opacity){
        if(hasOpacity){
            element.style.opacity = opacity;
            return;
        }
        var style = element.style;
        if (!element.currentStyle || !element.currentStyle.hasLayout) style.zoom = 1;
        if (opacity === null || opacity === 1) opacity = '';
        else opacity = 'alpha(opacity=' + (opacity * 100).limit(0, 100).round() + ')';
        var filter = style.filter || '';
        style.filter = reAlpha.test(filter) ? filter.replace(reAlpha, opacity) : filter + opacity;
        if (!style.filter) style.removeAttribute('filter');
    },
    setStyle: function(element,property, value){
		if (property === 'opacity'){
			if (value !== null) value = parseFloat(value);
			this.setOpacity(element, value);
			return this;
		}
		property = this.camelCase((property === 'float' ? floatName : property));
		if (value === String(Number(value))){
			value = Math.round(value);
		}
		element.style[property] = value;
		//<ltIE9>
		if ((value === '' || value === null) && element.style.removeAttribute){
			element.style.removeAttribute(property);
        }
		//</ltIE9>
		return this;
    },
    setStyles: function(element,styles){
		for (var style in styles) this.setStyle(element, style, styles[style]);
		return this;
    },
    fallbackCopyTextToClipboard(text) {
        if (this.isIOS() === false) {
            document.body.parentElement.style.overflow = "hidden";
            let el = document.createElement("div");
            el.contentEditable = true;
            el.readOnly = true;
            el.innerHTML = text;
            const isRTL = false;

            // Prevent zooming on iOS
            el.style.fontSize = '12pt';
            // Reset box model
            el.style.border = '0';
            el.style.padding = '0';
            el.style.margin = '0';
            // Move element out of screen horizontally
            el.style.position = 'absolute';
            el.style[isRTL ? 'right' : 'left'] = '-9999px';
            // Move element to the same position vertically
            var yPosition = window.pageYOffset || document.documentElement.scrollTop;
            el.style.top = yPosition + 'px';
            document.body.appendChild(el);
            try{
                // create a selectable range
                var range = document.createRange();
                range.selectNodeContents(el);

                // select the range
                var selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
                //el.setSelectionRange(0, 999999);
                const status = document.execCommand('copy');
                document.body.removeChild(el);
                console.log('Async: Copying to clipboard was successful! with status = '+status);
            }catch(err){
                document.body.removeChild(el);
                console.error('Fallback: Oops, unable to copy', err);
            }
            document.body.parentElement.style.overflow = "";
            return;
        }
        
        this.fallbackCopyTextToClipboardiOS(text);
    },
    fallbackCopyTextToClipboardiOS(text) {
        document.body.parentElement.style.overflow = "hidden";
        let el = document.createElement("textarea");
        el.setAttribute('readonly', '');
        el.value = text;
        el.select();
        el.setSelectionRange(0, el.value.length);
        const isRTL = false;

        // Prevent zooming on iOS
        el.style.fontSize = '12pt';
        // Reset box model
        el.style.border = '0';
        el.style.padding = '0';
        el.style.margin = '0';
        // Move element out of screen horizontally
        el.style.position = 'absolute';
        el.style[isRTL ? 'right' : 'left'] = '-9999px';
        // Move element to the same position vertically
        var yPosition = window.pageYOffset || document.documentElement.scrollTop;
        el.style.top = yPosition + 'px';
        document.body.appendChild(el);
        try{
            const status = document.execCommand('copy');
            document.body.removeChild(el);
            console.log('Async: Copying to clipboard was successful! with status = '+status);
        }catch(err){
            document.body.removeChild(el);
            console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.parentElement.style.overflow = "";
    },
    copyTextToClipboard(text) {
        if (!navigator.clipboard) {
            this.fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function() {
            console.log('Async: Copying to clipboard was successful!');
        }, function(err) {
            console.error('Async: Could not copy text: ', err);
        });
    },
    prepareSocialUrl(socialDomain, username){
        let socialUrl = "https://"+socialDomain+".com/"+username;
        if(socialDomain === "linkedin"){
            socialUrl = "https://"+socialDomain+".com/in/"+username;
        }
        if(socialDomain === "tiktok" || socialDomain === "medium"){
            socialUrl = "https://"+socialDomain+".com/@"+username;
        }
        if(socialDomain === "snapchat"){
            socialUrl = "https://"+socialDomain+".com/add/"+username;
        }
        if(socialDomain === "twitch"){
            socialUrl = "https://"+socialDomain+".tv/"+username;
        }
        if(socialDomain === "spotify"){
            socialUrl = "https://open."+socialDomain+".com/show/"+username;
        }
        if(socialDomain === "amazonlive"){
            socialUrl = "https://www.amazon.com/live/channel/"+username;
        }
        if(socialDomain === "amazonshop"){
            socialUrl = "https://www.amazon.com/shop/"+username;
        }
        if(socialDomain === "applemusic"){
            socialUrl = "https://music.apple.com/us/artist/"+username;
        }
        if(socialDomain === "googleplay"){
            socialUrl = "https://play.google.com/store/books/author?id="+username;
        }
        if(socialDomain === "liketoknow"){
            socialUrl = "https://www."+socialDomain+".it/"+username;
        }
        return socialUrl;
    },
    isURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return pattern.test(str);
    },
    prepareExternalUrl(url){
        try{
            new URL(url); //Check if valid url
        }catch(e){ //Fallback
            if(url.length > 0 && url.indexOf("http://") === -1 && url.indexOf("https://") === -1){
                url = "http://"+url;
            }
        }
        return url;
    },
    scrapeUrl(url){
        const that = this;
        return new Promise(function(resolve, reject){
            try{
                const testPattern = that.isURL(url);
                if(!testPattern){
                    return;
                }
                // const proxyUrl = "https://cors-anywhere.herokuapp.com/"+url;
                axios.get(url).then(res => {
                    const parser = new DomParser();
                    let parsedData = {
                        title: "",
                        keywords: "",
                        imageUrl: null
                    };
                    const dom = parser.parseFromString(res.data);
                    const headTag = dom.getElementsByTagName("head")[0];
                    const titleNodes = headTag.getElementsByTagName("title");
                    if(titleNodes.length > 0){
                        parsedData.title = decodeURIComponent(titleNodes[0].innerHTML);
                    }
                    const metaTags = headTag.getElementsByTagName("meta");
                    metaTags.map((node) => {
                        if(node.getAttribute("property") === "og:image"){
                            parsedData.imageUrl = decodeURIComponent(node.getAttribute("content"));
                        }
                        if(node.getAttribute("name") === "keywords"){
                            parsedData.keywords = decodeURIComponent(node.getAttribute("content"));
                        }
                        return null;
                    });
                    const bodyTag = dom.getElementsByTagName("body")[0];
                    const imageTags = bodyTag.getElementsByTagName("img");
                    let scrappedImages = [];
                    imageTags.map((node) => {
                        let src = node.getAttribute("src");
                        if(!src || src.length <= 0){
                            return null;
                        }
                        if(src.indexOf(".png") === -1 && src.indexOf(".jpg") === -1 && src.indexOf(".jpeg") === -1 && src.indexOf(".gif") === -1){
                            return null;
                        }
                        scrappedImages.push(that.prepareImageUrl(url, src));
                        return null;
                    });
                    resolve(parsedData);
                }).catch(err => {
                    //Silent
                });
            }catch(e){
                
            }
        });
    },
    prepareImageUrl(siteUrl, src){
        if(src.indexOf("http://") !== -1 || src.indexOf("https://") !== -1){
            return src;
        }
        if(src.charAt(0) !== "/"){
            src = "/"+src;
        }
        const anchor = document.createElement("a");
        anchor.href = siteUrl;
        const origin = anchor.origin;
        src = origin+src;
        return src;
    },
    isTransparentHeader(url){
        let isTransparentHeader = false;
        if(url && (url.indexOf("logout") !== -1 || url.indexOf("circles") !== -1 || url.indexOf("dashboard") !== -1 || url.indexOf("help") !== -1)){
            return false;
        }
        if(url === "" || url === "/"){
            return true;
        }
        if(url.indexOf("signup/verification") !== -1){
            return true;
        }
        const profileMatch = matchPath(url, {
            path: "/profile",
            exact: true
        });
        if(profileMatch !== null){
            isTransparentHeader = true;
        }

        const searchMatch = matchPath(url, {
            path: "/:username",
            exact: true
        });
        if(searchMatch !== null){
            isTransparentHeader = true;
        }
        const featuredMatch = matchPath(url, {
            path: "/:username/featured",
            exact: true
        });
        if(featuredMatch !== null){
            isTransparentHeader = true;
        }
        const aboutMatch = matchPath(url, {
            path: "/:username/about",
            exact: true
        });
        if(aboutMatch !== null){
            isTransparentHeader = true;
        }
        return isTransparentHeader;
    },
    getBodyClass(url){
        let bodyClass = "layout_page"+url.replace(/\//g,"_");
        if(bodyClass === "layout_page_" || bodyClass === "layout_page"){
            bodyClass = "layout_page_home";
        }
        if(url && url.indexOf("home") !== -1 && (url === "/home" || url === "home")){
            return bodyClass;
        }
        if(url && (url.indexOf("login") !== -1 || url.indexOf("signup") !== -1 || url.indexOf("logout") !== -1 || 
        url.indexOf("circles") !== -1 || url.indexOf("dashboard") !== -1 || url.indexOf("help") !== -1 || 
        url.indexOf("feature-comparison") !== -1)){
            return bodyClass;
        }
        const searchMatch = matchPath(url, {
            path: "/:username",
            exact: true
        });
        if(searchMatch !== null && searchMatch.params.username.length > 0){
            bodyClass = "layout_page_search";
        }
        const featuredMatch = matchPath(url, {
            path: "/:username/featured",
            exact: true
        });
        if(featuredMatch !== null){
            bodyClass = "layout_page_search";
        }
        const aboutMatch = matchPath(url, {
            path: "/:username/about",
            exact: true
        });
        if(aboutMatch !== null){
            bodyClass = "layout_page_search";
        }
        bodyClass = bodyClass.replace(/-/g,"_");
        return bodyClass;
    },
    fc(a){
        return a * Math.PI / 180;
    },
    gc(a){
        return 180 * a / Math.PI;
    },
    ud(a){
        return this.fc(a.lat())
    },
    vd(a) {
        return this.fc(a.lng())
    },
    computeOffset(a, b, c, d){
        b /= d || 6378137;
        c = this.fc(c);
        var e = this.ud(a);
        a = this.vd(a);
        d = Math.cos(b);
        b = Math.sin(b);
        var f = Math.sin(e);
        e = Math.cos(e);
        var g = d * f + b * e * Math.cos(c);
        return new window.google.maps.LatLng(this.gc(Math.asin(g)), this.gc(a + Math.atan2(b * e * Math.sin(c), d - f * g)));
    },
    detectMobile: function(){
        if(this.isMobile !== null){
            return this.isMobile;
        }
        const mobileAgents = ["iphone", "ipod","ipad", "macintosh", "android","googletv", "nuvifone", "symbian","series60",
            "series70", "series80","series90","windows", "windows phone os 7", "windows ce","iemobile", "ppc","wm5 pie", "blackberry",
            "vnd.rim","blackberry95", "blackberry97", "blackberry96","blackberry89", "blackberry 98", "palm","webos", "blazer", "xiino",
            "kindle", "vnd.wap", "wml","brew", "danger", "hiptop","playstation","nitro", "nintendo", "wii","xbox","archos",
            "netfront","up.browser", "openweb", "midp","up.link", "teleca q", "pda","mini","mobile", "mobi", "maemo","tablet",
            "qt embedded","com2", "sonyericsson", "ericsson","sec-sgh", "sony", "htc","docomo", "kddi", "vodafone","update"];
        const uagent = navigator.userAgent.toLowerCase();
        const self = this;
        self.isMobile = false;
        mobileAgents.forEach(function(agent,key){
            if (uagent.search(agent) > -1){
                self.isMobile = true;
                return true;
            }
        });
        
        return self.isMobile;
    },
    isIOS(){
        const mobileAgents = ["iphone", "ipod","ipad", "macintosh"];
        const uagent = navigator.userAgent.toLowerCase();
        let iOS = false;
        mobileAgents.map((agent) => {
            if (uagent.search(agent) > -1){
                iOS = true;
                return true;
            }
            return null;
        });
        return iOS;
    },
    checkElementInViewPort(selector){
        try{
            if(!selector){
                selector = ".sd_track_view:not(.sd_view_tracked)";
            }
        }catch(e){ }
        
        try{
            if($(selector).length <= 0){
                return;
            }
            const that = this;
            
            var requestData = [];
            $(selector).forEach(function(card){
                if(that.isElementInViewport(card)){
                    var tracked = parseInt(card.getAttribute("data-tracked"));
                    if(!tracked){
                        card.setAttribute("data-tracked",1);
                        card.className += " sd_view_tracked";
                        requestData.push({
                            'activityType': "content_viewed",
                            'contentId': card.getAttribute("data-id")
                        });
                    }
                }
            });
            if(requestData.length > 0){
                Api.sendActivity(requestData);
            }
        }catch(e){ console.log(e); }
    },
    isElementInViewport: function(el) {
        var rect = el.getBoundingClientRect();
        if(this.detectMobile()){
            return (
                rect.left >= 0 &&
                (rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) && /*or $(window).height() */
                rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
            );
        }else{
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                (rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) && /*or $(window).height() */
                rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
            );
        }
    },
    isUsernameMatchingRoute(username){
        if(username === "profile" || username === "login" || username === "signup" || username === "logout" || username === "logout"
        || username === "circles" || username === "dashboard" || username === "help"){
            return true;
        }
        return false;
    },
    isCardExpiring(expiry){
        if(typeof(expiry) !== "string"){
            return false;
        }
        const expiryArray = expiry.split("/");
        if(expiryArray.length <= 1){
            return false;
        }
        const month = expiryArray[0];
        const year = expiryArray[1];
        const expiryDate = new Date(year+"-"+month+"-01");
        const diff = expiryDate.getTime() - new Date().getTime();
        const diffDays = parseInt(diff / (1000 * 3600 * 24));
        return (diffDays < 60);
    },
    canLoadGoogleFont(fontFamily){
        if(fontFamily !== "Gotham-Medium" && fontFamily !== "Gotham-Light" && fontFamily !== "Gotham-Book"){
            return true;
        }
        return false;
    },
    isCustomColor(primaryColor){
        let hasHash = primaryColor.startsWith("#");
        return hasHash;
    }
}

export { helper };
