import React from "react";
import Button from "../..//CustomButtons/Button.jsx";
import MaterialIcon from "@mdi/react";
import { mdiChevronRight } from '@mdi/js';

class NextArrow extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            className: this.props.className,
            currentSlide: this.props.currentSlide,
            slideCount: this.props.slideCount,
            onClick: this.props.onClick
        };
        this.onClick = this.onClick.bind(this);
    }
    componentWillReceiveProps(props){
        this.setState({
            className: props.className,
            currentSlide: props.currentSlide,
            onClick: props.onClick,
        });
    }
    onClick(){
        if(this.state.onClick){
            this.state.onClick();
        }
    }
    render(){
        const { className } = this.state;
        return (
            <Button className={className} onClick={this.onClick}><MaterialIcon path={mdiChevronRight} className="MuiSvgIcon-root" /></Button>
        )
    }
}

export default NextArrow;