import {
  container,
  sectionDark,
  section,
  mlAuto,
  mrAuto,
  title,
  description,
  cardTitle,
  blackColor,
  whiteColor,
  hexToRgb,
  primaryColor
} from "../../../material-kit-pro-react.jsx";

const testimonialsSection = theme => ({
  container,
  mlAuto,
  mrAuto,
  description,
  cardTitle,

  title: {
      ...title,
      [theme.breakpoints.down("md")]: {
        fontSize: "2rem",
        lineHeight: "normal"
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: "1.8rem",
    },
    [theme.breakpoints.down("xs")]: {
        fontSize: "1.5rem",
    },
  },
  sectionDark: {
    ...sectionDark,
    "& $cardDescription": {
      color: "rgba(" + hexToRgb(whiteColor) + ", 0.76)"
    },
    "& $cardTitle": {
      color: whiteColor
    },
    backgroundSize: "550% 450%"
  },
  cardDescription: {
    ...description,
    [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
        fontSize: "0.7rem",
    },
  },
  cardCategory: {
    ...description
  },
  sectionImage: {
    ...sectionDark,
    ...section,
    position: "relative",
    "& $container": {
      zIndex: "2",
      position: "relative"
    },
    "&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
      backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.7)"
    },
    "& $title": {
      color: whiteColor
    },
    "& $description": {
      color: "rgba(" + hexToRgb(whiteColor) + ", 0.76)"
    }
  },
  testimonials: {
    backgroundColor: whiteColor,
    padding: "80px 0 0",
    "& $cardDescription": {
      fontStyle: "italic"
    },
    "& .slick-slider": {
        "& >button": {
            "&:before": {
                color: blackColor,
            },
            [theme.breakpoints.down("xs")]: {
                width: "24px",
                "&:before": {
                    fontSize: "24px",
                },
            },
        }
    }
  },
  textCenter: {
    textAlign: "center"
  },
  icon: {
    marginTop: "30px",
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      fontSize: "40px",
      lineHeight: "40px"
    },
    "& svg": {
      width: "40px",
      height: "40px"
    }
  },
  card1: {
    "& $cardDescription": {
      marginBottom: "50px",
      fontStyle: "italic"
    }
  },
  starIcons: {
    width: "24px",
    height: "24px"
  },
  testimonial2: {
    "& .slick-dots": {
      display: "none !important"
    }
  },
  card2: {
    maxWidth: "650px",
    margin: "60px auto",
    "& $cardDescription": {
      fontStyle: "italic",
      whiteSpace: "break-spaces",
    },
    [theme.breakpoints.down("xs")]: {
        paddingRight: "24px",
        paddingLeft: "24px",
    }
  },
    testimonialText: {
        "& .button, & .button:hover": {
            display: "block",
            backgroundColor: "#4da9ef",
            borderRadius: "20px",
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            marginRight: "auto",
            marginLeft: "auto",
            textTransform: "Capitalize",
            fontWeight: "bold",
        },
    },
    userImg: {
        height: "100px !important",
        objectFit: "cover",
    },
    userHandle: {
        color: primaryColor[0],
        "&:hover": {
            color: primaryColor[0]
        }
    }
});

export default testimonialsSection;
