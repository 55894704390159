/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons

// core components
import cardFooterStyle from "../../../assets/jss/material-kit-pro-react/components/cardFooterStyle.jsx";

function CardFooter({ ...props }) {
    const {
      classes,
      className,
      children,
      plain,
      profile,
      pricing,
      testimonial,
      stats,
      chart,
      product,
      ...rest
    } = props;
    const cardFooterClasses = classNames({
      [classes.cardFooter]: true,
      [classes.cardFooterPlain]: plain,
      [classes.cardFooterProfile]: profile || testimonial,
      [classes.cardFooterPricing]: pricing,
      [classes.cardFooterTestimonial]: testimonial,
      [classes.cardFooterStats]: stats,
      [classes.cardFooterChart]: chart || product,
      [className]: className !== undefined
    });
    return (
      <div className={cardFooterClasses} {...rest}>
        {children}
      </div>
    );
  }
  
  CardFooter.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    plain: PropTypes.bool,
    profile: PropTypes.bool,
    pricing: PropTypes.bool,
    testimonial: PropTypes.bool,
    stats: PropTypes.bool,
    chart: PropTypes.bool,
    product: PropTypes.bool,
    children: PropTypes.node
  };
  
  export default withStyles(cardFooterStyle)(CardFooter);
