/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  primaryColor,
  dangerColor,
  successColor,
  whiteColor,
  grayColor,
  defaultFont,
  roseColor,
  infoColor,
} from "../../material-kit-pro-react.jsx";

import customSelectStyleDefault from "../../material-kit-pro-react/customSelectStyle.jsx";
const customSelectStyle = theme => ({
    ...customSelectStyleDefault,
    disabled: {
        "&:before": {
            backgroundColor: "transparent !important"
        }
    },
    underline: {
        "&:hover:not($disabled):before,&:before": {
            borderBottomColor: grayColor[11] + " !important",
            borderBottomWidth: "1px !important"
        },
        "&:after": {
            borderBottomColor: primaryColor[0]
        }
    },
    underlineError: {
        "&:after": {
            borderBottomColor: dangerColor[0]
        }
    },
    underlineSuccess: {
        "&:after": {
            borderBottomColor: successColor[0]
        }
    },
    labelRoot: {
        ...defaultFont,
        color: grayColor[12] + " !important",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "1.42857",
        top: "10px",
        letterSpacing: "unset",
        zIndex: "1",
        cursor: "text",
        "& + $underline": {
            marginTop: "0px"
        },
        "& .info_tooltip_icon": {
            marginLeft: "5px"
        },
        "& .private_icon": {
            cursor: "auto",
        },
        "&.MuiInputLabel-shrink": {
            "& .info_tooltip_icon": {
                width: "22.7px",
                height: "22.7px",
            }
        },
        width: "max-content"
    },
    labelRootError: {
        color: dangerColor[0] + " !important"
    },
    labelRootSuccess: {
        color: successColor[0] + " !important"
    },
    feedback: {
        position: "absolute",
        bottom: "4px",
        right: "25px",
        zIndex: "2",
        display: "block",
        width: "24px",
        height: "24px",
        textAlign: "center",
        pointerEvents: "none"
    },
    formControl: {
        margin: "0 0 17px 0",
        paddingTop: "27px",
        position: "relative",
        "& .MuiInputBase-root.Mui-disabled": {
            backgroundColor: grayColor[19]
        },
        "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
            color: grayColor[13]
        }
    },
    whiteUnderline: {
        "&:hover:not($disabled):before,&:before": {
        borderBottomColor: whiteColor
        },
        "&:after": {
        borderBottomColor: whiteColor
        }
    },
    input: {
        color: grayColor[13],
        height: "unset",
        "&,&::placeholder": {
        fontSize: "14px",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: "400",
        lineHeight: "1.42857",
        opacity: "1"
        },
        "&::placeholder": {
        color: grayColor[12]
        }
    },
    whiteInput: {
        "&,&::placeholder": {
        color: whiteColor,
        opacity: "1"
        }
    },
    animate: {
        animation: "customSpin 1.3s infinite linear"
    },
    cancelIcon: {
        cursor: "pointer",
        pointerEvents: "all",
        right: "0px"
    },
    hasClearIcon: {
        right: "25px"
    },
    hasEndAdornment: {
        right: "50px"
    },
    hasClearEndAdornment: {
        right: "25px"
    },
    primary: {
        "&.MuiListItem-root:hover, &.Mui-selected:hover": {
            backgroundColor: primaryColor[0]+" !important"
        },
        "&.MuiListItem-root:after, &.MuiListItem-root:hover:after": {
            borderBottomColor: primaryColor[0],
            borderLeftColor: primaryColor[0],
        },
        "&.MuiListItem-root.Mui-selected:hover:after": {
            borderBottomColor: whiteColor,
            borderLeftColor: whiteColor,
        },
        "&.MuiFormControl-root > div": {
            "&:after": {
                borderBottomColor: primaryColor[0] + "!important"
            }
        }
    },
    rose: {
        "&.MuiListItem-root:hover, &.Mui-selected:hover": {
            backgroundColor: roseColor[0]+" !important"
        },
        "&.MuiListItem-root:after, &.MuiListItem-root:hover:after": {
            borderBottomColor: roseColor[0],
            borderLeftColor: roseColor[0],
        },
        "&.MuiListItem-root.Mui-selected:hover:after": {
            borderBottomColor: whiteColor,
            borderLeftColor: whiteColor,
        },
        "&.MuiFormControl-root > div": {
            "&:after": {
                borderBottomColor: roseColor[0] + "!important"
            }
        }
    },
    success: {
        "&.MuiListItem-root:hover, &.Mui-selected:hover": {
            backgroundColor: successColor[0]+" !important"
        },
        "&.MuiListItem-root:after, &.MuiListItem-root:hover:after": {
            borderBottomColor: successColor[0],
            borderLeftColor: successColor[0],
        },
        "&.MuiListItem-root.Mui-selected:hover:after": {
            borderBottomColor: whiteColor,
            borderLeftColor: whiteColor,
        },
        "&.MuiFormControl-root > div": {
            "&:after": {
                borderBottomColor: successColor[0] + "!important"
            }
        }
    },
    info: {
        "&.MuiListItem-root:hover, &.Mui-selected:hover": {
            backgroundColor: infoColor[0]+" !important"
        },
        "&.MuiListItem-root:after, &.MuiListItem-root:hover:after": {
            borderBottomColor: infoColor[0],
            borderLeftColor: infoColor[0],
        },
        "&.MuiListItem-root.Mui-selected:hover:after": {
            borderBottomColor: whiteColor,
            borderLeftColor: whiteColor,
        },
        "&.MuiFormControl-root > div": {
            "&:after": {
                borderBottomColor: infoColor[0] + "!important"
            }
        }
    },
    gray: {
        "&.MuiListItem-root:hover, &.Mui-selected:hover": {
            backgroundColor: grayColor[0]+" !important"
        },
        "&.MuiListItem-root:after, &.MuiListItem-root:hover:after": {
            borderBottomColor: grayColor[0],
            borderLeftColor: grayColor[0],
        },
        "&.MuiListItem-root.Mui-selected:hover:after": {
            borderBottomColor: whiteColor,
            borderLeftColor: whiteColor,
        },
        "&.MuiFormControl-root > div": {
            "&:after": {
                borderBottomColor: grayColor[0] + "!important"
            }
        }
    },
    customRose: {
        "&.MuiListItem-root:hover, &.Mui-selected:hover": {
            backgroundColor: roseColor[5]+" !important"
        },
        "&.MuiListItem-root:after, &.MuiListItem-root:hover:after": {
            borderBottomColor: roseColor[5],
            borderLeftColor: roseColor[5],
        },
        "&.MuiListItem-root.Mui-selected:hover:after": {
            borderBottomColor: whiteColor,
            borderLeftColor: whiteColor,
        },
        "&.MuiFormControl-root > div": {
            "&:after": {
                borderBottomColor: roseColor[5] + "!important"
            }
        }
    },
    customSuccess: {
        "&.MuiListItem-root:hover, &.Mui-selected:hover": {
            backgroundColor: successColor[8]+" !important"
        },
        "&.MuiListItem-root:after, &.MuiListItem-root:hover:after": {
            borderBottomColor: successColor[8],
            borderLeftColor: successColor[8],
        },
        "&.MuiListItem-root.Mui-selected:hover:after": {
            borderBottomColor: whiteColor,
            borderLeftColor: whiteColor,
        },
        "&.MuiFormControl-root > div": {
            "&:after": {
                borderBottomColor: successColor[8] + "!important"
            }
        }
    },
    customInfo: {
        "&.MuiListItem-root:hover, &.Mui-selected:hover": {
            backgroundColor: infoColor[8]+" !important"
        },
        "&.MuiListItem-root:after, &.MuiListItem-root:hover:after": {
            borderBottomColor: infoColor[8],
            borderLeftColor: infoColor[8],
        },
        "&.MuiListItem-root.Mui-selected:hover:after": {
            borderBottomColor: whiteColor,
            borderLeftColor: whiteColor,
        },
        "&.MuiFormControl-root > div": {
            "&:after": {
                borderBottomColor: infoColor[8] + "!important"
            }
        }
    },
    formControlDisabled: {
        "& > div": {
            "&:before": {
                borderBottomStyle: "dotted"
            },
        },
        "& .MuiSelect-icon": {
            display: "none"
        }
    },
    selectFormControl: {
        ...customSelectStyleDefault.selectFormControl,
        "& label + .MuiInput-formControl": {
            zIndex: "1"
        }
    },
    icons: {
        display: "inline-block",
        verticalAlign: "top",
        marginRight: "0px",
        maxHeight: "22px",
        height: "22px",
        width: "22px",
        position: "absolute",
        top: "8px",
        left: "5px",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            maxHeight: "18px",
            height: "18px",
            width: "18px",
        },
        "& img": {
            verticalAlign: "top",
            height: "auto !important",
            width: "22px !important",
            margin: "0px auto",
            [theme.breakpoints.down("sm")]: {
                width: "13px !important",
            }
        },
    },
    icon: {
        fontSize: "20px",
        width: "22px",
        height: "auto",
        color: "rgba(0, 0, 0, 0.87)",
        verticalAlign: "top",
        [theme.breakpoints.down("sm")]: {
            width: "13px",
        },
    },
});

export default customSelectStyle;
