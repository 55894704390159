/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";

import buttonStyle from "../../../assets/jss/material-kit-pro-react/components/buttonStyle.jsx";

const RegularButton = React.forwardRef((props, ref) => {
    const {
        classes,
        round,
        children,
        fullWidth,
        disabled,
        simple,
        size,
        block,
        link,
        justIcon,
        fileButton,
        className,
        color,
        ...rest
    } = props;
    let buttonColor = color;
    if(typeof(color) === "string" && color.indexOf("#") !== -1){
        buttonColor = "custom";
    }
    const btnClasses = classNames({
        [classes.button]: true,
        [classes[size]]: size,
        [classes[buttonColor]]: buttonColor,
        [classes.round]: round,
        [classes.fullWidth]: fullWidth,
        [classes.disabled]: disabled,
        [classes.simple]: simple,
        [classes.block]: block,
        [classes.link]: link,
        [classes.justIcon]: justIcon,
        [classes.fileButton]: fileButton,
        [className]: className
    });
    return (
        <Button {...rest} ref={ref} className={btnClasses}>
            {children}
        </Button>
    );
});

RegularButton.propTypes = {
    classes: PropTypes.object.isRequired,
    //   color: PropTypes.oneOf([
    //     "primary",
    //     "secondary",
    //     "info",
    //     "success",
    //     "warning",
    //     "danger",
    //     "rose",
    //     "gray",
    //     "white",
    //     "twitter",
    //     "facebook",
    //     "google",
    //     "linkedin",
    //     "pinterest",
    //     "youtube",
    //     "tumblr",
    //     "github",
    //     "behance",
    //     "dribbble",
    //     "reddit",
    //     "instagram",
    //     "instagramGradient",
    //     "transparent",
    //     "customInfo",
    //     "customSuccess",
    //     "customRose",
    //     "blue",
    //     "amazon",
    //     "custom"
    //   ]),
    size: PropTypes.oneOf(["sm","lg"]),
    simple: PropTypes.bool,
    round: PropTypes.bool,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    block: PropTypes.bool,
    link: PropTypes.bool,
    justIcon: PropTypes.bool,
    fileButton: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string
};

export default withStyles(buttonStyle)(RegularButton);
