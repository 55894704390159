import React from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Link from '@material-ui/core/Link';
import classNames from "classnames";
import { Link as RouterLink } from "react-router-dom";
import GridContainer from "./Grid/GridContainer.jsx";
import GridItem from "./Grid/GridItem.jsx";
import Button from "./CustomButtons/Button.jsx";
import Config from '../../../Config';
import { HashLink } from 'react-router-hash-link';
import Footer from "./Footer/Footer.jsx";
// import PreFooterComponent from "./PreFooter"

import marketingFooterStyle from "../../assets/jss/material-kit-pro-react/views/componentsSections/marketingFooterStyle.jsx";

const MarketingFooter = class extends React.Component {    
    render() {
        const { classes } = this.props;
        const supportUrl = Config.getSupportUrl();
        const adminAppUrl = Config.getAdminUrl();
        return (
            <div>
                {/* <PreFooterComponent /> */}
                <Footer
                    className={classes.footerParent}
                    theme="white"
                    content={
                        <div className={classNames(classes.pullCenter, classes.copyRight)}>
                            Copyright &copy; {1900 + new Date().getYear()}{" "}          
                            <Link href="https://www.smearch.com" underline="none" target="_blank">
                                Smearch LLC
                            </Link>{" "}
                        </div>
                    }
                >
                    <div className={classes.footer}>
                        <GridContainer>
                            <GridItem xs={6} sm={4} md={2}>
                                <h5>
                                    <RouterLink to={"/"} className={classes.link} >
                                        Smearch
                                    </RouterLink>
                                </h5>
                                <ul className={classes.linksVertical}>
                                    <li>
                                        <Link href={supportUrl} className="" target="_blank" >
                                            Get Help
                                        </Link>
                                        <Link underline="none" href={adminAppUrl+"/help/privacy"} className="" >
                                            Privacy
                                        </Link>
                                        <Link underline="none" href={adminAppUrl+"/help/terms"} className="" >
                                            Terms
                                        </Link>
                                    </li>
                                </ul>
                                <div className="for-mobile">
                                    <h5>Follow Us</h5>
                                    <ul className={classes.socialButtons}>
                                        <li>
                                            <Button justIcon simple href="https://www.instagram.com/searchmysocial/" target="_blank" color="instagram">
                                            <i className="fab fa-instagram" />
                                            </Button>
                                        </li>
                                        <li>
                                            <Button justIcon simple href="https://www.facebook.com/searchmysocial/" target="_blank" color="facebook">
                                            <i className="fab fa-facebook-square" />
                                            </Button>
                                        </li>
                                        <li>
                                            <Button justIcon simple href="https://www.youtube.com/channel/UCRwb2bTFcp4xWpBQl9Ei7Xw" target="_blank" color="youtube">
                                            <i className="fab fa-youtube" />
                                            </Button>
                                        </li>
                                        <li>
                                            {/* <Button justIcon simple href="#pablo" color="twitter">
                                            <i className="fab fa-twitter" />
                                            </Button> */}
                                        </li>
                                    </ul>
                                </div>
                            </GridItem>
                            <GridItem xs={6} sm={4} md={2}>
                                <h5>
                                    <RouterLink to={"/home"} className={classes.link} >
                                        Search My Social
                                    </RouterLink>
                                </h5>
                                <ul className={classes.linksVertical}>
                                <li>
                                    <HashLink to="/home#overview" scroll={el => el.scrollIntoView({ behavior: 'smooth' })} className={classes.link}>
                                        Overview
                                    </HashLink>
                                </li>
                                <li>
                                    <HashLink to="/home#testimonials" scroll={el => el.scrollIntoView({ behavior: 'smooth' })} className={classes.link}>
                                        Testimonials
                                    </HashLink>
                                </li>
                                <li>
                                    <RouterLink to="/feature-comparison" className={classes.link}>
                                        Comparison
                                    </RouterLink>
                                </li>
                                <li>
                                    <HashLink to="/home#pricing" scroll={el => el.scrollIntoView({ behavior: 'smooth' })} className={classes.link}>
                                        Pricing
                                    </HashLink>
                                </li>
                                <li>
                                    <HashLink to="/home#faqs" scroll={el => el.scrollIntoView({ behavior: 'smooth' })} className={classes.link}>
                                        FAQs
                                    </HashLink>
                                </li>
                                <li>
                                    <Link underline="none" href={adminAppUrl+"/login"}>
                                        Login
                                    </Link>
                                </li>
                                <li>
                                    <Link underline="none" href={adminAppUrl+"/signup?"+window.location.search.substr(1)+"&refType=smearch_site&refLink=footer"} >
                                        Sign Up
                                    </Link>
                                </li>
                                </ul>
                            </GridItem>
                            <GridItem xs={12} sm={4} md={4} className="for-desktop">
                                <h5>Follow Us</h5>
                                <ul className={classes.socialButtons}>
                                <li>
                                    <Button justIcon simple href="https://www.instagram.com/searchmysocial/" target="_blank" color="instagram">
                                    <i className="fab fa-instagram" />
                                    </Button>
                                </li>
                                <li>
                                    <Button justIcon simple href="https://www.facebook.com/searchmysocial/" target="_blank" color="facebook">
                                    <i className="fab fa-facebook-square" />
                                    </Button>
                                </li>
                                <li>
                                    <Button justIcon simple href="https://www.youtube.com/channel/UCRwb2bTFcp4xWpBQl9Ei7Xw" target="_blank" color="youtube">
                                    <i className="fab fa-youtube" />
                                    </Button>
                                </li>
                                <li>
                                    {/* <Button justIcon simple href="#pablo" color="twitter">
                                    <i className="fab fa-twitter" />
                                    </Button> */}
                                </li>
                                </ul>
                            </GridItem>
                        </GridContainer>
                    </div>
                </Footer>
            </div>
        )
    }
};


MarketingFooter.propTypes = {
    classes: PropTypes.object
};
  
export default withStyles(marketingFooterStyle)(MarketingFooter);
