const systemNotificationsStyle = theme => ({
    main: {
        zIndex: 1110,
        position: "fixed",
        width: "100%",
        top: "0px",
        "& .MuiPaper-root": {
            marginBottom: "0px"
        }
    }
});

export default systemNotificationsStyle;
