import React from 'react'
// import Api from '../../assets/js/utils/Api';
import Cookie from '../../assets/js/utils/Cookie';

import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Paper from '@material-ui/core/Paper';
import SnackbarContent from "./Snackbar/SnackbarContent.jsx";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
import Warning from "@material-ui/icons/Warning";
import { ReactComponent as InfoIcon } from "../../assets/icons/info_icon.svg";

import systemNotificationsStyle from "../../assets/jss/systemNotificationsStyle";

class SystemNotifications extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        this.appState = this.store.getState();
        let showNotification = (this.appState.notifications.length > 0 ? true: false);
        if(Cookie.read("searchmysocial_hide_notification") !== null){
            showNotification = false;
        }

        const notifications = this.prepareNotifications(this.appState.notifications);
        this.state = {
            notifications: notifications,
            showNotification: showNotification
        };
        this.hideNotification = this.hideNotification.bind(this);
    }
    componentDidMount(){
        if(this.state.notifications.length <= 0){
            this.loadSettings();

            setTimeout(() => {
                this.loadSettings();
            }, 3000);
        }
    }
    componentWillReceiveProps(props){
        this.appState = this.store.getState();
        if(this.appState.notifications === null){
            this.loadSettings();
            // this.store.dispatch({
            //     type: "UPDATE_STATE",
            //     state: {notifications: []}
            // });
        }
    }

    loadSettings(){
        const that = this;
        let notifications = [];
        if(localStorage.getItem("searchmysocial_sysnotifications") !== null){
            notifications = JSON.parse(localStorage.getItem("searchmysocial_sysnotifications"));
        }
        const allowedNotifications = this.prepareNotifications(notifications);
        if(allowedNotifications.length > 0){
            that.setState({notifications: allowedNotifications, showNotification: true});
        }
        // Api.getSettings().then(data => {
        //     const topics = data.en.topics;
        //     const packages = data.en.packages;
        //     const onboarding = data.en.onboarding;
        //     const notifications = data.en.notifications;
        //     localStorage.setItem("searchmysocial_topics",JSON.stringify(topics));
        //     localStorage.setItem("searchmysocial_packages",JSON.stringify(packages));
        //     localStorage.setItem("searchmysocial_onboarding",JSON.stringify(onboarding));
        //     localStorage.setItem("searchmysocial_notifications",JSON.stringify(notifications));

        //     const allowedNotifications = this.prepareNotifications(notifications);
        //     if(allowedNotifications.length > 0){
        //         that.setState({notifications: allowedNotifications, showNotification: true});
        //     }
        // });
    }
    hideNotification(notification){
        Cookie.options.duration = 1;
        Cookie.write("searchmysocial_hide_notification_"+notification.id,1);
        Cookie.options.duration = false;
        const allowedNotifications = this.prepareNotifications(this.state.notifications);
        const showNotification = (allowedNotifications.length > 0 ? true : false);
        this.setState({notifications: allowedNotifications, showNotification: showNotification });
    }
    getNotificationIcon(notification){
        if(notification.type === "info" || notification.type === "danger"){
            return InfoIcon;
        }
        if(notification.type === "success"){
            return Check;
        }
        if(notification.type === "warning"){
            return Warning;
        }
    }
    prepareNotifications(notifications){
        const { authorized } = this.appState;
        let allowedNotifications = [];
        notifications.map((notification) => {
            if(Cookie.read("searchmysocial_hide_notification_"+notification.id) !== null){
                notification['showNotification'] = false;
                allowedNotifications.push(notification);
                return null;
            }
            if(notification.visibility === "allUsers" && !authorized){
                notification['showNotification'] = false;
                allowedNotifications.push(notification);
                return null;
            }
            if(notification.visibility === "allUsers" && authorized){
                notification['showNotification'] = true;
                allowedNotifications.push(notification);
                return null;
            }
            notification['showNotification'] = true;
            allowedNotifications.push(notification);
            return null;
        });
        const that = this;
        allowedNotifications.map((notification) => {
            if(notification.showNotification && notification.autoHide){
                setTimeout(function(){
                    that.hideNotification(notification);
                },5000);
            }
            return null;
        });
        return allowedNotifications;
    }
    render(){
        const { notifications } = this.state;
        const { classes } = this.props;
        if(notifications.length <= 0){
            return <></>
        }
        return (
            <div className={classes.main}>
                {
                    notifications.map((notification) => {
                        return <Slide key={notification.id} direction="down" in={notification.showNotification} timeout={{enter: 300, exit: 300}} mountOnEnter unmountOnExit>
                            <Paper elevation={4}>
                                <SnackbarContent
                                    message={
                                        <span>
                                            <b>{notification.type.toUpperCase()}:</b> <span  dangerouslySetInnerHTML={{__html: notification.message}}></span>
                                        </span>
                                    }
                                    close={notification.canClose}
                                    color={notification.type}
                                    // icon={this.getNotificationIcon(notification)}
                                    onClose={() => this.hideNotification(notification)}
                                />
                            </Paper>
                        </Slide>
                        
                    })
                }
            </div>
            
        );
    }
}

export default withStyles(systemNotificationsStyle)(SystemNotifications);