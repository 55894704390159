import React from 'react';
import { createBrowserHistory } from "history";
import AuthApi from "../../../assets/js/utils/Auth";
import Config from "../../../../Config";
import Api from "../../../assets/js/utils/Api";
import { Link } from 'react-router-dom';
import { matchPath } from 'react-router';
import PasswordMatch from "../../../assets/js/utils/PasswordMatch";
import LoaderComponent from '../../components/Loader'
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Card from "../../components/Card/Card.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
// import InfoArea from "../../components/InfoArea/InfoArea.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
// import Checkbox from "@material-ui/core/Checkbox";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import Warning from "../../components/Typography/Warning.jsx";
import Snackbar from '@material-ui/core/Snackbar';
import RecaptchaV2 from "../../components/Google/RecaptchaV2";
import MaterialLink from "@material-ui/core/Link";
import { ReactComponent as AmazonIcon } from "../../../assets/icons/amazon.svg";
import { ReactComponent as GoogleIcon } from "../../../assets/icons/google.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/icons/facebook2.svg";

// @material-ui/icons
// import Timeline from "@material-ui/icons/Timeline";
// import Code from "@material-ui/icons/Code";
// import Group from "@material-ui/icons/Group";
import Email from "@material-ui/icons/Email";
// import Check from "@material-ui/icons/Check";
import AuthIcon from "@material-ui/icons/Lock";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Close from "@material-ui/icons/Close";
import LockIcon from "@material-ui/icons/Lock";

import signupModalStyle from "../../../assets/jss/auth/signupModalStyle.jsx";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
  
Transition.displayName = "Transition";
const history = createBrowserHistory();

const SignupModal = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resendCode = this.resendCode.bind(this);
        this.confirmSignUp = this.confirmSignUp.bind(this);
        this.codeResendNotice = this.codeResendNotice.bind(this);
        this.emailRef = React.createRef();
        this.nameRef = React.createRef();

        this.email = Math.random().toString(36).slice(-8);
        this.password = Math.random().toString(36).slice(-8);
        this.passwordConfirm = Math.random().toString(36).slice(-8);

        this.state = {
            [this.email]: "",
            [this.password]: "",
            [this.passwordConfirm]: "",
            code: "",
            loading: false,
            // terms: false,
            validation: {
                email: '',
                password: '',
                passwordConfirm: '',
                code: '',
                // terms: '',
                isValid: false
            },
            requireLowerletter: false,
            requireUpperletter: false,
            requireNumber: false,
            requireSymbol: false,
            requireLength: false,
            requestCode: false,
            showError: false,
            errorMessage: "",
            codeResendNotice: false,
            user: this.getUser(),
            showRecaptcha: false,
            botDetected: false
        };        
    }
    componentWillReceiveProps(props){
        this.setState({
            user: this.getUser()
        });
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        if(name === this.email){
            state[name] = e.target.value.toLowerCase();
        }
        this.setState(state);
    }
    handleCheckbox(e, name){
        let state = {};
        state[name] = e.target.checked;
        this.setState(state);
    }
    componentDidUpdate(prevProps, prevState){
        // const { terms } = this.state;
        const email = this.state[this.email];
        const password = this.state[this.password];
        const passwordConfirm = this.state[this.passwordConfirm];

        const prevEmail = prevState[this.email];
        const prevPassword = prevState[this.password];
        const prevPasswordConfirm = prevState[this.passwordConfirm];

        let validateEmail = false;
        let validatePassword = false;
        let validatePasswordConfirm = false;
        if(prevEmail !== email){
            validateEmail = true;
        }
        if(prevPassword !== password){
            validatePassword = true;
            validatePasswordConfirm = true;
        }
        if(prevPasswordConfirm !== passwordConfirm){
            validatePasswordConfirm = true;            
        }
        if(validateEmail || validatePassword || validatePasswordConfirm 
            // || terms !== prevState.terms
        ){
            this.validateForm(validateEmail, validatePassword, validatePasswordConfirm);
        }
    }
    handleSubmit(e){
        e.preventDefault();
        const isValid = this.validateForm(true, true, true);
        if(!isValid || this.state.loading){
            return;
        }
        if(Config.isRecaptchaEnabled()){
            this.setState({showRecaptcha: true});
            return;
        }
        if(this.isBotDetected()){
            this.setState({botDetected: true});
            return;
        }
        const email = this.state[this.email];
        const password = this.state[this.password];
        AuthApi.signUp(email, password, this);
        this.setState({loading: true, showError: false});
    }
    validateForm(validateEmail = false, validatePassword = false, validatePasswordConfirm = false){
        let validation = {
            email: (validateEmail ? 'success': this.state.validation.email),
            password: (validatePassword ? 'success': this.state.validation.password),
            passwordConfirm: (validatePasswordConfirm ? 'success': this.state.validation.passwordConfirm),
            // terms: 'success',
            isValid: true
        };

        const email = this.state[this.email];
        const password = this.state[this.password];
        const passwordConfirm = this.state[this.passwordConfirm];

        var emailRegex = /\S+@\S+\.\S+/;
        if(validateEmail && (email.length <= 0 || !emailRegex.test(email))){
            if(validateEmail){
                validation.email = "error";
            }
            validation.isValid = false;
        }
        
        const that = this;
        const check = PasswordMatch.check(password,function(requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength){
            that.setState({
              showPasswordErrors: true,
              requireLowerletter: requireLowerletter,
              requireUpperletter: requireUpperletter,
              requireNumber: requireNumber,
              requireSymbol: requireSymbol,
              requireLength: requireLength
            });
        });
        if(password.length <= 0 || check === false){
            if(validatePassword){
                validation.password = "error";
            }
            validation.isValid = false;
        }

        if(passwordConfirm.length <= 0 || password !== passwordConfirm){
            if(validatePasswordConfirm){
                validation.passwordConfirm = "error";
            }
            validation.isValid = false;
        }else if(passwordConfirm.length > 0 && password === passwordConfirm){
            if(validation.password === "success"){
                validation.passwordConfirm = "success";
            }
        }

        // if(this.state.terms === false){
        //     validation.terms = "error";
        //     validation.isValid = false;
        // }

        this.setState({validation: validation});
        return validation.isValid;
    }
    onValidateSuccess(){
        const email = this.state[this.email];
        this.setState({loading: false, requestCode: true});
        let url = "/signup/verification/"+email+window.location.search;
        history.push(url);
    }
    onValidateConfirmSuccess(data){
        const email = this.state[this.email];
        const password = this.state[this.password];
        const that = this;
        AuthApi.signIn(email, password, {
            onValidateSuccess: function(data){
                if(data.numberOfResponses > 0){
                    const user = Api.prepareMemberObject(data.response[0]);
                    try{
                        setTimeout(function(){
                            var fullName = "";
                            if(user.hasOwnProperty("firstName")){
                                fullName = user.firstName;
                            }
                            if(user.hasOwnProperty("lastName")){
                                fullName += " "+user.lastName;
                            }
                            
                            if('FS' in window){
                                window.FS.identify(user.userId, {
                                    displayName: fullName.trim(),
                                    email: user.userEmail,
                                    userId: user.userId
                                });
                            }
                        },1000);
                    }catch(e){  }
                    
                    that.props.onClose();
                    if(user.userId === user.username){
                        that.showOnboarding();
                    }
                    that.store.dispatch({
                        type: "LOGIN",
                        state: {
                            authorized: true,
                            user: user
                        }
                    });                    
                }
            },
            onValidateFail: function(err){
                that.props.onSuccess();
            }
        });
    }
    onValidateFail(err){
        let errorMessage = "";
        if(typeof(err) === "string"){
            errorMessage = err;
        }else{
            errorMessage = err.message;
        }
        this.setState({
            loading: false,
            showError: true,
            errorMessage: errorMessage
        });
    }
    resendCode(event){
        event.preventDefault();
        event.stopPropagation();
        this.setState({codeResendNotice: true});
        const email = this.state[this.email];
        AuthApi.resendSignUp(email,this);
    }
    confirmSignUp(event){
        event.preventDefault();
        event.stopPropagation();
        if (this.state.code.length <= 0) {
            this.setState({
                ...this.state,
                validation: {
                    ...this.state.validation,
                    code: "error"
                }
            });
            return;
        }
        const email = this.state[this.email];
        const code = this.state.code;
        this.setState({loading: true, showError: false});
        AuthApi.confirmSignUp(email,code,this);
    }
    codeResendNotice(open){
        this.setState({codeResendNotice: open});
    }
    getUser(){
        const { searchedUser, url } = this.store.getState();
        let searchUser = null;
        let searchMatch = matchPath(url, {
            path: "/:username",
        });
        
        if(searchedUser !== false && searchMatch !== null && searchMatch.params.hasOwnProperty("username")){
            searchUser = null;
            if(searchedUser !== null && searchMatch.params.username.toLowerCase() === searchedUser.username.toLowerCase()){
                searchUser = searchedUser;
            }
        }
        return searchUser;
    }
    showOnboarding(){
        // this.setState({signupCodeModal: false});
        const { user } = this.state;
        let url = "/user/onboarding";
        if(user !== null){
            url += "?refType=signup_link&refUId="+user.userId;
        }
        this.history.push(url);
    }
    onRecaptcha(){
        if(this.isBotDetected()){
            this.setState({botDetected: true});
            return;
        }
        const email = this.state[this.email];
        const password = this.state[this.password];
        AuthApi.signUp(email, password, this);
        this.setState({loading: true, showError: false, showRecaptcha: false});
    }
    isBotDetected(){
        const email = this.emailRef.current.value;
        const name = this.nameRef.current.value;
        if(email.length <= 0 && name.length <= 0){
            return false;
        }
        var requestData = [];
        requestData.push({
            'activityType': "honeypot_submitted",
            'url': window.location.href,
            'data': {
                email: email,
                name: name
            }
        });
        
        Api.sendActivity(requestData);
        return true;
    }
    render() {
        const { classes } = this.props;
        const { validation, loading, requestCode, showError, errorMessage, showRecaptcha } = this.state;
        const { /*requireLowerletter, requireUpperletter, requireNumber,*/ requireSymbol, requireLength, botDetected } = this.state;
        const { authorized } = this.store.getState();
        const showPasswordErrors = (validation.password === "success" || validation.password === "error");
        if(authorized){
            return <></>
        }

        const email = this.state[this.email];
        const password = this.state[this.password];
        const passwordConfirm = this.state[this.passwordConfirm];
        const returnUrl = "/signup";
        const amazonUrl = Config.getAmazonLoginUrl(returnUrl);
        const googleUrl = Config.getGoogleLoginUrl(returnUrl);
        const facebookUrl = Config.getFacebookLoginUrl(returnUrl);

        return (
            <>
            <Dialog
                classes={{
                    root: classes.modalRoot+" "+classes.modalRootCustom,
                    paper: classes.modal + " " + classes.modalLogin+" "+classes.customModalSignup,
                    scrollPaper: classes.paperScrollPaperCustom,
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="login-modal-slide-title"
                aria-describedby="login-modal-slide-description"
            >
                <Card plain className={classes.modalSignupCard}>
                    <DialogTitle
                        id="login-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                        >
                        <Button
                            simple
                            className={classes.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            {" "}
                            <Close className={classes.modalClose} />
                        </Button>
                        <h5 className={classes.cardTitle + " " + classes.modalTitle}>Register</h5>
                    </DialogTitle>
                    <DialogContent
                        id="signup-modal-slide-description"
                        className={classes.modalBody}
                        >
                        <GridContainer justify="center" className={classes.modalGridContainer}>
                            {/* <GridItem xs={12} sm={5} md={5}>
                                <InfoArea
                                className={classes.infoArea}
                                title="Marketing"
                                description="We've created the marketing campaign of the website. It was a very interesting collaboration."
                                icon={Timeline}
                                iconColor="rose"
                                />
                                <InfoArea
                                className={classes.infoArea}
                                title="Fully Coded in HTML5"
                                description="We've developed the website with HTML5 and CSS3. The client has access to the code using GitHub."
                                icon={Code}
                                iconColor="primary"
                                />
                                <InfoArea
                                className={classes.infoArea}
                                title="Built Audience"
                                description="There is also a Fully Customizable CMS Admin Dashboard for this product."
                                icon={Group}
                                iconColor="info"
                                />
                            </GridItem> */}
                            <GridItem xs={12} sm={12} md={12}>
                                {/* <div className={classes.textCenter}>
                                <Button justIcon round color="twitter">
                                    <i
                                    className={classes.socials + " fab fa-twitter"}
                                    />
                                </Button>
                                {` `}
                                <Button justIcon round color="dribbble">
                                    <i
                                    className={classes.socials + " fab fa-dribbble"}
                                    />
                                </Button>
                                {` `}
                                <Button justIcon round color="facebook">
                                    <i
                                    className={classes.socials + " fab fa-facebook-f"}
                                    />
                                </Button>
                                {` `}
                                <h4 className={classes.socialTitle}>
                                    or be classical
                                </h4>
                                </div> */}
                                {
                                    botDetected ?
                                        <p className={classes.textCenter}>Thanks!</p>
                                    :
                                        requestCode ?
                                            <div>
                                                <GridItem>
                                                    <Warning>
                                                        We have sent a code by email. Enter it below to confirm your account.
                                                    </Warning>
                                                </GridItem>
                                                <GridContainer>
                                                    <GridItem>
                                                        {
                                                            showError ?
                                                            <GridItem className={"passwordCheck-notValid-customizable "+classes.verifyError}>
                                                                <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                                                                <span className="checkPasswordText-lowerletter">{errorMessage}</span>
                                                            </GridItem>
                                                            :
                                                            <></>
                                                        }
                                                        <form onSubmit={this.confirmSignUp} noValidate>
                                                            <GridItem>                             
                                                                <CustomInput
                                                                    success={validation.code === "success"}
                                                                    error={validation.code === "error"}
                                                                    id="input-code"
                                                                    inputProps={{
                                                                        required: true,
                                                                        onChange: (e) => this.handleChange(e,'code'),
                                                                        name: "code",
                                                                        type: "text",
                                                                        startAdornment: (
                                                                            <InputAdornment position="start" className={classes.inputAdornment} >
                                                                                <VerifiedUser className={classes.inputAdornmentIcon}/>
                                                                            </InputAdornment>
                                                                        ),
                                                                        placeholder:"Verification Code"
                                                                    }}                                    
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                />
                                                            </GridItem>                              
                                                            <GridItem className={classes.textCenter}>
                                                                {
                                                                    loading ?
                                                                        <LoaderComponent />
                                                                    :
                                                                        <>
                                                                        <Button type="submit" color="primary" round>
                                                                            <AuthIcon className={classes.icons} /> Confirm Account
                                                                        </Button>
                                                                        <p className="redirect-customizable text-center mt-2"><span>Didn't receive a code?</span>&nbsp;<span className={classes.anchor} onClick={(e) => this.resendCode(e)}>Resend it</span></p>
                                                                        </>
                                                                }
                                                                
                                                            </GridItem>
                                                        </form>
                                                    </GridItem>
                                                </GridContainer>                        
                                            </div>
                                        :
                                        <>
                                            {
                                                showError ?
                                                <GridItem className={"passwordCheck-notValid-customizable"}>
                                                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                                                    <span className="checkPasswordText-lowerletter">{errorMessage}</span>
                                                </GridItem>
                                                :
                                                <></>
                                            }
                                            <form className={classes.form}>
                                                <CustomInput
                                                    formControlProps={{
                                                        fullWidth: true,
                                                        className: classes.customFormControlClasses
                                                    }}
                                                    success={validation.email === "success"}
                                                    error={validation.email === "error"}
                                                    id={"input-"+this.email}
                                                    inputProps={{
                                                        onChange: (e) => this.handleChange(e, this.email),
                                                        name: this.email,
                                                        value: email,
                                                        startAdornment: (
                                                            <InputAdornment position="start" className={classes.inputAdornment} >
                                                                <Email className={classes.inputAdornmentIcon}/>
                                                            </InputAdornment>
                                                        ),
                                                        placeholder:"Email"
                                                    }}
                                                />
                                                <CustomInput
                                                    formControlProps={{
                                                        fullWidth: true,
                                                        className: classes.customFormControlClasses
                                                    }}
                                                    success={validation.password === "success"}
                                                    error={validation.password === "error"}
                                                    id={"input-"+this.password}                                       
                                                    inputProps={{
                                                        onChange: (e) => this.handleChange(e, this.password),
                                                        name: this.password,
                                                        type: "password",
                                                        value: password,
                                                        startAdornment: (
                                                            <InputAdornment position="start" className={classes.inputAdornment}>
                                                                <LockIcon className={classes.inputAdornmentIcon}></LockIcon>
                                                            </InputAdornment>
                                                        ),
                                                        placeholder:"Password"
                                                    }}
                                                />
                                                {
                                                    showPasswordErrors ?
                                                        <GridItem>
                                                            <div>
                                                                <div className={(requireSymbol?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-symbols"}>
                                                                    <span aria-hidden="true" className="validation-error-symbol check-numbers">{requireSymbol? '\u2713' : '\u2716' }</span>
                                                                    <span className="checkPasswordText-numbers">Password must contain a special character</span>
                                                                </div>
                                                                <div className={(requireLength?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-length"}>
                                                                    <span aria-hidden="true" className="validation-error-symbol check-length">{requireLength? '\u2713' : '\u2716' }</span>
                                                                    <span className="checkPasswordText-length">Password must contain at least 8 characters</span>
                                                                </div>
                                                            </div>
                                                        </GridItem>
                                                    :
                                                    <></>
                                                }
                                                <CustomInput
                                                    success={validation.passwordConfirm === "success"}
                                                    error={validation.passwordConfirm === "error"}
                                                    id={"input-"+this.passwordConfirm}
                                                    inputProps={{
                                                        required: true,
                                                        onChange: (e) => this.handleChange(e, this.passwordConfirm),
                                                        name: this.passwordConfirm,
                                                        type: "password",
                                                        value: passwordConfirm,
                                                        placeholder:"Type Password Again",
                                                        startAdornment: (
                                                            <InputAdornment position="start" className={classes.inputAdornment}>
                                                                <LockIcon className={classes.inputAdornmentIcon}></LockIcon>
                                                            </InputAdornment>
                                                        ),
                                                    }}                                    
                                                    formControlProps={{
                                                        fullWidth: true,
                                                        className: classes.customFormControlClasses+" "+classes.removeMargin
                                                    }}
                                                />
                                                <p>
                                                    Already Have an Account?{" "}
                                                    <Link to="/login" onClick={() => this.props.onClose()}>Sign In</Link>
                                                </p>
                                                {/* <FormControlLabel
                                                    classes={{label: classes.label}}
                                                    control={
                                                        <Checkbox                                                
                                                            tabIndex={-1}
                                                            onClick={(e) => this.handleCheckbox(e, "terms")}
                                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                            classes={{checked: classes.checked, root: classes.checkRoot}}
                                                            checked={this.state.terms}
                                                        />
                                                    }
                                                    label={
                                                        <span>
                                                            I agree to the{" "}
                                                            <Link to="/help/terms" onClick={this.props.onClose}>terms and conditions</Link>.
                                                        </span>
                                                    }
                                                /> */}
                                                    
                                                <div className={classes.botFields}>
                                                    <label htmlFor="email">Email Address</label>
                                                    <input type="email" name="email" id="email" autoComplete="off" ref={this.emailRef} />
                                                    <label htmlFor="name">Name</label>
                                                    <input type="text" name="name" id="name" autoComplete="off" ref={this.nameRef} />
                                                </div>
                                                <div className={classes.textCenter}>
                                                    {
                                                        loading ?
                                                            <LoaderComponent />
                                                        :
                                                        validation.isValid ?
                                                            showRecaptcha ?
                                                                <></>
                                                            :
                                                            <Button round color="primary" onClick={this.handleSubmit}>Get started</Button>
                                                        :
                                                            <Button round color="primary" disabled>Get started</Button>
                                                    }                                        
                                                </div>
                                                {
                                                    Config.isRecaptchaEnabled() ?
                                                        <div className={classes.recaptcha+" "+(showRecaptcha ? classes.showRecaptcha : "")}>
                                                            <RecaptchaV2 onChange={(response) => this.onRecaptcha(response)} show={showRecaptcha} />
                                                        </div>
                                                    :
                                                        <></>
                                                }
                                                <div className={classes.textCenter+" "+classes.socialLinks}>
                                                    <p>or use one of the following to create your account</p>
                                                    <MaterialLink href={amazonUrl} underline="none">
                                                        <Button color="amazon">
                                                            <AmazonIcon className={"MuiSvgIcon-root amazon-icon"} />
                                                            Continue with Login with Amazon
                                                        </Button>
                                                    </MaterialLink>
                                                    <MaterialLink href={googleUrl} underline="none">
                                                        <Button color="google" className={classes.googleButton}>
                                                            <GoogleIcon className={"MuiSvgIcon-root google-icon"} />
                                                            Continue with Google
                                                        </Button>
                                                    </MaterialLink>
                                                    <MaterialLink href={facebookUrl} underline="none">
                                                        <Button color="facebook">
                                                            <FacebookIcon className={"MuiSvgIcon-root facebook-icon"} />
                                                            Continue with Facebook
                                                        </Button>
                                                    </MaterialLink>
                                                </div>
                                            </form>
                                        </>
                                }
                            </GridItem>
                        </GridContainer>
                    </DialogContent>                    
                </Card>
            </Dialog>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                className={classes.snackbar}
                open={this.state.codeResendNotice}
                autoHideDuration={1000}
                onClose={this.codeResendNotice}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">Verification Code Resent!</span>}
            />
            </>
        )
    }
}

SignupModal.defaultProps = {
    open: false
}
SignupModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    onSuccess: PropTypes.func
}
export default withStyles(signupModalStyle)(SignupModal);