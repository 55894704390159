import {
    whiteColor,
    infoColor,
    roseColor,
    primaryColor,
    successColor,
    grayColor
} from "./material-kit-pro-react.jsx";
  
const scrollTopStyle = theme => ({
    button: {
        border: "none",
        borderRadius: "5px",
        bottom: "60px",
        display: "block",
        float: "left",
        opacity: 0.5,
        filter: "alpha(opacity=90)",
        outline: "none",
        padding: "0px",
        position: "fixed",
        right: "35px",
        "-webkit-transition": "all 2s",
        "-moz-transition": "all 2s",
        "-o-transition": "all 2s",
        "-ms-transition": "all 2s",
        transition: "all 2s",
        zIndex: 1400,
        cursor: "pointer",
        visibility: "visible",
        "&.Offscreen": {
            opacity: 0,
            filter: "alpha(opacity=0)",
            visibility: "hidden"
        },
        "& span": {
            borderRadius: "50%",
            boxShadow: "0 7px 15px rgba(0, 0, 0, .10)",
            width: "50px",
            height: "50px",
            textAlign: "center",
            lineHeight: "45px",
            display: "block",
            transition: "all 250ms ease-in-out",
            backgroundColor: infoColor[0]+" !important",
            "& svg": {
                fill: whiteColor+" !important",
                verticalAlign: "middle"
            },
            "&:hover": {
                backgroundColor: infoColor[0]+" !important",
            },
            "&:hover svg": {
                fill: whiteColor+" !important",
            }
        },
        "&:hover": {
            opacity: 1,
            filter: "alpha(opacity=100)",
            "-webkit-transition": "opacity .1s",
            "-moz-transition": "opacity .1s",
            "-o-transition": "opacity .1s",
            "-ms-transition": "opacity .1s",
            transition: "opacity .1s",
        },
        "&.Offscreen:hover": {
            opacity: 0,
            filter: "alpha(opacity=0)",
            cursor: "default",
        },
        [theme.breakpoints.down('sm')]: {
            bottom: "90px",
        },
    },
    primary: {
        "& span, & span:hover": {
            backgroundColor: primaryColor[0]+" !important"
        }
    },
    rose: {
        "& span, & span:hover": {
            backgroundColor: roseColor[0]+" !important"
        }
    },
    success: {
        "& span, & span:hover": {
            backgroundColor: successColor[0]+" !important"
        }
    },
    info: {
        "& span, & span:hover": {
            backgroundColor: infoColor[0]+" !important"
        }
    },
    gray: {
        "& span, & span:hover": {
            backgroundColor: grayColor[26]+" !important"
        }
    },
    customRose: {
        "& span, & span:hover": {
            backgroundColor: roseColor[5]+" !important"
        }
    },
    customSuccess: {
        "& span, & span:hover": {
            backgroundColor: successColor[8]+" !important"
        }
    },
    customInfo: {
        "& span, & span:hover": {
            backgroundColor: infoColor[8]+" !important"
        }
    },
});
  
export default scrollTopStyle;
  