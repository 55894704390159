import React from "react";
import { Helmet } from "react-helmet";
import { Redirect } from 'react-router-dom';
// import SliderComponent from "../components/Smearch/Slider.jsx";
import YoutubeVideoContainer from '../components/Smearch/YoutubeVideo.jsx'
// import FeatureComponent from "../components/Smearch/Features.jsx";
// import VideoComponent from '../components/Smearch/Video.jsx';
import PricingComponent from "../components/Smearch/Pricing.jsx";
import TestimonialsComponent from "../components/Smearch/Testimonials.jsx";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import ProductSection from "../components/Smearch/ProductSection";
import ProductFeatures from "../components/Smearch/ProductFeatures.jsx";
import Config from '../../../Config';
import Button from "../components/CustomButtons/Button.jsx";
import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import { HashLink } from 'react-router-hash-link';
import MarketingFooter from "../components/MarketingFooter"
import { helper } from '../../assets/js/utils/Element';
import Link from "@material-ui/core/Link";
import youtubeIcon from "../../assets/img/youtube-platform-icon-white.png";

import SearchmysocialStyle from "../../assets/jss/material-kit-pro-react/views/searchmysocialStyle.jsx";

const Searchmysocial = class extends React.Component {
    constructor(props) {
        super(props);

        let windowScrollTop;
        if (window.innerWidth >= 768) {
            windowScrollTop = window.pageYOffset / 3;
        } else {
            windowScrollTop = 0;
        }
        this.state = {
            transform: "translate3d(0," + windowScrollTop + "px,0)"
        };

        this.history = this.props.history;
        this.store = this.props.store;
    }
    render() {
        const { classes } = this.props;
        const cdnUrl = Config.getCdnUrl();
        const adminAppUrl = Config.getAdminUrl();
        const insta = cdnUrl+"/www/insta.png";
        const image = cdnUrl+"/www/carousel1.jpg";
        const { authorized, user } = this.store.getState();
        let pathName = window.location.pathname;
        if(authorized && pathName === "/"){
            if(user.userId === user.username){
                return <Redirect to='/user/onboarding' />
            }else{
                return <Redirect to='/dashboard?islogin=1' />
            }            
        }
        return (
            <>
                <div>
                    <div
                        className={classes.pageHeader}
                        style={{ backgroundImage: "url(" + image + ")" }}
                    >
                        <div className={classes.headerContainer}>
                            <GridContainer>
                                <GridItem xs={12} className={classes.textLeft}>
                                    <h1>Search <span>my</span> social</h1>
                                    {/* <h3>Powered by smearch</h3> */}
                                </GridItem>
                                <GridItem xs={12} className={classes.textLeft}>
                                    <h4 className="first-line">Make your Instagram feed searchable, while housing all of your social platforms and link-based content into one spot...</h4>
                                    <h4><b>THE LINK IN YOUR BIO!</b></h4>
                                    <h4 className="third-line">All of your content into one <b>searchable, clickable and shoppable place.</b></h4>
                                    <div className="social-icons-content">
                                        <div>
                                            <Button justIcon simple>
                                                <img src={insta} alt={"instagram"}></img>
                                            </Button>
                                            <span className="text">OFFICIAL INSTAGRAM PARTNER 2021</span>
                                        </div>
                                        <div>
                                            <Button justIcon simple color="youtube" className={classes.youtubeButton}>
                                                <img src={youtubeIcon} alt={"youtube"}></img>
                                            </Button>
                                            <span className="text">OFFICIAL PARTNER 2021</span>
                                        </div>
                                    </div>
                                    <p>The basic search engine is free! No credit card or downloading required. Cancel anytime.</p>
                                    <Link underline="none" href={adminAppUrl+"/signup?"+window.location.search.substr(1)+"&refType=smearch_site_sms&refLink=cta_free"} className="link-button">
                                        <Button size={"lg"} className={"button"}>Get Started For Free!</Button>
                                    </Link>
                                    <HashLink to="#overview" className={classes.buttonLink} 
                                        scroll={el => el.scrollIntoView({ behavior: 'smooth' })} 
                                    >Overview</HashLink>
                                    <HashLink to="#testimonials" className={classes.buttonLink} scroll={el => el.scrollIntoView({ behavior: 'smooth' })} >Testimonials</HashLink>
                                    <HashLink to="#pricing" className={classes.buttonLink} scroll={el => el.scrollIntoView({ behavior: 'smooth' })} >Packages</HashLink>
                                    <HashLink to="#faqs" className={classes.buttonLink} scroll={el => el.scrollIntoView({ behavior: 'smooth' })} >FAQs</HashLink>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                    {/* <SliderComponent /> */}
                    <div className={classNames(classes.main, classes.mainRaised)}>
                        <ProductSection id="overview"/>
                        <TestimonialsComponent id="testimonials" />
                        {/* <FeatureComponent id="features"/> */}
                        <YoutubeVideoContainer />
                        <PricingComponent id="pricing"/>
                    </div>
                    <ProductFeatures id="faqs"/>
                    {/* <VideoComponent /> */}
                    <Helmet 
                        htmlAttributes={{
                            class: helper.getBodyClass(this.history.location.pathname)
                        }}
                        title={process.env.REACT_APP_TITLE}
                    />
                </div>
                <MarketingFooter />
            </>
        );
    }
};

export default withStyles(SearchmysocialStyle)(Searchmysocial);
