import {
    whiteColor,
    grayColor,
    primaryColor,
    title,
    blackColor,
} from "../material-kit-pro-react.jsx";

const searchResultSlideStyle = theme => ({
    modalFeaturedHeaderCustom: {
        padding: "0px !important",
        position: "absolute",
        zIndex: 5,
        left: "0px"
    },
    modalFeaturedButtonCustom: {
        padding: "1rem",
        margin: "0px",
        color: "inherit",
        cursor: "auto",
        "&:hover": {
            color: "inherit",
        },
        "&:focus": {
            color: "inherit",
        },
        "& svg": {
            width: "22px !important",
            height: "22px !important"
        }
    },
    title: {
        ...title,
        fontSize: "20px",
        marginTop: "0px",
        marginBottom: theme.spacing(1),
        textAlign: "left",
        minHeight: "0px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "15px",
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: "0px"
        }
    },
    locationContainer: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        alignItems: "center"
    },
    location: {
        textAlign: "left",
        fontSize: "13px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        marginBottom: "0px"
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    iconButton: {
        marginTop: '4px',
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    iconButtonBlock: {
        display: "block"
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2)
    },
    close: {
        padding: theme.spacing(0.5),
    },
    icons: {
        width: "17px",
        height: "17px",
        color: whiteColor
    },
    snackbar: {
        // position: "absolute",
        // left: "0px",
        // bottom: "auto",
        // right: "0px",
        // transform: "none",
        // width: "288px",
        // margin: "0px auto",
        // marginTop: "130px"
    },
    coverPhoto: {
        width: "100%",
        borderRadius: "6px 6px 0px 0px",
        minHeight: "150px",
        height: "450px",
        objectFit: "cover"
    },
    slideContent: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    },
    label: {
        color: grayColor[12] + " !important",
        fontSize: "14px"
    },
    menuItems: {
        width: "auto",
        alignSelf: "center",
        paddingRight: "0px",
        paddingLeft: "0px",
    },
    dropdownLink: {
        "&,&:hover,&:focus": {
            color: "inherit",
            textDecoration: "none",
            display: "flex",
            padding: "0.75rem 1.25rem 0.75rem 0.75rem",
            alignItems: "center"
        },
        "& i": {
            marginRight: "5px"
        },
        "&:hover": {
            "& svg, svg *": {
                fill: whiteColor
            }
        }
    },
    dropdownButton: {
        textTransform: "Capitalize",
        margin: "0px"
    },
    dropdownManager: {
        display: "inline-flex"
    },
    customIcon: {
        color: primaryColor[0]
    },
    autoWidth: {
        width: "auto",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    resetMargins: {
        marginLeft: "0px",
        marginRight: "0px"
    },
    dotsSection: {
        lineHeight: "51px",
        position: "absolute",
        left: "0px",
        right: "0px",
        maxWidth: "80px",
        margin: "0px auto",
        textAlign: "center",
        fontSize: "13px"
    },
    googleMapLink: {
        "& button": {
            padding: "0px",
            margin: "0px",
            float: "right",
        },
        "& svg": {
            width: "40px",
            height: "40px",
            color: "#6f6f6f"
        },
    },
    dotCircle: {
        border: "1px solid "+blackColor,
        borderRadius: "100%",
        width: "5px",
        height: "5px",
        display: "inline-block",
        margin: "0px 5px",
        "&.active":{
            backgroundColor: blackColor
        }
    },
    customAccordionIcon:{
        paddingLeft: "28px",
        "& div[class*='Accordion-root']": {
            marginBottom: "0px"
        },
        "& .MuiExpansionPanelSummary-root": {
            paddingTop: "15px"
        },
        '& .Mui-expanded':{
            '& .MuiIconButton-label:before':{
                content:"'\\f057'",
                display:"inline-block",
                fontFamily: "'Font Awesome 5 Free'",
                fontWeight: 900,
            },
        },
        '& .MuiExpansionPanelSummary-expandIcon':{
            position:"absolute !important",
            left:"-40px !important",
            right: "auto !important",
            top: "5px !important",
            '& .MuiIconButton-label:before':{
                content:"'\\f055'",
                display:"inline-block",
                fontFamily: "'Font Awesome 5 Free'",
                fontWeight: 900,
                MozOsxFontSmoothing: "grayscale",
                WebkitFontSmoothing: "antialiased",
                fontStyle: "normal",
                fontVariant: "normal",
                textRendering: "auto",
                lineHeight: 1
            },
            '& svg':{
                display:"none",
            },
        } 
    },
    viewMore: {
        display: "block",
        textAlign: "center",
        padding: "5px",
        boxSizing: "border-box",
        fontSize: "16px",
        maxWidth: "100%",
        width: "100%",
        marginTop: "15px",
        textTransform: "capitalize",
        boxShadow: "none !important"
    },
    manageButtons: {
        "& .sd-icon": {
            width: "40px",
            height: "40px",
            minWidth: "40px",
            padding: "0px",
            marginRight: "10px",
        },
        "& .sd-icon svg": {
            width: "30px",
            height: "30px",
        },
        "& .close-icon": {
            float: "right",
            marginRight: "0px",
        }
    },
    carouselHeightFix: {
        height: "auto !important"
    },
    optionsContainer: {
        minHeight: "51px"
    },
    copyButton: {
        marginBottom: "0px"
    },
    textAlign: {
        textAlign: "center",
        fontSize: "12px"
    },
    contentCoverPhoto: {
        position: "relative",
    },
    addPhotoText: {
        position: "absolute",
        bottom: "2px",
        right: "0px",
        left: "0px",
        width: "95%",
        textAlign: "center",
        border: "2px solid "+whiteColor,
        margin: "0px auto",
        padding: "5px",
        "& p": {
            margin: "0px",
            fontSize: "20px",
            fontWeight: "500",
            "& button": {
                margin: "0px",
                padding: "0px 5px",
                fontSize: "20px",
                textTransform: "none",
                lineHeight: "normal",
                verticalAlign: "bottom",
                color: primaryColor[0]+" !important",
                textDecoration: "underline !Important"
            }
        }
    }
});

export default searchResultSlideStyle;