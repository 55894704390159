import React from 'react'

// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from '@material-ui/core/styles/withTheme';
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import ButtonBase from '@material-ui/core/ButtonBase';
import Link from "@material-ui/core/Link";

import { ReactComponent as ShoppingBagIcon } from "../../../assets/icons/shopping_bag.svg";

import ContentTypeIcon from "../ContentTypeIcon";

import threeColumnGridStyle from "../../../assets/jss/user/threeColumnGridStyle";
import defaultImage from "../../../assets/img/image_placeholder.jpg";

class ThreeColumnGrid extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            user: this.props.user,
            response: this.props.response,
        };
    }
    componentWillReceiveProps(props){
        this.setState({
            response: props.response,
        });
    }
    getCoverPhoto(content){
        if(content.hasOwnProperty("coverMediaSourceUrl") && !content.hasOwnProperty("coverMedia") && 
        content.coverMediaSourceUrl && content.coverMediaSourceUrl.length > 0){
            return content.coverMediaSourceUrl;
        }
        if(!content.hasOwnProperty("coverMedia")){
            return defaultImage;
        }
        let isButton = this.isButton(content);
        if(isButton){
            return content.coverMedia.downloadUrlProfile;
        }
        return content.coverMedia.downloadUrlMain;
    }
    getGridListCols(){
        if (isWidthUp('xl', this.props.width)) {
            return 4;
        }      
        if (isWidthUp('lg', this.props.width)) {
            return 4;
        }         
        return 3;
    }
    getGridSpacing(){
        if (isWidthUp('xl', this.props.width) || isWidthUp('lg', this.props.width) || isWidthUp('md', this.props.width)) {
            return 16;
        }      
        return 8;
    }
    isShowPicture(content){
        if(!content.hasOwnProperty("coverMedia") && !content.hasOwnProperty("coverMediaSourceUrl")){
            return false;
        }
        if(!content.hasOwnProperty("showPicture")){
            return true;
        }
        return content.showPicture;
    }
    isButton(content){
        if(content.hasOwnProperty("isButton") && content.isButton){
            return true;
        }
        return false;
    }
    getButtonStyles(){
        const { primaryColor } = this.props;
        const { fontSize, buttonStyle } = this.props.user.guestPreferences;
        const { palette } = this.props.theme;
        let buttonStyles = {
            backgroundColor: primaryColor, 
            fontSize: (fontSize === "large" ? "14px" : "12px"),
            borderRadius: (buttonStyle === "rounded" ? "30px" : "0px"),
            color: palette.custom.buttonText
        };
        return buttonStyles;
    }
    render(){
        const { response } = this.state;
        const { classes, showStar, user } = this.props;
        const buttonStyles = this.getButtonStyles();
        return (
            <GridContainer justify="center" alignItems="center" className={classes.gridContainer}>
                <GridList cellHeight={160} spacing={this.getGridSpacing()} className={classes.gridList} cols={this.getGridListCols()}>
                    {response.map((content, key) => (
                        <GridListTile data-type="Content" data-id={content.contentId} key={content.contentId} className={(this.isButton(content) ? classes.buttonView : "")+" "+classes.gridTile+" sd_track_view"} style={(this.isButton(content) ? buttonStyles : {})}>
                                {
                                    this.isButton(content) ?
                                        <ButtonBase className={(this.isShowPicture(content) ? classes.buttonIcon : "")+" "+classes.buttonBase} style={buttonStyles}>
                                            {
                                                this.isShowPicture(content) ?
                                                    <img src={this.getCoverPhoto(content)} alt={content.title} onClick={() => this.props.onOpen(true, content, key)} style={buttonStyles} />
                                                :
                                                <></>
                                            }
                                            <Link href={user.username+"/"+content.contentId} onClick={(e) => {e.preventDefault(); this.props.onOpen(true, content, key)}} underline="none" style={buttonStyles}>{content.title}</Link>
                                        </ButtonBase>
                                    :
                                    <ButtonBase className={classes.buttonBase}>
                                        {
                                            content.hasOwnProperty("type") && content.type !== "" ?
                                                <ContentTypeIcon contentType={content.type} user={user} />
                                            :
                                                <></>
                                        }
                                        {
                                            (content.hasOwnProperty("hasPurchaseInfo") && content.isAffiliate && content.hasPurchaseInfo && showStar) ?
                                                <ShoppingBagIcon className={classes.cartIcon+" MuiSvgIcon-root"} />
                                            :
                                                <></>
                                        }
                                        {
                                            this.isShowPicture(content) ?
                                                <>
                                                    <Link href={user.username+"/"+content.contentId} className={classes.overlay} onClick={(e) => {e.preventDefault(); this.props.onOpen(true, content, key)}}>{" "}</Link>
                                                    <img src={this.getCoverPhoto(content)} alt={content.title} />
                                                </>
                                            :
                                                <>
                                                    <Link href={user.username+"/"+content.contentId} className={classes.overlay+" "+classes.overlayTitle} onClick={(e) => {e.preventDefault(); this.props.onOpen(true, content, key)}} underline="none" style={buttonStyles}>
                                                        {content.title}
                                                    </Link>
                                                </>
                                        }
                                    </ButtonBase>
                                }
                        </GridListTile>
                    ))}
                </GridList>
            </GridContainer>
        );
    }
}

ThreeColumnGrid.defaultProps = {
    onOpen: function(){ },
    showStar: true
}
ThreeColumnGrid.propTypes = {
    classes: PropTypes.object,
    onOpen: PropTypes.func,
    showStar: PropTypes.bool
};
  
export default withTheme(withWidth()(withStyles(threeColumnGridStyle)(ThreeColumnGrid)));