import React from 'react'
import Config from '../../../Config';
import Header from "./Header/Header.jsx";
import HeaderLinks from "./Header/HeaderLinks.jsx";
import LoginModal from "./Auth/LoginModal.js";
import SignupModal from "./Auth/SignupModal.js";
import LoginOptionsModal from "./Auth/LoginOptionsModal.js";
import SignupCodeModal from "./Auth/SignupCodeModal.js";
import FeedbackModal from "./FeedbackModal";

class NavbarComponent extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        this.appState = this.store.getState();
        
        let color = "info";
        if(this.props.isTransparentHeader === true){
            color = "transparent";
        }
        this.state = {
            open: false,
            color: color,
            loginModal: false,
            signupModal: false,
            loginOptionsModal: false,
            signupCodeModal:false,
            signupCode:"",
            socialSignup:"",
            signupUrl: Config.getSignupUrl(),
            loginUrl: Config.getLoginUrl(),
            feedbackMode: false
        };

        this.updateDrawer = this.updateDrawer.bind(this);
        this.onLoginDialogClose = this.onLoginDialogClose.bind(this);
        this.onLoginDialogOpen = this.onLoginDialogOpen.bind(this);
        this.onSignupDialogClose = this.onSignupDialogClose.bind(this);
        this.onSignupDialogOpen = this.onSignupDialogOpen.bind(this);
        this.onSignupSuccess = this.onSignupSuccess.bind(this);
        this.onLoginOptionsDialogClose = this.onLoginOptionsDialogClose.bind(this);
        this.onLoginOptionsDialogOpen = this.onLoginOptionsDialogOpen.bind(this);
        this.onSignupCodeDialogClose = this.onSignupCodeDialogClose.bind(this);
        this.onSignupCodeDialogOpen = this.onSignupCodeDialogOpen.bind(this);
        this.signupModalShow = this.signupModalShow.bind(this);
        this.onFeedback = this.onFeedback.bind(this);
    }
    componentWillReceiveProps(props){
        this.appState = this.store.getState();
        let color = "info";
        if(props.isTransparentHeader === true){
            color = "transparent";
        }
        this.setState({
            color: color
        });
    }
    updateDrawer(open){
        this.setState({open: open})
    }
    onLoginDialogClose(){
        this.setState({loginModal: false});
    }
    onLoginDialogOpen(){
        this.setState({loginModal: true, open: false});
    }
    onSignupDialogClose(){
        this.setState({signupModal: false});
    }
    onSignupDialogOpen(){
        this.setState({signupModal: true, open: false});
    }
    onSignupSuccess(){
        this.setState({loginModal: true, signupModal: false});
    }
    onLoginOptionsDialogClose(){
        this.setState({loginOptionsModal: false});
    }
    onLoginOptionsDialogOpen(){
        this.setState({loginOptionsModal: true});
    }
    onSignupCodeDialogClose(){
        this.setState({signupCodeModal: false});
    }
    onSignupCodeDialogOpen = ( param ) => {
        this.setState({signupCodeModal: true, socialSignup: param});
    }
    signupModalShow(code){
        if(this.state.socialSignup === "socialSignup"){
            window.location = this.state.signupUrl;
        }else if(this.state.socialSignup === "socialLogin"){
            window.location = this.state.loginUrl;
        }else{
            this.setState({signupModal: true, open: false, signupCodeModal:false, signupCode: code});
        }
    }
    onFeedback(mode = true){
        this.setState({feedbackMode: mode, open: false});
    }
    render(){
        const { color, loginModal, signupModal, loginOptionsModal, signupCodeModal, signupCode, feedbackMode } = this.state;
        const { authorized, user } = this.appState;
        if(authorized && this.history.location.pathname === "/user/onboarding"){
            return <></>
        }
        return (
            <>
                <Header
                    brand={process.env.REACT_APP_MENU_TITLE}
                    links={
                        <HeaderLinks 
                            dropdownHoverColor="info" 
                            store={this.store} 
                            onClose={this.updateDrawer} 
                            onLogin={this.onLoginDialogOpen}
                            onSignup={this.onSignupDialogOpen}
                            onSignupCode={this.onSignupCodeDialogOpen}
                            onLoginOptions={this.onLoginOptionsDialogOpen}
                            onFeedback={this.onFeedback}
                            color={color}
                        />
                    }
                    desktopLinks={
                        <HeaderLinks 
                            dropdownHoverColor="info"
                            desktop={true}
                            store={this.store} 
                            onClose={this.updateDrawer} 
                            onLogin={this.onLoginDialogOpen}
                            onSignupCode={this.onSignupCodeDialogOpen}
                            onSignup={this.onSignupDialogOpen}
                            onFeedback={this.onFeedback}
                            color={color}
                            changeColorOnScroll={{
                                height: 100,
                                color: "info"
                            }}
                        />
                    }
                    bottomBarLinks={
                        <HeaderLinks
                            dropdownHoverColor="info"
                            bottomBar={true}
                            store={this.store} 
                            onClose={this.updateDrawer} 
                            onLogin={this.onLoginDialogOpen}
                            onSignupCode={this.onSignupCodeDialogOpen}
                            onSignup={this.onSignupDialogOpen}
                            onFeedback={this.onFeedback}
                            color={color}
                        />
                    }
                    fixed
                    changeColorOnScroll={{
                        height: 100,
                        color: "info"
                    }}
                    color={color}
                    bottomBarColor="info"
                    store={this.store}
                    history={this.history}
                    open={this.state.open}
                    updateDrawer={this.updateDrawer}
                />
                {
                    authorized === false ?
                        <>
                            <LoginModal store={this.store} onClose={this.onLoginDialogClose} open={loginModal} history={this.history} />
                            <SignupModal store={this.store} onClose={this.onSignupDialogClose} open={signupModal} onSuccess={this.onSignupSuccess} history={this.history} code={signupCode}/>
                            <LoginOptionsModal store={this.store} onClose={this.onLoginOptionsDialogClose} open={loginOptionsModal} history={this.history} />
                            <SignupCodeModal showSignup={this.signupModalShow} store={this.store} onClose={this.onSignupCodeDialogClose} open={signupCodeModal} history={this.history} />
                        </>
                    :
                        feedbackMode ?
                            <FeedbackModal open={feedbackMode} authorizedUser={user} onClose={() => this.onFeedback(false)}/>
                        :
                            <></>
                }                
            </>
        );
    }
}

export default NavbarComponent;