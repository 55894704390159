import React from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import LoaderComponent from "../Loader";

import googleRecaptchaV2Style from "../../../assets/jss/material-kit-pro-react/components/googleRecaptchaV2Style.jsx";

class RecaptchaV2 extends React.Component {
    constructor(props){
        super(props);
        this.gRecaptcha = React.createRef();
        this.renderId = null;
        this.onChange = this.onChange.bind(this);

        this.state = {
            loading: false,
            show: this.props.show,
            token: null
        };
    }
    componentDidMount(){
        if(this.gRecaptcha.current === null){
            return;
        }

        if(typeof(window.grecaptcha) === "object" && typeof(window.grecaptcha.render) === "function"){
            this.renderCaptcha();
            return;
        }

        setTimeout(() => {
            if(typeof(window.grecaptcha) === "object" && typeof(window.grecaptcha.render) === "function"){
                this.renderCaptcha();
                return;
            }
        },2000);

        setTimeout(() => {
            if(typeof(window.grecaptcha) === "object" && typeof(window.grecaptcha.render) === "function" && this.renderId === null){
                this.renderCaptcha();
                return;
            }
        },3000);

        setTimeout(() => {
            if(typeof(window.grecaptcha) === "object" && typeof(window.grecaptcha.render) === "function" && this.renderId === null){
                this.renderCaptcha();
                return;
            }
        },4000);
    }
    componentWillReceiveProps(props){
        const { token } = this.state;
        if(token !== null){
            this.resetCaptcha();
        }else{
            if(this.state.show !== props.show && this.state.show){
                if(this.renderId === null){
                    this.renderCaptcha();
                }else{
                    this.resetCaptcha();
                }
            }else if(props.show && this.renderId === null){
                this.renderCaptcha();
            }
        }
        if(this.state.show !== props.show){
            this.setState({show: props.show});
        }
    }
    renderCaptcha(){
        this.renderId = window.grecaptcha.render(this.gRecaptcha.current, {
            'sitekey' : process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
            'callback': this.onChange
        });
    }
    resetCaptcha(){
        this.renderId = window.grecaptcha.reset(this.renderId);
    }
    onChange(token){
        this.setState({token: token});
        if(this.props.onChange){
            this.props.onChange(token);
        }
    }
    render(){
        const { classes } = this.props;
        const { loading } = this.state;
        return (
            <div className={classes.formControl+" "+(loading ? classes.loading : "")}>
                {
                    loading ?
                        <LoaderComponent align="left" />
                    :
                        <></>
                }
                <div className="g-recaptcha" ref={this.gRecaptcha}></div>
            </div>
        )
    }
}

RecaptchaV2.defaultProps = {
    onChange: null,
    show: false
}
RecaptchaV2.propTypes = {
    classes: PropTypes.object,
    onChange: PropTypes.func,
    show: PropTypes.bool
};

export default withStyles(googleRecaptchaV2Style)(RecaptchaV2);