import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Help from "@material-ui/icons/Help";
import GridContainer from "../Grid/GridContainer.jsx";
import GridItem from "../Grid/GridItem.jsx";
import InfoArea from "../InfoArea/InfoArea.jsx";
import Config from '../../../../Config';

import productFeaturesStyle from "../../../assets/jss/material-kit-pro-react/views/Smearch/productFeaturesStyle.jsx";

const ProductFeatures = class extends React.Component {
  constructor(props) {
    super(props);

    this.store = this.props.store;
  }
  render() {
    const { classes, ...rest  } = this.props;
    const cdnUrl = Config.getCdnUrl();
    const bg9 = cdnUrl+"/www/faqbgnd.jpg";
    return (
        <div className="cd-section" {...rest}>
            <div
                className={classes.features5}
                style={{ backgroundImage: `url(${bg9})` }}
            >
            <GridContainer>
                <GridItem
                    xs={12}
                    sm={8}
                    md={8}
                    className={
                    classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
                    }
                >
                    <h2 className={classes.title}>Our Most Frequently Asked Questions</h2>
                </GridItem>
                <div className={classes.container}>
                    <GridContainer className={classes.gridContainer}>
                    <GridItem xs={12} sm={4} className={classes.gridItem}>
                        <InfoArea
                        vertical
                        className={classes.infoArea5}
                        icon={Help}
                        title="Which Social Media platforms can I connect to, so that they are searchable by keyword/location?"
                        description={
                            <p>Currently, Search My Social can import Instagram, YouTube and Link Tree, but anything with a link can be added manually to your Search My Social platform to make it searchable!</p>
                        }
                        iconColor="info"
                        />
                    </GridItem>
                    <GridItem xs={12} sm={4} className={classes.gridItem}>
                        <InfoArea
                        vertical
                        className={classes.infoArea5}
                        icon={Help}
                        title="Do I need a business profile to set up a Search My Social account?"
                        description={
                            <p>No! As long as you have a public profile (as opposed to a private one!), you can set up your own personal search engine using Search My Social.</p>
                        }
                        iconColor="danger"
                        />
                    </GridItem>
                    <GridItem xs={12} sm={4} className={classes.gridItem}>
                        <InfoArea
                        vertical
                        className={classes.infoArea5}
                        icon={Help}
                        title="How do I share my Search My Social profile with my followers?"
                        description={
                            <p>It’s super easy to direct your followers to your Search My Social page! Just copy the link from your Search Me or About Me page, and paste it into your bio link!</p>
                        }
                        iconColor="primary"
                        />
                    </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.gridContainer}>
                    <GridItem xs={12} sm={4} className={classes.gridItem}>
                        <InfoArea
                        vertical
                        className={classes.infoArea5}
                        icon={Help}
                        title="Can Search My Social replace my need for a blog?"
                        description={
                            <p>Some of our most loyal users do not have blogs and they love that Search My Social has some of the features they want from a blog. Although, there is nothing else on the market that can efficiently and easily house together your online content they way our platform can and make it searchable by keyword/location.</p>
                        }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={4} className={classes.gridItem}>
                        <InfoArea
                        vertical
                        className={classes.infoArea5}
                        icon={Help}
                        title="Can I add click through links to my search results?"
                        description={
                            <p>Sure can! Any content that you have in your Search My Social account can have a click through link. It will always have a default link (where it was originally found) that can be used if you don't want to add a click through link.</p>
                        }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={4} className={classes.gridItem}>
                        <InfoArea
                        vertical
                        className={classes.infoArea5}
                        icon={Help}
                        title="Why do some search links have a viewing window and others don't? "
                        description={
                            <p>We've made the viewing window optional So, those of you that want to have a direct click-through, can have that and others can choose to allow their audience to see the viewing window before clicking.</p>
                        }
                        />
                    </GridItem>
                    </GridContainer>
                </div>
                </GridContainer>
            </div>
        </div>
    );
  }
};

ProductFeatures.propTypes = {
  classes: PropTypes.object
};
export default withStyles(productFeaturesStyle)(ProductFeatures);
