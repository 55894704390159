import React from 'react'

// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from '@material-ui/core/styles/withTheme';
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Link from "@material-ui/core/Link";
import ButtonBase from '@material-ui/core/ButtonBase';

import { ReactComponent as ShoppingBagIcon } from "../../../assets/icons/shopping_bag.svg";

import ContentTypeIcon from "../ContentTypeIcon";

import listViewStyle from "../../../assets/jss/user/listViewStyle";
import defaultImage from "../../../assets/img/image_placeholder.jpg";

class ListView extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            user: this.props.user,
            response: this.props.response,
        };
    }
    componentWillReceiveProps(props){
        this.setState({
            response: props.response,
        });
    }
    getCoverPhoto(content){
        if(content.hasOwnProperty("coverMediaSourceUrl") && !content.hasOwnProperty("coverMedia") && 
        content.coverMediaSourceUrl && content.coverMediaSourceUrl.length > 0){
            return content.coverMediaSourceUrl;
        }
        if(!content.hasOwnProperty("coverMedia")){
            return defaultImage;
        }
        let isButton = this.isButton(content);
        if(isButton){
            return content.coverMedia.downloadUrlProfile;
        }
        return content.coverMedia.downloadUrlMain;
    }
    getTitle(content){
        if(!content.hasOwnProperty("title")){
            return;
        }
        if(content.title.length <= 64){
            return content.title;
        }
        return content.title.substr(0,64).trim()+"..."
    }
    getLocation(content){
        if(content.location.length <= 64){
            return content.location;
        }
        return content.location.substr(0,64).trim()+"..."
    }
    isShowPicture(content){
        if(!content.hasOwnProperty("coverMedia") && !content.hasOwnProperty("coverMediaSourceUrl")){
            return false;
        }
        if(!content.hasOwnProperty("showPicture")){
            return true;
        }
        return content.showPicture;
    }
    isButton(content){
        if(content.hasOwnProperty("isButton") && content.isButton){
            return true;
        }
        return false;
    }
    getButtonStyles(){
        const { primaryColor } = this.props;
        const { fontSize, buttonStyle } = this.props.user.guestPreferences;
        const { palette } = this.props.theme;
        let buttonStyles = {
            backgroundColor: primaryColor, 
            fontSize: (fontSize === "large" ? "14px" : "12px"),
            borderRadius: (buttonStyle === "rounded" ? "30px" : "0px"),
            color: palette.custom.buttonText
        };
        return buttonStyles;
    }
    isDarkMode(){
        const { user } = this.props;
        return (user.hasOwnProperty("guestPreferences") && user.guestPreferences.theme === "dark");
    }
    render(){
        const { response } = this.state;
        const { classes, showStar, user } = this.props;
        const buttonStyles = this.getButtonStyles();
        const { buttonStyle } = this.props.user.guestPreferences;
        return (
            <GridContainer className={classes.gridContainer}>
                {response.map((content, key) => (
                    <GridContainer data-type="Content" key={content.contentId} className={(this.isButton(content) ? classes.buttonView : "")+" "+classes.listView+" "+classes.gridSpacing+" sd_track_view"} style={(this.isButton(content) ? buttonStyles : {})} data-id={content.contentId}>
                        {
                            this.isButton(content) ?
                                <ButtonBase className={(this.isShowPicture(content) ? classes.buttonIcon : "")+" "+classes.buttonBase} style={buttonStyles}>
                                    {
                                        this.isShowPicture(content) ?
                                            <img src={this.getCoverPhoto(content)} alt={content.title} onClick={() => this.props.onOpen(true, content, key)} style={buttonStyles} />
                                        :
                                        <></>
                                    }
                                    <Link href={user.username+"/"+content.contentId} onClick={(e) => {e.preventDefault(); this.props.onOpen(true, content, key)}} underline="none" style={buttonStyles}>{content.title}</Link>
                                </ButtonBase>
                            :
                            <>
                                {
                                    this.isShowPicture(content) ?
                                        <>
                                            <GridItem xs={5} sm={4} md={4} lg={4} className={classes.gridItem}>
                                                <img src={this.getCoverPhoto(content)} alt={content.title} className={classes.coverPhoto} onClick={() => this.props.onOpen(true, content, key)} />
                                                {
                                                    content.hasOwnProperty("type") && content.type !== "" ?
                                                        <ContentTypeIcon contentType={content.type} user={user} />
                                                    :
                                                        <></>
                                                }
                                                {
                                                    (content.hasOwnProperty("hasPurchaseInfo") && content.isAffiliate && content.hasPurchaseInfo && showStar) ?
                                                        <ShoppingBagIcon className={classes.cartIcon+" MuiSvgIcon-root"} />
                                                    :
                                                        <></>
                                                }
                                            </GridItem>
                                            <GridItem xs={7} sm={8} md={8} lg={8} className={classes.gridItem+" "+(this.isDarkMode() ? classes.darkTitle : "")}>
                                                <h3 className={classes.title} onClick={() => this.props.onOpen(true, content, key)}>{this.getTitle(content)}</h3>
                                                {
                                                    content.hasOwnProperty("location") && content.location.length > 0 ?
                                                        <p className={classes.location}>{this.getLocation(content)}</p>
                                                    :
                                                    <></>
                                                }
                                            </GridItem>
                                        </>
                                    :
                                    <GridItem xs={12} sm={12} md={12} lg={12} className={classes.gridItem+" "+classes.hidePicture} style={{borderRadius: (buttonStyle === "rounded" ? "30px" : "0px")}}>
                                        <ButtonBase className={classes.buttonBase}>
                                            <Link href={user.username+"/"+content.contentId} onClick={(e) => {e.preventDefault(); this.props.onOpen(true, content, key)}} underline="none" style={buttonStyles}>{content.title}</Link>
                                        </ButtonBase>
                                    </GridItem>
                                }
                            </>
                        }
                        
                                                
                    </GridContainer>
                ))}
            </GridContainer>
        );
    }
}

ListView.defaultProps = {
    onOpen: function(){ },
    showStar: true
}
ListView.propTypes = {
    classes: PropTypes.object,
    onOpen: PropTypes.func,
    showStar: PropTypes.bool
};
  
export default withTheme(withStyles(listViewStyle)(ListView));