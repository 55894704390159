import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "./CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "./CustomInput/CustomInput.jsx";
import Api from '../../assets/js/utils/Api';
import contentFlagModalStyle from "../../assets/jss/dashboard/content/contentFlagModalStyle";
import CustomSelect from "./CustomInput/CustomSelect";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const FeedbackModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        const authorizedUser = this.props.authorizedUser;

        this.state = {
            feedbackType: "",
            description: "",
            userId: authorizedUser.userId,
            email: authorizedUser.userEmail,
            feedbackSubmit: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.sendActivity = this.sendActivity.bind(this);
    }
    handleChange(event, name) {
        this.setState({
            [name]: event.target.value
        });
    }
    sendActivity(){
        var requestData = [];
        requestData.push({
            'activityType': "feedback",
            'userId': this.state.userId,
            'data': {
                'feedbackType': this.state.feedbackType,
                'description': this.state.description,
                'email': this.state.email,
            }
        });
        Api.sendActivity(requestData);
        this.setState({
            feedbackSubmit: true,
        })
    }
    getFeedbackTypes(){
        return [
            {
                itemValue: "general",
                itemLabel: "General Feedback"
            },
            {
                itemValue: "bug",
                itemLabel: "I think I found a bug"
            },
            {
                itemValue: "idea",
                itemLabel: "I have an idea"
            },
        ];
    }
    render() {
        const { classes } = this.props;
        const { feedbackType ,description, email, feedbackSubmit } = this.state;
        const feedbackTypes = this.getFeedbackTypes();
        return (
            <Dialog
                classes={{
                root: classes.modalRoot,
                paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="deleteContent-slide-title"
                aria-describedby="deleteContent-slide-description"
            >
                {
                    feedbackSubmit ?
                        <DialogTitle
                            id="deleteContent-slide-title"
                            disableTypography
                            className={classes.modalHeader}
                            >
                            <h4 className={classes.modalTitle}>Thanks! We are thankful you took the time to leave this - we will check it out!</h4>
                        </DialogTitle>
                    :
                    <></>
                }
                {
                    !feedbackSubmit ?
                        <DialogContent
                            id="deleteContent-slide-description"
                            className={classes.modalBody}
                        >
                            <CustomSelect 
                                formControlProps={{
                                    fullWidth: true
                                }}
                                selectProps={{
                                    onChange: (e) => this.handleChange(e,"feedbackType"),
                                    value: feedbackType
                                }}
                                labelText={"Feedback Type"}
                                inputProps={{
                                    name: "feedbackType",
                                    id: "outlined-feedbackType",
                                    className: classes.alignLeft
                                }}
                                items={feedbackTypes}
                                itemLabel="itemLabel"
                                itemValue="itemValue"
                            />
                            <CustomInput
                                id="outlined-description"
                                labelText="Description"                                    
                                inputProps={{
                                    value: description,
                                    onChange: (e) => this.handleChange(e, 'description'),
                                    name: "description",
                                    multiline: true,
                                    rows: 3,
                                }}                                    
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                            <CustomInput
                                id="outlined-email"
                                labelText="Email"
                                inputProps={{
                                    value: email,
                                    onChange: event => this.handleChange(event, "email"),
                                    name: "email",
                                }}                                    
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.formControl
                                }}
                            />
                        </DialogContent>
                    :
                    <></>
                }
                <DialogActions
                    className={classes.modalFooter + " " + classes.justifyContentCenter}
                >
                    {
                        feedbackSubmit ?
                            <Button className={classes.closeButton} onClick={() => this.props.onClose()} color="info" round>OK</Button>
                        :
                            <>
                                <Button className={""} onClick={this.sendActivity} color="info" round>OK</Button>
                                <Button onClick={() => this.props.onClose()} color="info" round>Cancel</Button>
                            </>
                    }
                </DialogActions>
            </Dialog>
        );
    }
};

FeedbackModal.defaultProps = {
  open: false
};
FeedbackModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool
};
export default withStyles(contentFlagModalStyle)(FeedbackModal);
