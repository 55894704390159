/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
    container,
    description,
    cardTitle,
    blackColor,
    whiteColor,
    grayColor,
    hexToRgb,
    primaryColor,
} from "./material-kit-pro-react.jsx";

const homePageStyle = theme => ({
    description,
    cardTitle: {
        ...cardTitle,
        textDecoration: "none",
        textAlign: "center !important",
        marginBottom: "0px !important",
        marginTop: "20px !important"
    },
    container: {
        ...container,
        zIndex: "4",
        [theme.breakpoints.down("sm")]: {
            paddingBottom: "100px"
        }
    },
    pageHeader: {
        color: whiteColor,
        border: "0",
        height: "100%",
        margin: "0",
        display: "flex!important",
        padding: "120px 0px",
        position: "relative",
        minHeight: "100vh",
        alignItems: "center",
        "&:before": {
            // background: "rgba(" + hexToRgb(blackColor) + ", 0.5)"
        },
        "&:before,&:after": {
            position: "absolute",
            zIndex: "1",
            width: "100%",
            height: "100%",
            display: "block",
            left: "0",
            top: "0",
            content: '""'
        },
        [theme.breakpoints.down("sm")]: {
            padding: "80px 0px",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "60px 0px",
        },
        background: "#E2E2E1 !important"
    },
    form: {
        marginBottom: "20px"
    },
    cardHeader: {
        width: "auto",
        textAlign: "center"
    },
    socialLine: {
        marginTop: "1rem",
        textAlign: "center",
        padding: "0"
    },
    inputIconsColor: {
        color: grayColor[13]
    },
    textCenter: {
        textAlign: "center"
    },
    iconButtons: {
        marginRight: "3px !important",
        marginLeft: "3px !important"
    },
    block: {
        color: "inherit",
        padding: "0.9375rem",
        fontWeight: "500",
        fontSize: "12px",
        textTransform: "uppercase",
        borderRadius: "3px",
        textDecoration: "none",
        position: "relative",
        display: "block"
    },
    inlineBlock: {
        display: "inline-block",
        padding: "0px",
        width: "auto"
    },
    list: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0"
    },
    left: {
        float: "left!important",
        display: "block",
        "&,& *,& *:hover,& *:focus": {
        color: whiteColor + "  !important"
        }
    },
    right: {
        padding: "15px 0",
        margin: "0",
        float: "right",
        "&,& *,& *:hover,& *:focus": {
        color: whiteColor + "  !important"
        }
    },
    icon: {
        width: "18px",
        height: "18px",
        top: "3px",
        position: "relative"
    },
    footer: {
        position: "absolute",
        width: "100%",
        background: "transparent",
        bottom: "0",
        color: whiteColor,
        zIndex: "2"
    },
    anchor: {
        color: primaryColor[0],
        cursor: "pointer"
    },
    passwordControl: {
        marginBottom: "5px"
    },
    suspended: {
        background: whiteColor,
        textAlign: "center",
        color: grayColor[1],
        padding: theme.spacing(2),
        borderRadius: "6px",
        boxShadow:
            "0 16px 24px 2px rgba(" +
            hexToRgb(blackColor) +
            ", 0.14), 0 6px 30px 5px rgba(" +
            hexToRgb(blackColor) +
            ", 0.12), 0 8px 10px -5px rgba(" +
            hexToRgb(blackColor) +
            ", 0.2)",
        "& div": {
            marginBottom: theme.spacing(1),
            display: "block"
        }
    },
    forgotGridItem: {
        padding: "0px",
        display: "flex",
        justifyContent: "space-between"
    },
    socialLinks: {
        marginTop: theme.spacing(2),
        "& a": {
            display: "block"
        },
        "& button": {
            width: "300px",
            height: "42px",
            padding: "0px 1px",
            textTransform: "none",
            "& .MuiButton-label": {
                height: "42px",
                justifyContent: "start",
                padding: "1px 0px"
            },
            [theme.breakpoints.down("xs")]: {
                width: "270px"
            }
        },
        "& svg": {
            display: "inline-block",
            height: "100%",
            width: "36px",
            marginRight: "6px",
            padding: "6px",
            textAlign: "center",
            verticalAlign: "middle",
            "&.google-icon": {
                background: whiteColor,
                borderRadius: "2px"
            }
        }
    },
    googleButton: {
        backgroundColor: "#4285F4",
        boxShadow:
            "0 2px 2px 0 rgba(" +
            hexToRgb("#4285F4") +
            ", 0.14), 0 3px 1px -2px rgba(" +
            hexToRgb("#4285F4") +
            ", 0.2), 0 1px 5px 0 rgba(" +
            hexToRgb("#4285F4") +
            ", 0.12)",
        "&:hover,&:focus": {
            backgroundColor: "#4285F4",
            boxShadow:
                "0 14px 26px -12px rgba(" +
                hexToRgb("#4285F4") +
                ", 0.42), 0 4px 23px 0px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12), 0 8px 10px -5px rgba(" +
                hexToRgb("#4285F4") +
                ", 0.2)"
        }
    },
    errorsSpacing: {
        marginTop: "10px"
    }
});

export default homePageStyle;
