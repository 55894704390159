import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../Grid/GridContainer.jsx";
import GridItem from "../Grid/GridItem.jsx";
import Card from "../Card/Card.jsx";
import CardBody from "../Card/CardBody.jsx";
import CardAvatar from "../Card/CardAvatar.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import { Link as RouterLink } from "react-router-dom";
import Carousel from "react-slick";
import Muted from "../../components/Typography/Muted.jsx";
import Config from '../../../../Config';
import Link from "@material-ui/core/Link";

import testimonialsStyle from "../../../assets/jss/material-kit-pro-react/views/Smearch/testimonialsStyle.jsx";

const TestimonialComponent = class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            testimonials: this.getTestimonials()
        }

        this.store = this.props.store;
    }
    getTestimonials(){
        let testimonials = [
            {
                img: "https://cdn1-us-west-2.smearch.com/www/referrals/baranbakery.png",
                link: "/baranbakery",
                handle: "@baranbakery",
                name: "Bernice Baran",
                quote: "\"Search My Social has made it easier for both my followers and I to communicate my content with each other. Everything my followers need is just a click away and I don't have to constantly update my website on instagram. Once they click the link, everything is neatly displayed for them to be directed to the exact blog post they are looking for. It's customizable to my needs and user friendly on both ends. I can’t imagine my platform without it.\""
            },
            {
                img: "https://cdn1-us-west-2.smearch.com/www/referrals/befatbehappy.jpeg",
                link: "/befatbehappy",
                handle: "@befatbehappy",
                name: "Caitlin Sakdalan",
                quote: "\"Search my Social has been an incredible addition and tool to further engage my audience. For me, it’s been great to have the ability to confidently post about multiple campaigns/activations with call to action links, and have all of those be visible simultaneously. Search My Social allows me to direct my followers to other outside links and customize the photo with a call to action - something I cannot do on Instagram alone. For my audience, I think Search My Social has been particularly helpful in letting my audience get informed and/or get inspired by specific topics. If they want to see everything I’ve posted about pasta or rice, they can search and find the collective posts within that category. I also love that my followers can find everywhere I’ve posted based on location. As a food-focused travel creator, this has become an important feature that I’ve had to manually create on Instagram through customized hashtags. On search my social, you can just type in ‘Los Angeles’ and all of my posts will come up.\""
            },
            {
                img: "https://cdn1-us-west-2.smearch.com/www/referrals/mamaknowsnutrition.jpeg",
                link: "/mamaknowsnutrition",
                handle: "@mamaknows_nutrition",
                name: "Kacie Barnes",
                quote: "\"I am LOVING search my social and honestly was so excited to start using it because as someone who is always creating content it is amazing to finally have an easy way for my followers (and me!) find the content they’re looking for. Instead of going to google and finding what they want from someone else now you can make sure they get it from you - and if you want to build a successful business from your Instagram like I have - it is HUGE when you can be the one providing your followers with what they need. I’ve always wanted an easy way to integrate all my platforms in one place and Search My Social makes that easy too. This has so much more functionality than the other link in bio tools from what I’ve seen. And the feedback from my followers has been amazing. They really like being able to use the search tool! At the end of the day for me it’s about serving my community the best and Search My Social helps make that possible\""
            },
            {
                img: "https://cdn1-us-west-2.smearch.com/www/referrals/bringingupbabe.jpeg",
                link: "/bringingupbabe",
                handle: "@bringingupbabe",
                name: "Bringing Up Babe",
                quote: "\"I have gone through a few different options for my link in bio - I am so happy that I finally found Search My Social! The income from all my hard work on my page comes directly from the links in my bio, so it is very important to me that they are easily found and always working.\n \nSearch My Social has made such a difference for me. I used to field hundreds of questions a day and that took up a lot of my time! My audience is learning to check the search engine feature for an answer before asking me, saving me lots of precious time! Not only does the search engine save me time in the long run, but it sets my account apart. People can find fun activities to do with their toddler based on what materials they have or what age group they are! They can also search topics they are curious to learn more about without having to scroll endlessly through my feed.\n \nI am so happy to have found Search My Social! It’s full of amazing features, is super user friendly, and the support team is so helpful!\""
            },
            {
                img: "https://cdn1-us-west-2.smearch.com/www/referrals/kalememaybe.jpeg",
                link: "/kalememaybe",
                handle: "@kalememaybe",
                name: "Carina Wolff",
                quote: "Using Search My Social has been a great way to take my \"link in bio\" to the next level. I love that I can add images to my links, and it's easy to keep everything organized for my audience to find it. The search feature is also such a game-changer. It's easy for content to get lost on Instagram, but with the search bar, my audience doesn't have to do all the digging — Search My Social does it for them!"
            },
            {
                img: "https://cdn1-us-west-2.smearch.com/www/referrals/thefivefoottraveler.jpeg",
                link: "/thefivefoottraveler",
                handle: "@thefivefoottraveler",
                name: "Sarah Gallo",
                quote: "\"Search My Social is a game changer! With so much travel content on my feed, my followers now have the ability to search their favorite destinations (or research new places for an upcoming trip) and find everything that I’ve ever posted about it. What a brilliant idea!\""
            },
            {
                img: "https://cdn1-us-west-2.smearch.com/www/referrals/theobgynpa.jpeg",
                link: "/theobgynpa",
                handle: "@theobgynpa",
                name: "Kristy Goodman",
                quote: "\"The OBGYN PA began as a way to share information about women’s health with the world. Search My Social allows my followers to search my posts for complex topics and find exactly what they’re looking for. Even better, I can link blog posts directly to each picture, increasing traffic to my blog.\""
            },
            {
                img: "https://cdn1-us-west-2.smearch.com/www/referrals/health_beet.jpeg",
                link: "/healthbeet",
                handle: "@healthbeet",
                name: "healthbeet",
                quote: "\"Search My Social is genius!! Both as a publisher and as a follower, I find myself using it every day.  I was looking for a specific recipe I had posted last year on my instagram, and instead of scrolling through hundreds of posts, I found it using the search bar in seconds. I'm not sure how I lived without this tool!\""
            },
        ];

        return testimonials;
    }
    renderTestimonial(testimonialUser, key){
        const { classes } = this.props;
        return (
            <div key={key}>
                <Card testimonial plain className={classes.card2}>
                    <CardAvatar testimonial plain>
                        <RouterLink to={testimonialUser.link}>
                            <img className={classes.userImg} src={testimonialUser.img} alt="..." />
                        </RouterLink>
                    </CardAvatar>
                    <CardBody plain>
                        <h5 className={classes.cardDescription}>
                            {testimonialUser.quote}
                        </h5>
                        <h4 className={classes.cardTitle}>{testimonialUser.name}</h4>
                        <Muted>
                            <h6>
                                <RouterLink to={testimonialUser.link} className={classes.userHandle}>
                                    {testimonialUser.handle}
                                </RouterLink>
                            </h6>
                        </Muted>
                    </CardBody>
                </Card>
            </div>
        )
    }
    render() {
        const { classes, ...rest } = this.props;
        const adminAppUrl = Config.getAdminUrl();
        const { testimonials } = this.state;
        // const cdnUrl = Config.getCdnUrl();
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            adaptiveHeight: true
        };
        return (
            <div className="cd-section" {...rest}>
                <div className={classes.testimonials + " " + classes.testimonial2}>
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem
                                xs={12}
                                md={10}
                                className={
                                    classes.mlAuto + " " + classes.mrAuto + "  " + classes.textCenter + "  " + classes.testimonialText
                                }
                            >
                                <h2 className={classes.title}>Our Customers Love Us</h2>
                                <h5 className={classes.description}>We empower you, as a content creator to get the most out your online influence, by giving your audience the opportunity to search your social media channels from one centralized place. Maximize your conversion rates, increase click-throughs, and allow your content to last forever.</h5>
                                <h5 className={classes.description}>Don’t just take our word for it, check out what our loyal customers have to say. While you are at it, click on their profiles and check out their Search My Social accounts!</h5>
                                <Link underline="none" href={adminAppUrl+"/signup?"+window.location.search.substr(1)+"&refType=smearch_site_sms&refLink=cta_premium"} >
                                    <Button size={"lg"} className={"button"}>
                                        Try it for yourself!
                                    </Button>
                                </Link>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Carousel {...settings}>
                                    {
                                        testimonials.map((testimonial, key) => {
                                            return this.renderTestimonial(testimonial, key)
                                        })
                                    }
                                </Carousel>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
        );
    }
};

TestimonialComponent.propTypes = {
  classes: PropTypes.object
};
export default withStyles(testimonialsStyle)(TestimonialComponent);
