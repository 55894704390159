import React from "react";
import { helper } from '../../../assets/js/utils/Element';
import Api from '../../../assets/js/utils/Api';
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import contentAffiliateModalStyle from "../../../assets/jss/dashboard/content/contentAffiliateModalStyle";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import CustomInput from "../../components/CustomInput/CustomInput";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CopyIcon from '@material-ui/icons/FileCopyRounded';
import Snackbar from '@material-ui/core/Snackbar';
import Close from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ContentAffiliateModal = class extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            open: false,
            content: this.props.content,
        };

        this.cancel = this.cancel.bind(this);
        this.copyDiscountCode = this.copyDiscountCode.bind(this);
        this.sendActivity = this.sendActivity.bind(this);
    }
    componentWillReceiveProps(props){
        this.setState({
            content: props.content,
        });
    }
    prepareUrl(href){
        if (!href.match(/^[a-zA-Z]+:\/\//)){
            href = 'http://' + href;
        }
        return href;
    }
    copyDiscountCode(code){
        helper.copyTextToClipboard(code);
        this.setState({
            open: true,
        });
    }
    
    cancel(){
        this.setState({
            open: false,
        });
    }
    sendActivity(type = null){
        var requestData = [];
        let activityType = "content_url_clicked";
        if(type === "secondary"){
            activityType = "content_affiliate_clicked";
        }
        requestData.push({
            'activityType': activityType,
            'contentId': this.state.content.contentId
        });
        Api.sendActivity(requestData);
        this.props.onClose();
    }
    render() {
        const { content } = this.state;
        const { classes, user } = this.props;
        return (
            <Dialog
                classes={{
                root: classes.modalRoot,
                paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="contentAffiliate-slide-title"
                aria-describedby="contentAffiliate-slide-description"
            >
                <DialogTitle
                    id="contentAffiliate-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.closeButton}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>You are about to leave to a third party website</h4>
                </DialogTitle>
                {
                    content.hasOwnProperty("discountCode") && content.discountCode.trim().length > 0 ?
                        <GridItem> 
                            <GridContainer alignItems="center">
                                <GridItem xs={8} sm={8} md={8} lg={8}> 
                                    <CustomInput
                                        id="outlined-discountCode"
                                        labelText="Discount Code"                                    
                                        inputProps={{
                                            value: content.discountCode,
                                            disabled: true,
                                        }}                                    
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={3} sm={3} md={3} lg={3}>
                                    <Button
                                        color={user.guestPreferences.primaryColor}
                                        className={classes.iconButton}
                                        aria-label="Copy to Clipboard"
                                        component="span"
                                        round
                                        onClick={() => this.copyDiscountCode(content.discountCode)}
                                        justIcon
                                        >
                                        <CopyIcon />
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    :
                        <></>
                } 
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    className={classes.snackbar}
                    open={this.state.open}
                    autoHideDuration={1500}
                    onClose={this.cancel}
                    ContentProps={{
                    'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Copied to Clipboard</span>}
                    action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.cancel}
                    >
                        <CloseIcon />
                    </IconButton>,
                    ]}
                />
                {
                    content.hasOwnProperty("affiliateUrl") && content.affiliateUrl.trim().length > 0 ?
                        <DialogActions className={classes.modalFooter + " " + classes.justifyContentCenter + " " + classes.textCenter}>
                            <a href={this.prepareUrl(content.affiliateUrl)} onClick={() => this.sendActivity("secondary")} rel="noopener noreferrer" target="_blank" className={classes.iconButton}>
                                <Button type="button" color={user.guestPreferences.primaryColor} round className={classes.button}>Take me there!</Button>
                            </a>
                            {
                                content.hasOwnProperty("isSponsored") && content.isSponsored ?
                                    <p className={classes.textAlign}>I may be receiving a commission at the above third party link</p>
                                :
                                <></>
                            }
                        </DialogActions>
                    :
                        content.hasOwnProperty("url") && content.url.trim().length > 0 ?
                            <DialogActions className={classes.modalFooter + " " + classes.justifyContentCenter + " " + classes.textCenter}>
                                <a href={this.prepareUrl(content.url)} onClick={() => this.sendActivity()} rel="noopener noreferrer" target="_blank" className={classes.iconButton}>
                                    <Button type="button" color={user.guestPreferences.primaryColor} round className={classes.button}>Take me there!</Button>
                                </a>
                                {
                                    content.hasOwnProperty("isSponsored") && content.isSponsored ?
                                        <p className={classes.textAlign}>I may be receiving a commission at the above third party link</p>
                                    :
                                    <></>
                                }
                            </DialogActions>
                        :
                        <></>
                }
            </Dialog>
        );
    }
};

ContentAffiliateModal.defaultProps = {
  open: false
};
ContentAffiliateModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
};
export default withStyles(contentAffiliateModalStyle)(ContentAffiliateModal);
