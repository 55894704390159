import React from 'react'
import { helper } from '../../../assets/js/utils/Element'
import Api from '../../../assets/js/utils/Api';
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from '@material-ui/core/styles/withTheme';
import Link from "@material-ui/core/Link";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Grid from '@material-ui/core/Grid';
import GridItem from "../../components/Grid/GridItem.jsx";
import SocialIcon from "./SocialIcon";

import userAboutStyle from "../../../assets/jss/user/userAboutStyle";

class UserAbout extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.user = this.props.user;

        let socialIcons = Api.getSocialIcons();
        this.state = {
            socialIcons: socialIcons,
        }
    }
    getUserName(){
        const user = this.user;
        let userName = "";
        if(user.hasOwnProperty("firstName")){
            userName = user.firstName;
        }
        if(user.hasOwnProperty("lastName") && user.lastName.length > 0){
            userName += " "+user.lastName;
        }
        return userName;
    }
    onSearchTerm(e, searchTerm){
        e.preventDefault();
        this.props.onSearchTerm(searchTerm);
    }
    renderSocialIcon(type){
        const socialIconsValues = this.user.socialIcons;
        return <SocialIcon type={type} socialIcons={socialIconsValues} />
    }
    prepareBlogUrl(url){
        let result = url.replace(/(^\w+:|^)\/\//, '');
        return result;
    }
    renderSocialIcons(){
        const socialIcons =  this.user.socialIcons;
        let socialIconsRendered = [];
        for (const socialIcon in socialIcons){
            const icon = this.renderSocialIcon(socialIcon);
            if(socialIcon === "blogUrl" || icon === null){
                continue;
            }
            socialIconsRendered.push(<li key={socialIcon}>
                {icon}
            </li>)
        }
        return socialIconsRendered;
    }
    getTermsStyle(){
        const { primaryColor } = this.props;
        const { palette } = this.props.theme;
        let termsStyle = {
            backgroundColor: primaryColor, 
            color: palette.custom.buttonText
        };
        return termsStyle;
    }
    render(){
        const { classes } = this.props;
        const user = this.user;
        const socialIcons =  user.socialIcons;
        const { theme } = user.guestPreferences;
        const termsStyle = this.getTermsStyle();
        let topSearchTerms;
        if(!user.hasOwnProperty("topSearchTerms")){
            topSearchTerms = "";
        }else{
            let topSearchTermsStr = user.topSearchTerms.trim();
            let topSearchTermslen = topSearchTermsStr.length;
            if(topSearchTermslen <= 0){
                topSearchTerms = "";
            }else{
                topSearchTerms = user.topSearchTerms.split(',');
            }
        }

        let template = "classic"
        if(user !== null && user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("template") && user.guestPreferences.template){
            template = user.guestPreferences.template;            
        }

        return (
            <Grid item className={classes.container+" "+(theme === "dark" ? classes.dark : classes.light)} xs={12} md={8} lg={8}>
                <GridContainer justify="flex-start" alignItems="center" className={classes.gridContainerSpacing}>
                    <GridItem className={classes.userAvatarContainer}>
                        {
                            (user.hasOwnProperty("guestPreferences") && user.guestPreferences.theme === "dark") ?

                                <h3 className={classes.title + " " + classes.darkTitle}>{this.getUserName()}</h3>
                            :
                            <h3 className={classes.title}>{this.getUserName()}</h3>
                        }
                    </GridItem>
                </GridContainer>
                {
                    socialIcons.hasOwnProperty("blogUrl") && socialIcons.blogUrl.length > 0 ?
                        <Link underline="always" className={classes.blogLink} style={{color: this.props.primaryColor}} target="_blank" href={helper.prepareExternalUrl(socialIcons.blogUrl)}>
                            {this.prepareBlogUrl(socialIcons.blogUrl)}
                        </Link>
                    :
                    <></>
                }
                {
                    template !== "simple" ?
                        <GridContainer justify="flex-start" alignItems="center" className={classes.gridContainerSpacing}>
                            <GridItem className={classes.userAvatarContainer+" "+classes.socialIconsContainer}>
                                <ul>
                                    {this.renderSocialIcons()}
                                </ul>
                            </GridItem>
                        </GridContainer>
                    :
                    <></>
                }
                <GridContainer justify="flex-start" alignItems="center" className={classes.about}>
                    <p>{user.bio}</p>
                </GridContainer>
                {
                    topSearchTerms ?
                        <GridContainer justify="flex-start" alignItems="center" className={classes.topSearchTerms}>
                            <label className={classes.topSearchTermsLabel}>
                                <span>Some of my top search terms</span>
                            </label>
                            <div className="react-tagsinput">
                                <span>
                                    {
                                        topSearchTerms.map((content, key) => {
                                            return (
                                                <Link onClick={(e) => this.onSearchTerm(e, content)} href={"/"+user.username+"?q="+content} key={key}>
                                                    <span className="react-tagsinput-tag rose" key={key} style={termsStyle}>{content}</span>
                                                </Link>
                                            )
                                        })
                                    }
                                </span>
                            </div>
                        </GridContainer>
                    :
                    <></>
                }
            </Grid>
        )
    }
}

UserAbout.defaultProps = {
    onSearchTerm: function(){ }
}
UserAbout.propTypes = {
    classes: PropTypes.object,
    onSearchTerm: PropTypes.func
};
  
export default withTheme(withStyles(userAboutStyle)(UserAbout));