/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
// core components
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check"; 

import MaterialIcon from "@mdi/react";
import { mdiInformationOutline, mdiLockOutline } from '@mdi/js';

import customSelectStyle from "../../../assets/jss/material-kit-pro-react/components/customSelectStyle.jsx";

class CustomSelect extends React.Component {
    constructor(props){
        super(props);

        this.input = React.createRef();
        this.forceFocus = false;
        this.onChange = this.onChange.bind(this);
        this.onShowInfo = this.onShowInfo.bind(this);
    }
    onChange(e){
        if(this.props.inputProps.onChange){
            this.props.inputProps.onChange(e);
        }
    }
    onShowInfo(){
        if(this.props.onShowInfo){
            this.props.onShowInfo();
        }
    }
    renderItems(){
        const { classes, items, itemLabel, itemValue } = this.props;
        let color = this.props.color;
        if(typeof(color) === "string" && color.indexOf("#") !== -1){
            color = "custom";
        }
        const selectedClasses = classNames({
            [" " + classes.selectMenuItemSelectedMultiple]: true,
            [classes[color]]: true
        });
        let itemsArray = [];
        items.map((item, key) => {
            let label = "";
            let value = "";
            if(typeof(item) === "object"){
                label = item[itemLabel];
                value = item[itemValue];
            }else{
                label = item;
                value = item;
            }
            itemsArray.push(<MenuItem
                key={key} 
                value={value}
                classes={{
                    root: classes.selectMenuItem + " " + classes[color],
                    selected: selectedClasses
                }}
            >
                {label}
            </MenuItem>);
            return null;
        });
        return itemsArray;
    }
    render(){
        const {
            classes,
            formControlProps,
            labelText,
            id,
            labelProps,
            inputProps,
            error,
            success,
            showInfoTooltip,
            showPrivateIcon,
            selectProps
        } = this.props;
        let color = this.props.color;
        if(typeof(color) === "string" && color.indexOf("#") !== -1){
            color = "custom";
        }

        const labelClasses = classNames({
            [" " + classes.labelRootError]: error,
            [" " + classes.labelRootSuccess]: success && !error,
            [classes[color]]: true
        });
        var formControlClasses;
        if (formControlProps !== undefined) {
            formControlClasses = classNames({
                [formControlProps.className]: true,
                [classes.selectFormControl]: true,
                [classes[color]]: true,
                [classes.formControlDisabled]: inputProps.disabled ? true : false,

            });
        } else {
            formControlClasses = classNames({
                [classes.selectFormControl]: true,
                [classes[color]]: true,
                [classes.formControlDisabled]: inputProps.disabled ? true : false,
            });
        }

        return (
            <FormControl {...formControlProps} className={formControlClasses}>
                {labelText !== undefined ? (
                    <InputLabel
                        className={classes.labelRoot + " " + labelClasses}
                        htmlFor={id}
                        {...labelProps}
                    >
                        {labelText}
                        {
                            showPrivateIcon ?
                                <MaterialIcon path={mdiLockOutline} className="MuiSvgIcon-root private_icon info_tooltip_icon" />
                            :
                            <></>
                        }
                        {
                            showInfoTooltip ?
                                <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={this.onShowInfo} />
                            :
                            <></>
                        }
                    </InputLabel>
                ) : null}
                <Select {...selectProps}
                    inputProps={inputProps}
                    MenuProps={{
                        className: classes.selectMenu,
                        classes: { paper: classes.selectPaper }
                    }}
                    classes={{
                        select: classes.select
                    }}
                >
                    {this.renderItems()}
                </Select>
                {error ? (
                    <Clear className={classes.feedback + " " + classes.labelRootError} />
                ) : success ? (
                    <Check className={classes.feedback + " " + classes.labelRootSuccess} />
                ) : null }
            </FormControl>
        )
    }
}

CustomSelect.defaultProps = {
    loading: false,
    inputProps: {
        value: "",
        onChange: function(){ }
    },
    selectProps: {
        value: "",
        onChange: function(){ }
    },
    onClear: null,
    showClear: false,
    showInfoTooltip: false,
    onShowInfo: function(){ },
    showPrivateIcon: false,
    color: "primary",
    itemLabel: "",
    itemValue: ""
}

CustomSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
    loading: PropTypes.bool,
    onClear: PropTypes.func,
    showClear: PropTypes.bool,
    showInfoTooltip: PropTypes.bool,
    onShowInfo: PropTypes.func,
    showPrivateIcon: PropTypes.bool,
    // color: PropTypes.oneOf([
    //     "primary",
    //     "info",
    //     "success",
    //     "gray",
    //     "rose",
    //     "customInfo",
    //     "customSuccess",
    //     "customRose",
    //     ""
    // ]),
    selectProps: PropTypes.object,
    items: PropTypes.array.isRequired,
    itemLabel: PropTypes.string,
    itemValue: PropTypes.string
};

export default withStyles(customSelectStyle)(CustomSelect);
