import React from 'react'
import { helper } from '../../../assets/js/utils/Element';
import Api from '../../../assets/js/utils/Api';

// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from '@material-ui/core/styles/withTheme';
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Grid from '@material-ui/core/Grid';
import withWidth from '@material-ui/core/withWidth';
// import ButtonBase from '@material-ui/core/ButtonBase';
import GridItem from "../../components/Grid/GridItem.jsx";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown.jsx";
import Button from "../../components/CustomButtons/Button";
import ButtonBase from '@material-ui/core/ButtonBase';

import StarHalfIcon from '@material-ui/icons/StarHalf';
import MaterialIcon from "@mdi/react";
import { mdiDotsVertical, mdiGoogleMaps,  mdiFoodVariant, mdiPodcast } from '@mdi/js';
import { ReactComponent as ShoppingBagIcon } from "../../../assets/icons/shopping_bag.svg";
import PlacesIcon from '@material-ui/icons/LocationOn';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import PoolOutlinedIcon from '@material-ui/icons/PoolOutlined';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkIcon from "@material-ui/icons/Link";
import tiktokIcon from "../../../assets/img/tiktok-icon.png";
import instagramTvIcon from "../../../assets/img/instagram-tv-icon.png";
import instagramVideoIcon from "../../../assets/img/instagram-video-icon.png";  

import defaultImage from "../../../assets/img/image_placeholder.jpg";
import { Link } from '@material-ui/core';
import CustomInput from "../../components/CustomInput/CustomInput";
import CopyIcon from '@material-ui/icons/FileCopyRounded';
import Accordion from "../../components/Accordion/Accordion.jsx";

import ContentDeleteModal from "../Dashboard/Content/ContentDeleteModal";
// import WebLinkEditWizard from "../../pages/dashboard/platforms/WebLinkEditWizard";
import ContentAffiliateModal from "../../components/Search/ContentAffiliateModal";
import ContentFlagModal from "../Dashboard/Content/ContentFlagModal";

import streamViewCardStyle from "../../../assets/jss/user/streamViewCardStyle";

class StreamViewCard extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        this.state = {
            user: this.props.user,
            activeRotate2: "",
            deleteMode: false,
            editMode: false,
            affiliateMode: false,
            flagMode: false,
            response: this.props.response,
            platforms: this.props.platforms,
            content: null,
            key: null,
            rotateContent: null
        };

        this.deleteCatalog = this.deleteCatalog.bind(this);
        this.onDeleteModalClose = this.onDeleteModalClose.bind(this);
        this.onAccordionChange = this.onAccordionChange.bind(this);
        this.sendActivity = this.sendActivity.bind(this);
        this.onWizardClose = this.onWizardClose.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.copyDiscountCode = this.copyDiscountCode.bind(this);
        this.onAffiliateModalClose = this.onAffiliateModalClose.bind(this);
        this.onFlagModalClose = this.onFlagModalClose.bind(this);
    }
    componentWillReceiveProps(props){
        this.setState({
            response: props.response,
            platforms: props.platforms,
        });
    }
    getCoverPhoto(content){
        if(content.hasOwnProperty("coverMediaSourceUrl") && !content.hasOwnProperty("coverMedia") && 
        content.coverMediaSourceUrl && content.coverMediaSourceUrl.length > 0){
            return content.coverMediaSourceUrl;
        }
        if(!content.hasOwnProperty("coverMedia")){
            return defaultImage;
        }
        let isButton = this.isButton(content);
        if(isButton){
            return content.coverMedia.downloadUrlProfile;
        }
        return content.coverMedia.downloadUrlMain;
    }
    isShowPicture(content){
        if(!content.hasOwnProperty("coverMedia") && !content.hasOwnProperty("coverMediaSourceUrl")){
            return false;
        }
        if(!content.hasOwnProperty("showPicture")){
            return true;
        }
        return content.showPicture;
    }
    isButton(content){
        if(content.hasOwnProperty("isButton") && content.isButton){
            return true;
        }
        return false;
    }
    getMenu(){
        const { classes } = this.props;
        const authorizedUser = this.store.getState().user;
        let menuItems = [];    
        menuItems.push(<Button simple className={classes.dropdownLink+" "+classes.dropdownButton} onClick={() => this.flagModal(this.state.content)}>
            Flag Content
        </Button>);
        if(authorizedUser.hasOwnProperty("userPreferences") && authorizedUser.userPreferences.showBetaFeatures){
            menuItems.push(<Link href={"/"} className={classes.dropdownLink}>
                Save to List
            </Link>);
            menuItems.push(<Link href={"/"} className={classes.dropdownLink}>
                Share
            </Link>);
        }
        return menuItems;
    }
    prepareUrl(href){
        if (!href.match(/^[a-zA-Z]+:\/\//)){
            href = 'http://' + href;
        }
        return href;
    }
    getAccordionData(content, user, classes, key){
        let accordionData = [];

        let privateNotes = null;
        if(content.hasOwnProperty("privateNotes") && content.privateNotes.trim().length > 0){
            privateNotes =  <div>
                                <h6>Private Notes</h6>
                                <p>{content.privateNotes}</p>
                            </div>
        }
        let keywords = null;
        if(content.hasOwnProperty("keywords") && typeof(content.keywords === "object")){
            let regularTagsStr = content.keywords.toString();
            content.keywords = regularTagsStr;
        }
        if(content.hasOwnProperty("keywords") && content.keywords.trim().length > 0){
            keywords =  <div>
                            <h6>Search Key Words</h6>
                            <p>{content.keywords}</p>
                        </div>
        }
        let manageContent = null;
        manageContent = (
            <div>
                {privateNotes}
                {keywords}
            </div>
        )
        if(content.ownerId === user.userId){
            accordionData.push(
                {
                    title: "Manage this Search Result",
                    content: manageContent
                }
            )
        }
        return accordionData;
    }
    onAccordionChange(active){
        
    }
    sendActivity(e, url, content, type = null){
        e.preventDefault();
        var requestData = [];
        let activityType = "content_url_clicked";
        if(type === "secondary"){
            activityType = "content_affiliate_clicked";
        }
        requestData.push({
            'activityType': activityType,
            'contentId': content.contentId
        });
        Api.sendActivity(requestData);
        window.open(url,"_blank");
    }
    copyDiscountCode(code){
        helper.copyTextToClipboard(code);
        const modal = document.querySelector(".searchFormResultsModal");
        if(modal){
            document.documentElement.setAttribute("data-modalscroll", modal.scrollTop);
        }
        
        if(this.props.onCopy){
            this.props.onCopy();
        }
    }
    toggleBoolean(content, attrib){
        if(!content.hasOwnProperty(attrib)){
            return;
        }
        content[attrib] = !content[attrib];
        this.setState({
            content: content,
        });
        if(attrib === "isFeatured"){
            content.featuredSequence = 100
        }
        Api.updateContent(content.contentId, content).then((data) => {
            //Handle Success
        }).catch(err => {
            //Handle Error
        });
    }
    onDeleteModalClose(){
        this.setState({deleteMode: false});
    }
    deleteModal(content, key){
        this.setState({deleteMode: true, content: content, editMode: false, key: key});
    }
    deleteCatalog(){
        const content = this.state.content;
        const key = this.state.key;
        if(this.props.onDelete){
            this.props.onDelete(key, content);
        }
        this.setState({
            content: null,
            deleteMode: false,
            key: null,
        });
        Api.deleteContent(content.contentId);
        //this.props.onSearchResultOpen(false);
    }
    getContentPlatform(){
        const { platforms, content } = this.state;
        let defaultPlatform = {
            contentPlatformId: null
        };
        let contentPlatformId = null;
        if(content.hasOwnProperty("contentPlatformId")){
            contentPlatformId = content.contentPlatformId;
        }
        platforms.map(userPlatform => {
            if(contentPlatformId === userPlatform.contentPlatformId){
                defaultPlatform = userPlatform;
            }
            return null;
        });
        this.contentPlatform = defaultPlatform;
        return this.contentPlatform;
    }
    onWizardClose(afterEdit, newContent = null){
        if(newContent !== null){
            this.setState({editMode: false, content: newContent});
            if(this.props.onEdit){
                this.props.onEdit(this.state.key, newContent);
            }
            // setTimeout(() => {
            //     this.addStylesForRotatingCards();
            // }, 100);
        }else{
            this.setState({editMode: false});
        }        
    }
    onEdit(content, key){
        this.setState({editMode: true, content: content, key: key});
    }
    affiliateModal(){
        this.setState({affiliateMode: true});
    }
    onAffiliateModalClose(){
        this.setState({affiliateMode: false});
    }
    flagModal(content, key){
        this.setState({flagMode: true, content: content});
    }
    onFlagModalClose(){
        this.setState({flagMode: false});
    }
    manageSearchResult(content){
        // const { classes } = this.props;
        // this.setState({
        //     activeRotate2: classes.activateRotate,
        //     rotateContent: content
        // });

        // setTimeout(() => {
        //     this.addStylesForRotatingCards();
        // }, 1000);
    }
    rotateBack(){
        this.setState({
            activeRotate2: "",
            rotateContent: null
        });

        // setTimeout(() => {
        //     this.addStylesForRotatingCards();
        // }, 1000);
    }
    renderYoutubeVideo(content){
        let videoUrl = "";
        if(content.hasOwnProperty("url") && content.url){
            videoUrl = content.url;
        }
        if(videoUrl.length <= 0 && content.hasOwnProperty("affiliateUrl") && content.affiliateUrl){
            videoUrl = content.affiliateUrl;
        }
        if(videoUrl.length <= 0){
            return <img src={this.getCoverPhoto(content)} alt={content.title} />;
        }
        const code = videoUrl.split("=")[1];
        return <iframe title={content.title} width="100%" height="315" src={"https://www.youtube.com/embed/"+code} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    }
    renderTypeIcon(content){
        const { classes } = this.props;
        let icon = null;
        switch(content.type){
            case "instagram_post":
                icon = <i className={classes.customIcon+" fab fa-instagram "+classes.instagramLink} style={{color: this.props.primaryColor}} />;
                break;
            case "link":
                icon = <LinkIcon className={classes.customIcon} style={{fill: this.props.primaryColor}}/>;
                break;
            case "recommendation":
                icon = <StarHalfIcon className={classes.customIcon} style={{fill: this.props.primaryColor}}/>;
                break;
            case "recipe":
                icon = <MaterialIcon path={mdiFoodVariant} className={classes.customIcon} style={{fill: this.props.primaryColor}}/>
                break;
            case "place":
                icon = <PlacesIcon className={classes.customIcon} style={{fill: this.props.primaryColor}}/>
                break;
            case "photo":
                icon = <CameraAltOutlinedIcon className={classes.customIcon} style={{fill: this.props.primaryColor}}/>
                break;
            case "shopping_items":
                icon = <ShoppingBagIcon className={classes.customIcon} style={{fill: this.props.primaryColor}}/>
                break;
            case "workout":
                icon = <PoolOutlinedIcon className={classes.customIcon} style={{fill: this.props.primaryColor}}/>;
                break;
            case "youtube":
                icon = <YouTubeIcon className={classes.customIcon} style={{fill: this.props.primaryColor}}/>;
                break;
            case "tiktok":
                icon = <img src={tiktokIcon} alt="tiktok" className={classes.customIcon} style={{width: "100%"}} />;
                break;
            case "podcast":
                icon = <MaterialIcon path={mdiPodcast} className={classes.customIcon} style={{fill: this.props.primaryColor}} />
                break;
            case "instagram_tv":
                icon = <img src={instagramTvIcon} alt="instagram tv" className={classes.customIcon} style={{width: "100%"}} />;
                break;
            case "instagram_video":
                icon = <img src={instagramVideoIcon} alt="instagram Video" className={classes.customIcon} style={{width: "100%"}} />;
                break;
            default:
                break;
        }
        return icon;
    }
    openAffiliateUrl(content){
        let url = "";
        if(content.affiliateUrl && content.affiliateUrl.trim().length > 0){
            url = this.prepareUrl(content.affiliateUrl);
        }else if(content.hasOwnProperty("url") && content.url.trim().length > 0){
            url = this.prepareUrl(content.url);
        }
        window.open(url, '_blank');
    }
    renderFront(content, key){
        const { classes, user } = this.props;
        const authorizedUser = this.store.getState().user;
        const { primaryColor } = user.guestPreferences;

        if(content === null){
            return (<></>)
        }
        return (
            <div className={classes.front+" "+classes.customFront}>
                <div className={classes.cardBody+" "+classes.cardBodyRotate}>
                    <Grid className={" sd_track_view "+(this.isShowPicture(content)?"":classes.hidePicture)} data-type="Content" data-id={content.contentId} key={content.contentId}>
                        <div className={classes.coverPhoto}>
                            {
                                content.hasOwnProperty("type") && content.type === "youtube" ?
                                    this.renderYoutubeVideo(content)
                                :
                                    content.hasOwnProperty("type") && content.type === "tiktok" ?
                                        <img src={this.getCoverPhoto(content)} alt={content.title} />
                                    :
                                    <img src={this.getCoverPhoto(content)} alt={content.title} />
                            }
                        </div>
                        <GridContainer className={classes.resetMargins+" "+classes.optionsContainer} justify="space-between" alignItems="flex-end">
                            <GridItem className={classes.autoWidth}>
                                {
                                    (authorizedUser) ?
                                        <CustomDropdown
                                            noLiPadding
                                            hoverColor={primaryColor}
                                            buttonText=""
                                            caret={false}
                                            buttonProps={{
                                                className: classes.navLink,
                                                color: "transparent",
                                                justIcon: true
                                            }}
                                            popperProps={{
                                                responsive: false
                                            }}
                                            managerProps={{
                                                className: classes.dropdownManager
                                            }}
                                            buttonIconImage={<MaterialIcon path={mdiDotsVertical} className={classes.materialIcon+" MuiSvgIcon-root"} />}
                                            dropdownList={this.getMenu()}
                                            dropPlacement= "bottom-start"
                                        />
                                    :
                                    <></>
                                }
                                {
                                    content.hasOwnProperty("type") && content.type !== "" ?
                                        <Button justIcon type="button" color="transparent" onClick={() => this.openAffiliateUrl(content)} className={classes.contentTypeIcon}>
                                            {this.renderTypeIcon(content)}
                                        </Button>
                                    :
                                        <></>
                                }
                            </GridItem> 
                            {
                                content.hasOwnProperty("hasPurchaseInfo") && content.hasPurchaseInfo && content.isAffiliate ?
                                    <GridItem className={classes.autoWidth}>
                                        {
                                            content.hasOwnProperty("isSponsored") && content.isSponsored ?
                                                <Button justIcon type="button" color="transparent" onClick={() => this.affiliateModal()}>
                                                    <ShoppingBagIcon className={classes.customIcon+" MuiSvgIcon-root"} style={{color: this.props.primaryColor}} />
                                                </Button>
                                            :
                                                (content.affiliateUrl && content.affiliateUrl.trim().length > 0) ?
                                                    <a href={this.prepareUrl(content.affiliateUrl)} onClick={(e) => this.sendActivity(e, content.affiliateUrl, content, 'secondary')} rel="noopener noreferrer" target="_blank">
                                                        <Button justIcon type="button" color="transparent">
                                                            <ShoppingBagIcon className={classes.customIcon+" MuiSvgIcon-root"} style={{color: this.props.primaryColor}} />
                                                        </Button>
                                                    </a>
                                                :
                                                    (content.url && content.url.trim().length > 0) ?
                                                        <a href={this.prepareUrl(content.url)} onClick={(e) => this.sendActivity(e, content.url, content)} rel="noopener noreferrer" target="_blank">
                                                            <Button justIcon type="button" color="transparent">
                                                                <ShoppingBagIcon className={classes.customIcon+" MuiSvgIcon-root"} style={{color: this.props.primaryColor}} />
                                                            </Button>
                                                        </a>
                                                    :
                                                    <></>
                                                
                                        }
                                    </GridItem>
                                :
                                <></>
                            }                                       
                        </GridContainer>
                        <GridContainer className={classes.slideContent+" "+(content.ownerId === authorizedUser.userId ? classes.manageButtonPadding : '')}>
                            <GridItem xs={12}>                           
                                <h3 className={classes.title}>{content.title}</h3>
                            </GridItem>
                            {
                                content.hasOwnProperty("location") && content.location.trim().length > 0 ?
                                    <div className={classes.locationContainer}>
                                        <GridItem xs={9}>                           
                                            <p className={classes.location}>{content.location}</p>
                                        </GridItem>
                                        <GridItem xs={3} className={classes.googleMapLink}>                           
                                            <a href={"https://www.google.com/maps/search/?api=1&query="+content.latitude+","+content.longitude} rel="noopener noreferrer" target="_blank">
                                                <Button justIcon type="button" color="transparent" className={classes.button}>
                                                    <MaterialIcon path={mdiGoogleMaps} className={classes.customIcon+" MuiSvgIcon-root"} style={{color: this.props.primaryColor}} />
                                                </Button>
                                            </a>
                                        </GridItem>
                                    </div>
                                :
                                <></>
                            }
                            {
                                content.hasOwnProperty("discountCode") && content.discountCode.trim().length > 0 ?
                                    <GridItem> 
                                        <GridContainer alignItems="center">
                                            <GridItem xs={8} sm={8} md={8} lg={8}> 
                                                <CustomInput
                                                    id="outlined-discountCode"
                                                    labelText="Discount Code"                                    
                                                    inputProps={{
                                                        value: content.discountCode,
                                                        disabled: true,
                                                    }}                                    
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    ref={this.discountCodeInput}
                                                />
                                            </GridItem>
                                            <GridItem xs={3} sm={3} md={3} lg={3}>
                                                <Button
                                                    color={user.guestPreferences.primaryColor}
                                                    className={classes.iconButton+" "+classes.copyButton}
                                                    aria-label="Copy to Clipboard"
                                                    component="span"
                                                    round
                                                    onClick={() => this.copyDiscountCode(content.discountCode)}
                                                    justIcon
                                                    >
                                                    <CopyIcon />
                                                </Button>
                                            </GridItem>
                                        </GridContainer>                                
                                    </GridItem>
                                :
                                    <></>
                            }
                            {
                                (content.affiliateUrl && content.affiliateUrl.trim().length > 0) ?
                                    <GridItem> 
                                        <a href={this.prepareUrl(content.affiliateUrl)} onClick={(e) => this.sendActivity(e, content.affiliateUrl, content, 'secondary')} rel="noopener noreferrer" target="_blank" className={classes.iconButton+" "+classes.iconButtonBlock}>
                                            <Button className={classes.viewMore} type="button" color={primaryColor}>
                                                Visit
                                            </Button>
                                        </a>
                                        {
                                            content.hasOwnProperty("isSponsored") && content.isSponsored ?
                                                <p className={classes.textAlign}>I may be receiving a commission at the above third party link</p>
                                            :
                                            <></>
                                        }
                                    </GridItem>
                                :
                                (content.hasOwnProperty("url") && content.url.trim().length > 0) ?
                                    <GridItem> 
                                        <a href={this.prepareUrl(content.url)} onClick={(e) => this.sendActivity(e, content.url, content)} rel="noopener noreferrer" target="_blank" className={classes.iconButton+" "+classes.iconButtonBlock}>
                                            <Button className={classes.viewMore} type="button" color={primaryColor}>
                                                Visit
                                            </Button>
                                        </a>
                                        {
                                            content.hasOwnProperty("isSponsored") && content.isSponsored ?
                                                <p className={classes.textAlign}>I may be receiving a commission at the above third party link</p>
                                            :
                                            <></>
                                        }
                                    </GridItem>
                                :
                                <></>
                            }  
                            {
                                content.hasOwnProperty("publicDescription") && content.publicDescription.trim().length > 0 ?
                                    <GridItem className={classes.customAccordionIcon}>
                                        <Accordion
                                            activeColor={primaryColor}
                                            collapses={[
                                                {
                                                    title: "Description",
                                                    content: <div dangerouslySetInnerHTML={{__html: content.publicDescription}}></div>
                                                }
                                            ]}
                                        />
                                    </GridItem>
                                :
                                <></>
                            }
                            <GridItem className={classes.customAccordionIcon}>
                                <Accordion
                                    activeColor={primaryColor}
                                    // onChange={this.onAccordionChange}
                                    collapses={
                                        this.getAccordionData(content, authorizedUser, classes, key)
                                    }
                                />
                            </GridItem>
                        </GridContainer>
                    </Grid>
                </div>
            </div>
        );
    }
    getButtonStyles(){
        const { primaryColor } = this.props;
        const { fontSize, buttonStyle } = this.props.user.guestPreferences;
        const { palette } = this.props.theme;
        let buttonStyles = {
            backgroundColor: primaryColor, 
            fontSize: (fontSize === "large" ? "14px" : "12px"),
            borderRadius: (buttonStyle === "rounded" ? "30px" : "0px"),
            color: palette.custom.buttonText
        };
        return buttonStyles;
    }
    render(){
        const { response, deleteMode, /*editMode,*/ affiliateMode, flagMode, content } = this.state;
        const { classes, user } = this.props;
        const authorizedUser = this.store.getState().user;
        const buttonStyles = this.getButtonStyles();
        const { buttonStyle, theme } = this.props.user.guestPreferences;
        const isDark = (theme === "dark");
        // const match = {
        //     params: {
        //         contentId: content  ? content.contentId : null
        //     }
        // }
        return (
            <GridItem xs={12} lg={12} className={classes.marginAuto+" "+classes.removePadding}>
                <GridContainer justify="center" alignItems="center" className={classes.streamCardLists}>
                    {response.map((content, key) => (
                        this.isButton(content) ?
                            <GridItem xs={12} sm={12} md={12} lg={12} className={classes.rotatingCardContainerCustom+" "+classes.gridItem+" "+classes.buttonView} key={content.contentId} style={(this.isButton(content) ? buttonStyles : {})}>
                                <ButtonBase className={(this.isShowPicture(content) ? classes.buttonIcon : "")+" "+classes.buttonBase} style={buttonStyles}>
                                    {
                                        this.isShowPicture(content) ?
                                            <img src={this.getCoverPhoto(content)} alt={content.title} onClick={() => this.props.onOpen(true, content, key)} style={buttonStyles} />
                                        :
                                        <></>
                                    }
                                    <Link href={user.username+"/"+content.contentId} onClick={(e) => {e.preventDefault(); this.props.onOpen(true, content, key)}} underline="none" style={buttonStyles}>{content.title}</Link>
                                </ButtonBase>
                            </GridItem>
                        :
                        this.isShowPicture(content) ?
                            <div className={classes.gridListRoot} key={content.contentId}>
                                <div
                                    className={
                                        classes.rotatingCardContainer +
                                        " " +
                                        classes.manualRotate +
                                        " " +
                                        classes.rotatingCardContainerCustom
                                    }
                                >
                                    <div className={classes.card+" "+classes.cardRotate+" "+classes.cardRotateCustom+" "+(isDark ? classes.darkCard : "")}>
                                        {this.renderFront(content, key)}
                                    </div>
                                </div>
                            </div>
                        :
                        <GridItem xs={12} sm={12} md={12} lg={12} className={classes.rotatingCardContainerCustom+" "+classes.gridItem+" "+classes.buttonView} key={content.contentId} style={{borderRadius: (buttonStyle === "rounded" ? "30px" : "0px")}}>
                            <ButtonBase className={classes.buttonBase}>
                                <Link href={"/c/"+content.contentId} onClick={(e) => {e.preventDefault(); this.props.onOpen(true, content, key)}} underline="none" style={buttonStyles}>{content.title}</Link>
                            </ButtonBase>
                        </GridItem>
                    ))}
                </GridContainer>
                {
                    deleteMode ?
                        <ContentDeleteModal open={deleteMode} onClose={() => this.onDeleteModalClose()} onSuccess={() => this.deleteCatalog()} />
                    :
                    <></>
                }                                
                {/* {
                    editMode ?
                        <WebLinkEditWizard match={match} open={editMode} store={this.store} content={this.state.content} onWizardClose={this.onWizardClose} platform={this.getContentPlatform()} onDelete={() => this.deleteModal()} hardrefresh={false} />
                    :
                        <></>
                } */}
                {
                    affiliateMode ?
                        <ContentAffiliateModal content={content} open={affiliateMode} onClose={() => this.onAffiliateModalClose()} user={user} />  
                    :
                    <></>
                }
                {
                    flagMode ?
                        <ContentFlagModal open={flagMode} content={content} authorizedUser={authorizedUser} onClose={() => this.onFlagModalClose()}/>
                    :
                    <></>
                }
            </GridItem>
        );
    }
}

StreamViewCard.defaultProps = {
    onOpen: function(){ },
    showStar: true
}
StreamViewCard.propTypes = {
    classes: PropTypes.object,
    onOpen: PropTypes.func,
    showStar: PropTypes.bool
};
  
export default withTheme(withWidth()(withStyles(streamViewCardStyle)(StreamViewCard)));