/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  container,
  title,
  main,
  whiteColor,
  grayColor,
  mainRaised,
  hexToRgb,
  blackColor,
} from "../../material-kit-pro-react.jsx";

const searchmysocialStyle = theme => ({
    main: {
        ...main
        /*overflow: "hidden"*/
    },
    mainRaised: {
        ...mainRaised,
        textAlign: "center",
        margin: "-60px 30px -60px",
        overflow: "hidden",
        [theme.breakpoints.down("xs")]: {
            paddingRight: "10px",
            paddingLeft: "10px",
            marginTop: "-30px",
            marginBottom: "-30px",
        },
    },
    parallax: {
        height: "90vh",
        overflow: "hidden"
    },
    container: {
        ...container,
        zIndex: 1
    },
    title: {
        ...title,
        color: whiteColor
    },
    brand: {
        color: whiteColor,
        textAlign: "center",
        "& h1": {
        fontSize: "4.2rem",
        fontWeight: "600",
        display: "inline-block",
        position: "relative"
        }
    },
    proBadge: {
        position: "relative",
        fontSize: "22px",
        textTransform: "uppercase",
        fontWeight: "700",
        right: "-10px",
        padding: "10px 18px",
        top: "-30px",
        background: whiteColor,
        borderRadius: "3px",
        color: grayColor[18],
        lineHeight: "22px",
        boxShadow: "0 5px 5px -2px rgba(" + hexToRgb(grayColor[25]) + ",.4)"
    },
    pageHeader: {
        position: "relative",
        height: "100vh",
        maxHeight: "1600px",
        backgroundPosition: "50%",
        backgroundSize: "cover",
        margin: "0",
        padding: "0",
        border: "0",
        display: "flex",
        WebkitBoxAlign: "center",
        MsFlexAlign: "center",
        alignItems: "center",
        "&:before": {
            background: "rgba(" + hexToRgb(blackColor) + ", 0.5)"
        },
        "&:after,&:before": {
        position: "absolute",
        zIndex: "1",
        width: "100%",
        height: "100%",
        display: "block",
        left: "0",
        top: "0",
        content: "''"
        }
    },
    headerContainer: {
        ...container,
        zIndex: "2",
        position: "relative",
        "& h1, & h3, & h4, & h6, & p": {
            color: whiteColor
        },
        "& h1": {
            fontSize: "48px",
            marginTop: "0px",
            lineHeight: "normal",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontFamily: '"Gotham-Medium","Roboto", "Helvetica", "Arial", sans-serif',
            "& span": {
                color: "#4da9ef"
            },
            [theme.breakpoints.down("md")]: {
                fontSize: "2rem",
            },
            [theme.breakpoints.down("sm")]: {
                fontSize: "1.7rem",
            },
            [theme.breakpoints.down("sm")]: {
                fontSize: "1.4rem",
            },
        },
        "& h3": {
            margin: "0px",
            lineHeight: "normal",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: "24px",
            [theme.breakpoints.down("sm")]: {
                fontSize: "20px",
            },
            fontFamily: '"Gotham-Medium","Roboto", "Helvetica", "Arial", sans-serif',
        },
        "& h4": {
            marginTop: "18px",
            marginBottom: "18px",
            "&.first-line": {
                maxWidth: "460px"
            },
            "&.third-line": {
                maxWidth: "440px",
            },
            "&.small-width1": {
                maxWidth: "220px"
            },
            "&.small-width2": {
                maxWidth: "180px"
            },
            [theme.breakpoints.down("sm")]: {
                fontSize: "1.1rem",
                marginTop: "15px",
                marginBottom: "15px",
            },
            [theme.breakpoints.down("xs")]: {
                fontSize: "1rem",
                marginTop: "10px",
                marginBottom: "10px",
            },
        },
        "& p": {
            marginTop: theme.spacing(3),
            maxWidth: "330px",
            [theme.breakpoints.down("xs")]: {
                marginTop: "10px",
            }
        },
        "& a": {
            marginRight: "5px",
            paddingRight: "5px",
            borderRight: "1px solid #1ba9f5",
            color: "#1ba9f5",
            "&:last-child": {
                border: "none",
                padding: "0px",
                margin: "0px"
            }, 
            "&:hover, &:active, &:visited, &:focus": {
                color: "#1ba9f5",
            }
        },
        "& .link-button": {
            borderRight: "none"
        },
        "& .button, & .button:hover": {
            display: "block",
            backgroundColor: "#4da9ef",
            borderRadius: "20px",
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            textTransform: "Capitalize",
            fontWeight: "bold",
            [theme.breakpoints.down("sm")]: {
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
            },
            [theme.breakpoints.down("sm")]: {
                marginTop: "10px",
                marginBottom: "10px",
            }
        },
        "& .social-icons-content": {
            paddingTop: theme.spacing(1),
            "& button": {
                padding: "0px",
                width: "20px",
                height: "20px",
                minWidth: "20px",
                "& img": {
                    width: "100%",
                }
            },
            "& .fab": {
                lineHeight: "20px",
            },
            "& .text": {
                color: whiteColor,
                fontWeight: "bold",
                paddingLeft: theme.spacing(1),
                [theme.breakpoints.down("xs")]: {
                    fontSize: "12px"
                }
            }
        }
    },
    textLeft: {
        textAlign: "left"
    },
    button: {
        margin: "0px",
        marginTop: "10px",
        fontSize: "18px",
        padding: "0.8rem 2rem",
        "& span":{
            textTransform: "none"
        }
    },
    logo: {
        maxWidth: "200px",
        [theme.breakpoints.down("xs")]: {
            maxWidth: "150px",
        },
    },
    buttonLink: {
        padding: "0px",
        color: whiteColor,
        "&:hover": {
            color: whiteColor,
            textDecoration: "none"
        }
    },
    youtubeButton: {
        width: "85px !important",
        "&+.text": {
            position: "relative",
            top: "3px"
        }
    }
});

export default searchmysocialStyle;
