import { whiteColor } from "../material-kit-pro-react.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
  
const aboutModalStyle = theme => ({
    ...modalStyle(theme),
    modalScrollPaper: {
        [theme.breakpoints.down("sm")]: {
            height: "auto"
        }
    },
    modalCustom: {
        maxWidth: "500px",
        [theme.breakpoints.down("sm")]: {
            marginTop: "16px !important"
        }
    },
    modalHeaderCustom: {
        paddingTop: "0px"
    },
    modalCloseButtonCustom: {
        position: "absolute",
        right: "0px",
        top: "0px",
        margin: "0px"
    },
    darkModalRoot: {
        "& $modalCustom": {
            backgroundColor: "rgb(1, 1, 1)",
            boxShadow: "0px 0px 5px -1px "+whiteColor
        }
    },
    editInfo: {
        textAlign: "right"
    }
});
  
export default aboutModalStyle;
  