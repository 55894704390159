import {
    whiteColor,
    blackColor,
    container,
    title,
    main,
    mainRaised,
    hexToRgb,
} from "../material-kit-pro-react.jsx";
import customSelectStyle from "../material-kit-pro-react/customSelectStyle.jsx";
import imagesStyle from "../material-kit-pro-react/imagesStyles.jsx";

const searchPageStyle = theme => ({
    ...customSelectStyle,
    ...imagesStyle,
    main: {
        ...main,
        zIndex: "1107",
    },
    mainRaised: {
        ...mainRaised,
        marginTop: "300px",
        [theme.breakpoints.down('sm')]: {
            marginTop: "240px"
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: "100px"
        }
    },
    profile: {
        textAlign: "center",
        "& img": {
            maxWidth: "160px",
            width: "100%",
            margin: "0 auto",
            transform: "translate3d(0, -50%, 0)",
            height: "160px",
            objectFit: "cover",
            [theme.breakpoints.down("xs")]: {
                height: "80px",
                maxWidth: "80px",
                transform: "translate3d(0, -55px, 0)",
            }
        }
    },
    name: {
        marginTop: "-80px",
        "& h6": {
            marginBottom: "0px"
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: "-55px",
            "& h6": {
                marginBottom: "0px"
            }
        }
    },
    icons: {
        width: "17px",
        height: "17px",
        color: whiteColor
    },
    container: {
        ...container,
        padding: theme.spacing(2)
    },
    title: {
        ...title,
        position: "relative",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none"
    },
    buttonSpacing: {
        marginLeft: "10px",
    },
    hide: {
        display: "none"
    },
    gridContainerSpacing: {
        marginBottom: theme.spacing(2)
    },
    userAvatarContainer: {
        maxWidth: "none",
        width: "auto",
        flexBasis: "auto",
        paddingRight: "0px",
    },
    userAvatar: {
        maxWidth: "100px",
        [theme.breakpoints.down('sm')]: {
            maxWidth: "60px",
        }
    },
    iconButton: {
        color: blackColor,
        marginLeft: "5px",
        "& i": {
            fontSize: "30px !important",
        }
    },
    form: {
        width: "100%"
    },
    parallax: {
        height: "380px",
        backgroundPosition: "top center"        
    },
    marginAuto: {
        marginLeft: "auto !important",
        marginRight: "auto !important"
    },
    carousel: {
        "& .slick-list": {
            minHeight: "400px"
        },
        "& .slick-dots": {
            position: "fixed",
            bottom: "0px",
            left: "0px",
            right: "0px",
            marginBottom: "0px",
            padding: "0px",
            backgroundColor: whiteColor,
            height: "25px",
            boxShadow:
                "0px -3px 18px 0px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12)",
            [theme.breakpoints.down('sm')]: {
                bottom: "40px",
            }
        },
        "& .slick-dots li": {
            width: "auto",
            height: "auto",
            verticalAlign: "middle",
            marginBottom: "3px",
            marginTop: "3px",
            boxShadow:
                "3px 3px 18px 3px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12), 3px 3px 10px 3px rgba(" +
                hexToRgb(blackColor) +
                ", 0.15)",
        },
        "& .slick-dots li button": {
            width: "auto",
            height: "auto",
            padding: "0px"
        },
        "& .slick-dots li button::before": {
            width: "40px",
            height: "10px",
            borderRadius: "0px",
            backgroundColor: blackColor,
            top: "auto",
            position: "static",
            display: "block"
        },
        "& .slick-dots li.slick-active button::before": {
            backgroundColor: whiteColor,
        },
        "& .slick-list .slick-slide": {
            opacity: "0",
            maxHeight: "100px",
            "&.slick-active": {
                opacity: "1",
                maxHeight: "none",
            }
        }
    },
    aboutLink: {
        position: "absolute",
        top: "10px",
        right: theme.spacing(2),
        textDecoration: "underline !important",
        fontSize: "14px"
    },
    hiddenSplash: {
        zIndex: "1105"
    },
    snackbar: {
        zIndex: "1600"
    },
    textAlign: {
        textAlign: "center"
    },
    mobileSpacing: {
        "@media (max-width: 480px)": {
            paddingRight: "0px",
            paddingLeft: "0px",      
        }
    },
    profileAvatar: {
        "& img":{
            cursor: "pointer"
        }
    },
    editLink: {
        position: "absolute",
        top: "10px",
        left: theme.spacing(2),
        textDecoration: "underline !important",
        fontSize: "14px"
    },
    previewLabel: {
        color: blackColor,
        top: "22px",
        marginLeft: "0px",
        position: "absolute",
        zIndex: 1110,
        background: "rgba(" + hexToRgb(whiteColor) + ", 0.7)",
        transform: "rotate(-45deg)",
        height: "30px",
        width: "200px",
        textAlign: "center",
        border: "1px solid "+blackColor,
        lineHeight: "30px",
        left: "-65px",
        [theme.breakpoints.down("sm")]: {
            height: "28px",
            lineHeight: "28px",
            top: "8px",
            left: "-82px",
            marginLeft: "0px",
            fontSize: "14px"
        }
    },
    classic: {
        //No Change
    },
    simple: {
        "&$coverPhotoBackground": {
            backgroundImage: "none !important",
            backgroundColor: whiteColor,
        },
        "& $mainRaised": {
            boxShadow: "none",
            marginLeft: "0px",
            marginRight: "0px",
            marginTop: "75px",
            zIndex: "unset",
            [theme.breakpoints.down("sm")]: {
                marginTop: "50px",
            }
        },
        "& $profileAvatar img": {
            maxWidth: "80px",
            height: "80px",
            transform: "none",
            position: "absolute",
            left: "0px",
            top: "-72px",
            right: "0px",
            zIndex: "1110",
            [theme.breakpoints.down("sm")]: {
                maxWidth: "60px",
                height: "60px",
                top: "-54px",
            }
        }
    },
});

export default searchPageStyle;